import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useState } from "react";
import { Link } from "react-router-dom";

const LoginAuthFirst = (props) => {
  const [open, setOpen] = useState(true);
  const onCloseModal = () => setOpen(false);
  const handleCloseModal = () => {
    props.hideLoginModal()
    onCloseModal()
  }

  return (
    <div>
      <Modal
        classNames="loginAuthModal"
        open={open}
        onClose={()=>{
          handleCloseModal()
        }
      }
        center   
      >
        <span className="closeModal" onClick={handleCloseModal}>
          ✖
        </span>
        <div className="content_LAuth">
          <h2>LOGIN REQUIRED</h2>
          <p>Please login to the platform, in order to use the services.</p>
        </div>
        <div className="content_LAuth_action">
          <button type="button" className="btn-cancel" onClick={handleCloseModal}>
            Cancel
          </button>
          <Link to={{pathname: '/login', state:window.location.pathname}} type="button" className="getStarted2 ">
            Login
          </Link>
        </div>
      </Modal>
    </div>
  );
};

export default LoginAuthFirst;
