import Layout from "../../Components/Layout";

const Listing = () => {
  return ( 
          <Layout>
            <div className="registration_Pages">
                <div className="page_header">
                    <h3 className="pageHeading">Table Fields</h3>
                    <div className="action_heading">
                        <button type="button" className="btn btn-small btn-primary">Add New</button>
                    </div>
                </div>
                <div className="table_listing">
                    <div className="table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Name</th>
                              <th>Name</th>
                              <th>Name</th>
                              <th>Name</th>
                              <th>Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                            </tr>
                            <tr>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                            </tr>
                            <tr>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                            </tr>
                            <tr>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                            </tr>
                            <tr>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                            </tr>
                            <tr>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                              <td>Rahul Singh</td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </Layout>
   );
}
 
export default Listing;