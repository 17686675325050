import React, { useState, useContext, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Layout from '../Components/Layout';
import LoginArt from '../assets/images/login-art.png';
import { feathersAuthentication } from '../server/feathers-binding';
import SignUpForm from '../Components/forms/signup-form';
import { AuthContext } from '../contexts/authContext';
import services from '../server/services';
import { Helmet } from 'react-helmet';
import { LayoutForLoginPages } from '../Components/LayoutForLoginPages';
import TimezoneSelect from 'react-timezone-select';

const SignUpPage = (props) => {
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const [created, setCreated] = useState(false);
  const [error, setError] = useState('');
  const [validationErr, setValidationErr] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [terms, setTerms] = useState(false);

  const submit = async (data) => {
    try {
      let verifyToken = localStorage.getItem("verifyToken")

      if (verifyToken) {
        return ""
      }
      setLoading(true);
      data.role = 'user';
      let validateData = validateFormData(data);
      if (Object.keys(validateData).length > 0) {
        setValidationErr(validateData);
      } else {
        setValidationErr('');
        let cognitoRes = await authContext.signUpWithEmail(
          // data.email,
          // data.email,
          // data.password
          `${data.target.fName.value}${data.target.lName.value}`,
          data.target.email.value,
          data.target.password.value
        );
        if (cognitoRes) {
          data.timezone = selectedTimezone;
          let serverRes = await services['users'].create(data);
          if (serverRes.action.type === 'SERVICES_USERS_CREATE_FULFILLED') {
            toast.success('User signup successfully', {
              hideProgressBar: true,
              autoClose: 2000,
            });
            props.history.push('/verify-email');
          } else {
            throw new Error(
              'Something went wrong. Please contact us if getting issue to signup'
            );
          }
        }
      }
      setLoading(false);
    } catch (err) {
      toast.error(err.message, { hideProgressBar: true, autoClose: 2000 });
      setLoading(false);
      setError(err.message);
    }
  };

  localStorage.setItem("verifyToken", true)
  useEffect(() => {
    const script = document.createElement("script");
    const REACT_APP_CYBERSIARA_PUBLIC_KEY =
      process.env.REACT_APP_CYBERSIARA_PUBLIC_KEY;

    script.innerHTML = `
      $(function () {
        var PublicKey = "${REACT_APP_CYBERSIARA_PUBLIC_KEY}";
        InitCaptcha(PublicKey);
        $('.CaptchaSubmit').click(function () {
          if (CheckCaptcha()) {
            // console.log(CyberSiaraToken);
            localStorage.removeItem("verifyToken")
          }
        });
      });
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  let validateFormData = (data) => {
    let error = {};
    if (!data.target.fName.value || data.target.fName.value.trim().length === 0) {
      error.fName = 'This field is required';
    }
    if (!data.target.lName.value || data.target.lName.value.trim().length === 0) {
      error.lName = 'This field is required';
    }
    if (!data.target.email.value || data.target.email.value.trim().length === 0) {
      error.email = 'This field is required';
    }
    if (!data.target.password.value || data.target.password.value.trim().length === 0) {
      error.password = 'This field is required';
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{9,}$/.test(data.target.password.value)
    ) {
      error.password =
        'Password must follow the policy i.e. should be more than 8 character, At least one uppercase letter, At least one lowercase letter, At least one digit, At least one special symbol';
    }
    if (!data.target.confirmPassword.value || data.target.confirmPassword.value.trim().length === 0) {
      error.confirmPassword = 'This field is required';
    }
    if (
      data.target.password.value &&
      data.target.confirmPassword.value &&
      data.target.confirmPassword.value !== data.target.password.value
    ) {
      error.confirmPassword = "Password doesn't matched";
    }
    if (!selectedTimezone.label) {
      error.timezone = 'This field is required';
    }

    return error;
  };
  // let validateFormData = (data) => {
  //   let error = {};
  //   if (!data.firstName || data.firstName.trim().length === 0) {
  //     error.firstName = 'This field is required';
  //   }
  //   if (!data.lastName || data.lastName.trim().length === 0) {
  //     error.lastName = 'This field is required';
  //   }
  //   if (!data.email || data.email.trim().length === 0) {
  //     error.email = 'This field is required';
  //   }
  //   if (!data.password || data.password.trim().length === 0) {
  //     error.password = 'This field is required';
  //   } else if (
  //     !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{9,}$/.test(data.password)
  //   ) {
  //     error.password =
  //       'Password must follow the policy i.e. should be more than 8 character, At least one uppercase letter, At least one lowercase letter, At least one digit, At least one special symbol';
  //   }
  //   if (!data.confirmPassword || data.confirmPassword.trim().length === 0) {
  //     error.confirmPassword = 'This field is required';
  //   }
  //   if (
  //     data.password &&
  //     data.confirmPassword &&
  //     data.confirmPassword !== data.password
  //   ) {
  //     error.confirmPassword = "Password doesn't matched";
  //   }
  //   if (!selectedTimezone.label) {
  //     error.timezone = 'This field is required';
  //   }

  //   return error;
  // };

  let propsforLoginPage = {
    sectionVals: { subHead: 'REGISTRATION', head: 'CREATE A NEW ACCOUNT' },
    headingHero: 'Sign Up',
    sectionPara: 'Enter your details to create a new account and access our services.',
    sectionHeading: "REGISTER your AcCOUNT",

  }

  try {
    let prevMetaDesc = document.getElementById("baseDescription")
    prevMetaDesc.remove()
    //use the piece of loading state to return other component until you have the data
  } catch (error) {
  }
  const [togglePass, settogglePass] = useState(false)
  const [togglePass1, settogglePass1] = useState(false)

  return (
    <>
      <Helmet>
        <title>Sign-up to Legateca for Legal Advice Uk</title>
        <link rel="canonical" href="https://legateca.com/signup" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta name="title" content="Sign-up to Legateca for Legal Advice Uk" />
        <meta name="description" content="Sign up now on Legateca to meet your legal needs, including automatic recommendations, finding mediators and lawyers, and making appointments and paying."></meta>
      </Helmet>
      <LayoutForLoginPages
        {...propsforLoginPage}
      >
        <div className='pt-[26px] pb-[36px] 3xl:py-[40px] lg:px-[40px] px-[20px] w-full lg:max-w-[500px] 3xl:min-w-[1200px] 3xl:max-w-[1200px] bg-white rounded-[8px]'>
          <div className='text-[#006766] 3xl:text-[60px] text-[28px] not-italic font-medium leading-[48px] tracking-[0.56px] uppercase'>
            REGISTER YOUR ACCOUNT
          </div>
          <form onSubmit={(e) => {
            e.preventDefault()
            submit(e)
          }}>
            <div className='pt-[30px]  3xl:pt-[50px] flex flex-col gap-6'>

              <div className='w-full flex lg:flex-row flex-col gap-6  lg:justify-between items-center'>
                <LoginInput
                  required
                  label="First Name"
                  name="fName"
                  type="text"
                  placeHolder="John"
                  error={validationErr.fName && <div className='text-[red] text-sm 3xl:text-[28px]'>
                    {validationErr.fName}
                  </div>}
                />
                <LoginInput
                  required
                  label="Last Name"
                  name="lName"
                  type="text"
                  placeHolder="Doe"
                  error={validationErr.lName && <div className='text-[red] text-sm 3xl:text-[28px]'>
                    {validationErr.lName}
                  </div>}
                />
              </div>
              <LoginInput
                required
                label="Enter your email address"
                name="email"
                placeHolder="mail@abc.com"
                error={validationErr.email && <div className='text-[red] text-sm 3xl:text-[28px]'>
                  {validationErr.email}
                </div>}
              />
              <div className='w-full flex lg:flex-row flex-col gap-6  lg:justify-between items-center'>
                <div className='w-full relative'>
                  <div className='text-[#707070] 3xl:text-[30px] text-sm not-italic font-semibold !leading-normal'>
                    Enter Password
                  </div>
                  <input
                    required
                    name={'password'}
                    type={togglePass ? 'text' : 'password'}
                    placeholder={"***********"}
                    className='w-full 3xl:text-[30px] text-sm rounded-[5px] px-[10px] py-[14px] 3xl:py-[30px] 3xl:p-[20px] bg-transparent border border-[#aeaeae] ' />
                  {!togglePass ? <svg onClick={() => settogglePass(pr => !pr)} className='cursor-pointer absolute right-2 top-10' width={14} height={11} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.76953 1.90039C4.64844 1.23633 5.74219 0.708984 7.08984 0.708984C8.65234 0.708984 9.92188 1.43164 10.8398 2.29102C11.7578 3.13086 12.3633 4.14648 12.6562 4.84961C12.7148 5.00586 12.7148 5.18164 12.6562 5.33789C12.4023 5.96289 11.875 6.86133 11.0938 7.64258L13.1445 9.26367C13.3594 9.41992 13.3984 9.71289 13.2227 9.9082C13.0664 10.123 12.7734 10.1621 12.5781 9.98633L1.01562 0.923828C0.800781 0.767578 0.761719 0.474609 0.9375 0.279297C1.09375 0.0644531 1.38672 0.0253906 1.58203 0.201172L3.76953 1.90039ZM4.53125 2.50586L5.42969 3.20898C5.87891 2.81836 6.44531 2.58398 7.08984 2.58398C8.45703 2.58398 9.58984 3.7168 9.58984 5.08398C9.58984 5.51367 9.47266 5.9043 9.29688 6.23633L10.3516 7.05664C11.0156 6.39258 11.4844 5.63086 11.7383 5.08398C11.4648 4.49805 10.957 3.67773 10.1953 2.97461C9.39453 2.23242 8.35938 1.64648 7.08984 1.64648C6.09375 1.64648 5.23438 1.99805 4.53125 2.50586ZM8.53516 5.65039C8.61328 5.47461 8.65234 5.2793 8.65234 5.08398C8.65234 4.22461 7.94922 3.52148 7.08984 3.52148C7.07031 3.52148 7.05078 3.52148 7.03125 3.52148C7.07031 3.63867 7.08984 3.73633 7.08984 3.83398C7.08984 4.04883 7.03125 4.22461 6.95312 4.40039L8.53516 5.65039ZM8.73047 8.18945L9.55078 8.83398C8.82812 9.22461 8.00781 9.45898 7.08984 9.45898C5.50781 9.45898 4.23828 8.75586 3.32031 7.89648C2.40234 7.03711 1.79688 6.02148 1.50391 5.33789C1.44531 5.18164 1.44531 5.00586 1.50391 4.84961C1.69922 4.40039 2.01172 3.83398 2.46094 3.24805L3.18359 3.83398C2.83203 4.2832 2.57812 4.73242 2.42188 5.08398C2.69531 5.66992 3.20312 6.50977 3.96484 7.21289C4.76562 7.95508 5.80078 8.52148 7.08984 8.52148C7.67578 8.52148 8.22266 8.4043 8.73047 8.18945ZM4.58984 5.08398C4.58984 5.04492 4.58984 4.98633 4.58984 4.92773L5.68359 5.78711C5.87891 6.19727 6.26953 6.50977 6.73828 6.60742L7.83203 7.48633C7.59766 7.54492 7.34375 7.58398 7.07031 7.58398C5.70312 7.58398 4.57031 6.4707 4.57031 5.08398H4.58984Z" fill="#707070" />
                  </svg> :
                    <svg onClick={() => settogglePass(pr => !pr)} className='cursor-pointer absolute right-2 top-10' width={14} height={11} viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.96484 4.74023C3.96484 3.37305 5.07812 2.24023 6.46484 2.24023C7.83203 2.24023 8.96484 3.37305 8.96484 4.74023C8.96484 6.12695 7.83203 7.24023 6.46484 7.24023C5.07812 7.24023 3.96484 6.12695 3.96484 4.74023ZM6.46484 6.30273C7.32422 6.30273 8.02734 5.61914 8.02734 4.74023C8.02734 3.88086 7.32422 3.17773 6.46484 3.17773C6.44531 3.17773 6.42578 3.17773 6.40625 3.17773C6.44531 3.29492 6.46484 3.39258 6.46484 3.49023C6.46484 4.19336 5.89844 4.74023 5.21484 4.74023C5.09766 4.74023 5 4.74023 4.90234 4.70117C4.90234 4.7207 4.90234 4.74023 4.90234 4.74023C4.90234 5.61914 5.58594 6.30273 6.46484 6.30273ZM2.69531 1.94727C3.61328 1.08789 4.88281 0.365234 6.46484 0.365234C8.02734 0.365234 9.29688 1.08789 10.2148 1.94727C11.1328 2.78711 11.7383 3.80273 12.0312 4.50586C12.0898 4.66211 12.0898 4.83789 12.0312 4.99414C11.7383 5.67773 11.1328 6.69336 10.2148 7.55273C9.29688 8.41211 8.02734 9.11523 6.46484 9.11523C4.88281 9.11523 3.61328 8.41211 2.69531 7.55273C1.77734 6.69336 1.17188 5.67773 0.878906 4.99414C0.820312 4.83789 0.820312 4.66211 0.878906 4.50586C1.17188 3.80273 1.77734 2.78711 2.69531 1.94727ZM6.46484 1.30273C5.17578 1.30273 4.14062 1.88867 3.33984 2.63086C2.57812 3.33398 2.07031 4.1543 1.79688 4.74023C2.07031 5.32617 2.57812 6.16602 3.33984 6.86914C4.14062 7.61133 5.17578 8.17773 6.46484 8.17773C7.73438 8.17773 8.76953 7.61133 9.57031 6.86914C10.332 6.16602 10.8398 5.32617 11.1133 4.74023C10.8398 4.1543 10.332 3.33398 9.57031 2.63086C8.76953 1.88867 7.73438 1.30273 6.46484 1.30273Z" fill="#707070" />
                    </svg>}

                </div>
                <div className='w-full'>
                  <div className='flex flex-col items-start relative'>
                    <div className='text-[#707070] 3xl:text-[30px] text-sm not-italic font-semibold !leading-normal'>
                      Confirm Password
                    </div>
                    <input
                      required
                      name={'confirmPassword'}
                      type={togglePass1 ? 'text' : 'password'}
                      placeholder={"***********"}
                      className='w-full 3xl:text-[30px] text-sm rounded-[5px] px-[10px] py-[14px] 3xl:py-[30px] 3xl:p-[20px] bg-transparent border border-[#aeaeae] ' />
                    {!togglePass1 ? <svg onClick={() => settogglePass1(pr => !pr)} className='cursor-pointer absolute right-2 top-10' width={14} height={11} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.76953 1.90039C4.64844 1.23633 5.74219 0.708984 7.08984 0.708984C8.65234 0.708984 9.92188 1.43164 10.8398 2.29102C11.7578 3.13086 12.3633 4.14648 12.6562 4.84961C12.7148 5.00586 12.7148 5.18164 12.6562 5.33789C12.4023 5.96289 11.875 6.86133 11.0938 7.64258L13.1445 9.26367C13.3594 9.41992 13.3984 9.71289 13.2227 9.9082C13.0664 10.123 12.7734 10.1621 12.5781 9.98633L1.01562 0.923828C0.800781 0.767578 0.761719 0.474609 0.9375 0.279297C1.09375 0.0644531 1.38672 0.0253906 1.58203 0.201172L3.76953 1.90039ZM4.53125 2.50586L5.42969 3.20898C5.87891 2.81836 6.44531 2.58398 7.08984 2.58398C8.45703 2.58398 9.58984 3.7168 9.58984 5.08398C9.58984 5.51367 9.47266 5.9043 9.29688 6.23633L10.3516 7.05664C11.0156 6.39258 11.4844 5.63086 11.7383 5.08398C11.4648 4.49805 10.957 3.67773 10.1953 2.97461C9.39453 2.23242 8.35938 1.64648 7.08984 1.64648C6.09375 1.64648 5.23438 1.99805 4.53125 2.50586ZM8.53516 5.65039C8.61328 5.47461 8.65234 5.2793 8.65234 5.08398C8.65234 4.22461 7.94922 3.52148 7.08984 3.52148C7.07031 3.52148 7.05078 3.52148 7.03125 3.52148C7.07031 3.63867 7.08984 3.73633 7.08984 3.83398C7.08984 4.04883 7.03125 4.22461 6.95312 4.40039L8.53516 5.65039ZM8.73047 8.18945L9.55078 8.83398C8.82812 9.22461 8.00781 9.45898 7.08984 9.45898C5.50781 9.45898 4.23828 8.75586 3.32031 7.89648C2.40234 7.03711 1.79688 6.02148 1.50391 5.33789C1.44531 5.18164 1.44531 5.00586 1.50391 4.84961C1.69922 4.40039 2.01172 3.83398 2.46094 3.24805L3.18359 3.83398C2.83203 4.2832 2.57812 4.73242 2.42188 5.08398C2.69531 5.66992 3.20312 6.50977 3.96484 7.21289C4.76562 7.95508 5.80078 8.52148 7.08984 8.52148C7.67578 8.52148 8.22266 8.4043 8.73047 8.18945ZM4.58984 5.08398C4.58984 5.04492 4.58984 4.98633 4.58984 4.92773L5.68359 5.78711C5.87891 6.19727 6.26953 6.50977 6.73828 6.60742L7.83203 7.48633C7.59766 7.54492 7.34375 7.58398 7.07031 7.58398C5.70312 7.58398 4.57031 6.4707 4.57031 5.08398H4.58984Z" fill="#707070" />
                    </svg> :
                      <svg onClick={() => settogglePass1(pr => !pr)} className='cursor-pointer absolute right-2 top-10' width={14} height={11} viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.96484 4.74023C3.96484 3.37305 5.07812 2.24023 6.46484 2.24023C7.83203 2.24023 8.96484 3.37305 8.96484 4.74023C8.96484 6.12695 7.83203 7.24023 6.46484 7.24023C5.07812 7.24023 3.96484 6.12695 3.96484 4.74023ZM6.46484 6.30273C7.32422 6.30273 8.02734 5.61914 8.02734 4.74023C8.02734 3.88086 7.32422 3.17773 6.46484 3.17773C6.44531 3.17773 6.42578 3.17773 6.40625 3.17773C6.44531 3.29492 6.46484 3.39258 6.46484 3.49023C6.46484 4.19336 5.89844 4.74023 5.21484 4.74023C5.09766 4.74023 5 4.74023 4.90234 4.70117C4.90234 4.7207 4.90234 4.74023 4.90234 4.74023C4.90234 5.61914 5.58594 6.30273 6.46484 6.30273ZM2.69531 1.94727C3.61328 1.08789 4.88281 0.365234 6.46484 0.365234C8.02734 0.365234 9.29688 1.08789 10.2148 1.94727C11.1328 2.78711 11.7383 3.80273 12.0312 4.50586C12.0898 4.66211 12.0898 4.83789 12.0312 4.99414C11.7383 5.67773 11.1328 6.69336 10.2148 7.55273C9.29688 8.41211 8.02734 9.11523 6.46484 9.11523C4.88281 9.11523 3.61328 8.41211 2.69531 7.55273C1.77734 6.69336 1.17188 5.67773 0.878906 4.99414C0.820312 4.83789 0.820312 4.66211 0.878906 4.50586C1.17188 3.80273 1.77734 2.78711 2.69531 1.94727ZM6.46484 1.30273C5.17578 1.30273 4.14062 1.88867 3.33984 2.63086C2.57812 3.33398 2.07031 4.1543 1.79688 4.74023C2.07031 5.32617 2.57812 6.16602 3.33984 6.86914C4.14062 7.61133 5.17578 8.17773 6.46484 8.17773C7.73438 8.17773 8.76953 7.61133 9.57031 6.86914C10.332 6.16602 10.8398 5.32617 11.1133 4.74023C10.8398 4.1543 10.332 3.33398 9.57031 2.63086C8.76953 1.88867 7.73438 1.30273 6.46484 1.30273Z" fill="#707070" />
                      </svg>}


                  </div>
                </div>
                {/* <LoginInput
                label="Enter Password"
                name="password"
                placeHolder="***********"
              />
              <LoginInput
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                placeHolder="***********"

              /> */}
              </div>
              {validationErr.password && <div className='text-[red] text-sm 3xl:text-[28px]'>
                {validationErr.password}
              </div>}
              <div>
                <div className='text-[#707070] 3xl:text-[30px] capitalize text-sm not-italic font-semibold !leading-normal'>
                  Select your timezone
                </div>
                <TimezoneSelect
                  name='timezone'
                  value={selectedTimezone}
                  className='w-full h-full'
                  onChange={(e) => setSelectedTimezone(e)}
                />
              </div>
            </div>
            <div className='flex items-center mt-2 mb-4 gap-1'>
              <input type="checkbox" id="terms" onChange={() => setTerms(!terms)} />
              <label for="terms">
                {" "}
                <a className='text-sm 3xl:text-[30px] not-italic font-normal leading-[26px] tracking-[0.28px] text-primary-tw text-decoration-none' href="/terms-and-conditions" target="_blank">
                  {" "}
                  I agree to T&Cs and Privacy Policy.
                </a>
              </label>
              <style >
                {`
  input[type="checkbox"]#terms {
    appearance: none; /* Remove default styles */
    width: 1.4em; /* Set the width of the checkbox */
    height: 1.4em; /* Set the height of the checkbox */
    border: 2px solid #000; /* Set border color */
    border-radius: 0.25em; /* Set border radius */
    outline: none; /* Remove outline on focus */
    transition: background-color 0.3s; /* Add transition for background color change */
  }
  input[type="checkbox"]#terms:checked {
    background-color: #E3B576; /* Set background color to white when checked */
  }
  input[type="checkbox"]#terms:checked::before {
    content: '\\2713'; /* Unicode escape sequence for checkmark icon */
    font-size: 1.2em; /* Set font size */
    color: #fff; /* Set color to white */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`}
              </style>
            </div>
            <div className="SiaraShield centered "></div>
            <button type="submit" className='bg-[#006766] CaptchaSubmit 3xl:py-[40px] py-[20px] text-white text-center 3xl:text-[30px] text-sm not-italic hover:!bg-[#e3b576] !border-none font-medium rounded-[6px] !mt-[30px] !mb-[20px] tracking-[0.28px] uppercase'>Sign up</button>
          </form>


          <div className='w-full mt-3 3xl:max-w-[420px] max-w-[192px] flex justify-between items-center mx-auto'>
            <span className='text-[#828282] 3xl:text-[30px] text-sm not-italic font-normal leading-[normal]'>
              Already Registered?
            </span>
            <span onClick={() => window.location.replace('/login')} className='text-[#006766] hover:underline cursor-pointer 3xl:text-[30px] text-sm not-italic font-semibold leading-[normal]'>
              Login
            </span>
          </div>
        </div>

      </LayoutForLoginPages>
    </>
  )
  // return (
  //   <Layout>
  //     <div>
  // <Helmet>
  //   <title>Sign-up to Legateca for Legal Advice Uk</title>
  //   <link rel="canonical" href="https://legateca.com/signup" />
  //   <meta name="robots" content="INDEX,FOLLOW" />
  //   <meta name="title" content="Sign-up to Legateca for Legal Advice Uk" />
  //   <meta name="description" content="Sign up now on Legateca to meet your legal needs, including automatic recommendations, finding mediators and lawyers, and making appointments and paying."></meta>
  // </Helmet>
  //     </div>
  //     <div className="page-breadcumb-area">
  //       <div className="container">
  //         <div className="row">
  //           <div className="col-12">
  //             <div className="wpo-breadcumb-wrap">
  //               <h2>Sign Up</h2>
  //               <ul>
  //                 <li>
  //                   <a href="/">Home</a>
  //                 </li>
  //                 <li>
  //                   <span>&nbsp; / &nbsp;</span>
  //                   <span>Sign Up</span>
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="page-content ">
  //       <div className="registration_Pages centerRegiPage container">
  //         <div className="registration-form-container">
  //           <div className="register_art_fame">
  //             <img src={LoginArt} alt="Art" />
  //             <div className="login-content">
  //               <p>
  //                 Legateca - Personalised and accessible solution for your legal
  //                 needs
  //               </p>
  //               <p>Automatic recommendations</p>
  //               <p>Find mediators and lawyers</p>
  //               <p>Pay and book appointments</p>
  //             </div>
  //           </div>
  //           <div className="registration-form">
  //             <h1>Sign Up</h1>
  //             <SignUpForm
  //               onSubmit={submit}
  //               loading={loading}
  //               error={validationErr}
  //               ValidationError={validationErr}
  //               changeTimezone={(e) => setSelectedTimezone(e)}
  //               selectedTimezone={selectedTimezone}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       {/* <ToastContainer/> */}
  //     </div>
  //   </Layout>
  // );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  authenticate: feathersAuthentication.authenticate,
})(SignUpPage);

const LoginInput = ({ label, error, placeHolder, name, type, }) => {
  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className='flex flex-col items-start'>
        <div className='text-[#707070] 3xl:text-[30px] capitalize text-sm not-italic font-semibold !leading-normal'>
          {label}
        </div>
        <input required name={name} type={type ? type : name} placeholder={placeHolder} className='w-full 3xl:text-[30px] text-sm rounded-[5px] px-[10px] py-[14px] 3xl:py-[30px] 3xl:p-[20px] bg-transparent border border-[#aeaeae] ' />
      </div>
      {error}
      {/* {validationErr.email && <div className='text-[red] text-sm 3xl:text-[28px]'>
        {validationErr.email}
      </div>} */}
    </div>
  )
}