import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout";
import { Helmet } from "react-helmet";
import { HeroSection } from "../About";
import Footer from "../../Components/Footer";

export const Anchor = ({ href, children, mail }) => {
  return <a className='text-primary-tw font-montserrat' href={href} target={mail ? '' : "_blank"
  } rel="noreferrer" >
    {children}
  </a >
}

const Privacy = (props) => {
  // useEffect(() => {
  //   window.scroll(0, 0);
  //   return () => { };
  // }, []);

  try {
    let prevMetaDesc = document.getElementById("baseDescription");
    prevMetaDesc.remove();
    //use the piece of loading state to return other component until you have the data
  } catch (error) { }
  const [selectedPara, setselectedPara] = useState(0)
  const arrayOfContents = [
    'What kind of data is collected by us?',
    'What kind of data is not collected by us?',
    'What is the purpose of data collection?',
    'In what manner do we collect the data?',
    'Who can access your personal data that we collect?',
    'How long do we hold your data for?',
    'Cookies',
    'Issues or Enquiries',
    'Changes to this policy',
  ]
  return (
    <>
      <Helmet>
        <title>Privacy-Policy | Legateca</title>
        <link rel="canonical" href="https://legateca.com/privacy-policy" />
        <meta name="robots" content="INDEX,FOLLOW" />
        {/* <meta name="title" content="Privacy-Policy | Legateca " /> */}
        <meta name="title" content="Privacy-Policy | Legateca | Legateca " />
        <meta
          name="description"
          // content="Our Website uses cookies to identify you as a unique user. Legateca uses this information to improve the Service and the Website and to provide you with an enjoyable browsing experience."
          content="Our Website uses cookies to identify you as a unique user. Legateca uses this information to improve the Service and the Website ."
        ></meta>
      </Helmet>
      <HeroSection
        heading="Privacy Policy"
        bgImg={'./privacy.png'} />

      <main className="xl:py-[100px] py-[70px] xl:px-0 px-10 w-full xl:max-w-[1240px] 3xl:max-w-[2400px] mx-auto font-montserrat">
        <section className="flex justify-between xl:gap-0 gap-10 w-full xl:flex-row flex-col-reverse">
          <div className="w-full xl:max-w-[818px] 3xl:max-w-[1520px] flex flex-col xl:gap-[46px] gap-5">
            <div className="text-[#006766] xl:max-w-[776px] 3xl:max-w-[1520px] text-values-align text-2xl 3xl:text-[60px] not-italic font-medium !leading-[31px] 3xl:!leading-[130%] tracking-[0.48px] uppercase">
              This privacy policy will help you better understand how we collect, use, share your personal information
            </div>
            <SectionCard head='privacy policy'>
              <Description>
                Legateca is an official trading name for AIWON BUSINESS SERVICES LTD, a company registered in England and Wales under company number 11412650 whose registered address is at 41 Heather Drive Thatcham RG18 4BU, UK. Aiwon Business Services Ltd is a Data controller for the purpose of the Data Protection Act 2018 and the UK General Data Protection Regulation as amended or supplemented (The ‘Data Protection Laws’).
              </Description>
              <Description>
                Legateca is an online legal SaaS portal. We help our customers to manage legal issues, difficulties in their lives. This privacy policy is intended to provide our firm commitment to the privacy of the details/data of our customers that they provide us.
              </Description>
              <Description>
                Your privacy and data are of utmost importance to us. We want to assure you that our use of data obtained via Google APIs strictly adheres to Google's API Services User Data Policy, including the Limited Use requirements. We utilize this data solely to enhance your experience by providing and improving the user-facing features within our application. Rest assured, your data is never transferred or used for purposes other than those explicitly agreed upon, ensuring your privacy and security. For more information, please refer to the Google API Services User Data Policy.

              </Description>
              <Description>
                We recommend that you read this Privacy Notice in full to ensure you are fully informed.

              </Description>
            </SectionCard>

            <SectionCard id={0} head='What kind of data is collected by us?'>
              <Description>
                As a Legateca customer we may collect data such as - Personal data including name, gender, marital status, partner’s name, email address, postal address, children’s names, Date of Births; Your own data that you may upload or submit to Legateca.              </Description>
              <Description>
                Any other information that you choose to send to us, including any request for further Legateca Services or for general communication.</Description>
              <Description>

                Legally relevant information for the purposes of assisting and guiding you to pursue your legal dispute resolution.
              </Description>
              <Description>
                De-personalised data which does not record personal data such as the date of your use of the Legateca website, the route used, the browser used and the IP address etc.
              </Description>
            </SectionCard>

            <SectionCard id={1} head='What kind of data is not collected by us?'>
              <Description>
                Financial data – we use Stripe as our payment provider and collect relevant payments data to process payments with Stripe (see Stripe’s terms and conditions for more detail). No credit/debit card information is ever stored in any of our digital or manual data repository. Any payment transactions are encrypted using SSL technology.
              </Description>

              <Description>
                We use Stripe as our identity verification provider and collect relevant data (see <Anchor href="https://stripe.com/en-gb/legal/identity">Stripe's Identity terms and conditions</Anchor> )
              </Description>
              <Description>
                Our application is hosted on Amazon Web Services, and we use some of their services (see <Anchor href={'https://aws.amazon.com/privacy/'}>
                  AWS Privacy policy
                </Anchor>)
              </Description>
            </SectionCard>
            <SectionCard id={2} head='What is the purpose of data collection?'>
              <Description>
                We use collected data for lawful purposes only. We may use data that we collect about You for registration on our website, managing your account, providing access to our website and services. We may use Technical Information, Identity Information, Contact Information or Profile information for taking consent, performance of a contract, step prior to entering a contract, complying with legal obligation or as part of legitimate interest confirmation.
              </Description>
            </SectionCard>
            <SectionCard id={3} head='In what manner do we collect the data?'>
              <Description>
                We may collect the data in the following ways of interaction: - On our website <Anchor href='https://legateca.com'>https://legateca.com</Anchor> , via our chat app on the website, by email or by phone.
              </Description>
            </SectionCard>

            <SectionCard id={4} head='Who can access your personal data that we collect?'>
              <Description>
                None of your personal dated is shared with any third parties or external sources, except in following scenarios;
                If we sell, transfer, or merge parts of our business or assets, your personal data may be transferred to a third party. Any new owner of our business may continue to use your personal data in the same way(s) that we have used it, as specified in this Privacy Policy.</Description>
              <Description>
                If We have your consent to do so, We may transfer your personal data outside of the UK or EEA in order to deliver Our Site and Apps to you, as well as any of Our Services.
              </Description>
              <Description>
                In some limited circumstances, We may be legally required to share certain personal data, which might include yours, if We are involved in legal proceedings or complying with legal obligations, a court order, or a request of a government authority; to assert legal rights or defend against legal claims; or to prevent, detect, or investigate illegal activity, fraud, abuse, violations of our terms of use or contracts of sale, or threats to the security of Our services or the physical safety of any person.
              </Description>
              <Description>
                We may sometimes contract with the following third parties to supply certain products and services. Those third-party data processors are bound by strict confidentiality and data security provisions, including (where relevant) a data processing agreement or addendum, and they can only use your data in the ways specified by Us.
              </Description>
              <Description>
                With partners, suppliers, agents and subcontractors who are essential in delivering the products and services you’ve chosen to use.
              </Description>
              <Description>
                We may share aggregated data for commercial reporting or research purposes that does not personally identify you, but which shows general trends in the legal marketplace and of Our/others products and services.
              </Description>
            </SectionCard>
            <SectionCard id={5} head='How long do we hold your data for?'>
              <Description>
                Your details will be held on our servers for 2 years following the last time you contacted us or from the conclusion of your service. This is to ensure that if you require any data in relation to your case, we can access this for you. We will not use your data for any other purpose than to process your service              </Description>

              <Description>
                Any Legateca customer or customer statistics that we may provide to prospective Legateca customers or partners are provided in the aggregate only and do not include any individually identifiable data.              </Description>
            </SectionCard>
            <SectionCard id={6} head='Cookies'>
              <Description>
                We use cookies to distinguish you from other users of the Website. This helps us provide you with a good experience when you browse the Website and allows Legateca to improve the Website and service. We use our own and third-party cookies for different purposes.
              </Description>
              <Description>
                Cookies are a tool used by web servers to store and retrieve information about their visiting users. Cookies may be small text files that are sent to your computer, tablet or any other device that allows you to browse the internet when you access certain web pages, or other similar technologies whose functionality is the same. Cookies allow, among other things, to keep a record of your browsing habits or your equipment, your preferences or your information to log in and remember them when you return. For more information about cookies and how they work see <Anchor href='https://allaboutcookies.org/'>https://www.allaboutcookies.org/</Anchor>
              </Description>
              <Description>
                By using legateca.com, you agree that we can place the following types of cookies on your device.
                Strictly necessary cookies – These cookies make our Site work. These cookies are essential to enable you to move around our Site and use our features, such as accessing secure areas. Without these cookies, we would not be able to remember your previous actions, determine whether you are logged in or not or enable you to see the electronic programme guide tailored to your chosen provider/region.
              </Description>
              <Description>
                Performance cookies – These cookies improve the performance of our Site. These cookies collect information about how visitors use our Site, for instance which pages visitors go to most often, and if visitors get error messages from web pages. These cookies don’t identify you personally. All information these cookies collect is aggregated and anonymous. These cookies enable us to manage errors and the performance and design of the Site, to track ad response rates and your visits to our affiliate websites, and to show you which shows are most popular.
              </Description>
              <Description>
                Functionality cookies – These cookies save you time. These cookies remember your username and can also be used to remember settings that you can customise. These cookies allow us to remember choices you make (e.g. whether you want to share your activity on Facebook) and provide enhanced, more personal features. They may also be used to provide services you have asked for such as watching a video or commenting. Without these cookies, we won’t be able to distinguish you from other users and, as a result, save you time.
              </Description>
              <Description>
                Advertising cookies: These cookies are those that allow, through certain data assigned or obtained from a user's device, to store or share with third parties, information on the behaviour of users, obtained through the observation of their browsing habits, which allows you to develop a specific profile. We use our own and/or third-party cookies in order, through the information received from the users of our web portal, to be able to improve and optimize our advertising campaigns on their platforms.              </Description>
              <Description>
                <div className="flex flex-col items-start">
                  <span>We use the following third party services.</span>
                  <span>Stripe payment and identity - <Anchor href='https://stripe.com/cookies-policy/legal'>https://stripe.com/cookies-policy/legal</Anchor> </span>
                  <span>Google analytics and ads - <Anchor href='https://policies.google.com/privacy' >https://policies.google.com/privacy</Anchor> </span>
                  <span>Facebook ads -<Anchor href='https://www.facebook.com/about/privacy/'> https://www.facebook.com/about/privacy/</Anchor> </span>
                  <span>LinkedIn -<Anchor href='https://www.linkedin.com/legal/cookie-policy'>
                    https://www.linkedin.com/legal/cookie-policy
                  </Anchor> </span>
                </div>

              </Description>
            </SectionCard>
            <SectionCard id={7} head='Issues or Enquiries'>
              <Description>
                Legateca tries to meet the best of standards while collecting and using personal data. For this reason, we take any Legateca customer or customer issues/complaints we receive very seriously. We appreciate if you bring it to our notice if you think that our collection or use of personal data is unfair, misleading or inappropriate. - You have the right to request any personal information held by us that relates to the Right of Access under the UK GDPR. - You have the right to modify any personal information held by us that relates to the Right of Access under the UK GDPR. - You also have the right to have any personal information held by us deleted at any time that relates to the Right of Access under the UK GDPR. Please note this may affect the services provided by us, to you if you choose to delete your personal information whilst using Legateca's services. It's also important to state that the Right of deletion only applies in certain circumstances.
              </Description>

              <Description>
                To contact us or to make a request to view any data that we may hold then either email dpo@legateca.com or write to: Legateca Compliance Department, 41 Heather Drive Thatcham RG18 4BU, UK. If you are not happy with the way in which your personal data is being handled by Legateca, you can complain to the Information Commissioner whose details can be found at:<Anchor href='http://www.ico.org.uk/'>http://www.ico.org.uk/</Anchor>
              </Description>

            </SectionCard>
            <SectionCard id={8} head='Changes to this policy'>
              <Description>
                We keep our Policies under regular review. This Policy was last updated in December 2021
              </Description>
            </SectionCard>
          </div>
          <TableOfContents array={arrayOfContents} />
          <div className="w-full xl:block hidden xl:max-w-[378px] 3xl:max-w-[778px] ">
            <div className="text-[#006766] pb-4 text-[22px] 3xl:text-[50px] not-italic font-semibold !leading-[149%] 3xl:!leading-[130%] tracking-[0.44px] uppercase">
              Table of Contents
            </div>
            {
              arrayOfContents.map((it, id) => {
                return <div href={`#heading${id}`} onClick={() => {
                  setselectedPara(id)
                  window.location.replace(`#heading${id}`)

                }} key={id} className={`font-montserrat ${id === selectedPara ? 'text-white bg-[#006766] font-[600]' : 'text-[#252525] border-b font-light border-[#bbb]'}  cursor-pointer  px-[10px] max-3xl:py-4 3xl:px-[40px] 3xl:py-12 text-sm 3xl:text-[30px] not-italic !leading-[149%] 3xl:!leading-[130%] tracking-[0.28px]`}>
                  {`${id + 1}`}. {it}
                </div>
              })
            }
          </div>
        </section>

      </main >

      <Footer />
    </>
  )
  // return (
  //   <Layout>
  //     <div>
  //       <Helmet>
  //         <title>Privacy-Policy | Legateca</title>
  //         <link rel="canonical" href="https://legateca.com/privacy-policy" />
  //         <meta name="robots" content="INDEX,FOLLOW" />
  //         <meta name="title" content="Privacy-Policy | Legateca" />
  //         <meta
  //           name="description"
  //           content="Our Website uses cookies to identify you as a unique user. Legateca uses this information to improve the Service and the Website and to provide you with an enjoyable browsing experience."
  //         ></meta>
  //       </Helmet>
  //     </div>
  //     <section className="page-breadcumb-area">
  //       <div className="container">
  //         <div className="row">
  //           <div className="col-12">
  //             <div className="wpo-breadcumb-wrap">
  //               <h2>Privacy Policy</h2>
  //               <ul>
  //                 <li>
  //                   <Link to="/home">Home</Link>
  //                 </li>
  //                 <li>
  //                   <span>&nbsp; / &nbsp;</span>
  //                   <span>Privacy Policy</span>
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //     <div className="sec-categories">
  //       <section className="content-page policy-content about-box">
  //         <div className="container">
  //           <h1 className="mb-4"> Privacy Policy </h1>
  //           <p>
  //             Legateca is an official trading name for AIWON BUSINESS SERVICES
  //             LTD, a company registered in England and Wales under company
  //             number 11412650 whose registered address is at 41 Heather Drive
  //             Thatcham RG18 4BU, UK. Aiwon Business Services Ltd is a Data
  //             controller for the purpose of the Data Protection Act 2018 and the
  //             UK General Data Protection Regulation as amended or supplemented
  //             (The ‘Data Protection Laws’).
  //           </p>
  //           <p>
  //             Legateca is an online legal SaaS portal. We help our customers to
  //             manage legal issues, difficulties in their lives. This privacy
  //             policy is intended to provide our firm commitment to the privacy
  //             of the details/data of our customers that they provide us.
  //           </p>
  //           <p>
  //             Your privacy and data are of utmost importance to us. We want to
  //             assure you that our use of data obtained via Google APIs strictly
  //             adheres to{" "}
  //             <a
  //               href="https://developers.google.com/terms/api-services-user-data-policy/"
  //               target="_blank"
  //             >
  //               Google's API Services User Data Policy
  //             </a>
  //             , including the Limited Use requirements. We utilize this data
  //             solely to enhance your experience by providing and improving the
  //             user-facing features within our application. Rest assured, your
  //             data is never transferred or used for purposes other than those
  //             explicitly agreed upon, ensuring your privacy and security. For
  //             more information, please refer to the Google API Services User
  //             Data Policy.
  //           </p>
  //           <p>
  //             We recommend that you read this Privacy Notice in full to ensure
  //             you are fully informed.
  //           </p>
  //           <h3>What kind of data is collected by us?</h3>
  //           <p>
  //             As a Legateca customer we may collect data such as - Personal data
  //             including name, gender, marital status, partner’s name, email
  //             address, postal address, children’s names, Date of Births; Your
  //             own data that you may upload or submit to Legateca.{" "}
  //           </p>
  //           <p>
  //             Any other information that you choose to send to us, including any
  //             request for further Legateca Services or for general
  //             communication.{" "}
  //           </p>
  //           <p>
  //             Legally relevant information for the purposes of assisting and
  //             guiding you to pursue your legal dispute resolution.
  //           </p>
  //           <p>
  //             De-personalised data which does not record personal data such as
  //             the date of your use of the Legateca website, the route used, the
  //             browser used and the IP address etc.{" "}
  //           </p>
  //           <h3>What kind of data is not collected by us? - </h3>
  //           <p>
  //             Financial data – we use Stripe as our payment provider and collect
  //             relevant payments data to process payments with Stripe (see{" "}
  //             <a target="_blank" href="https://stripe.com/in/legal#section_c4">
  //               Stripe’s terms and conditions
  //             </a>{" "}
  //             for more detail). No credit/debit card information is ever stored
  //             in any of our digital or manual data repository. Any payment
  //             transactions are encrypted using SSL technology.{" "}
  //           </p>
  //           <p>
  //             We use Stripe as our identity verification provider and collect
  //             relevant data (see{" "}
  //             <a target="_blank" href="https://stripe.com/en-gb/identity/legal">
  //               Stripe's Identity terms and conditions
  //             </a>
  //             )
  //           </p>
  //           <p>
  //             Our application is hosted on Amazon Web Services, and we use some
  //             of their services (see{" "}
  //             <a href="https://aws.amazon.com/privacy/" target="_blank">
  //               AWS Privacy policy
  //             </a>{" "}
  //             ){" "}
  //           </p>
  //           <h3>What is the purpose of data collection?</h3>
  //           <p>
  //             We use collected data for lawful purposes only. We may use data
  //             that we collect about You for registration on our website,
  //             managing your account, providing access to our website and
  //             services. We may use Technical Information, Identity Information,
  //             Contact Information or Profile information for taking consent,
  //             performance of a contract, step prior to entering a contract,
  //             complying with legal obligation or as part of legitimate interest
  //             confirmation.
  //           </p>
  //           <h3>In what manner do we collect the data?</h3>
  //           <p>
  //             We may collect the data in the following ways of interaction: - On
  //             our website{" "}
  //             <a href="https://legateca.com" target="_blank">
  //               https://legateca.com
  //             </a>
  //             , via our chat app on the website, by email or by phone.
  //           </p>
  //           <h3>Who can access your personal data that we collect?</h3>
  //           <p>
  //             None of your personal dated is shared with any third parties or
  //             external sources, except in following scenarios;
  //           </p>
  //           <p>
  //             If we sell, transfer, or merge parts of our business or assets,
  //             your personal data may be transferred to a third party. Any new
  //             owner of our business may continue to use your personal data in
  //             the same way(s) that we have used it, as specified in this Privacy
  //             Policy.
  //           </p>
  //           <p>
  //             If We have your consent to do so, We may transfer your personal
  //             data outside of the UK or EEA in order to deliver Our Site and
  //             Apps to you, as well as any of Our Services.
  //           </p>
  //           <p>
  //             In some limited circumstances, We may be legally required to share
  //             certain personal data, which might include yours, if We are
  //             involved in legal proceedings or complying with legal obligations,
  //             a court order, or a request of a government authority; to assert
  //             legal rights or defend against legal claims; or to prevent,
  //             detect, or investigate illegal activity, fraud, abuse, violations
  //             of our terms of use or contracts of sale, or threats to the
  //             security of Our services or the physical safety of any person.{" "}
  //           </p>
  //           <p>
  //             We may sometimes contract with the following third parties to
  //             supply certain products and services. Those third-party data
  //             processors are bound by strict confidentiality and data security
  //             provisions, including (where relevant) a data processing agreement
  //             or addendum, and they can only use your data in the ways specified
  //             by Us.{" "}
  //           </p>
  //           <p>
  //             With partners, suppliers, agents and subcontractors who are
  //             essential in delivering the products and services you’ve chosen to
  //             use.{" "}
  //           </p>
  //           <p>
  //             We may share aggregated data for commercial reporting or research
  //             purposes that does not personally identify you, but which shows
  //             general trends in the legal marketplace and of Our/others products
  //             and services.{" "}
  //           </p>
  //           <h3>How long do we hold your data for?</h3>
  //           <p>
  //             Your details will be held on our servers for 2 years following the
  //             last time you contacted us or from the conclusion of your service.
  //             This is to ensure that if you require any data in relation to your
  //             case, we can access this for you. We will not use your data for
  //             any other purpose than to process your service
  //           </p>
  //           <p>
  //             Any Legateca customer or customer statistics that we may provide
  //             to prospective Legateca customers or partners are provided in the
  //             aggregate only and do not include any individually identifiable
  //             data.
  //           </p>
  //           <h3>Cookies </h3>
  //           <p>
  //             We use cookies to distinguish you from other users of the Website.
  //             This helps us provide you with a good experience when you browse
  //             the Website and allows Legateca to improve the Website and
  //             service. We use our own and third-party cookies for different
  //             purposes.{" "}
  //           </p>
  //           <p>
  //             Cookies are a tool used by web servers to store and retrieve
  //             information about their visiting users. Cookies may be small text
  //             files that are sent to your computer, tablet or any other device
  //             that allows you to browse the internet when you access certain web
  //             pages, or other similar technologies whose functionality is the
  //             same. Cookies allow, among other things, to keep a record of your
  //             browsing habits or your equipment, your preferences or your
  //             information to log in and remember them when you return. For more
  //             information about cookies and how they work see{" "}
  //             <a href="https://www.allaboutcookies.org/" target="_blank">
  //               https://www.allaboutcookies.org/
  //             </a>
  //           </p>
  //           <p>
  //             By using legateca.com, you agree that we can place the following
  //             types of cookies on your device.
  //           </p>
  //           <p>
  //             Strictly necessary cookies – These cookies make our Site work.
  //             These cookies are essential to enable you to move around our Site
  //             and use our features, such as accessing secure areas. Without
  //             these cookies, we would not be able to remember your previous
  //             actions, determine whether you are logged in or not or enable you
  //             to see the electronic programme guide tailored to your chosen
  //             provider/region.
  //           </p>
  //           <p>
  //             Performance cookies – These cookies improve the performance of our
  //             Site. These cookies collect information about how visitors use our
  //             Site, for instance which pages visitors go to most often, and if
  //             visitors get error messages from web pages. These cookies don’t
  //             identify you personally. All information these cookies collect is
  //             aggregated and anonymous. These cookies enable us to manage errors
  //             and the performance and design of the Site, to track ad response
  //             rates and your visits to our affiliate websites, and to show you
  //             which shows are most popular.
  //           </p>
  //           <p>
  //             Functionality cookies – These cookies save you time. These cookies
  //             remember your username and can also be used to remember settings
  //             that you can customise. These cookies allow us to remember choices
  //             you make (e.g. whether you want to share your activity on
  //             Facebook) and provide enhanced, more personal features. They may
  //             also be used to provide services you have asked for such as
  //             watching a video or commenting. Without these cookies, we won’t be
  //             able to distinguish you from other users and, as a result, save
  //             you time.
  //           </p>
  //           <p>
  //             Advertising cookies: These cookies are those that allow, through
  //             certain data assigned or obtained from a user's device, to store
  //             or share with third parties, information on the behaviour of
  //             users, obtained through the observation of their browsing habits,
  //             which allows you to develop a specific profile. We use our own
  //             and/or third-party cookies in order, through the information
  //             received from the users of our web portal, to be able to improve
  //             and optimize our advertising campaigns on their platforms.
  //           </p>
  //           <p>We use the following third party services.</p>
  //           <p>
  //             Stripe payment and identity -{" "}
  //             <a href="https://stripe.com/cookies-policy/legal" target="_blank">
  //               https://stripe.com/cookies-policy/legal
  //             </a>{" "}
  //           </p>
  //           <p>
  //             Google analytics and ads -
  //             <a href="https://policies.google.com/privacy " target="_blank">
  //               https://policies.google.com/privacy{" "}
  //             </a>{" "}
  //           </p>
  //           <p>
  //             Facebook ads -{" "}
  //             <a
  //               href="https://www.facebook.com/about/privacy/ "
  //               target="_blank"
  //             >
  //               https://www.facebook.com/about/privacy/{" "}
  //             </a>{" "}
  //           </p>
  //           <p>
  //             LinkedIn -{" "}
  //             <a
  //               href="https://www.linkedin.com/legal/cookie-policy "
  //               target="_blank"
  //             >
  //               https://www.linkedin.com/legal/cookie-policy{" "}
  //             </a>{" "}
  //           </p>
  //           <h3>Issues or Enquiries</h3>
  //           <p>
  //             Legateca tries to meet the best of standards while collecting and
  //             using personal data. For this reason, we take any Legateca
  //             customer or customer issues/complaints we receive very seriously.
  //             We appreciate if you bring it to our notice if you think that our
  //             collection or use of personal data is unfair, misleading or
  //             inappropriate. - You have the right to request any personal
  //             information held by us that relates to the Right of Access under
  //             the UK GDPR. - You have the right to modify any personal
  //             information held by us that relates to the Right of Access under
  //             the UK GDPR. - You also have the right to have any personal
  //             information held by us deleted at any time that relates to the
  //             Right of Access under the UK GDPR. Please note this may affect the
  //             services provided by us, to you if you choose to delete your
  //             personal information whilst using Legateca's services. It's also
  //             important to state that the Right of deletion only applies in
  //             certain circumstances.{" "}
  //           </p>
  //           <p>
  //             To contact us or to make a request to view any data that we may
  //             hold then either email dpo@legateca.com or write to: Legateca
  //             Compliance Department, 41 Heather Drive Thatcham RG18 4BU, UK. If
  //             you are not happy with the way in which your personal data is
  //             being handled by Legateca, you can complain to the Information
  //             Commissioner whose details can be found at:{" "}
  //             <a href="http://www.ico.org.uk/" target="_blank">
  //               http://www.ico.org.uk/
  //             </a>
  //           </p>
  //           <h3>Changes to this policy</h3>
  //           <p>
  //             We keep our Policies under regular review. This Policy was last
  //             updated in December 2021
  //           </p>
  //         </div>
  //       </section >
  //     </div>
  //   </Layout>
  // );
};

export default Privacy;

export const Description = ({ children }) => {
  return (
    <div className="text-[#252525] text-justify text-sm 3xl:text-[30px] not-italic font-normal !leading-[26px] 3xl:!leading-[150%] tracking-[0.28px]">
      {children}
    </div>
  )
}
export const SectionCard = ({ head, id, children }) => {
  return (
    <div className="flex flex-col gap-3 items-start">
      <div id={`heading${id}`} className="text-[#006766] 3xl:text-[50px] xl:text-2xl text-[20px] not-italic font-bold !leading-[31px] 3xl:!leading-[130%] tracking-[0.48px] uppercase">
        {head}
      </div>
      {children}
    </div>
  )
}

export const TableOfContents = ({ array }) => {
  const [selectedPara, setselectedPara] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setselectedValue] = useState(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSelect = (id) => {
    setselectedPara(id);
    setDropdownOpen(false);
    // window.location.replace(`#heading${id}`);
  };

  return (
    <div className="w-full xl:hidden block" >
      <div
        className="text-[#006766] pb-4 text-[22px] 3xl:text-[50px] not-italic font-semibold !leading-[149%] 3xl:!leading-[130%] tracking-[0.44px] uppercase cursor-pointer"
      >
        Table of Contents
      </div>
      <div
        className="flex items-center justify-between cursor-pointer p-3 w-full border border-zinc-400 rounded-lg"
        onClick={toggleDropdown}
      >
        <span
          className="text-[#006766] text-base not-italic font-semibold uppercase cursor-pointer"

        >{(selectedValue !== null && selectedValue) || 'Select a content'}</span>
        <svg
          className={`h-6 w-6 transition-transform duration-300 transform ${dropdownOpen ? 'rotate-180' : ''
            }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      {dropdownOpen && (
        <div className="w-full bg-white border border-[#bbb] z-10">
          {array.map((it, id) => (
            <a className="!text-decoration-none no-underline" href={`#heading${id}`}>
              <div
                key={id}
                onClick={() => {
                  setselectedValue(it)
                  handleSelect(id)
                }}
                className={`font-montserrat block text-text-decoration-none  ${id === selectedPara ? 'text-white bg-[#006766]' : 'text-[#252525] border-b border-[#bbb]'
                  } cursor-pointer px-[10px] max-3xl:py-4 3xl:px-[40px] 3xl:py-12 text-sm 3xl:text-[30px] not-italic font-light !leading-[149%] 3xl:!leading-[130%] tracking-[0.28px]`}
              >
                {`${id + 1}. ${it}`}
              </div>
            </a>
          ))}
        </div>
      )}

    </div>
  );
};

