import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, useParams } from "react-router-dom";
import { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import LoginAuthFirst from "../../Modals/LoginAuth";
import services from "../../../server/services";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "react-responsive-modal";

const LawyemSlots = (props) => {
  const {
    user,
    lawyerId,
    lawyerData,
    transactionData,
    availabilityException,
    dateStr,
    history,
    lawyerInfo,
  } = props;
  let { id } = useParams();
  // console.log(lawyerData.queryResult.data[0], "**************");
  // console.log(props.lawyerId, "*****111111");

  const [slotsData, setSlotsData] = useState("");
  const [finalSlotsData, setFinalSlotsData] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [slotData, setSlotData] = useState({});
  const [loginFirst, setLoginFirst] = useState(false);
  const [blockedSlots, setBlockedSlots] = useState([]);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [oldBookings, setOldBookings] = useState([]);
  const [duration, setDuration] = useState({});
  const [confirmSlotStartTime, setConfirmSlotStartTime] = useState();
  const [confirmSlotEndTime, setConfirmSlotEndTime] = useState();

  useEffect(() => {
    if (user.timezone) {
      const timeDuration = moment
        .utc(Math.abs(user.timezone.offset) * 3600 * 1000)
        .format("HH:mm");
      setDuration(timeDuration);
    }
  }, [user]);

  useEffect(() => {
    let oldBookingsArray = [];
    let dayHourData = _.find(props.generalAvailability.queryResult?.data, {
      day: props.selectedDay,
    });
    if (props.bookingData.queryResult?.data && dayHourData?.slots) {
      for (let booking of props.bookingData.queryResult?.data) {
        let oldBooking = _.find(dayHourData?.slots, {
          _id: booking.slotId,
        });
        if (!oldBooking) {
          oldBookingsArray.push(booking);
        }
      }
      setOldBookings(oldBookingsArray);
    }
  }, [
    props.generalAvailability.queryResult?.data,
    props.bookingData.queryResult?.data,
  ]);

  useEffect(() => {
    const slotException = availabilityException.queryResult?.data.filter(
      (element) => element.type === "slot" && element.date == dateStr
    );
    if (slotException) {
      const slotIdList = slotException.map(function (item) {
        return item["slotId"];
      });
      setBlockedSlots(slotIdList);
    }
  }, [availabilityException]);
  const GetAvailabilityExceptionData = async () => {
    const res = await services["availability-exception"].find({
      query: { lawyerId: `${props?.lawyerId}`, $limit: 999 },
    });
  };

  useEffect(() => {
    GetAvailabilityExceptionData();
    getBookingDeatil();
    let dayHourData = _.find(props.generalAvailability.queryResult?.data, {
      day: props.selectedDay,
    });
    setSlotsData(dayHourData);
  }, []);

  const getBookingDeatil = async () => {
    try {
      let queryObj = {
        query: {
          lawyerId: props.lawyerId,
          date: props.selectedDate,
          $limit: 999,
        },
      };
      await services["booking"].find(queryObj);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    let x = JSON.parse(JSON.stringify(slotsData));
    if (slotsData.slots) {
      let slotData = JSON.parse(JSON.stringify(slotsData.slots));
      let newSlotData = [];

      try {
        if (props.bookingData.queryResult?.data.length > 0) {
          for (let slot of slotData) {
            let isSlotBooked = _.find(props.bookingData.queryResult?.data, {
              slotId: slot._id,
            });
            if (isSlotBooked && isSlotBooked.status === "pending") {
              slot.isBooked = true;
            } else if (isSlotBooked && isSlotBooked.status === "canceled") {
              let checkPendingSlot = _.find(
                props.bookingData.queryResult?.data,
                {
                  slotId: isSlotBooked.slotId,
                  status: "pending",
                }
              );
              if (checkPendingSlot) {
                slot.isBooked = true;
              } else {
                slot.isBooked = false;
              }
            } else if (blockedSlots.includes(slot._id)) {
              slot.isBooked = true;
            } else if (oldBookings) {
              for (let oldBooking of oldBookings) {
                const bookingStartTime = oldBooking.startTime.replace(":", "");
                const bookingEndTime = oldBooking.endTime.replace(":", "");
                const slotStartTime = slot.startTime.replace(":", "");
                const slotEndTime = slot.endTime.replace(":", "");

                if (
                  bookingStartTime >= slotStartTime &&
                  bookingStartTime < slotEndTime
                ) {
                  slot.isBooked = true;
                } else if (
                  bookingEndTime > slotStartTime &&
                  bookingEndTime <= slotEndTime
                ) {
                  slot.isBooked = true;
                } else if (
                  bookingStartTime < slotStartTime &&
                  bookingEndTime > slotEndTime
                ) {
                  slot.isBooked = true;
                }
              }
            } else {
              slot.isBooked = false;
            }
            newSlotData.push(slot);
          }
          x.slots = newSlotData;
          setFinalSlotsData(x);
        } else {
          for (let slot of slotData) {
            if (blockedSlots.includes(slot._id)) {
              slot.isBooked = true;
            } else {
              slot.isBooked = false;
            }
            newSlotData.push(slot);
          }
          x.slots = newSlotData;
          setFinalSlotsData(x);
        }
      } catch (error) {
        console.log("error", error.message);
      }
    }
  }, [props.bookingData, oldBookings]);

  const saveBooking = async () => {
    try {
      let body = {
        userId: props.user?._id,
        lawyerId: props.lawyerId,
        slotId: slotData._id,
        date: props.selectedDate,
        startTime: slotData.startTime,
        endTime: slotData.endTime,
        StartDate: moment(props.selectedDate, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        ),
      };

      let data = await services["booking"].create(body);
      if (data.action.type === "SERVICES_BOOKING_CREATE_FULFILLED") {
        toast.success("Slot Booked Successfully", {
          hideProgressBar: true,
          autoClose: 2000,
        });
      } else {
        toast.error("Something went wrong.", {
          hideProgressBar: true,
          autoClose: 2000,
        });
      }
      getBookingDeatil();
      props.closeModal();
      props.checkAvaiableCredit();
    } catch (error) {}
  };

  const checkAvaiableCredit = async () => {
    try {
      await services["transactions"].find({
        query: {
          userId: user._id,
          paymentFor: "lawyer",
          isUsed: false,
          lawyerId: lawyerData.queryResult.data[0]._id,
        },
      });
    } catch (error) {
      console.error({ error });
    }
  };

  // useEffect(() => {
  //   checkAvaiableCredit();
  // }, []);

  const confirmBooking = async (slot, slotStartTime, slotEndTime) => {
    setConfirmSlotStartTime(slotStartTime);
    setConfirmSlotEndTime(slotEndTime);
    if (user && user.email) {
      if (
        lawyerData.queryResult.data[0].perSessionFee === 0 &&
        transactionData.queryResult &&
        transactionData.queryResult?.data.length === 0
      ) {
        let obj = [
          {
            userId: user._id,
            paymentObject: { trailPayment: true },
            metaDataObject: { trailPayment: true },
            paymentFor: "lawyer",
            lawyerId: lawyerData.queryResult.data[0]._id,
            groupId: uuidv4(),
            isTrailPayment: true,
          },
        ];
        console.log(obj);
        await services["transactions"].create(obj);
        setSlotData(slot);

        setConfirm(true);
      } else if (
        lawyerData.queryResult.data[0].perSessionFee !== 0 &&
        transactionData.queryResult?.total === 0
      ) {
        setSlotData(slot);
        setShowNotificationModal(true);
      } else {
        setSlotData(slot);
        setConfirm(true);
      }
    } else {
      setLoginFirst(true);
    }
  };

  const isTimePassed = (startTime) => {
    try {
      let isSameDate = isSameDateFun(props.selectedDate);
      if (isSameDate) {
        let currentTime = moment(moment().format("HH:mm"), "HH:mm");
        let slotStartTime = moment(startTime, "HH:mm");
        return currentTime.isBefore(slotStartTime);
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };
  const isSameDateFun = (selectedDate1) => {
    try {
      let currentDate = moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY");
      let selectedDate = moment(selectedDate1, "DD-MM-YYYY");
      return currentDate.isSame(selectedDate);
    } catch (error) {
      return false;
    }
  };

  const hanldeMakePayment = () => {
    const body = {
      userId: props.user._id,
      lawyerId: props.lawyerId,
      slotId: slotData._id,
      date: props.selectedDate,
      startTime: slotData.startTime,
      endTime: slotData.endTime,
      StartDate: moment(props.selectedDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
    };
    const paymentProps = {
      amount:
        lawyerData.queryResult.data[0].minSessionToAttend *
          lawyerData.queryResult.data[0].perSessionFee *
          100 +
        lawyerData.queryResult.data[0].perSessionCommission *
          lawyerData.queryResult.data[0].minSessionToAttend *
          100,
      paymentFor: "lawyer",
      successRedirectUrl: window.location.pathname,
      failureRedirectUrl: window.location.pathname,
      lawyerId: lawyerData.queryResult.data[0]._id,
      metaData: {
        isTrailPayment: false,
        trailPayment: false,
        vat: 0.2,
        stripeAccountId: lawyerData.queryResult.data[0].stripeAccountId,
        minSessionToAttend: lawyerData.queryResult.data[0].minSessionToAttend,
        perSessionCommission:
          lawyerData.queryResult.data[0].perSessionCommission,
        totalSessionCommission:
          lawyerData.queryResult.data[0].perSessionCommission *
          lawyerData.queryResult.data[0].minSessionToAttend,
        perSessionLawyerFee: lawyerData.queryResult.data[0].perSessionFee,
        totalLawyerFee:
          lawyerData.queryResult.data[0].minSessionToAttend *
          lawyerData.queryResult.data[0].perSessionFee,
        VATverified: lawyerData.queryResult.data[0].isVATverified,
      },
      body: body,
    };
    history.push({
      pathname: "/payment",
      state: paymentProps,
    });
  };
  const isInNextHours = (time, allowedNextHours) => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();

    const [hour, minutes] = time.split(":");
    const bookingTime = new Date(now);
    bookingTime.setHours(parseInt(hour, 10), parseInt(minutes, 10));

    // Calculate the difference in minutes
    const timeDiff = (bookingTime - now) / (60 * 1000);

    // Check if the time is within the allowed next hours
    return timeDiff >= 0 && timeDiff <= allowedNextHours * 60;
  };

  return (
    <div className={confirm ? "xl:min-h-[552px] xl:max-h-[552px] " : ""}>
      {confirm && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="flex flex-col pt-[30px]">
            {/* <h2>Book a slot on {moment(dateStr).format("D MMMM YYYY")}</h2> */}
            <div className="text-sm text-ThemePrimary font-medium text-center uppercase">
              Confirm
            </div>
            <div className="text-[42px] text-ThemeSecondary font-medium text-center uppercase">
              ARE YOU SURE?
            </div>
          </div>
          {/* <div className="modal-header" style={{ width: "100%" }}>
            <h2> Book this slot?</h2>
          </div> */}
          <div className="pl-[30px] w-full max-w-[420px] mx-auto pr-[18px] border-b border-[#ebebeb] flex flex-col custom-Border2">
            <div className="flex w-full  items-center justify-between border-b border-[#ebebeb] pb-[14px]">
              <div className="text-sm text-ThemeSecondary font-semibold uppercase ">
                NAME
              </div>
              <div className="text-primary-tw text-sm">
                {lawyerInfo?.companyName}
              </div>
            </div>
            <div className="flex w-full items-center justify-between border-b border-[#ebebeb] pb-[14px]">
              <div className="text-sm text-ThemeSecondary font-semibold uppercase ">
                Fee
              </div>
              <div className="text-primary-tw text-sm">
                {lawyerInfo?.perSessionFee === 0
                  ? "Free"
                  : `£${lawyerInfo?.perSessionFee}`}
              </div>
            </div>
            <div className="flex w-full items-center justify-between border-b border-[#ebebeb] pb-[14px]">
              <div className="text-sm text-ThemeSecondary font-semibold uppercase ">
                Booking Slot
              </div>
              <div className="text-primary-tw text-sm">
                {" "}
                {confirmSlotStartTime} - {confirmSlotEndTime}
              </div>
            </div>
            <div className="flex w-full items-center justify-between border-b border-[#ebebeb] pb-[14px]">
              <div className="text-sm text-ThemeSecondary font-semibold uppercase ">
                Lawyer
              </div>
              <div className="text-primary-tw text-sm">
                {lawyerInfo?.firstName} {lawyerInfo?.lastName}
              </div>
            </div>
            <div className="flex w-full items-center justify-between border-b border-[#ebebeb] pb-[14px]">
              <div className="text-sm text-ThemeSecondary font-semibold uppercase ">
                SERVICE
              </div>
              <div className="text-primary-tw text-sm max-w-[250px] truncate">
                {lawyerInfo?.tags.join(",")}
              </div>
            </div>
          </div>
          {/* <div className="slot-listing">
            <div className="slotBox SlotBooked">
              {confirmSlotStartTime} - {confirmSlotEndTime}
            </div>
          </div> */}
          <button
            type="button"
            className="btn btn-primary py-3 "
            onClick={saveBooking}
          >
            Confirm
          </button>
          <div
            onClick={() => setConfirm(false)}
            className="text-center mb-4 flex gap-2 items-center justify-center cursor-pointer"
          >
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7812 5.98389C12.7812 6.3667 12.4805 6.66748 12.125 6.66748H2.82812L6.46484 10.1128C6.73828 10.3589 6.73828 10.7964 6.49219 11.0425C6.24609 11.3159 5.83594 11.3159 5.5625 11.0698L0.75 6.47607C0.613281 6.33936 0.558594 6.17529 0.558594 5.98389C0.558594 5.81982 0.613281 5.65576 0.75 5.51904L5.5625 0.925293C5.83594 0.679199 6.24609 0.679199 6.49219 0.952637C6.73828 1.19873 6.73828 1.63623 6.46484 1.88232L2.82812 5.32764H12.125C12.5078 5.32764 12.7812 5.62842 12.7812 5.98389Z"
                fill="#252525"
              />
            </svg>
            GO BACK
          </div>
        </div>
        //       <div
        //         style={{
        //           display: "flex",
        //           justifyContent: "center",
        //           paddingBottom: "20px",
        //         }}
        //       >
        //         <button
        //           className="btn-cancel w-auto mr-3"
        //           onClick={() => setConfirm(false)}
        //         >
        //           Cancel
        //         </button>
        //         <button className="btn btn-primary w-auto" onClick={saveBooking}>
        //           Confirm
        //         </button>
        //       </div>
        // </div>
      )}
      <div>
        <div className="flex flex-col pt-[30px]">
          {/* <h2>Book a slot on {moment(dateStr).format("D MMMM YYYY")}</h2> */}
          <div className="text-sm text-ThemePrimary font-medium text-center uppercase">
            {moment(dateStr).format("D MMMM YYYY")}
          </div>
          <div className="text-[42px] text-ThemeSecondary font-medium text-center uppercase">
            Book a slot
          </div>
        </div>
        {/* <div className="slot-listing"> */}
        <div className=" flex w-full max-xl:max-h-[300px] overflow-y-auto max-xl:justify-center max-xl:my-[20px] xl:py-[20px] gap-[20px] items-center flex-wrap xl:flex-nowrap xl:max-w-[622px] max-w-[322px] mx-auto  px-0">
          {finalSlotsData &&
            finalSlotsData.slots.map((slot) => {
              // console.log("slot", slot)
              const currentDate = moment();
              const inputDate = moment(dateStr);

              let slotStartTime = slot.startTime;
              let slotEndTime = slot.endTime;
              const allowedNextHours = 4; // Change this to the desired number of hours

              // Check if either startTime or endTime is in the next allowed hours
              if (
                (isInNextHours(slot.startTime, allowedNextHours) &&
                  inputDate.isSame(currentDate, "day")) ||
                (isInNextHours(slot.endTime, allowedNextHours) &&
                  inputDate.isSame(currentDate, "day"))
              ) {
                // Update isBooked to true
                slot.isBooked = true;
              }

              if (user.timezone) {
                if (user.timezone.offset >= 0) {
                  slotStartTime = moment(slot.startTime, "HH:mm")
                    .add(duration, "HH:mm")
                    .format("HH:mm");
                  slotEndTime = moment(slot.endTime, "HH:mm")
                    .add(duration, "HH:mm")
                    .format("HH:mm");
                } else {
                  slotStartTime = moment(slot.startTime, "HH:mm")
                    .subtract(duration, "HH:mm")
                    .format("HH:mm");
                  slotEndTime = moment(slot.endTime, "HH:mm")
                    .subtract(duration, "HH:mm")
                    .format("HH:mm");
                }
              }

              return (
                <div
                  key={slot._id}
                  className={
                    // slot.isBooked ? "slotBox SlotBooked " : "slotBox NotBooked"
                    `cursor-pointer ${
                      slot.isBooked
                        ? "text-white font-medium bg-ThemeSecondary min-w-[138px] max-w-[138px]  py-[18px] text-center "
                        : " text-center min-w-[138px] max-w-[138px] text-black font-medium bg-white  py-[18px] border border-[#252525]"
                    }`
                  }
                  onClick={() => {
                    if (!slot.isBooked) {
                      let isTimepass = isTimePassed(slotStartTime);
                      if (isTimepass) {
                        confirmBooking(slot, slotStartTime, slotEndTime);
                      } else {
                        toast.warning("Please select a time in future", {
                          hideProgressBar: true,
                          autoClose: 2000,
                        });
                      }
                    }
                  }}
                  id={slot._id}
                >
                  {slotStartTime} - {slotEndTime}
                  {/* (Booked-{" "}
                  {slot.isBooked ? "true" : "false"}) */}
                </div>
              );
            })}
        </div>

        <div className="text-center max-w-[300px] min-w-[300px] mx-auto ">
          <button
            type="button"
            className="btn btn-primary max-lg:!px-3 !w-full py-3 mb-4"
            onClick={() => hanldeMakePayment()}
          >
            Continue to payment
          </button>
        </div>
        <div
          onClick={() => {
            props.closeModal();
          }}
          className="text-center mb-4 flex gap-2 items-center justify-center cursor-pointer"
        >
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7812 5.98389C12.7812 6.3667 12.4805 6.66748 12.125 6.66748H2.82812L6.46484 10.1128C6.73828 10.3589 6.73828 10.7964 6.49219 11.0425C6.24609 11.3159 5.83594 11.3159 5.5625 11.0698L0.75 6.47607C0.613281 6.33936 0.558594 6.17529 0.558594 5.98389C0.558594 5.81982 0.613281 5.65576 0.75 5.51904L5.5625 0.925293C5.83594 0.679199 6.24609 0.679199 6.49219 0.952637C6.73828 1.19873 6.73828 1.63623 6.46484 1.88232L2.82812 5.32764H12.125C12.5078 5.32764 12.7812 5.62842 12.7812 5.98389Z"
              fill="#252525"
            />
          </svg>
          GO BACK
        </div>
      </div>
      {loginFirst && (
        <LoginAuthFirst hideLoginModal={() => setLoginFirst(false)} />
      )}
      <Modal
        // open={true}
        open={showNotificationModal}
        onClose={() => {
          setShowNotificationModal(false);
        }}
        classNames={{
          modal: "xl:max-w-[862px] xl:min-w-[862px]",
        }}
        center
      >
        <div className=" px-[78px] py-[70px] flex flex-col gap-[10px] justify-center items-center">
          <svg
            width={99}
            height={99}
            viewBox="0 0 99 99"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="49.0566"
              cy="49.0215"
              r="47.0215"
              stroke="#006766"
              strokeWidth={4}
            />
            <path
              d="M36.6896 56.8951C37.9811 56.8951 39.0738 57.9878 39.0738 59.2793C39.0738 60.6701 37.9811 61.6635 36.6896 61.6635H31.9212C30.5304 61.6635 29.5369 60.6701 29.5369 59.2793C29.5369 57.9878 30.5304 56.8951 31.9212 56.8951H36.6896ZM55.7635 56.8951C57.0549 56.8951 58.1477 57.9878 58.1477 59.2793C58.1477 60.6701 57.0549 61.6635 55.7635 61.6635H44.637C43.2462 61.6635 42.2528 60.6701 42.2528 59.2793C42.2528 57.9878 43.2462 56.8951 44.637 56.8951H55.7635ZM70.8636 26.6948C74.3406 26.6948 77.2215 29.5758 77.2215 33.0528V64.8425C77.2215 68.4189 74.3406 71.2005 70.8636 71.2005H26.3579C22.7816 71.2005 20 68.4189 20 64.8425V33.0528C20 29.5758 22.7816 26.6948 26.3579 26.6948H70.8636ZM70.8636 31.4633H26.3579C25.4639 31.4633 24.7685 32.258 24.7685 33.0528V36.2317H72.4531V33.0528C72.4531 32.258 71.6583 31.4633 70.8636 31.4633ZM72.4531 45.7687H24.7685V64.8425C24.7685 65.7366 25.4639 66.432 26.3579 66.432H70.8636C71.6583 66.432 72.4531 65.7366 72.4531 64.8425V45.7687Z"
              fill="#006766"
            />
          </svg>
          <div className="flex flex-col pt-[30px]">
            {/* <h2>Book a slot on {moment(dateStr).format("D MMMM YYYY")}</h2> */}
            <div className="text-sm text-ThemePrimary font-medium text-center uppercase">
              make a payment
            </div>
            <div className="text-[42px] text-ThemeSecondary font-medium text-center uppercase">
              Please make payment to proceed further
            </div>
          </div>

          <div className="text-center max-w-[300px] min-w-[300px] mx-auto ">
            <button
              type="button"
              className="btn btn-primary max-lg:!px-3 !w-full py-3 mb-4"
              onClick={() => hanldeMakePayment()}
            >
              Continue to payment
            </button>
          </div>
          <div
            onClick={() => setShowNotificationModal(false)}
            className="text-center mb-4 flex gap-2 items-center justify-center cursor-pointer"
          >
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7812 5.98389C12.7812 6.3667 12.4805 6.66748 12.125 6.66748H2.82812L6.46484 10.1128C6.73828 10.3589 6.73828 10.7964 6.49219 11.0425C6.24609 11.3159 5.83594 11.3159 5.5625 11.0698L0.75 6.47607C0.613281 6.33936 0.558594 6.17529 0.558594 5.98389C0.558594 5.81982 0.613281 5.65576 0.75 5.51904L5.5625 0.925293C5.83594 0.679199 6.24609 0.679199 6.49219 0.952637C6.73828 1.19873 6.73828 1.63623 6.46484 1.88232L2.82812 5.32764H12.125C12.5078 5.32764 12.7812 5.62842 12.7812 5.98389Z"
                fill="#252525"
              />
            </svg>
            GO BACK
          </div>
        </div>
        {/* <div className="paymentNoti-cont">
          <p>
            It seems like you have not done the payment to use this service.
            Please make the payment to proceed further.
            <br />
            <br />
            *you may need to provide proof of identity if not done already
          </p>
          <div className="bttn-group">
            <button
              type="button"
              className="btn-cancel w-auto mr-2"
              onClick={() => setShowNotificationModal(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary w-auto"
              onClick={() => hanldeMakePayment()}
            >
              Make Payment
            </button>
          </div>
        </div> */}
      </Modal>

      {/* MODAL FOR PAYMENT CONFIRMATION DONE */}

      {
        <Modal
          // open={true}
          // onClose={() => {
          //   setShowNotificationModal(false);
          // }}
          classNames={{
            modal: "xl:max-w-[862px] xl:min-w-[862px]",
          }}
          center
        >
          <div className=" px-[78px] py-[70px] flex flex-col gap-[10px] justify-center items-center">
            <svg
              width={99}
              height={99}
              viewBox="0 0 99 99"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="49.0566"
                cy="49.416"
                r="47.0215"
                stroke="#006766"
                strokeWidth={4}
              />
              <path
                d="M70.2695 34.1427C71.2629 35.1361 71.2629 36.6263 70.2695 37.5204L44.0429 63.7469C43.1489 64.7403 41.6587 64.7403 40.7646 63.7469L27.254 50.2363C26.2606 49.3422 26.2606 47.852 27.254 46.8586C28.1481 45.9645 29.6382 45.9645 30.5323 46.8586L42.4535 58.7798L66.9912 34.1427C67.8853 33.2486 69.3754 33.2486 70.2695 34.1427Z"
                fill="#006766"
              />
            </svg>

            <div className="flex flex-col pt-[30px]">
              {/* <h2>Book a slot on {moment(dateStr).format("D MMMM YYYY")}</h2> */}
              <div className="text-sm text-ThemePrimary font-medium text-center uppercase">
                SUCCESS
              </div>
              <div className="text-[42px] text-ThemeSecondary font-medium text-center uppercase">
                YOUR BOOKING HAS BEEN CONFIRMED
              </div>
            </div>

            <div className="text-center max-w-[300px] min-w-[300px] mx-auto ">
              <button
                type="button"
                className="btn btn-primary max-lg:!px-3 !w-full py-3 mb-4"
                // onClick={() => hanldeMakePayment()}
                onClick={() => history.push("/lawyer")}
              >
                Done
              </button>
            </div>
            <div
              onClick={() => setShowNotificationModal(false)}
              className="text-center mb-4 flex gap-2 items-center justify-center cursor-pointer"
            >
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7812 5.98389C12.7812 6.3667 12.4805 6.66748 12.125 6.66748H2.82812L6.46484 10.1128C6.73828 10.3589 6.73828 10.7964 6.49219 11.0425C6.24609 11.3159 5.83594 11.3159 5.5625 11.0698L0.75 6.47607C0.613281 6.33936 0.558594 6.17529 0.558594 5.98389C0.558594 5.81982 0.613281 5.65576 0.75 5.51904L5.5625 0.925293C5.83594 0.679199 6.24609 0.679199 6.49219 0.952637C6.73828 1.19873 6.73828 1.63623 6.46484 1.88232L2.82812 5.32764H12.125C12.5078 5.32764 12.7812 5.62842 12.7812 5.98389Z"
                  fill="#252525"
                />
              </svg>
              GO BACK
            </div>
          </div>
          {/* <div className="paymentNoti-cont">
          <p>
            It seems like you have not done the payment to use this service.
            Please make the payment to proceed further.
            <br />
            <br />
            *you may need to provide proof of identity if not done already
          </p>
          <div className="bttn-group">
            <button
              type="button"
              className="btn-cancel w-auto mr-2"
              onClick={() => setShowNotificationModal(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary w-auto"
              onClick={() => hanldeMakePayment()}
            >
              Make Payment
            </button>
          </div>
        </div> */}
        </Modal>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || {},
    bookingData: (state.booking && state.booking) || {},
    lawyerData: (state.users && state.users) || {},
    transactionData: state.transactions || {},
    availabilityException:
      (state["availability-exception"] && state["availability-exception"]) ||
      {},
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(LawyemSlots);
