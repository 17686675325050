import React, { Component } from "react";
import "./Sass/Main.scss";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import HomePage from "./Pages/Homepage";
import AboutUs from "./Pages/Aboutuspage/Aboutus";
import AutoLogin from "./Pages/autoLogin";
import OurTeam from "./Pages/Our-Team/Ourteam";
import Lawyem from "./Pages/LawyemPage/Lawyem";
import ContactUs from "./Pages/ContactUs";
import PrelegatePage from "./Pages/PrelegatePage";
import LoginPage from "./Pages/LoginPage";
import SignUpPage from "./Pages/SignUpPage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import SendCode from "./Pages/SendResetPassword";
import EmailVarificationPage from "./Pages/verifyEmail";
import ChangePassword from "./Pages/ForgotPasswordPage";
import Listing from "./Pages/Listing";
import PrelegateQuestions from "./Pages/PrelegateQuestions";
import FormFields from "./Pages/Form";
import MySchedulePage from "./Pages/MySchedulePage";
import SettingPage from "./Pages/SettingPage";
import PaymentPage from "./Pages/PaymentPage";
import ProfilePage from "./Pages/Profile";
import NotFoundPage from "./Pages/notFoundPage";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  feathersAuthentication,
  feathersClient,
} from "./server/feathers-binding";
import legatecafirstlogo from "./assets/images/legatecaFirstLogo.svg";

import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import AboutPage from "./Pages/About";
import LawyemProfile from "./Components/LawyemModule/LawyemProfilePage/LawyemProfile";
import Blogs from "./Pages/Blogs/blogs";
import SummaryPageBlog from "./Pages/Blogs/blogsArticle";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ServicesPage from "./Pages/services";
import PrivacyPolicy from "./Pages/Privacy";
import Terms from "./Pages/TermsAndConditions";
import Disclaimer from "./Pages/Disclaimer";
import { useDispatch } from "react-redux";
import { updateAuth } from "./actions";
import Faq from "./Pages/FAQ";
import Partner from "./Pages/Partner";
import { AuthIsSignedIn, AuthIsNotSignedIn } from "./contexts/authContext";
import services from "./server/services";
import PageSummary from "./Pages/PageSummary";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";

const locationHelper = locationHelperBuilder({});

const IsAuthenticated = connectedRouterRedirect({
  redirectPath: "/login",
  authenticatedSelector: (state) => state.auth.isSignedIn,
  wrapperDisplayName: "IsAuthenticated",
});

const IsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || `/dashboard`,
  allowRedirectBack: false,
  authenticatedSelector: (state) => !state.auth.isSignedIn,
  wrapperDisplayName: "IsNotAuthenticated",
});

export { legatecafirstlogo };
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reauthenticated: false,
    };
  }

  async componentDidMount() {
    let that = this;
    try {
      let res = await feathersClient.authenticate();
      that.setState({
        reauthenticated: true,
      });
    } catch (error) {
      this.setState({
        reauthenticated: true,
      });
      console.log(error.message);
    }
  }
  componentWillMount = async () => {
    if (localStorage.getItem("idToken")) {
      await services.authentication.create({
        strategy: "jwt",
        accessToken: localStorage.getItem("idToken"),
      });
    }
  };

  render() {
    if (!this.state.reauthenticated) {
      return <div></div>;
    }

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>
              Legateca - Legal Aid, Family Law Advice, Corporate Finance Lawyer
            </title>
            <meta
              name="description"
              id="baseDescription"
              content="Get professional legal advice services. We created Legateca with the vision to use technology to make cost-effective, Artificial Intelligence-powered legal consulting and dispute resolution service available in the UK."
            />
            <meta
              name="keywords"
              id="basekeywords"
              content="legal,legal aid,family law,family law solicitors,family law advice,employment law solicitors ,tenants rights uk,consumer lawyers,immigration solicitors ,corporate finance lawyer,contract lawyer,non-disclosure agreement lawyers,lawyers,ai,ml,artificial intelligence,machine learning"
            />
          </Helmet>
          <Switch>
            <Route exact path="/dashboard/profile" component={ProfilePage} />
            <Route exact path="/payment" component={PaymentPage} />
            <Route
              exact
              path="/dashboard/my-schedule"
              component={MySchedulePage}
            />
            <Route exact path="/dashboard/settings" component={SettingPage} />
            <Route exact path="/booking" component={MySchedulePage} />
            <Route exact path="/" component={HomePage} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/impersonation/:token" component={AutoLogin} />
            <Route exact path="/our-team" component={OurTeam} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/about" component={AboutPage} />
            <Route
              exact
              path="/lawyer"
              render={(props) => <Lawyem userRole="lawyer" {...props} />}
            />
            <Route
              exact
              path="/lawyer/:tag"
              render={(props) => <Lawyem userRole="lawyer" {...props} />}
            />
            <Route
              exact
              path="/lawyer-profile/:id"
              render={(props) => <LawyemProfile userRole="lawyer" {...props} />}
            />
            <Route
              exact
              path="/mediate"
              render={(props) => <Lawyem userRole="mediator" {...props} />}
            />
            <Route
              exact
              path="/mediate-profile/:id"
              render={(props) => (
                <LawyemProfile userRole="mediator" {...props} />
              )}
            />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/signup" component={SignUpPage} />
            <Route exact path="/send-code" component={SendCode} />
            <Route
              exact
              path="/change-password"
              component={ForgotPasswordPage}
            />
            <Route
              exact
              path="/verify-email"
              component={EmailVarificationPage}
            />
            <Route exact path="/list" component={Listing} />
            <Route exact path="/form" component={FormFields} />
            <Route
              exact
              path="/service/:serviceName"
              component={PrelegatePage}
            />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/partner" component={Partner} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms-and-conditions" component={Terms} />
            <Route exact path="/disclaimer" component={Disclaimer} />
            <Route exact path="/articles" component={Blogs} />
            <Route exact path="/articles/:id" component={SummaryPageBlog} />

            <Route
              exact
              path="/service/:service/:subCategoryId"
              component={PrelegateQuestions}
            />
            <Route
              exact
              path="/prelegate/:serviceName/"
              component={PageSummary}
            />
            <Route exact path="/services" component={ServicesPage} />
            <Route exact path="/dashboard" component={HomePage} />
            <Route exact path="/home" component={HomePage} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </HelmetProvider>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAuthState: (data) => {
      dispatch(updateAuth(data));
    },
    authenticate: feathersAuthentication.authenticate,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    authenticate: feathersAuthentication.authenticate,
  })
)(App);

const MainRoute = () => (
  <Switch>
    <Route exact path="/dashboard/profile" component={ProfilePage} />
    <Route exact path="/payment" component={PaymentPage} />
    <Route exact path="/dashboard/my-schedule" component={MySchedulePage} />
    <Route exact path="/dashboard/settings" component={SettingPage} />
    <Route exact path="/booking" component={MySchedulePage} />
    <Route exact path="/prelegate/:serviceName/" component={PageSummary} />
    <Route exact path="/articles/:id" component={SummaryPageBlog} />
  </Switch>
);
//just random comment here
// purpose to commit
