import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import services from "../server/services";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import moment from "moment";

// import PropTypes from "prop-types";
// import { propTypes } from "react-bootstrap/esm/Image";

const CheckoutForm = (props) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const redirectToLastPage = (duration) => {
    setTimeout(() => {
      props.history.push(props.successRedirectUrl);
    }, duration);
  };

  useEffect(() => {
    let createPayment = async () => {
      let paymentIntentObj = {
        amount: props.amount,
        paymentFor: props.paymentFor,
        metadata: props.metaData,
      };

      // if (props.paymentFor === "lawyer") {
      //   paymentIntentObj.application_fee_amount = props.application_fee_amount;
      //   paymentIntentObj.destination = props.stripeAccountId;
      // }
      let data = await services["payment"].create(paymentIntentObj);
      if (data.value) {
        setClientSecret(data.value);
      } else {
        toast.error("Something went wrong. Please try again later", {
          hideProgressBar: true,
          autoClose: 2000,
        });
        redirectToLastPage(2000);
      }
    };

    createPayment();
  }, []);

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    // Create a Transfer to the connected account (later):
    try {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${props.user.firstName} ${props.user.lastName}`,
            address: {
              line1: "41 Heather Drive",
              postal_code: "RG184BU",
              city: "Thatcham",
              state: "Berkshire",
              country: "GB",
            },
            email: `${props.user.email}`
          },
        },
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        saveTransaction(payload);
        setSucceeded(true);
        /////Invoice Number
        const getMail = JSON.parse(sessionStorage.getItem('state'))
        const getUserId = getMail.auth.user._id;

        ////Get
        let uId;
        let receiptNo;
        let invoiceNo;

        function getSecondPart(str) {
          return str.split('-')[1];
        }

        fetch(`${process.env.REACT_APP_API_URL}/invoice/?&$limit=1&$sort[createdAt]=-1&$select[]=receiptNo&$select[]=invoiceNo`, {
          method: "GET",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
          .then((data) => {
            const jsonified = JSON.parse(JSON.stringify(data))
            uId = jsonified.data[0]._id
            receiptNo = jsonified.data[0].receiptNo
            invoiceNo = jsonified.data[0].invoiceNo

            var receiptNo = getSecondPart(receiptNo.toString());
            var receiptNoIncrement = parseInt(receiptNo) + 1

            var invoiceNo = getSecondPart(invoiceNo.toString());
            var invoiceNoIncrement = parseInt(invoiceNo) + 1

            //zeroes location
            function number(number) {
              var str = "" + parseInt(number)
              var pad = "0000"
              var ans = pad.substring(0, pad.length - str.length) + str
              return ans;
            }

            ///Update  
            fetch(`${process.env.REACT_APP_API_URL}/invoice`, {
              method: "post",
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.idToken
              },

              //make sure to serialize your JSON body
              body: JSON.stringify({
                "userid": getUserId,
                "receiptNo": "RPT" + moment().format('YYYY') + moment().month() + moment().date() + "-" + number(receiptNoIncrement),
                "invoiceNo": "INV" + moment().format('YYYY') + moment().month() + moment().date() + "-" + number(invoiceNoIncrement)
              })
            }).then((response) => {

            });

          });
        /*.then( (response) => {
          const data = response.json();
          console.log(data) 
          ///Update  
          fetch(`${process.env.REACT_APP_API_URL}/invoice`, {
            method: "post",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.idToken
            },

            //make sure to serialize your JSON body
            body: JSON.stringify(        {
              "userid": getUserId,
              "receiptNo": "LEGAREC-002",
              "invoiceNo": "LEGAINV-002"
          })
          }).then( (response) => { 
            //do something awesome that makes the world a better place
          });
        });*/


      }
      /////
    } catch (error) {
      setError(`Payment failed. Please try again later.`);
      setProcessing(false);
    }
  };
  // const handleSubmit = async (ev) => {
  //   ev.preventDefault();
  //   // console.log(props)

  //   //   console.log("Hiya",props.phoneNo)
  //   setProcessing(true);
  //   // Create a Transfer to the connected account (later):
  //   try {
  //     const payload = await stripe.confirmCardPayment(clientSecret, {
  //       payment_method: {
  //         card: elements.getElement(CardElement),
  //         billing_details: {
  //           name: `${props.user.firstName} ${props.user.lastName}`,
  //           address: {
  //             line1: props.address,
  //             postal_code: props.postalCode,
  //             state: props.county,
  //             country: props.country,
  //           },
  //           email: `${props.user.email}`,
  //           phone: props.phoneNo,
  //         },
  //       },
  //     });

  //     if (payload.error) {
  //       setError(`Payment failed ${payload.error.message}`);
  //       setProcessing(false);
  //     } else {
  //       setError(null);
  //       setProcessing(false);
  //       saveTransaction(payload);
  //       setSucceeded(true);
  //       /////Invoice Number
  //       const getMail = JSON.parse(sessionStorage.getItem("state"));
  //       const getUserId = getMail.auth.user._id;

  //       ////Get
  //       let uId;
  //       let receiptNo;
  //       let invoiceNo;

  //       function getSecondPart(str) {
  //         return str.split("-")[1];
  //       }

  //       fetch(
  //         `${process.env.REACT_APP_API_URL}/invoice/?&$limit=1&$sort[createdAt]=-1&$select[]=receiptNo&$select[]=invoiceNo`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       )
  //         .then((response) => response.json())
  //         .then((data) => {
  //           const jsonified = JSON.parse(JSON.stringify(data));
  //           uId = jsonified.data[0]._id;
  //           receiptNo = jsonified.data[0].receiptNo;
  //           invoiceNo = jsonified.data[0].invoiceNo;

  //           var receiptNo = getSecondPart(receiptNo.toString());
  //           var receiptNoIncrement = parseInt(receiptNo) + 1;

  //           var invoiceNo = getSecondPart(invoiceNo.toString());
  //           var invoiceNoIncrement = parseInt(invoiceNo) + 1;

  //           //zeroes location
  //           function number(number) {
  //             var str = "" + parseInt(number);
  //             var pad = "0000";
  //             var ans = pad.substring(0, pad.length - str.length) + str;
  //             return ans;
  //           }

  //           ///Update
  //           fetch(`${process.env.REACT_APP_API_URL}/invoice`, {
  //             method: "post",
  //             headers: {
  //               Accept: "application/json",
  //               "Content-Type": "application/json",
  //               Authorization: "Bearer " + localStorage.idToken,
  //             },

  //             //make sure to serialize your JSON body
  //             body: JSON.stringify({
  //               userid: getUserId,
  //               receiptNo:
  //                 "RPT" +
  //                 moment().format("YYYY") +
  //                 moment().month() +
  //                 moment().date() +
  //                 "-" +
  //                 number(receiptNoIncrement),
  //               invoiceNo:
  //                 "INV" +
  //                 moment().format("YYYY") +
  //                 moment().month() +
  //                 moment().date() +
  //                 "-" +
  //                 number(invoiceNoIncrement),
  //             }),
  //           }).then((response) => {});
  //         });
  //       /*.then( (response) => {
  //         const data = response.json();
  //         console.log(data) 
  //         ///Update  
  //         fetch(`${process.env.REACT_APP_API_URL}/invoice`, {
  //           method: "post",
  //           headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'Authorization': "Bearer " + localStorage.idToken
  //           },

  //           //make sure to serialize your JSON body
  //           body: JSON.stringify(        {
  //             "userid": getUserId,
  //             "receiptNo": "LEGAREC-002",
  //             "invoiceNo": "LEGAINV-002"
  //         })
  //         }).then( (response) => { 
  //           //do something awesome that makes the world a better place
  //         });
  //       });*/
  //     }
  //     /////
  //   } catch (error) {
  //     setError(`Payment failed. Please try again later.`);
  //     setProcessing(false);
  //   }
  // };

  const bookSelectedSlot = async () => {
    if (props.body) {
      const response = await services["booking"].find({
        query: { slotId: props.body.slotId, status: "pending" },
      });
      if (response.action.payload?.total === 0) {
        let data = await services["booking"].create(props.body);
        if (data.action.type === "SERVICES_BOOKING_CREATE_FULFILLED") {
          toast.success("Slot Booked Successfully", {
            hideProgressBar: true,
            autoClose: 2000,
          });
        } else {
          toast.error("Something went wrong.", {
            hideProgressBar: true,
            autoClose: 2000,
          });
        }
        redirectToLastPage(2000);
      } else {
        toast.error("Slot is already booked, Please select an another slot", {
          hideProgressBar: true,
          autoClose: 3000,
        });
        redirectToLastPage(2000);
      }
    } else {
      redirectToLastPage(3000);
    }
  };

  const saveTransaction = async (paymentObj) => {
    let obj;
    if (props.paymentFor === "recommendate") {
      obj = {
        userId: props.user._id,
        paymentObject: paymentObj,
        metaDataObject: props.metaData,
        paymentFor: props.paymentFor,
        // lawyerId: props.lawyerId,
        groupId: uuidv4(),
        isTrailPayment: false,
      };
    } else {
      obj = {
        userId: props.user._id,
        paymentObject: paymentObj,
        metaDataObject: props.metaData,
        paymentFor: props.paymentFor,
        lawyerId: props.lawyerId,
        groupId: uuidv4(),
      };
    }
    if (props.paymentFor === "lawyer") {
      let arr = [];
      obj.metaData = props.metaData;
      for (let x = 0; x < props.metaData.minSessionToAttend; x++) {
        arr.push(obj);
      }
      await services["transactions"].create(arr);
    } else {
      await services["transactions"].create(obj);
      toast.success("Payment Successful", {
        hideProgressBar: true,
        autoClose: 1500,
      });
      setTimeout(() => {
        bookSelectedSlot();
      }, 2200);
      return
    }
    toast.success("Payment Successful", {
      hideProgressBar: true,
      autoClose: 1500,
    });
    setTimeout(() => {
      bookSelectedSlot();
    }, 2200);
  };
  return (
    <form id="payment-form" className='w-full' onSubmit={handleSubmit}>
      <div className='text-ThemeSecondary font-[600] capitalize text-base text-center my-[16px]'>Pay via Credit/Debit card</div>
      <CardElement id="card-element" onChange={handleChange} options={{ hidePostalCode: true }} />
      <div className="text-right">
        <button
          className={`getStarted !bg-ThemePrimary ${disabled ? "disabled opacity-40" : ""}`}
          disabled={processing || disabled || succeeded}
          id="submit"
        >
          <span id="button-text">
            {processing ? (
              <div className="spinner" id="spinner">
                Processing...
              </div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
      </div>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      {/* <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded
      </p> */}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || {},
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(CheckoutForm);
