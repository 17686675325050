import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useEffect } from 'react';

let ForgotPwdForm = (props) => {
  const { handleSubmit } = props;

  useEffect(() => {
    const script = document.createElement("script");
    const REACT_APP_CYBERSIARA_PUBLIC_KEY =
      process.env.REACT_APP_CYBERSIARA_PUBLIC_KEY;
      
      script.innerHTML = `
      $(function () {
        console.log("Fax")
        var PublicKey = "${REACT_APP_CYBERSIARA_PUBLIC_KEY}";
        InitCaptcha(PublicKey);
        $('.CaptchaSubmit').click(function () {
          if (CheckCaptcha()) {
            // console.log(CyberSiaraToken);
            // Please write your submit button click event function here //
          }
        });
      });
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          name="code"
          component="input"
          type="text"
          className="form-control input-lg"
          placeholder="Code"
        />
        {props.ValidationError && props.ValidationError.code && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.code}
          </div>
        )}
      </div>
      <div className="form-group">
        <Field
          name="email"
          component="input"
          type="email"
          className="form-control input-lg"
          placeholder="Email address"
          required
        />
        {props.ValidationError && props.ValidationError.email && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.email}
          </div>
        )}
      </div>
      <div className="form-group">
        <Field
          name="password"
          component="input"
          type="password"
          className="form-control input-lg"
          placeholder="Password"
        />
        {props.ValidationError && props.ValidationError.password && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.password}
          </div>
        )}
      </div>
      <div className="form-group">
        <Field
          name="confirmPassword"
          component="input"
          type="password"
          className="form-control input-lg"
          placeholder="Confirm Password"
        />
        {props.ValidationError && props.ValidationError.confirmPassword && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.confirmPassword}
          </div>
        )}
      </div>
      <div className="SiaraShield centered"></div>

      <div className="form-group text-center mb-0">
        <button type="submit" className="getStarted CaptchaSubmit">
          Change Passowrd
        </button>
      </div>
      <div className="form-group text-center mb-0">
        <p>
          Want to go back to login?<Link to={{pathname: '/login', state:"/home"}}> Click here to Login</Link>
        </p>
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  return {};
}

ForgotPwdForm = compose(
  withRouter,
  reduxForm({
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    form: "login",
  }),
  connect(mapStateToProps)
)(ForgotPwdForm);

export default ForgotPwdForm;
