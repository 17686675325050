import React from "react"
import { HeroSection } from "../Pages/About"
import Footer from "./Footer"

export const LayoutForLoginPages = ({
    sectionVals,
    sectionPara,
    sectionHeading,
    children,
    bgImg = './login-pages.png',
    // bgImg = 'https://s3-alpha-sig.figma.com/img/9208/b459/5103047f1736ae78759f02b155a863b4?Expires=1705881600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=E3Vt6VJ2V2vxPE9saVICl8UU08N--YZdPGD0ntYcUVnW-6MkhR8-ZZMyQ3OIjIwYASvVb011ERk8CD4X64pox4MCMykaMspqeUfAXdAIoBbYW-dWnEUXjxBVPSuxlqp3QZ5qzMmiHy2LRVxjLUX61usiiGL4sIPvDl6s6ksP7e09Q5O4BRmUJFanem4ILCh183LD5oD~5Yf0nUjS0nygs0pH2~egY~Q5ILD-4sFl~k9LLrvqf~c0KSYGRD6XUznRvyCN-8bruyhbVoW0Xhw0Yoi5M7~NH3Cn9NV7uUYCqmGNlHKk0ngTamv9rTqqu2g1X1gHuyEZeupuQwfw8ELZVA__',
    headingHero }) => {

    const { subHead, head } = sectionVals
    let Subheading = 'text-[#E3B576] lg:text-sm 3xl:text-[30px] text-base not-italic font-medium 3xl:!leading-[130%] !leading-[45px] tracking-[0.28px] uppercase'
    let heading = 'text-[#006766] text-center text-[22px] 3xl:text-[60px] lg:text-[42px] not-italic lg:font-medium font-semibold 3xl:!leading-[130%] !leading-[160%] tracking-[0.84px] uppercase'
    let bgImgBack = 'https://s3-alpha-sig.figma.com/img/4099/a648/673431504381f7f288089e7f5a92990e?Expires=1705276800&Signature=FvmoQWZX~LEQnWGVCgpTPGwqEXp46tcxANE9HaHegTu9z2qs8-83N53IKkfeJXWpYJF3Vt9jSlI22BVDRUd9iN-h6lyZEzwRVG0AVPxp-pUhFT3s5TmYaaXcxrr5DVQY3AdSnsXa3yD99bLe2WSsLskg0hx~FThCaaqjXGWl6L4yH77iYg2i9IsyZyfgImqrdU~ADOz5Osw-XE0sQFseJ01798YGFCouDvQARCK3m7~e4ztNEjT52U8QSXEXHmvqz2-37p5Va41lBMU~ynbz6D2DjDgA3a~5xrqdYadWZ~t9ac8Me7sRHGs~Zq55RLT5zBzUedh-Wk5gWndVjAo89w__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'

    return (
        <React.Fragment>
            <HeroSection bgImg={bgImg} heading={headingHero} />
            <main className='xl:py-[100px] py-[70px] w-full xl:max-w-[1240px] 3xl:max-w-[2400px] mx-auto flex flex-col gap-[42px]'>
                <div className=' flex flex-col xl:gap-[15px] items-center w-full mx-auto xl:max-w-[960px] 3xl:max-w-[2400px] '>
                    <div className="w-full xl:px-0 padding-md flex flex-col items-center">
                        <div className={Subheading}>
                            {subHead}
                        </div>
                        <div className={heading}>
                            {head}
                        </div>
                    </div>
                </div>

                <section className="bg-class w-full ">
                    <div className="bg-[#006766F2] flex lg:flex-row flex-col lg:justify-between lg:gap-0 gap-6 items-center lg:pl-[46px] px-[26px] lg:pr-[36px] py-[40px] 3xl:px-[80px] 3xl:py-[44px]   w-full h-full">
                        <div className={`flex flex-col justify-center lg:!items-start text-values-align !items-center 3xl:gap-7 max-3xl:gap-4 ${headingHero === 'Login' ? 'lg:min-w-[568px] lg:max-w-[568px]' : 'lg:min-w-[622px] lg:max-w-[622px]'} 3xl:min-w-[910px] 3xl:max-w-[910px]`}>
                            <img className='w-[143px] h-[26px] 3xl:w-[283px] 3xl:h-[56px] object-cover' loading='lazy' src="/legateca-login.png" alt='legateca'/>
                            <div className='text-white text-[42px] 3xl:text-[60px] not-italic font-medium 3xl:leading-[130%] !leading-[48px] tracking-[0.84px] uppercase'>
                                {sectionHeading}
                            </div>
                            <div className='xl:max-w-[486px]  3xl:max-w-[786px] text-white text-base 3xl:text-[30px] xl:text-sm not-italic 3xl:!leading-[130%] font-normal leading-[26px] tracking-[0.28px]'>
                                {sectionPara}
                            </div>
                        </div>
                        {children}
                    </div>
                    <style>
                        {`
            .bg-class{
              background-image:url(${bgImgBack});
              background-repeat:no-repeat;
              background-size:cover
            }
            `}
                    </style>
                </section >
            </main>
            <Footer />
        </React.Fragment>
    )
}