import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { compose } from "recompose";
import { withRouter, useParams, Link } from "react-router-dom";
import services from "../../../server/services";
import NoRecordFound from "./../../../assets/images/no-data-found.png";
import LoadingImage from "./../../../assets/images/loading-data.gif";
import Summary from "./Summary";
import PrelegateQuestion from "./PrelegateQuestion/PrelegateQuestion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { legatecafirstlogo } from "../../../App";

const PrelegateQuestions = (props) => {
  /* getting subcategory from route */
  var { userAnswer } = props;
  var { service, subCategoryId } = useParams();
  const { user } = useSelector((store) => store.auth);

  const [currentSliderValue, setCurrentSliderValue] = useState(1);
  const [parentOption, setParentOption] = useState();
  const [showSubQue, setShowSubQue] = useState("");
  const [currentSubSliderValue, setCurrentSubSliderValue] = useState();

  let [counter, setcounter] = useState(0);
  let [show, setShow] = useState(true);
  let [activeQuestion, setactiveQuestion] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [answerBody, setAnswerBody] = useState([]);
  const [finalAnswerBody, setFinalAnswerBody] = useState([]);
  let DataValidationError = "Need to give answer to move further";
  let regexError = "Regular Expression Error Message";

  useEffect(() => {
    getQuestionList();
  }, []);

  useEffect(() => {
    if (props.questions.queryResult.data.length) {
      setactiveQuestion(props.questions.queryResult.data[counter]);
    }
  }, [props]);

  const getQuestionList = async () => {
    try {
      if (localStorage.getItem("idToken")) {
        await services["questions"].find({
          query: {
            category: subCategoryId,
            service: service,
            $sort: {
              createdAt: 1,
            },
            $limit: 1000,
            $skip: 0,
          },
        });
      } else {
        toast.error("Session expired. Please login again.", {
          hideProgressBar: true,
          autoClose: 2000,
        });
        props.history.push({
          pathname: "/login",
          state: window.location.pathname,
        });
      }
    } catch (error) { }
  };

  const nextQuestion = async (e) => {
    // window.scroll(0, 0);

    if (counter < 0) {
      return null;
    } else {
      if (!selectedAnswer) {
        toast.error(DataValidationError, {
          hideProgressBar: true,
          autoClose: 2000,
        });
      } else if (activeQuestion.regex) {
        const regexp = activeQuestion.regex;
        const exp = new RegExp(regexp);

        if (!exp.test(selectedAnswer)) {
          toast.error(activeQuestion.regexErrorMessage, {
            hideProgressBar: true,
            autoClose: 2000,
          });
        } else {
          await addToFinalAnsBody();
          setcounter(counter + 1);
          setactiveQuestion(props.questions.queryResult.data[counter + 1]);
          resetState();
        }
      } else {
        await addToFinalAnsBody();
        setcounter(counter + 1);
        setactiveQuestion(props.questions.queryResult.data[counter + 1]);
        resetState();
      }
    }
  };

  const resetState = () => {
    setCurrentSliderValue(1);
    setParentOption("");
    setShowSubQue("");
    setCurrentSubSliderValue("");
  };

  const prevQuestion = (e) => {
    if (counter <= 0) {
      return (counter = 0);
    } else {
      setcounter(--counter);
      setactiveQuestion(props.questions.queryResult.data[counter]);
      let newFinalAnsBody = JSON.parse(JSON.stringify(finalAnswerBody));
      newFinalAnsBody.pop();
      setFinalAnswerBody(newFinalAnsBody);
    }
  };

  const addToFinalAnsBody = async () => {
    let answerData = {
      answertype: activeQuestion.type,
      text: "",
      options: {
        selectedOption: "",
        question: {
          answerType: null,
          text: "",
          selectedOption: "",
          selectedRange: "",
        },
      },
      selectedRange: 0,
    };

    if (activeQuestion.type === "text") {
      answerData.text = selectedAnswer;
    } else if (activeQuestion.type === "range") {
      answerData.selectedOption = selectedAnswer;
    } else if (activeQuestion.type === "option") {
      answerData.options = selectedAnswer;
    }

    let ansData = {
      questionId: activeQuestion._id,
      userId: user._id,
      answer: answerData,
      categoryId: `${subCategoryId}`,
    };
    setFinalAnswerBody([...finalAnswerBody, ansData]);
    setSelectedAnswer("");
  };

  const answerSelected = (value) => {
    setSelectedAnswer(value);
  };

  const SubmitAnswer = async () => {
    if (!selectedAnswer) {
      toast.error(DataValidationError, {
        hideProgressBar: true,
        autoClose: 2000,
      });
    } else if (activeQuestion.regex) {
      const regexp = activeQuestion.regex;
      const exp = new RegExp(regexp);

      if (!exp.test(selectedAnswer)) {
        toast.error(activeQuestion.regexErrorMessage, {
          hideProgressBar: true,
          autoClose: 2000,
        });
      } else {
        await addToFinalAnsBody();
      }
    } else {
      await addToFinalAnsBody();
    }
    setShow(false);
  };

  useEffect(() => {
    if (counter === props.questions.queryResult.data.length - 1) {
      services["user-answers"].create(finalAnswerBody);
    }
  }, [finalAnswerBody]);

  useEffect(() => {
    if (userAnswer.isError) {
      toast.error(userAnswer.isError, {
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
    if (userAnswer.isFinished) {
      setcounter(++counter);
      setactiveQuestion("");
      toast.success("Answer submitted", {
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  }, [userAnswer]);
  return (
    <>
      {show && props.questions.isLoading ? (
        <div className="noRecord_found loadingFull">
          <img src={LoadingImage} alt="No Data Found" className="img-fluid" />
        </div>
      ) : !props.questions.isError &&
        props.questions.queryResult.data.length > 0 ? (
        <div className="container">
          {/* <ToastContainer /> */}
          <div className="questioner_area">
            {counter < props?.questions?.queryResult?.data?.length && (
              <div className="questionNumber">
                <span className="currentQuestionNumber">
                  {(counter + 1).toString().padStart(2, "0")}
                </span>

                <span className="totalQuestions">
                  /
                  {props.questions.queryResult.data.length
                    .toString()
                    .padStart(2, "0")}
                </span>
              </div>
            )}
            {activeQuestion ? (
              <PrelegateQuestion
                question={activeQuestion}
                answerSelected={answerSelected}
                currentSliderValue={currentSliderValue}
                setCurrentSliderValue={setCurrentSliderValue}
                parentOption={parentOption}
                setParentOption={setParentOption}
                showSubQue={showSubQue}
                setShowSubQue={setShowSubQue}
                currentSubSliderValue={currentSubSliderValue}
                setCurrentSubSliderValue={setCurrentSubSliderValue}
              />
            ) : (
              <div className="thankyou-section">
                Thank you for answering the question. Please check your
                registered email for the recommendations.
              </div>
            )}
            {counter === props.questions.queryResult.data.length &&
              props.questions.queryResult.data.length > 0 &&
              answerBody.length === props.questions.queryResult.data.length && (
                <Summary
                  summary={answerBody}
                  allQuestins={props.questions.queryResult.data}
                ></Summary>
              )}
            <div className="bottom-btn">
              {counter <= props.questions.queryResult.data.length - 1 && (
                <a
                  onClick={(e) => prevQuestion(e)}
                  style={{ color: "black" }}
                  // className="btn-cancel"
                  className='bg-[#f2f2f2] px-4 py-3 rounded-md font-montserrat font-[500]'
                >
                  <i
                    className="fa fa-angle-double-left mr-2"
                    aria-hidden="true"
                  ></i>
                  Previous
                </a>
              )}
              {counter < props.questions.queryResult.data.length - 1 ? (
                <a
                  onClick={(e) => nextQuestion(e)}
                  style={{ color: "white", backgroundColor: "#0B6767" }}
                  // className="getStarted"
                  className='bg-ThemeSecondary text-white rounded-md px-4 py-3 font-montserrat font-[500]'

                >
                  Next
                  <i
                    className="fa fa-angle-double-right ml-2"
                    aria-hidden="true"
                  ></i>
                </a>
              ) : counter === props.questions.queryResult.data.length - 1 ? (
                <a
                  onClick={(e) => SubmitAnswer(e)}
                  style={{ color: "white", backgroundColor: "#0B6767" }}
                  className="getStarted"
                >
                  Submit
                  <i
                    className="fa fa-angle-double-right ml-2"
                    aria-hidden="true"
                  ></i>
                </a>
              ) : (
                <div className="gotoDashboard">
                  <CommonButton variantVal={'primary'} to={"/"} label={'Go to Home'} />
                  {/* <Link
                    to={"/"}
                    style={{ color: "white" }}
                    className=""
                  >
                    <a>
                      Go to Home{" "}
                      <i
                        className="fa fa-angle-double-right ml-2"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </Link>{" "} */}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="noRecord_found">
          {/* <img src={NoRecordFound} alt="No Data Found" className="img-fluid" /> */}
          <h6>No Questions Added Any Yet</h6>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || {},
    questions: state.questions,
    userAnswer: state["user-answers"],
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {})
)(PrelegateQuestions);

const CommonButton = ({ variantVal, disabled, handleClick, to, label }) => {
  const variant = () => {
    switch (variantVal) {
      default:
      case "primary":
        return "hover:bg-[#E3B576] bg-[#006766]";
      case "secondary":
        return "hover:bg-[#006766] bg-[#E3B576]";
      case "secondary-not-hovered":
        return "bg-[#E3B576]";
    }
  };
  const toProps = !to || to === '' ? {} : { href: to }
  return (
    <div
      style={{
        opacity: disabled ? '.4' : '',
        pointerEvents: disabled ? 'none' : ''
      }}
      className="flex justify-center md:justify-start cursor-pointer font-montserrat">
      <div
        onClick={handleClick}
        className={` ${variant()} px-[20px] py-[9px] 3xl:py-6 3xl:px-10 flex rounded-md 3xl:rounded-lg font-montserrat `}>
        <a
          {...toProps}
          className="flex no-underline items-center "
        // href="https://legateca.com/about"

        >
          <div className="my-auto text-white   uppercase tracking-[0.28px] font-[80px] 3xl:text-[30px] text-[14px]">
            {label}
          </div>
        </a>
      </div>
    </div>
  );
};