import React from 'react';
import { NavLink } from 'react-router-dom';

import './NavigationItem.css'


const navigationItem = ( props ) => (
    <ul>
        <li> 
            <NavLink 
                to={props.link}
                exact={props.exact}
                >{props.children}</NavLink>
        </li>
    </ul>
    
);

export default navigationItem; 