// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileShow {
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileShow {
    display: inline;
  }
}
.mobileHide {
  display: inline;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileHide {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Sass/MobileView.scss"],"names":[],"mappings":"AAAA;EAAc,aAAA;AAEd;;AAAA;EAGI;IAAa,eAAA;EAEf;AACF;AAAA;EAAc,eAAA;AAGd;;AAFA;EAGG;IAAc,aAAA;EAIf;AACF","sourcesContent":[".mobileShow { display: none;}\n\n@media only screen\n  and (min-device-width : 320px)\n  and (max-device-width : 480px){ \n    .mobileShow {display: inline;}\n}\n\n.mobileHide { display: inline; }\n@media only screen\n  and (min-device-width : 320px)\n  and (max-device-width : 480px){\n   .mobileHide { display: none;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
