import React, { useEffect } from "react";
import Layout from "../Components/Layout";

const NotFoundPage = (props) => {

  return (
    <Layout>
      <section className="page-breadcumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="wpo-breadcumb-wrap">
                <h2>404 Not Found</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
