import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import {
  servicesRaw,
  // feathersAuthentication
} from '../server/feathers-binding';
import authenticationReducer from './authenticationReducer';

const reducers = {
  auth: authenticationReducer,
  users: servicesRaw.users.reducer,
  availability: servicesRaw.availability.reducer,
  form: formReducer,
  'google-calendar-auth': servicesRaw['google-calendar-auth'].reducer,
  'categories' : servicesRaw['categories'].reducer,
  'questions' : servicesRaw['questions'].reducer,
  'user-answers' : servicesRaw['user-answers'].reducer,
  'payment' : servicesRaw['payment'].reducer,
  'transactions' : servicesRaw['transactions'].reducer,
  'impersonation' : servicesRaw['impersonation'].reducer,
  'general-availability' : servicesRaw['general-availability'].reducer,
  'booking' : servicesRaw['booking'].reducer,
  'ratings' : servicesRaw['ratings'].reducer,
  'accountancy' : servicesRaw['accountancy'].reducer,
  'general-data' : servicesRaw['general-data'].reducer,
  'contact-us' : servicesRaw['contact-us'].reducer,
  'chnage-password' : servicesRaw['chnage-password'].reducer,
  'availability-exception' : servicesRaw['availability-exception'].reducer
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
