import React, { useEffect } from "react";
import AboutUs from "../../Components/Aboutus/Aboutus";
import Layout from "../../Components/Layout";

const Aboutus = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
    return () => {
    };
  }, []);

  return (
    <Layout>

      <AboutUs />
    </Layout>
  );
};

export default Aboutus;
