import { timeSlots } from "./constants";

export const CalenderModel = ({
  handleInputChange,
  handleRemoveClick,
  handleAddClick,
  onSubmit,
  timeList,
}) => {
  // 
  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <div className="slot-outer">
        <h2>Add slot</h2>
        {timeList.map((x, i) => {
          return (
            <div key={i} className="calender_fields">
              <div className="box">
                <div className="mcls">
                  <label> Start </label>
                  <select
                    name="time"
                    value={x.time}
                    className="form-control"
                    onChange={(e) => handleInputChange(e, i)}
                    required
                  >
                    {timeSlots.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mcls">
                  <label> End </label>
                  <select
                    name="endTime"
                    value={x.endTime}
                    className="form-control"
                    onChange={(e) => handleInputChange(e, i)}
                    required
                  >
                    {timeSlots.map((item) => (
                      <option key={item} value={item}>{item}</option>
                    ))}
                  </select>
                </div>
                <div className="mcls">
                  <label> Title </label>
                  <input
                    name="eventTitle"
                    type="text"
                    className="form-control"
                    placeholder="Enter Title"
                    value={x.eventTitle}
                    onChange={(e) => handleInputChange(e, i)}
                    required
                  />
                </div>
                <div className="btn-box">
                  {timeList.length !== 0 && (
                    <button
                      className="mr10 btn"
                      onClick={() => handleRemoveClick(i)}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  )}
                  {timeList.length - 1 === i && (
                    <button className="btn" onClick={handleAddClick}>
                      <i className="fa fa-plus"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(timeList)}</div> */}
        <div className="d-flex justify-content-start mb-4 pl-20">
          <button className="addEventPop btn btn-primary" type="submit">
            Save slot
          </button>
        </div>
      </div>
    </form>
  );
};
