
import { feathersAuthentication } from '../server/feathers-binding';

// Check if there is an unauthenticated response - if so invalidate session
// to require re-login as either the session expired or they don't have
// the correct permissions
export const reauthCheck = store => next => action => {
  if (action.payload && typeof(action.payload) !== 'undefined' && typeof(action.payload.code) !== 'undefined' &&
      (action.payload.code === 401 || action.payload.code === 500)) {
    store.dispatch(feathersAuthentication.logout());
  }
  return next(action);
}
