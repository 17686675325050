import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { useState, useEffect } from "react";
import LoginAuthFirst from "./Modals/LoginAuth";

import LawSimplifiedLogo from "../assets/images/law-simplified-logo.png";
import UkltaLogo from "../assets/images/uklta-logo.png";

import services from "../server/services";
import moment from "moment";
import { Image } from "react-bootstrap";
import legatecaLogo from "../assets/images/logo-green.png";
import clock from "../assets/images/clock.svg";
import phone from "../assets/images/phone.svg";
import envelope from "../assets/images/envelope.svg";
import twitterX from "../assets/images/twitterX.svg";
import twitterXGreen from "../assets/images/twitterXGreen.svg";
import WhatsAppButtonGreenSmall from "../assets/images/WhatsAppButtonGreenSmall.svg";
import SetSquaredLogo from "../assets/images/SETSquared.png";

const FooterMain = (props) => {
  const handleService = (path) => {
    props.history.push(path);
  };

  useEffect(() => {
    services["general-data"].find({});
  }, []);

  let [cookieStatus, setCookieStatus] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let data = localStorage.getItem("isCookieAccept");
    if (data) {
      setCookieStatus(false);
    }
  }, []);
  const isMobile = window.innerWidth <= 1180;

  return (
    <footer className="w-full">
      <div className="md:hidden flex flex-col items-start gap-[30px]  padding-md ">
        <img
          className="max-w-[234px] min-w-[234px] min-h-[43px] max-h-[43px] "
          alt="nill"
          src={legatecaLogo}
        />
        <p className="text-[#4D5053] 3xl:text-[30px] text-base not-italic font-normal max-w-[392px] py-0 my-0 leading-[150%] tracking-[0.16px]">
          Cost effective legal consulting and dispute resolution service
          available in the UK
        </p>
        <div className="flex items-center justify-between w-full mb-[30px]">
          {[
            // {
            //   src: SetSquaredLogo,
            //   link: "https://surrey-research-park.com/grow-your-business/",
            // },
            {
              src: LawSimplifiedLogo,
              link: "https://law-simplified.co.uk/we-are-proudly-collaborating-with-legateca-the-pioneers-in-legal-tech/",
            },
            { src: UkltaLogo, link: "https://www.uklta.org.uk/our-members/" },
          ].map((item, id) => {
            return (
              <a key={id} target="_blank" href={item.link}>
                <img
                  className="max-w-[120px] max-h-[52px] 3xl:max-w-[160px] 3xl:max-h-[82px]"
                  src={item.src}
                  alt="logoImgs"
                />
              </a>
            );
          })}
        </div>
      </div>
      <section className="w-full border-y border-[#00000021] py-[50px] padding-md lg:px-[120px] grid md:grid-cols-2 grid-col-1  gap-4 xl:gap-0 lg:flex justify-between">
        <div className="hidden md:flex flex-col items-start gap-[30px] ">
          <img
            className="max-w-[202px] min-w-[202px] max-h-[36px] min-h-[36px] 3xl:max-w-[234px] 3xl:min-w-[234px] 3xl:min-h-[58px] 3xl:max-h-[58px]"
            alt="nill"
            src={legatecaLogo}
          />
          <p className="text-[#4D5053]  3xl:text-[30px] text-base not-italic font-normal 3xl:max-w-[480px] max-w-[392px] py-0 my-0 leading-[150%] tracking-[0.16px]">
            Cost effective legal consulting and dispute resolution service
            available in the UK
          </p>
          <div className="flex items-center gap-[30px] ">
            {[
              // {
              //   src: SetSquaredLogo,
              //   link: "https://surrey-research-park.com/grow-your-business/",
              // },
              {
                src: LawSimplifiedLogo,
                link: "https://law-simplified.co.uk/we-are-proudly-collaborating-with-legateca-the-pioneers-in-legal-tech/",
              },
              { src: UkltaLogo, link: "https://www.uklta.org.uk/our-members/" },
            ].map((item, id) => {
              return (
                <a key={id} target="_blank" href={item.link}>
                  <img
                    className="max-w-[150px] max-h-[52px] 3xl:max-w-[160px] 3xl:max-h-[82px]"
                    src={item.src}
                    alt="logoImgs"
                  />
                </a>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-[9px] max-w-[120px] 3xl:max-w-[320px]">
          <div className="text-[#292F36] text-lg 3xl:text-[30px] not-italic lg:font-semibold leading-[125%] uppercase">
            Featured
          </div>
          <div className="text-[#4D5053] lg:text-base 3xl:text-[26px] text-sm cursor-pointer not-italic font-normal !leading-[300%] tracking-[0.16px]">
            <span onClick={() => handleService("service/prelegate")}>
              Get Started
            </span>{" "}
            <span onClick={() => handleService("service/recommendate")}>
              Instant Advice
            </span>{" "}
            <span onClick={() => handleService("/lawyer")}>Find A Lawyer</span>{" "}
            <span onClick={() => handleService("mediate")}>Mediation</span>
          </div>
        </div>
        <div className="flex flex-col gap-[9px] w-full xl:max-w-[120px] 3xl:max-w-[320px]">
          <div className="text-[#292F36] text-lg not-italic 3xl:text-[30px] lg:font-semibold leading-[125%] uppercase">
            Quicklinks
          </div>
          <div className="text-[#4D5053] lg:text-base text-sm 3xl:text-[26px] cursor-pointer  not-italic font-normal !leading-[300%] tracking-[0.16px]">
            <div
              className="xl:whitespace-nowrap "
              onClick={() => handleService("/terms-and-conditions")}
            >
              Terms & Conditions
            </div>
            <div
              className="whitespace-nowrap"
              onClick={() => handleService("/privacy-policy")}
            >
              Privacy Policy
            </div>
            <div
              className="whitespace-nowrap"
              onClick={() => handleService("/partner")}
            >
              Partnership
            </div>
            <div onClick={() => handleService("/faq")}>FAQs</div>
          </div>
        </div>
        <div className="flex flex-col gap-[9px] min-w-[258px] max-w-[258px] 3xl:max-w-[320px] ">
          <div className="text-[#292F36] text-lg not-italic lg:font-semibold 3xl:text-[30px] leading-[125%] uppercase">
            Contact
          </div>
          <div className="flex flex-col gap-4">
            <div className="">
              <div className="max-w-[225px] 3xl:max-w-[320px] flex gap-2 items-center text-[#4D5053] 3xl:text-[26px] text-base not-italic font-normal leading-[150%] tracking-[0.16px]">
                {" "}
                <img
                  className="inline"
                  alt="LOGO - Watch
"
                  src={clock}
                />{" "}
                MONDAY TO FRIDAY 8:30 - 18:00
              </div>
              <a
                style={{ textDecoration: "none" }}
                href={`mailto:${
                  props.generalData.queryResult?.data.length > 0
                    ? props.generalData.queryResult?.data[0].email
                    : ""
                }`}
                title={`${
                  props.generalData.queryResult?.data.length > 0
                    ? props.generalData.queryResult?.data[0].email
                    : "--"
                }`}
                className="flex gap-2 items-center font-montserrat text-[#4D5053] 3xl:text-[26px] text-base not-italic font-normal leading-[300%] tracking-[0.16px]"
              >
                {" "}
                <img
                  src={envelope}
                  alt="LOGO - Mail Box
              "
                />{" "}
                {props.generalData.queryResult?.data.length > 0
                  ? props.generalData.queryResult?.data[0].email
                  : "--"}
              </a>
              <a
                style={{ textDecoration: "none" }}
                href={`tel:${
                  props.generalData.queryResult?.data.length > 0
                    ? props.generalData.queryResult?.data[0].contactNo
                    : "--"
                }`}
                title={`${
                  props.generalData.queryResult?.data.length > 0
                    ? props.generalData.queryResult?.data[0].contactNo
                    : "--"
                }`}
                className="flex gap-2 items-center font-montserrat text-[#4D5053] 3xl:text-[26px] text-base not-italic font-normal leading-[300%] tracking-[0.16px]"
              >
                <img
                  src={phone}
                  alt="LOGO - Call
               "
                />{" "}
                {props.generalData.queryResult?.data.length > 0
                  ? props.generalData.queryResult?.data[0].contactNo
                  : "--"}
              </a>
            </div>
          </div>
          <div className="max-w-[225px] min-h-[22px] max-h-[22px] 3xl:max-w-[320px] 3xl:min-h-[42px] 3xl:max-h-[42px] cursor-pointer flex justify-between items-center">
            <a
              href="https://www.facebook.com/Legateca"
              target="_blank"
              title="facebook"
              rel="noreferrer"
              className="text-decoration-none text-[#252525] w-[17px] 3xl:w-[32px] h-full"
            >
              <i className="fab fa-facebook-f 3xl:text-[32px] w-full hover:text-[#006766] h-full"></i>
            </a>
            <a
              href="https://twitter.com/Legateca"
              target="_blank"
              rel="noreferrer"
              title="X"
              alt="X-icon"
              className="w-[17px] 3xl:w-[32px] h-full"
            >
              <img
                src={isHovered ? twitterXGreen : twitterX}
                alt="x"
                className="h-full w-full"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/legateca"
              target="_blank"
              title="linkedin"
              rel="noreferrer"
              className="text-decoration-none text-[#252525] w-[17px] 3xl:w-[32px] h-full"
            >
              <i className="fab fa-linkedin-in 3xl:text-[32px] w-full hover:text-[#006766] h-full"></i>
            </a>
            <a
              href="https://www.instagram.com/Legateca"
              target="_blank"
              title="instagram"
              rel="noreferrer"
              className="text-decoration-none text-[#252525] w-[17px] 3xl:w-[32px] h-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                className="h-full  w-full hover:fill-[#006766]"
                viewBox="0 0 50 50"
              >
                <path d="M 16 3 C 8.8545455 3 3 8.8545455 3 16 L 3 34 C 3 41.145455 8.8545455 47 16 47 L 34 47 C 41.145455 47 47 41.145455 47 34 L 47 16 C 47 8.8545455 41.145455 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.054545 5 45 9.9454545 45 16 L 45 34 C 45 40.054545 40.054545 45 34 45 L 16 45 C 9.9454545 45 5 40.054545 5 34 L 5 16 C 5 9.9454545 9.9454545 5 16 5 z M 37 11 C 35.9 11 35 11.9 35 13 C 35 14.1 35.9 15 37 15 C 38.1 15 39 14.1 39 13 C 39 11.9 38.1 11 37 11 z M 25 14 C 18.954545 14 14 18.954545 14 25 C 14 31.045455 18.954545 36 25 36 C 31.045455 36 36 31.045455 36 25 C 36 18.954545 31.045455 14 25 14 z M 25 16 C 29.954545 16 34 20.045455 34 25 C 34 29.954545 29.954545 34 25 34 C 20.045455 34 16 29.954545 16 25 C 16 20.045455 20.045455 16 25 16 z" />
              </svg>
            </a>
            <a
              href="https://www.youtube.com/channel/UCly74QwTom6btQnaLozddxw"
              target="_blank"
              rel="noreferrer"
              title="youtube"
              className="text-decoration-none flex flex-col items-center justify-center text-[#252525] hover:text-[#006766] w-[17px] 3xl:w-[32px] h-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 359"
                fill="currentColor"
              >
                <path
                  d="M501.303 55.965C495.416 33.935 478.068 16.588 456.038 10.7C416.106 -2.86102e-06 256 0 256 0C256 0 95.893 -2.86102e-06 55.961 10.7C33.935 16.588 16.584 33.935 10.697 55.965C0 95.893 0 179.203 0 179.203C0 179.203 0 262.511 10.697 302.435C16.584 324.465 33.935 341.817 55.961 347.704C95.893 358.4 256 358.4 256 358.4C256 358.4 416.106 358.4 456.038 347.704C478.068 341.817 495.416 324.465 501.303 302.435C511.999 262.511 511.999 179.203 511.999 179.203C511.999 179.203 511.999 95.893 501.303 55.965ZM204.797 256.004V102.401L337.816 179.203L204.797 256.004Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
        </div>
          <div className="max-w-[225px] min-h-[22px] max-h-[22px] 3xl:max-w-[320px] 3xl:min-h-[42px] 3xl:max-h-[42px] cursor-pointer flex justify-between items-center block md:hidden">
            <a aria-label="Chat on WhatsApp" href="https://wa.me/+441635745074">
                <img alt="Chat on WhatsApp" src={WhatsAppButtonGreenSmall}/>
            </a>
          </div>
      </section>
      <div className="text-[#252525] text-center 3xl:py-3 text-xs 3xl:text-[30px] 3xl:!leading-[130%] not-italic font-normal leading-9 tracking-[0.24px] capitalize">
        © Copyrights {`${new Date().getFullYear()}`}.{" "}
        <span className="text-[#006766] 3xl:text-[30px] 3xl:!leading-[130%] text-xs not-italic font-bold leading-9 tracking-[0.24px] capitalize">
          Legateca.
        </span>{" "}
        All Rights Reserved
      </div>
      {cookieStatus && (
        <div
          className="mb-0 z-[100] alert cookie-bar alert-dismissible fade show"
          role="alert"
        >
          <span>
            We use cookies to ensure that we give you the best experience on our
            website. If you continue to use this site we will assume that you
            are happy with it.&nbsp;&nbsp;
            <span
              onClick={() => props.history.push("/privacy-policy")}
              className="cookie-privacy"
            >
              Privacy Policy
            </span>
          </span>
          <button
            title="Accept"
            className="btn btn-small"
            onClick={() => {
              localStorage.setItem("isCookieAccept", true);
              setCookieStatus(false);
            }}
          >
            Accept
          </button>
          {/* <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
            <i className="far fa-window-close"></i>
          </button> */}
        </div>
      )}
    </footer>
    // <footer className='footer_main'>
    //   <div className='footer-width'>
    //     <div className='row'>
    //       <div className=' footer-div col-12 col-lg-4 col-md-5 col-sm-6 d-flex'>
    //         <div className='w-200'>
    //           <img className='w-50 d' src={legatecaLogo} alt='' />
    //           <p className='mb-0 mt-3'>
    //             Cost effective legal consulting and dispute <br /> resolution
    //             service available in the UK
    //           </p>
    //           <div id='footer-collab-logos-container'>
    //             <div id='footer-collab-logos'>
    //               <a href='https://www.surreyincubation.co.uk/' target='_blank'>
    //                 <Image
    //                   className='footer-collab-logo '
    //                   src={SetSquaredLogo}
    //                   width={150}
    //                   style={{ borderRadius: '25px!important' }}
    //                   alt='Legateca is an official partner of SET Squared'
    //                 />
    //               </a>

    //               <a
    //                 href='https://law-simplified.co.uk/we-are-proudly-collaborating-with-legateca-the-pioneers-in-legal-tech/'
    //                 target='_blank'
    //               >
    //                 <Image
    //                   className='footer-collab-logo'
    //                   src={LawSimplifiedLogo}
    //                   width={75}
    //                   alt='Legateca is an official partner of Lawsimplified'
    //                 />
    //               </a>

    //               <a
    //                 href='https://www.uklta.org.uk/our-members'
    //                 target='_blank'
    //               >
    //                 <Image
    //                   className='footer-collab-logo'
    //                   src={UkltaLogo}
    //                   width={75}
    //                   alt='Legateca is an official partner of UKLTA'
    //                 />
    //               </a>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div
    //         // style={{
    //         //   width: isMobile ? "auto" : "15%",
    //         // }}
    //         className='col-12 col-lg-3 col-md-2 col-sm-6 '
    //       >
    //         <h4>Features</h4>
    //         <ul className='footer-menu'>
    //           <li>
    //             <div
    //               className='footer-menu-btn'
    //               onClick={() => handleService('/service/prelegate')}
    //               title='Prelegate'
    //               style={{
    //                 color: 'gray',
    //                 cursor: 'pointer',
    //                 fontFamily: 'montserrat',
    //               }}
    //             >
    //               Get Started
    //             </div>
    //           </li>
    //           <li>
    //             <NavLink
    //               style={{
    //                 fontFamily: 'montserrat',
    //               }}
    //               to='/service/recommendate'
    //               title='Recommendate'
    //             >
    //               Instant Advice
    //             </NavLink>
    //           </li>
    //           <li>
    //             <NavLink
    //               style={{
    //                 fontFamily: 'montserrat',
    //               }}
    //               to='/lawyer'
    //               title='Laywer'
    //             >
    //               Find a Lawyer
    //             </NavLink>
    //           </li>
    //           <li>
    //             <NavLink
    //               style={{
    //                 fontFamily: 'montserrat',
    //               }}
    //               to='/mediate'
    //               title='Mediate'
    //             >
    //               Mediation
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </div>
    //       <div
    //         // style={{
    //         //   width: isMobile ? "auto" : "20%",
    //         // }}
    //         className='col-12 col-lg-3 col-md-2 col-sm-6'
    //       >
    //         <h4
    //           style={{
    //             fontFamily: 'montserrat',
    //           }}
    //         >
    //           Quicklinks
    //         </h4>
    //         <ul className='footer-menu'>
    //           <li>
    //             <NavLink
    //               style={{
    //                 fontFamily: 'montserrat',
    //               }}
    //               to='/terms-and-conditions'
    //               title='Disclaimer'
    //             >
    //               Terms and Conditions
    //             </NavLink>
    //           </li>
    //           <li>
    //             <NavLink
    //               style={{
    //                 fontFamily: 'montserrat',
    //               }}
    //               to='/privacy-policy'
    //               title='Disclaimer'
    //             >
    //               Privacy Policy
    //             </NavLink>
    //           </li>
    //           <li>
    //             <Link
    //               style={{
    //                 fontFamily: 'montserrat',
    //               }}
    //               to='/articles'
    //               title='Terms And Conditions'
    //             >
    //               Blog and Article
    //             </Link>
    //           </li>

    //           <li>
    //             <NavLink
    //               style={{
    //                 fontFamily: 'montserrat',
    //               }}
    //               to='/faq'
    //               title='Disclaimer'
    //             >
    //               FAQs
    //             </NavLink>
    //           </li>

    //           {/* <li>
    //             <Link to="/privacy-policy" title="Privacy Policy">
    //               Privacy policy
    //             </Link>
    //           </li> */}
    //           {/* <li>
    //             <Link to="/faq" title="Disclaimer">
    //               FAQs
    //             </Link>
    //           </li> */}
    //           {/* <li>
    //             <a href="/articles" title="Articles">
    //               Articles
    //             </a>
    //           </li> */}
    //         </ul>
    //       </div>
    //       <div className='col-12 col-lg-2 col-md-3 col-sm-6'>
    //         <h4>Contact</h4>
    //         <ul className='contact-detail-list'>
    //           <li>
    //             <span
    //               style={{ textTransform: 'capitalize' }}
    //               className='working-days d-flex'
    //             >
    //               {' '}
    //               <span className='d-flex'>
    //                 <img
    //                   style={{
    //                     marginRight: '10px',
    //                   }}
    //                   className='clock'
    //                   src={clock}
    //                   alt=''
    //                 />
    //               </span>
    //               Monday to Friday
    //             </span>{' '}
    //             <span className='timing'> 08:30 - 18:00</span>
    //           </li>
    //           <li className='email'>
    //             <span className='working-days d-flex'>
    //               <span className='d-flex'>
    //                 <img
    //                   style={{
    //                     marginRight: '10px',
    //                   }}
    //                   className='envelope'
    //                   src={envelope}
    //                   alt=''
    //                 />
    //               </span>
    //               <a
    //                 style={{
    //                   textDecoration: 'none',
    //                   textTransform: 'lowercase',
    //                   fontFamily: 'montserrat',
    //                 }}
    //                 href={`mailto:${
    //                   props.generalData.queryResult?.data.length > 0
    //                     ? props.generalData.queryResult?.data[0].email
    //                     : ''
    //                 }`}
    //                 title={`${
    //                   props.generalData.queryResult?.data.length > 0
    //                     ? props.generalData.queryResult?.data[0].email
    //                     : '--'
    //                 }`}
    //               >
    //                 {props.generalData.queryResult?.data.length > 0
    //                   ? props.generalData.queryResult?.data[0].email
    //                   : '--'}{' '}
    //               </a>
    //             </span>
    //           </li>
    //           <li className='phone-number'>
    //             {/* <span>Do You Have a Question?</span> */}
    //             <span className='working-days d-flex'>
    //               <span className='d-flex'>
    //                 <img
    //                   style={{
    //                     marginRight: '10px',
    //                     fontFamily: 'montserrat',
    //                   }}
    //                   className='phone'
    //                   src={phone}
    //                   alt=''
    //                 />
    //               </span>
    //               <a
    //                 style={{ textDecoration: 'none' }}
    //                 href={`tel:${
    //                   props.generalData.queryResult?.data.length > 0
    //                     ? props.generalData.queryResult?.data[0].contactNo
    //                     : '--'
    //                 }`}
    //                 title={`${
    //                   props.generalData.queryResult?.data.length > 0
    //                     ? props.generalData.queryResult?.data[0].contactNo
    //                     : '--'
    //                 }`}
    //               >
    //                 {props.generalData.queryResult?.data.length > 0
    //                   ? props.generalData.queryResult?.data[0].contactNo
    //                   : '--'}
    //               </a>
    //             </span>
    //           </li>
    //           <li>
    //             {/* <span>Social Networks</span> */}
    //             <ul className='flex items-center gap-8 mt-2 !p-0'>
    //               <li>
    //                 <a
    //                   href='https://www.facebook.com/Legateca'
    //                   target='_blank'
    //                   title='facebook'
    //                   rel='noreferrer'
    //                 >
    //                   <i className='fab fa-facebook-f'></i>
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   href='https://twitter.com/Legateca'
    //                   target='_blank'
    //                   rel='noreferrer'
    //                   title='X'
    //                 >
    //                   {/* <i
    //                     className="fab fa-x-twitter-f"
    //                     // style={{ color: "#9ba7bb", backgroundColor: "aqua" }}
    //                   ></i>{" "} */}
    // <img
    //   src={isHovered ? twitterXGreen : twitterX}
    //   alt='x'
    //   width={15}
    //   height={15}
    //   style={{
    //     marginBottom: '2px',
    //   }}
    //   onMouseEnter={() => setIsHovered(true)}
    //   onMouseLeave={() => setIsHovered(false)}
    // />
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   href='https://www.linkedin.com/company/legateca'
    //                   target='_blank'
    //                   title='linkedin'
    //                   rel='noreferrer'
    //                 >
    //                   <i className='fab fa-linkedin-in'></i>
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   href='https://www.instagram.com/Legateca'
    //                   target='_blank'
    //                   title='instagram'
    //                   rel='noreferrer'
    //                 >
    //                   <i className='fab fa-instagram'></i>
    //                 </a>
    //                 {/* <a
    //                   href="https://blog.legateca.com/"
    //                   target="_blank"
    //                   title="medium"
    //                   rel="noreferrer"
    //                 >
    //                   <i className="fab fa-medium"></i>
    //                 </a> */}
    //               </li>
    //               {/* <li>
    //                 <Link to="/" title="pinterest">
    //                   <i className="fab fa-pinterest"></i>
    //                 </Link>
    //               </li>
    //               <li>
    //                 <Link to="/" title="google-plus">
    //                   <i className="fab fa-google-plus-g"></i>
    //                 </Link>
    //               </li>
    //               <li>
    //                 <Link to="/" title="vimeo">
    //                   <i className="fab fa-vimeo-v"></i>
    //                 </Link>
    //               </li> */}
    //             </ul>
    //           </li>
    //         </ul>
    //       </div>
    //       {/* <div className="col-12 col-lg-4 col-md-5 col-sm-6 contact-detail"></div> */}
    //     </div>

    //     <div className='copyright row'>
    //       <div className='col-lg-12 col-md-12 col-12 text-center'>
    //         Copyright &copy; {moment().format('YYYY')} Legateca All rights
    //         reserved
    //       </div>
    //     </div>
    //     <Link to='/' id='back-to-top' title='Back to top'>
    //       <span className='scroll-back-to-top-inner'>Back To Top</span>&uarr;
    //     </Link>
    //   </div>
    //   {cookieStatus && (
    //     <div
    //       className="mb-0 alert cookie-bar alert-dismissible fade show"
    //       role="alert"
    //     >
    //       <span>
    //         We use cookies to ensure that we give you the best experience on our
    //         website. If you continue to use this site we will assume that you
    //         are happy with it.&nbsp;&nbsp;
    //         <span
    //           onClick={() => props.history.push("/privacy-policy")}
    //           className="cookie-privacy"
    //         >
    //           Privacy Policy
    //         </span>
    //       </span>
    //       <button
    //         title="Accept"
    //         className="btn btn-small"
    //         onClick={() => {
    //           localStorage.setItem("isCookieAccept", true);
    //           setCookieStatus(false);
    //         }}
    //       >
    //         Accept
    //       </button>
    //       <button
    //       type="button"
    //       className="close"
    //       data-dismiss="alert"
    //       aria-label="Close"
    //     >
    //       <!-- <span aria-hidden="true">&times;</span> -->
    //       <i className="far fa-window-close"></i>
    //     </button>
    //   </div>
    // </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || false,
    generalData: state["general-data"] || {},
  };
};

export default compose(withRouter, connect(mapStateToProps))(FooterMain);
