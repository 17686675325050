import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import services from "../../server/services";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Link } from "react-router-dom";
import { HeroSection } from "../../Pages/About";
import Footer from "../Footer";
import legatecafirstlogo from "../../assets/images/legatecaFirstLogo.svg";
import { variant } from "../../Pages/Partner";

const Contactus = (props) => {
  let initialState = {
    name: "",
    email: "",
    phone: "",
    // subject: "",
    note: "",
  };
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  const addValidateInfo = (value) => {
    let errors = {};

    if (!value.name) {
      errors.name = "Name Required";
    } else if (value.name.trim().length === 0) {
      errors.name = "Name Required";
    }
    if (!value.email) {
      errors.email = "Email Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
      errors.email = "Email address is invalid";
    }

    if (!/^\+?\d+$/.test(value.phone)) {
      errors.phone = "Contact Number Must be a Number";
    }
    if (!value.note) {
      errors.note = "Message Required";
    }

    return errors;
  };
  // const addValidateInfo = (value) => {
  //   let errors = {};

  //   if (!value.name) {
  //     errors.name = "Name Required";
  //   } else if (value.name.trim().length === 0) {
  //     errors.name = "Name Required";
  //   }
  //   if (!value.email) {
  //     errors.email = "Email Required";
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
  //     errors.email = "Email address is invalid";
  //   }

  //   if (!/^\+?\d+$/.test(value.phone)) {
  //     errors.phone = "Contact Number Must be a Number";
  //   }
  //   if (!value.subject) {
  //     errors.subject = "Subject Required";
  //   }
  //   if (!value.note) {
  //     errors.note = "Message Required";
  //   }

  //   return errors;
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const validate = (e) => {
    e.preventDefault();

    let verifyToken = localStorage.getItem("verifyToken")

    if (verifyToken) {
      return ""
    }

    const validationResult = addValidateInfo(values);
    if (Object.keys(validationResult).length > 0) {
      setErrors(validationResult);
    } else {
      let data = {
        name: values.name,
        email: values.email,
        phoneNo: values.phone,
        message: values.note,
        type: "contact",
      };
      services["contact-us"].create(data);
      toast.success("We will get in touch with you soon", {
        hideProgressBar: true,
        autoClose: 2000,
      });
      setErrors({});
      setValues(initialState);
    }
  };
  let Subheading =
    "text-[#E3B576] text-values-align lg:text-sm 3xl:text-[30px] text-base not-italic font-medium 3xl:!leading-[130%] !leading-[45px] tracking-[0.28px] uppercase";
  let Subheading2 =
    "text-white lg:text-sm text-base 3xl:text-[30px] 3xl:!leading-[130%]  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]";
  let heading =
    "text-white text-values-align text-[32px] 3xl:text-[60px] lg:text-[42px] not-italic lg:font-medium font-semibold 3xl:!leading-[130%] lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase";
  let headingTop =
    "text-[#006766] text-values-align text-[32px] 3xl:text-[60px] lg:text-[42px] not-italic lg:font-medium font-semibold 3xl:!leading-[130%] lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase";
  let heading2 =
    "text-white text-center 3xl:text-[30px] text-base not-italic font-semibold !leading-6 3xl:!leading-[130%] tracking-[0.32px] uppercase";
  let bgImg =
    "https://s3-alpha-sig.figma.com/img/4099/a648/673431504381f7f288089e7f5a92990e?Expires=1705276800&Signature=FvmoQWZX~LEQnWGVCgpTPGwqEXp46tcxANE9HaHegTu9z2qs8-83N53IKkfeJXWpYJF3Vt9jSlI22BVDRUd9iN-h6lyZEzwRVG0AVPxp-pUhFT3s5TmYaaXcxrr5DVQY3AdSnsXa3yD99bLe2WSsLskg0hx~FThCaaqjXGWl6L4yH77iYg2i9IsyZyfgImqrdU~ADOz5Osw-XE0sQFseJ01798YGFCouDvQARCK3m7~e4ztNEjT52U8QSXEXHmvqz2-37p5Va41lBMU~ynbz6D2DjDgA3a~5xrqdYadWZ~t9ac8Me7sRHGs~Zq55RLT5zBzUedh-Wk5gWndVjAo89w__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4";
  const script = document.createElement("script");

  localStorage.setItem("verifyToken", true)
  useEffect(() => {
    const REACT_APP_CYBERSIARA_PUBLIC_KEY =
      process.env.REACT_APP_CYBERSIARA_PUBLIC_KEY;

    script.innerHTML = `
      $(function () {
        var PublicKey = "${REACT_APP_CYBERSIARA_PUBLIC_KEY}";
        InitCaptcha(PublicKey);
        $('.CaptchaSubmit').click(function () {
          if (CheckCaptcha()) {
            // console.log(CyberSiaraToken);
            localStorage.removeItem("verifyToken")
            
          }
        });
      });
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <HeroSection heading="Contact us" bgImg={"./contact-us.png"} />
      <main className="xl:py-[100px] py-[70px] w-full xl:max-w-[1240px] 3xl:max-w-[2400px] mx-auto">
        <div className="xl:pb-[50px] pb-[30px] flex flex-col xl:gap-[15px] items-center">
          <div className="w-full xl:px-0 padding-md  flex flex-col  items-center">
            <div className={`${Subheading} `}>CONTACT US</div>
            <div className={`${headingTop}`}>GET IN TOUCH</div>
          </div>
        </div>
        <section className="bg-class w-full  ">
          <div className="bg-[#006766F2] flex xl:flex-row flex-col justify-between items-center px-[20px] py-[34px] 3xl:px-[80px] 3xl:py-[44px]   w-full h-full">
            <div
              className={
                "py-8 flex flex-col  xl:items-start items-center gap-4 xl:min-w-[480px] xl:max-w-[480px] 3xl:min-w-[910px] 3xl:max-w-[910px]"
              }
            >
              <div className={heading}>
                Want to know more, let’s start talking
              </div>
              <div className="flex justify-between sm:flex-row flex-col gap-2  xl:min-w-[480px] xl:max-w-[480px] 3xl:min-w-[910px] 3xl:max-w-[910px] w-full">
                <div className="flex flex-col gap-[9px] items-start xl:max-w-[246px] 3xl:min-w-[446px] 3xl:max-w-[446px] w-full">
                  <div className="flex gap-[9px] items-center">
                    <img className="object-cover" src="./call.png" alt="call" />
                    <div className={`!font-[700] !capitalize ${heading2} `}>
                      Call Us
                    </div>
                  </div>
                  <div className={`!font-[700]  ${heading2} `}>
                    +44 163 574 5074
                  </div>
                  <div
                    className={`text-white text-base not-italic font-light leading-[149%] tracking-[0.32px] 3xl:text-[24px]  3xl:!leading-[130%] `}
                  >
                    Wish to discuss your legal needs? Call us today.
                  </div>
                </div>
                <div className="flex flex-col gap-[9px] items-start  xl:max-w-[246px] 3xl:min-w-[446px] 3xl:max-w-[446px] w-full">
                  <div className="flex h-full gap-[9px] items-center">
                    <img
                      className="object-cover"
                      src="./message.png"
                      alt="message"
                    />
                    <div className={`!font-[700] !capitalize ${heading2} `}>
                      Email Us
                    </div>
                  </div>
                  <div className={`!font-[700] ${heading2} lowercase`}>
                    info@legateca.com
                  </div>
                  <div
                    className={`text-white text-base not-italic font-light leading-[149%] tracking-[0.32px] 3xl:text-[24px]  3xl:!leading-[130%] `}
                  >
                    We welcome your questions and enquiries.
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white w-full xl:min-w-[588px] xl:max-w-[588px] 3xl:min-w-[1088px] 3xl:max-w-[1088px] rounded-[8px] pt-[26px] pb-[36px] max-sm:px-2 sm:px-10 3xl:pb-[46px] 3xl:px-[40px]">
              <div className="text-[#006766] text-lg xl:text-3xl 3xl:text-[60px] not-italic font-medium 3xl:!leading-[130%] !leading-[28px] xl:!leading-[48px] tracking-[0.6px] uppercase">
                Send us a Message
              </div>
              <div className="text-[#707070] text-sm 3xl:text-[30px] not-italic font-normal !leading-[26px] 3xl:!leading-[130%] tracking-[0.28px]">
                Fill the form below
              </div>
              <form
                method="post"
                className="contact-validation-active flex flex-col 3xl:gap-8"
                id="contact-form-main"
                noValidate="noValidate"
                onSubmit={validate}
              >
                <div className='flex flex-col gap-[70px]'>
                  <div className="flex flex-col gap-2">
                    <div className="flex lg:flex-row flex-col items-center gap-[10px] w-full">
                      <InputCommon
                        id={0}
                        error={
                          errors.name && (
                            <span className="error-message 3xl:text-[28px]">
                              {errors.name}
                            </span>
                          )
                        }
                        value={values.name}
                        handleChange={handleChange}
                        name={"name"}
                        placeholder="Your Name"
                        svg={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-5"
                            viewBox="0 0 12 12"
                            fill="currentColor"
                          >
                            <path
                              d="M6 6C6.69223 6 7.36892 5.82405 7.9445 5.49441C8.52007 5.16477 8.96867 4.69623 9.23358 4.14805C9.49849 3.59987 9.5678 2.99667 9.43275 2.41473C9.2977 1.83279 8.96436 1.29824 8.47487 0.878681C7.98539 0.459123 7.36175 0.173401 6.68282 0.0576455C6.00388 -0.0581101 5.30015 0.00129985 4.66061 0.228363C4.02107 0.455426 3.47444 0.839943 3.08986 1.33329C2.70527 1.82664 2.5 2.40666 2.5 3C2.5 3.79565 2.86875 4.55871 3.52513 5.12132C4.1815 5.68393 5.07174 6 6 6ZM6 0.857144C6.49445 0.857144 6.9778 0.98282 7.38893 1.21828C7.80005 1.45374 8.12048 1.78841 8.3097 2.17996C8.49892 2.57152 8.54843 3.00238 8.45196 3.41805C8.3555 3.83372 8.1174 4.21555 7.76777 4.51523C7.41814 4.81491 6.97268 5.019 6.48773 5.10168C6.00277 5.18437 5.50011 5.14193 5.04329 4.97974C4.58648 4.81755 4.19603 4.5429 3.92133 4.19051C3.64662 3.83812 3.5 3.42382 3.5 3C3.5 2.43168 3.76339 1.88664 4.23223 1.48477C4.70107 1.08291 5.33696 0.857144 6 0.857144Z"
                              fill="currentColor"
                            />
                            <path
                              d="M6.5 6.85712H5.5C4.04131 6.85712 2.64236 7.3538 1.61091 8.2379C0.579463 9.122 0 10.3211 0 11.5714C0 11.6851 0.0526785 11.7941 0.146447 11.8744C0.240215 11.9548 0.367392 12 0.5 12H11.5C11.6326 12 11.7598 11.9548 11.8536 11.8744C11.9473 11.7941 12 11.6851 12 11.5714C12 10.3211 11.4205 9.122 10.3891 8.2379C9.35764 7.3538 7.95869 6.85712 6.5 6.85712ZM1.03 11.1428C1.15295 10.2002 1.67676 9.32954 2.50134 8.69707C3.32592 8.0646 4.39347 7.71471 5.5 7.71426H6.5C7.60653 7.71471 8.67408 8.0646 9.49866 8.69707C10.3232 9.32954 10.8471 10.2002 10.97 11.1428H1.03Z"
                              fill="currentColor"
                            />
                          </svg>
                        }
                      />
                      <InputCommon
                        id={1}
                        error={
                          errors.phone && (
                            <span className="error-message 3xl:text-[28px]">
                              {errors.phone}
                            </span>
                          )
                        }
                        value={values.phone}
                        handleChange={handleChange}
                        name={"phone"}
                        placeholder="Your Phone Number"
                        svg={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-5"
                            viewBox="0 0 12 14"
                            fill="currentColor"
                          >
                            <path
                              d="M10.6159 14H10.5374C1.47155 13.3915 0.184328 4.46385 0.00439445 1.73923C-0.0101039 1.52739 0.0113559 1.31429 0.0675442 1.11214C0.123733 0.909999 0.213545 0.722779 0.331836 0.561213C0.450127 0.399646 0.594571 0.266909 0.756891 0.170606C0.919212 0.0743029 1.09622 0.0163271 1.27777 5.04857e-07H3.81991C4.00472 -0.000208291 4.18532 0.0643519 4.33832 0.185318C4.49132 0.306285 4.60968 0.478084 4.67806 0.678462L5.37934 2.69231C5.44686 2.88806 5.46362 3.10266 5.42753 3.30943C5.39144 3.51621 5.30409 3.70605 5.17634 3.85539L4.19362 5.01308C4.34713 6.03117 4.76488 6.96967 5.39036 7.70159C6.01585 8.43352 6.81887 8.92355 7.69079 9.10538L8.69196 7.94769C8.82184 7.80024 8.98603 7.70067 9.16407 7.66141C9.34211 7.62215 9.52612 7.64494 9.69313 7.72692L11.4325 8.54C11.6016 8.62232 11.7459 8.76154 11.8469 8.9399C11.948 9.11827 12.0013 9.32771 12 9.54154V12.3846C12 12.813 11.8542 13.2239 11.5946 13.5269C11.335 13.8298 10.983 14 10.6159 14ZM1.3885 1.07692C1.26614 1.07692 1.14879 1.13365 1.06226 1.23464C0.97574 1.33562 0.927132 1.47258 0.927132 1.61539V1.65846C1.13936 4.84615 2.5004 12.3846 10.5882 12.9231C10.6488 12.9274 10.7096 12.9178 10.767 12.8947C10.8244 12.8716 10.8773 12.8355 10.9228 12.7885C10.9682 12.7415 11.0052 12.6845 11.0317 12.6207C11.0583 12.5569 11.0737 12.4877 11.0772 12.4169V9.54154L9.33788 8.72846L8.01375 10.2631L7.7923 10.2308C3.77839 9.64385 3.23397 4.95923 3.23397 4.91077L3.20629 4.65231L4.51658 3.10692L3.82453 1.07692H1.3885Z"
                              fill="currentColor"
                            />
                          </svg>
                        }
                      />
                    </div>
                    <InputCommon
                      id={2}
                      error={
                        errors.email && (
                          <span className="error-message 3xl:text-[28px]">
                            {errors.email}
                          </span>
                        )
                      }
                      value={values.email}
                      handleChange={handleChange}
                      name={"email"}
                      placeholder="Email Address"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-5"
                          viewBox="0 0 14 12"
                          fill="currentColor"
                        >
                          <path
                            d="M12.25 0H1.75C0.783562 0 0 1.024 0 2.28571V9.71429C0 10.9766 0.783562 12 1.75 12H12.25C13.2164 12 14 10.9766 14 9.71429V2.28571C14 1.024 13.2164 0 12.25 0ZM0.875 3L3.93706 6L0.875 9V3ZM13.125 9.71429C13.125 10.3451 12.7321 10.8571 12.25 10.8571H1.75C1.26744 10.8571 0.875 10.3451 0.875 9.71429L4.3015 6.35714L6.2125 8.22971C6.44569 8.45714 6.72262 8.57257 7 8.57257C7.27694 8.57257 7.55387 8.45771 7.78706 8.22971L9.6985 6.35714L13.125 9.71429ZM13.125 9L10.0625 6L13.125 3V9ZM7.52456 7.77257C7.37187 7.92229 7.19075 8.00114 7 8.00114C6.80925 8.00114 6.62769 7.92171 6.475 7.77257L4.66594 6L4.3015 5.64286L0.875 2.28629V2.28571C0.875 1.65543 1.26744 1.14286 1.75 1.14286H12.25C12.7321 1.14286 13.125 1.65543 13.125 2.28571L7.52456 7.77257Z"
                            fill="currentColor"
                          />
                        </svg>
                      }
                    />
                  </div>
                  <InputCommon
                    id={3}
                    error={
                      errors.note && (
                        <span className="error-message 3xl:text-[28px]">
                          {errors.note}
                        </span>
                      )
                    }
                    elem={true}
                    value={values.note}
                    handleChange={handleChange}
                    name={"note"}
                    placeholder="Short Summary"
                    svg={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-5"
                        viewBox="0 0 14 14"
                        fill="currentColor"
                      >
                        <path
                          d="M12.3574 1.45846e-06H1.64037C1.20532 1.45846e-06 0.788082 0.172852 0.480453 0.480527C0.172824 0.788203 0 1.2055 0 1.64062L0 8.70294C0 9.13805 0.172824 9.55535 0.480453 9.86303C0.788082 10.1707 1.20532 10.3436 1.64037 10.3436H4.18622C4.21522 10.3436 4.24304 10.3551 4.26355 10.3756C4.28406 10.3961 4.29558 10.4239 4.29558 10.4529V13.2343C4.29557 13.3858 4.34046 13.5338 4.42457 13.6597C4.50869 13.7856 4.62825 13.8838 4.76813 13.9417C4.90801 13.9997 5.06194 14.0148 5.21044 13.9853C5.35894 13.9557 5.49535 13.8828 5.60241 13.7757L9.00125 10.3764C9.01147 10.3662 9.02359 10.3582 9.03691 10.3528C9.05023 10.3473 9.0645 10.3446 9.07889 10.3446H12.3596C12.7947 10.3446 13.2119 10.1718 13.5195 9.86412C13.8272 9.55645 14 9.13915 14 8.70403V1.64062C14 1.42499 13.9575 1.21147 13.8749 1.01228C13.7924 0.813085 13.6713 0.632128 13.5188 0.479753C13.3662 0.327379 13.1851 0.206578 12.9859 0.124258C12.7866 0.0419376 12.573 -0.000286053 12.3574 1.45846e-06ZM13.3417 8.70294C13.3417 8.96401 13.238 9.21439 13.0534 9.39899C12.8688 9.5836 12.6185 9.68731 12.3574 9.68731H9.07671C8.87446 9.68584 8.67985 9.76448 8.53539 9.90606L5.13982 13.3109C5.12453 13.3263 5.10498 13.3368 5.08369 13.3411C5.06239 13.3454 5.0403 13.3432 5.02024 13.3349C5.00018 13.3265 4.98305 13.3124 4.97105 13.2943C4.95904 13.2762 4.95269 13.255 4.95282 13.2332V10.4529C4.95282 10.2499 4.87217 10.0551 4.72861 9.91155C4.58505 9.76797 4.39034 9.68731 4.18731 9.68731H1.64037C1.37934 9.68731 1.129 9.5836 0.944419 9.39899C0.759842 9.21439 0.656147 8.96401 0.656147 8.70294V1.64062C0.656147 1.37955 0.759842 1.12917 0.944419 0.944564C1.129 0.759959 1.37934 0.656249 1.64037 0.656249H12.3574C12.6185 0.656249 12.8688 0.759959 13.0534 0.944564C13.238 1.12917 13.3417 1.37955 13.3417 1.64062V8.70294Z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  />
                </div>


                <div className="flex  justify-between mt-3 overflow-hidden sm:flex-row flex-col items-center gap-4 sm:gap-0    ">
                  <div className="SiaraShield centered relative flex justify-center sm:justify-start"></div>
                  <span className="CaptchaSubmit ">
                    <button
                      type={"submit"}
                      className={`min-w-[204px] max-w-[204px] 3xl:min-w-[350px] 3xl:max-w-[350px] flex ${variant(
                        "primary"
                      )} px-[28px] py-[9px] 3xl:py-6 3xl:px-[32px]  items-center rounded-md 3xl:rounded-lg justify-between border-none`}
                    >
                      <img
                        src={legatecafirstlogo}
                        alt="nill"
                        className="3xl:h-[66px] 3xl:w-[50px]"
                      />
                      <div className="text-white text-sm not-italic font-medium  tracking-[0.28px] uppercase font-montserrat  3xl:text-[30px] ">
                        Submit Now
                      </div>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </section>
        <style>
          {`
          .bg-class{
            background-image:url(${bgImg});
            background-repeat:no-repeat;
            background-size:cover
          }
          `}
        </style>
      </main>
      <Footer />
    </>
  );
  // return (

  //   <article>
  //     <div className="page-breadcumb-area">
  //       <div className="container">
  //         <div className="row">
  //           <div className="col-12">
  //             <div className="wpo-breadcumb-wrap">
  //               <h2>Contact</h2>
  //               <ul>
  //                 <li>
  //                 <Link to="/home">Home</Link>
  //                 </li>
  //                 <li>
  //                   <span>&nbsp; / &nbsp;</span>
  //                   <span>Contact</span>
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <section className="sec-contact content_area">
  //       <div className="container">
  //         <div className="contact_info">
  //           <div className="row" style={{
  //             display:'flex',
  //             justifyContent:'center'
  //           }}>
  //             {/* <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
  //               <div className="office-info-item">
  //                 <div className="office-info-icon">
  //                   <div className="icon">
  //                     <i className="fas fa-map-marker-alt"></i>
  //                   </div>
  //                 </div>
  //                 <div className="office-info-text">
  //                   <h2>Address</h2>
  //                   <p>
  //                     {props.generalData.queryResult.data.length > 0
  //                       ? props.generalData.queryResult.data[0].address
  //                       : "--"}
  //                   </p>
  //                 </div>
  //               </div>
  //             </div> */}
  //             <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
  //               <div className="office-info-item">
  //                 <div className="office-info-icon">
  //                   <div className="icon">
  //                     <i className="far fa-envelope"></i>
  //                   </div>
  //                 </div>
  //                 <div className="office-info-text">
  //                   <h2>Email Us</h2>
  //                   <p>
  //                     {props.generalData.queryResult.data.length > 0
  //                       ? props.generalData.queryResult.data[0].email
  //                       : "--"}
  //                   </p>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
  //               <div className="office-info-item">
  //                 <div className="office-info-icon">
  //                   <div className="icon">
  //                     <i className="fas fa-mobile-alt"></i>
  //                   </div>
  //                 </div>
  //                 <div className="office-info-text">
  //                   <h2>Call Now</h2>
  //                   <p>
  //                     {props.generalData.queryResult.data.length > 0
  //                       ? props.generalData.queryResult.data[0].contactNo
  //                       : "--"}
  //                   </p>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="contact-title">
  //           <h2>Send us a Message</h2>
  //           <p>
  //             Other Ways to find answers - check FAQs
  //           </p>
  //         </div>
  //         <div className="contact-form-area">
  //           <form
  //             method="post"
  //             className="contact-validation-active"
  //             id="contact-form-main"
  //             novalidate="novalidate"
  //             onSubmit={validate}
  //           >
  //             <div className="field-group">
  //               <input
  //                 type="text"
  //                 className="form-control"
  //                 name="name"
  //                 id="name"
  //                 placeholder="Your Name*"
  //                 onChange={handleChange}
  //                 value={values.name}
  //               />
  //               {errors.name && (
  //                 <span className="error-message">{errors.name}</span>
  //               )}
  //             </div>
  //             <div className="field-group">
  //               <input
  //                 type="email"
  //                 className="form-control"
  //                 name="email"
  //                 id="email"
  //                 placeholder="Your Email*"
  //                 required
  //                 onChange={handleChange}
  //                 value={values.email}
  //               />
  //               {errors.email && (
  //                 <span className="error-message">{errors.email}</span>
  //               )}
  //             </div>
  //             <div className="field-group">
  //               <input
  //                 type="text"
  //                 className="form-control"
  //                 name="phone"
  //                 id="phone"
  //                 placeholder="Your Phone*"
  //                 required
  //                 onChange={handleChange}
  //                 value={values.phone}
  //               />
  //               {errors.phone && (
  //                 <span className="error-message">{errors.phone}</span>
  //               )}
  //             </div>
  //             <div className="field-group">
  //               <select
  //                 name="subject"
  //                 className="form-control"
  //                 onChange={handleChange}
  //               >
  //                 <option selected="">Select</option>
  //                 <option>Family Law</option>
  //                 <option>Personal Injury</option>
  //                 <option>Criminal Law</option>
  //                 <option>Education Law</option>
  //                 <option>Business Law</option>
  //               </select>
  //               {errors.subject && (
  //                 <span className="error-message">{errors.subject}</span>
  //               )}
  //             </div>
  //             <div className="fullwidth field-group">
  //               <textarea
  //                 className="form-control"
  //                 name="note"
  //                 id="note"
  //                 placeholder="Message..."
  //                 onChange={handleChange}
  //                 value={values.note}
  //               ></textarea>
  //               {errors.note && (
  //                 <span className="error-message">{errors.note}</span>
  //               )}
  //             </div>
  //             <div className="submit-area field-group centered">
  //               <div className="SiaraShield centered"></div>
  //             </div>
  //             <div className="submit-area field-group centered">
  //               <button type="submit" className="getStarted w-auto CaptchaSubmit">
  //                 Get in Touch
  //               </button>
  //               <div id="loader">
  //                 <i className="ti-reload"></i>
  //               </div>
  //             </div>
  //           </form>
  //         </div>
  //       </div>
  //       <section className="contact-map-section">
  //         <div className="contact-map">
  //           <iframe
  //             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39831.2153260705!2d-1.2875409487781122!3d51.394768181776506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48741e1d873bb805%3A0x789a70be03ee968b!2sThatcham%2C%20UK!5e0!3m2!1sen!2sin!4v1635924386753!5m2!1sen!2sin"
  //             title="Contact Map"
  //             allowfullscreen="allowfullscreen"
  //           ></iframe>
  //         </div>
  //       </section>
  //     </section>
  //   </article>
  // );
};

const mapStateToProps = (state) => {
  return {
    generalData: state["general-data"] || {},
  };
};

export default compose(withRouter, connect(mapStateToProps))(Contactus);
const InputCommon = ({
  placeholder,
  error,
  type,
  name,
  id,
  value,
  handleChange,
  svg,
  elem
}) => {
  const [idVal, setidVal] = useState(null);
  return (
    <div className="flex flex-col gap-1 items-start w-full">
      <div
        className={`flex w-full gap-2 ${elem ? "" : 'p-[10px]'} border-b ${id === idVal && value.length
          ? " text-[#006766] border-[#006766]"
          : "text-[#AEAEAE] border-[#AEAEAE]"
          }  items-center`}
      >
        {svg}
        {elem ? <textarea
          onClick={() => {
            setidVal(id);
          }}
          name={name}
          value={value}
          onChange={(e) => handleChange(e)}
          type={type}
          placeholder={placeholder}
          className="w-full max-h-[50px] input-cls p-2.5 border-none caret-[#AEAEAE] focus:caret-[#006766] text-[#AEAEAE] focus:text-[#006766] 3xl:text-[30px] max-xl:text-xs xl:text-sm not-italic font-[500] !leading-[149%] tracking-[0.28px] bg-transparent outline-none focus:!border-none   flex-col justify-start items-start gap-2.5 inline-flex"
        />
          : <input
            onClick={() => {
              setidVal(id);
            }}
            name={name}
            value={value}
            onChange={(e) => handleChange(e)}
            type={type}
            placeholder={placeholder}
            className="w-full input-cls p-2.5 border-none caret-[#AEAEAE] focus:caret-[#006766] text-[#AEAEAE] focus:text-[#006766] 3xl:text-[30px] max-xl:text-xs xl:text-sm not-italic font-[500] !leading-[149%] tracking-[0.28px] bg-transparent outline-none focus:!border-none   flex-col justify-start items-start gap-2.5 inline-flex"
          />
        }
        <style>
          {`
   .input-cls::placeholder {
    color: #AEAEAE;
    font-style: normal;
    font-weight: 500;
    line-height: 149%;
    letter-spacing: 0.28px;
  }
  @media screen and (min-width:2400px){
    .input-cls::placeholder {
      color: #AEAEAE;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 149%;
      letter-spacing: 0.28px;
    }
  }
   `}
        </style>
      </div>
      {error}
    </div>
  );
};
