import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import React, { useContext } from "react";
import { AuthContext } from "../../contexts/authContext";
import { toast } from "react-toastify";

let LoginForm = (props) => {
  const { handleSubmit } = props;
  const authContext = useContext(AuthContext);

  let hanldeResend = async () => {
    try {
      let email = document.getElementById("email").value;
      if (!email) {
        throw new Error("Please enter your email to resend varification code.");
      } else if (email.trim().length === 0) {
        throw new Error("Please enter your email to resend varification code.");
      }
      let data = await authContext.resendVerificationCode(email);
      toast.success("Verification code has resent.", {
        hideProgressBar: true,
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error.message, {
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          id="email"
          name="email"
          component="input"
          type="email"
          className="form-control input-lg"
          placeholder="Email address"
          required
        />
        {props.ValidationError && props.ValidationError.email && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.email}
          </div>
        )}
      </div>
      <div className="form-group">
        <Field
          name="code"
          component="input"
          type="text"
          className="form-control input-lg"
          placeholder="Verification Code"
        />
        {props.ValidationError && props.ValidationError.code && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.code}
          </div>
        )}
      </div>
      <div
        style={{
          textAlign: "end",
          margin: "-18px 0 15px 0",
          color: "#006766",
          cursor: "pointer",
        }}
        onClick={() => hanldeResend()}
      >
        Resend code
      </div>

      <div className="form-group text-center mb-0">
        <button
          style={{
            borderRadius: "5px",
            backgroundColor: "#E3B576",
            display: "inline-flex",
            padding: "9px 28px",
            alignItems: "center",
            color: "white",
            gap: "21px",
            fontWeight: 500,
            border: "none", // Remove the border

            textDecoration: "none",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#006766")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#E3B576")}
          type="submit"
          className="getStarted"
        >
          Verify
        </button>
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  return {};
}

LoginForm = compose(
  withRouter,
  reduxForm({
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    form: "login",
  }),
  connect(mapStateToProps)
)(LoginForm);

export default LoginForm;
