import MemoryStorage from "memorystorage";
var DB;
try {
  var x = "test-localstorage-" + Date.now();
  sessionStorage.setItem(x, x);
  var y = sessionStorage.getItem(x);
  sessionStorage.removeItem(x);
  if (y !== x) {
    throw new Error();
  }
  DB = sessionStorage;
} catch (e) {
  DB = new MemoryStorage("backupStorage"); // fall back to a memory-based implementation
}

export const loadState = () => {
  try {
    const serializedState = DB.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.log("Failed to load state: " + err);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    DB.setItem("state", serializedState);
  } catch (err) {
    // .log('Failed to save state: ' + err);
  }
};
