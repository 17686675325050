import React, { useEffect } from "react";
import Layout from "../../Components/Layout";

const Disclaimer = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
    return () => {
    };
  }, []);

  return (
    <Layout>
          <section className="page-breadcumb-area">
               <div className="container">
                    <div className="row">
                         <div className="col-12">
                         <div className="wpo-breadcumb-wrap">
                              <h2>Disclaimer</h2>
                              <ul>
                                   <li><a href="/lawyem">Home</a></li>
                                   <li><span>&nbsp; / &nbsp;</span><span>Disclaimer</span></li>
                              </ul>
                         </div>
                         </div>
                    </div>
               </div>
          </section>
          <div className="sec-categories">
               <section className="content-page about-box">
                    <div className="container">
                         <h1 className="mb-4"> Disclaimer </h1>
                         <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis, officiis in, ullam neque maiores a consectetur aperiam error delectus vero non minus? Ab aut, vitae sed eaque porro necessitatibus, culpa quas nihil eum aliquam veritatis dicta perspiciatis. Minus corporis repudiandae est, labore blanditiis sit ullam fugiat praesentium modi, asperiores quod..</p>
                         <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis, officiis in, ullam neque maiores a consectetur aperiam error delectus vero non minus? Ab aut, vitae sed eaque porro necessitatibus, culpa quas nihil eum aliquam veritatis dicta perspiciatis. Minus corporis repudiandae est, labore blanditiis sit ullam fugiat praesentium modi, asperiores quod..</p>
                    </div>
               </section>
         </div>
          
    </Layout>
  );
};

export default Disclaimer;
