import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createPromise } from 'redux-promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers';
import { loadState, saveState } from '../util/local-storage';
import { reauthCheck } from '../middleware/reauth-check';

const middleware = composeWithDevTools(
  applyMiddleware(createPromise(), thunk, reauthCheck)
);

const persistedState = loadState();
const store = createStore(rootReducer, persistedState, middleware);
store.subscribe(() => {
  saveState({
    auth: {
      isSignedIn: store.getState().auth.isSignedIn,
      token: store.getState().auth.token,
      user: store.getState().auth.user,
    }
  });
});


export default store;
