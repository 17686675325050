import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Layout from '../Components/Layout';
import LoginArt from '../assets/images/login-art.png';
import { feathersAuthentication } from '../server/feathers-binding';
import SignUpForm from '../Components/forms/resetpwd-code-form';
import { AuthContext } from '../contexts/authContext';
import services from '../server/services';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { LayoutForLoginPages } from '../Components/LayoutForLoginPages';
import { LoginInput } from './LoginPage';
import { useEffect } from 'react';

const SignUpPage = (props) => {
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);

  const [validationErr, setValidationErr] = useState('');

  const submit = async (data) => {
    try {
      let verifyToken = localStorage.getItem("verifyToken")

      if (verifyToken) {
        return ""
      }
      setLoading(true);
      let validateData = validateFormData(data);
      if (Object.keys(validateData).length > 0) {
        setValidationErr(validateData);
      } else {
        setValidationErr('');
        // let cognitoRes = await authContext.sendCode(data.email);
        let cognitoRes = await authContext.sendCode(data.target.email.value);

        if (cognitoRes) {
          toast.success(
            'Reset passowrd code has sent to registered email address',
            { hideProgressBar: true, autoClose: 2000 }
          );
          setTimeout(() => {
            props.history.push('/change-password');
          }, 2000);
        } else {
          throw new Error('Something went wrong.');
        }
      }
      setLoading(false);
    } catch (err) {
      toast.error(err.response.data.message, {
        hideProgressBar: true,
        autoClose: 2000,
      });
      setLoading(false);
    }
  };

  let validateFormData = (data) => {
    let error = {};

    if (!data.target.email.value || data.target.email.value.trim().length === 0) {
      error.email = 'This field is required';
    }
    // if (!data.email || data.email.trim().length === 0) {
    //   error.email = 'This field is required';
    // }
    return error;
  };
  let propsforLoginPage = {
    sectionVals: { subHead: 'Reset password', head: 'No problem if you have forgotten your password' },
    headingHero: 'Email confirmation',
    sectionPara: 'If you have forgotten your credentials, enter your email address to get a code for a password reset.',
    sectionHeading: "Confirm your EMAIL",

  }

  localStorage.setItem("verifyToken", true)
  useEffect(() => {
    const script = document.createElement("script");
    const REACT_APP_CYBERSIARA_PUBLIC_KEY =
      process.env.REACT_APP_CYBERSIARA_PUBLIC_KEY;

    script.innerHTML = `
      $(function () {
        var PublicKey = "${REACT_APP_CYBERSIARA_PUBLIC_KEY}";
        InitCaptcha(PublicKey);
        $('.CaptchaSubmit').click(function () {
          if (CheckCaptcha()) {
            // console.log(CyberSiaraToken);
            localStorage.removeItem("verifyToken")
          }
        });
      });
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://legateca.com/send-code" />
        <meta name="robots" content="INDEX,FOLLOW" />
      </Helmet>
      <LayoutForLoginPages
        {...propsforLoginPage}
      >
        <div className='lg:px-[40px] flex flex-col justify-center px-[20px] max-h-[530px] min-h-[530px] 3xl:max-h-[830px] 3xl:min-h-[830px] w-full lg:max-w-[500px] 3xl:min-w-[1200px] 3xl:max-w-[1200px] bg-white rounded-[8px]'>
          <div className='flex flex-col items-start'>
            <div className='text-[#006766] 3xl:text-[60px] text-[28px] not-italic font-medium leading-[48px] tracking-[0.56px] uppercase'>
              Confirm your email
            </div>
            <div className='xl:max-w-[486px]  3xl:max-w-[786px] text-primary-tw text-base 3xl:text-[30px] xl:text-sm not-italic 3xl:!leading-[130%] font-normal leading-[26px] tracking-[0.28px]'>
              A reset code will be sent to your registered email.
            </div>
          </div>
          <form onSubmit={(e) => {
            e.preventDefault()
            submit(e)
          }}>
            <div className='py-[30px]  3xl:py-[50px] flex flex-col gap-6'>
              <LoginInput
                label="Email"
                name="email"
                placeHolder="mail@abc.com"
              />
            </div>
            <div className="SiaraShield centered "></div>
            <button type="submit" className='bg-ThemeSecondary CaptchaSubmit 3xl:py-[40px] py-[20px] text-white text-center 3xl:text-[30px] text-sm not-italic hover:!bg-[#e3b576] !border-none font-medium rounded-[6px] !mt-[30px] !mb-[20px] tracking-[0.28px] uppercase'>Send reset code</button>
          </form>
          <div onClick={() => window.location.replace('/login')} className='text-ThemeSecondary cursor-pointer hover:underline pt-[20px] flex justify-center items-center gap-2'>
            <svg className='w-3 h-3 3xl:w-8 3xl:h-8' viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.97266 11.7578L0.96875 6.53516C0.832031 6.37109 0.777344 6.20703 0.777344 6.04297C0.777344 5.90625 0.832031 5.74219 0.941406 5.60547L5.94531 0.382812C6.19141 0.109375 6.62891 0.109375 6.875 0.355469C7.14844 0.601562 7.14844 1.01172 6.90234 1.28516L2.33594 6.04297L6.92969 10.8555C7.17578 11.1016 7.17578 11.5391 6.90234 11.7852C6.65625 12.0312 6.21875 12.0312 5.97266 11.7578Z" fill="currentColor" />
            </svg>
            <span className='text-sm 3xl:text-[30px] not-italic font-semibold leading-normal'>
              Go back to Login
            </span>
          </div>
        </div>
      </LayoutForLoginPages>
    </>
  )

  // return (
  //   <Layout>
  //     <div>
  // <Helmet>
  //   <link rel="canonical" href="https://legateca.com/send-code" />
  //   <meta name="robots" content="INDEX,FOLLOW" />
  // </Helmet>
  //     </div>
  //     <div className="page-breadcumb-area">
  //       <div className="container">
  //         <div className="row">
  //           <div className="col-12">
  //             <div className="wpo-breadcumb-wrap">
  //               <h2>Send Code</h2>
  //               <ul>
  //                 <li>
  //                   <a href="/">Home</a>
  //                 </li>
  //                 <li>
  //                   <span>&nbsp; / &nbsp;</span>
  //                   <span>Send Code</span>
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="page-content ">
  //       <div className="registration_Pages centerRegiPage container">
  //         <div className="registration-form-container">
  //           <div className="register_art_fame">
  //             <img src={LoginArt} alt="Art" />
  //             <div className="login-content">
  //               <p>
  //                 Legateca - Personalised and accessible solution for your legal
  //                 needs
  //               </p>
  //               <p>Automatic recommendations</p>
  //               <p>Find mediators and lawyers</p>
  //               <p>Pay and book appointments</p>
  //             </div>
  //           </div>
  //           <div className="registration-form">
  //             <h1>Send Code</h1>
  //             <SignUpForm
  //               onSubmit={submit}
  //               loading={loading}
  //               error={validationErr}
  //               ValidationError={validationErr}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       {/* <ToastContainer/> */}
  //     </div>
  //   </Layout>
  // );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  authenticate: feathersAuthentication.authenticate,
})(SignUpPage);
