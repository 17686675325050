import React from "react";
import IconAcessible from "../../assets/images/icon-acessible.png";
import IconIntuitive from "../../assets/images/icon-Intuitive.png";
import IconAffordable from "../../assets/images/icon-affordable.png";
import IconEnd from "../../assets/images/icon-end-to-end.png";
import AboutImg from "../../assets/images/about-img.jpg";
import { Link } from "react-router-dom";

const aboutus = () => {
  return (
    <article>
      <div className="page-breadcumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="wpo-breadcumb-wrap">
                <h2>About Us</h2>
                <ul>
                  <li>
                    {" "}
                    <Link to="/home">Home</Link>
                  </li>
                  <li>
                    <span>&nbsp; / &nbsp;</span>
                    <span>About</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="sec-categories about-box">
        <div className="container">
          <h5>
            Ordinary people need simple help <br /> with their legal troubles
          </h5>
          <div className="row mt-4">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="item">
                <div className="logo-icon">
                  <img src={IconAcessible} alt="Icon" />
                </div>
                <h3>Accessible</h3>
                <p>
                  We engage with you in an interactive and personalized manner,
                  helping you understand where you stand, and what options are
                  available to you.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="item">
                <div className="logo-icon">
                  <img src={IconIntuitive} alt="Icon" />
                </div>
                <h3>Innovative</h3>
                <p>
                  Our automatic recommendations engine uses Artificial
                  Intelligence (AI) and is built upon precise legislative
                  rulings and judicial precedents.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="item">
                <div className="logo-icon">
                  <img src={IconAffordable} alt="Icon" />
                </div>
                <h3>Affordable</h3>
                <p>
                  You have the ability to choose from SRA regulated Solicitors,
                  Chartered Legal Executives or low cost McKenzie Friends to
                  support you with court proceedings.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="item">
                <div className="logo-icon">
                  <img src={IconEnd} alt="Icon" />
                </div>
                <h3>End-to-end</h3>
                <p>
                  We enable a seamless experience from issue inception to
                  resolution. Fill our guided questionnaire, get automatic
                  recommendations, or just book legal experts.
                </p>
              </div>
            </div>
          </div>
          <div className="row about-content">
            <div className="col-lg-4 col-md-4 text-center">
              <img src={AboutImg} alt="About Icon" />
            </div>
            <div className="col-lg-8 col-md-8">
              <p>
                "If you do not believe that professional legal advice is
                affordable for ordinary people, then you are not alone. We
                created Legateca with the vision to use technology to make cost
                effective legal consulting and dispute resolution service
                available in the UK.
              </p>
              <p>
                It all started with the aim to address the challenge of lack of
                affordable Legal services; especially after 46% drop in the
                Legal Aid assistance post 'Legal Aid, Sentencing and Punishment
                of Offenders (LASPO)' Act. Legateca is aimed at bringing parity
                between lesser privileged and the privileged in the context of
                access to legal services."
              </p>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
};

export default aboutus;
