import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Layout from "../Components/Layout";
import LoginArt from "../assets/images/login-art.png";
import { feathersAuthentication } from "../server/feathers-binding";
import SignUpForm from "../Components/forms/email-verification-form";
import { AuthContext } from "../contexts/authContext";
import services from "../server/services";
import { Helmet } from "react-helmet";

const SignUpPage = (props) => {
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const [created, setCreated] = useState(false);
  const [error, setError] = useState("");
  const [validationErr, setValidationErr] = useState("");

  const submit = async (data) => {
    try {
      setLoading(true);
      let validateData = validateFormData(data);
      if (Object.keys(validateData).length > 0) {
        setValidationErr(validateData);
      } else {
        setValidationErr("");
        await authContext.verifyCode(data.email, data.code);
        toast.success("Verification Successful", {
          hideProgressBar: true,
          autoClose: 2000,
        });
        setTimeout(() => {
          props.history.push({
            pathname: "/login",
            state: "/home",
          });
        }, 2000);
      }
      setLoading(false);
    } catch (err) {
      toast.error(err.message, { hideProgressBar: true, autoClose: 2000 });
      setLoading(false);
      setError(err.message);
    }
  };

  let validateFormData = (data) => {
    let error = {};
    if (!data.email || data.email.trim().length === 0) {
      error.email = "This field is required";
    }
    if (!data.code || data.code.trim().length === 0) {
      error.code = "This field is required";
    }
    return error;
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <link rel="canonical" href="https://legateca.com/verify-email" />
          <meta name="robots" content="INDEX,FOLLOW" />
        </Helmet>
      </div>
      <div className="page-breadcumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="wpo-breadcumb-wrap">
                <h2>Email Verification</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <span>&nbsp; / &nbsp;</span>
                    <span>Email Verification</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content ">
        <div className="registration_Pages centerRegiPage container">
          <div className="registration-form-container">
            <div className="register_art_fame">
              <img src={LoginArt} alt="Art" />
              <div className="login-content">
                <p>
                  Legateca - Personalised and accessible solution for your legal
                  needs
                </p>
                <p>Automatic recommendations</p>
                <p>Find mediators and lawyers</p>
                <p>Pay and book appointments</p>
              </div>
            </div>
            <div className="registration-form">
              <h1>Email Verification</h1>
              <SignUpForm
                onSubmit={submit}
                loading={loading}
                ValidationError={validationErr}
              />
            </div>
          </div>
        </div>
        {/* <ToastContainer/> */}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  authenticate: feathersAuthentication.authenticate,
})(SignUpPage);
