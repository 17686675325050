import React from 'react'

export const CommonHeaders = ({ first, second }) => {
    return (
        <>
            <div className="text-[#E3B576]  lg:text-sm 3xl:text-[30px] 3xl:!leading-[130%]  text-base not-italic font-medium !leading-[45px] tracking-[0.28px] uppercase">
                {first}
            </div>
            <div className="text-[#006766] xl:max-w-[960px] mx-auto text-[32px] 3xl:text-[60px] 3xl:!leading-[130%] lg:text-[42px] not-italic lg:font-medium font-semibold lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase">
                {second}
            </div>
        </>
    )
}
