import FooterMain from "./Footer";
import Header from "./Header";

import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

const Layout = (props) => {
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const pathName = parts[parts.length - 1];
  // get path location ends here
  // {
  //   pathName === "home" || pathName === ""
  //     ? console.log("menu 1")
  //     : console.log("menu 2");
  // }

  return (
    <div className="page_wrapper">
      {pathName === "home" || pathName === "" ? "" : <Header />}

      {/* <Header /> */}
      <main className="main_page">{props.children}</main>
      <FooterMain />
    </div>
  );
};

export default Layout;
