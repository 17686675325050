import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import Layout from '../Components/Layout';
import { toast } from 'react-toastify';
import { AuthIsSignedIn, AuthIsNotSignedIn } from '../contexts/authContext';
import { HeroSection } from './About';
// import './checkout.css'
const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentPage = (props) => {
  const { state } = props.location;
  let discountAmount = 0.0;
  let vatPercentage = 20.0;

  let [isPropsValid, setIsPropsValid] = useState(false);
  let [paymentObj, setPatmentObj] = useState('');
  useEffect(() => {
    if (state?.notification) {
      toast.info('Please make payment to use Recommendate Service', {
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
    if (
      state &&
      state.amount &&
      state.successRedirectUrl &&
      state.failureRedirectUrl &&
      state.paymentFor
    ) {
      let obj = {
        destination: '',
        application_fee_amount: '',
      };
      // --------
      if (state.paymentFor === 'lawyer') {
        obj.totalAmount = parseFloat(state.amount / 100).toFixed(2);
        obj.vatAmount = parseFloat(
          obj.totalAmount * state.metaData.vat
        ).toFixed(2);
        obj.grossAmount = parseFloat(
          parseFloat(obj.totalAmount) +
          parseFloat(obj.vatAmount) -
          parseFloat(discountAmount)
        ).toFixed(2);
      } else {
        obj.totalAmount = parseFloat(state.amount / 100).toFixed(2);
        obj.vatAmount = state.recommendatePromotionPrice
          ? Number(
            state.metaData.vat * state.recommendatePromotionPrice
          ).toFixed(2)
          : Number(state.metaData.vat * obj.totalAmount).toFixed(2);
        obj.grossAmount = parseFloat(
          (state.recommendatePromotionPrice
            ? parseFloat(state.recommendatePromotionPrice)
            : parseFloat(obj.totalAmount)) +
          parseFloat(obj.vatAmount) -
          parseFloat(discountAmount)
        ).toFixed(2);
      }
      // -------------------
      obj.successRedirectUrl = state.successRedirectUrl;
      obj.failureRedirectUrl = state.failureRedirectUrl;
      obj.paymentFor = state.paymentFor;
      obj.discountAmount = parseFloat(discountAmount).toFixed(2);
      obj.metaData = state.metaData;

      if (state.paymentFor === 'recommendate') {
      } else if (state.paymentFor === 'lawyer') {
        if (state.body.lawyerId) {
          obj.lawyerId = state.body.lawyerId;
        } else {
          setIsPropsValid(false);
        }
      }
      setPatmentObj(obj);
      setIsPropsValid(true);
    } else {
      setIsPropsValid(false);
    }
  }, []);

  return (
    <AuthIsSignedIn>
      {/* <Layout> */}
      <HeroSection heading="Payment" />
      {/* <div className="page-breadcumb-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="wpo-breadcumb-wrap">
                  <h2>Payment</h2>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <span>&nbsp; / &nbsp;</span>
                      <span>Payment</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* <section className="payment_page content_area">
        <div className="container">
          {isPropsValid && paymentObj ? (
            <div className="payment_form_area">
              <h3> Payment Summary </h3>
              <div className="payment-complete-info">
                <div className="payment-item-details">
                  <ul></ul>
                </div>
                <div className="payment-summery-info">
                  <ul>
                    {paymentObj.paymentFor === 'lawyer' ? (
                      <>
                        <li>
                          <b>Product Price</b>
                          <span>
                            &#163;
                            {(
                              paymentObj.metaData.totalLawyerFee +
                              paymentObj.metaData.totalSessionCommission
                            ).toFixed(2)}
                          </span>
                        </li>
                      </>
                    ) : (
                      <li>
                        <b>Product Price:</b>
                        <span> &#163;{paymentObj.totalAmount}</span>
                      </li>
                    )}
                    {state.recommendatePromotionPrice && (
                      <li>
                        <b>Promotional Price:</b>
                        <span>
                          {' '}
                          &#163;
                          {parseFloat(
                            state.recommendatePromotionPrice
                          ).toFixed(2)}
                        </span>
                      </li>
                    )}

                    <li>
                      <b>VAT (20%):</b>
                      <span> &#163;{paymentObj.vatAmount}</span>
                    </li>
                  </ul>
                </div>
                <div className="grand-total-payment">
                  <ul>
                    <li>
                      <b>Total To Pay</b>{' '}
                      <span>&#163;{paymentObj.grossAmount}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Elements stripe={promise}>
                <CheckoutForm
                  amount={paymentObj.grossAmount * 100}
                  vat={paymentObj.vatAmount * 100}
                  successRedirectUrl={paymentObj.successRedirectUrl}
                  failureRedirectUrl={paymentObj.failureRedirectUrl}
                  paymentFor={paymentObj.paymentFor}
                  lawyerId={paymentObj.lawyerId}
                  metaData={paymentObj.metaData}
                  body={state.body ? state.body : null}
                // application_fee_amount={paymentObj.application_fee_amount}
                // stripeAccountId={paymentObj.destination}
                />
              </Elements>
            </div>
          ) : (
            <div>Something Went Wrong. Please try again later</div>
          )}
        </div>
      </section> */}
      {isPropsValid && paymentObj ? <div className='bg-white my-20 xl:px-[206px] px-6 rounded-md shadow-md xl:py-[30px] w-full xl:max-w-[862px] mx-auto'>
        <div className=" flex flex-col gap-[10px] justify-center items-center">

          <div className="flex flex-col pt-[30px]">
            <div className='text-sm text-ThemePrimary font-medium text-center uppercase'>PAYMENT</div>
            <div className='text-[42px] text-ThemeSecondary font-medium text-center uppercase'>SUMMARY</div>
          </div>
          <div className="pl-[30px] py-[20px] w-full mt-[20px] max-w-[420px] mx-auto pr-[18px] border-b border-[#ebebeb] flex flex-col custom-Border2">
            <div className="flex w-full  items-center justify-between   pb-[14px]"><div className='text-sm text-[#252525] font-[400]  '>Product Price</div><div className="text-primary-tw text-sm">£ {paymentObj.paymentFor === 'lawyer' ? (
              paymentObj.metaData.totalLawyerFee +
              paymentObj.metaData.totalSessionCommission
            ).toFixed(2) : paymentObj.totalAmount}</div></div>
            <div className="flex w-full  items-center justify-between   pb-[14px]"><div className='text-sm text-[#252525] font-[400]  '>Promotion</div><div className="text-primary-tw text-sm">£ {!paymentObj.metaData.discountAmount ? 0 : paymentObj.metaData.discountAmount}</div></div>
            <div className="flex w-full items-center justify-between border-b border-[#ebebeb] pb-[14px]"><div className='text-sm text-[#252525] font-[400]  '>VAT (20%)</div><div className="text-primary-tw text-sm">£ {paymentObj.vatAmount}</div></div>
            <div className="flex w-full items-center justify-between py-[14px]"><div className='text-sm text-ThemeSecondary font-semibold uppercase '>Total to pay</div><div className="text-sm text-ThemeSecondary font-semibold uppercase ">£{paymentObj.grossAmount}</div></div>
          </div>
          <Elements stripe={promise}>
            <CheckoutForm
              amount={paymentObj.grossAmount * 100}
              vat={paymentObj.vatAmount * 100}
              successRedirectUrl={paymentObj.successRedirectUrl}
              failureRedirectUrl={paymentObj.failureRedirectUrl}
              paymentFor={paymentObj.paymentFor}
              lawyerId={paymentObj.lawyerId}
              metaData={paymentObj.metaData}
              body={state.body ? state.body : null}
            // application_fee_amount={paymentObj.application_fee_amount}
            // stripeAccountId={paymentObj.destination}
            />
          </Elements>
          {/* <div className="text-center max-w-[300px]  min-w-[300px] mx-auto ">
            <button
              type="button"
              className="btn btn-primary max-lg:!px-3 !w-full py-3 mb-4"
            // onClick={() => hanldeMakePayment()}

            >
              PAY NOW
            </button>
          </div> */}
          <div
            onClick={() => props.history.goBack()}
            className="text-center mb-4 flex gap-2 items-center justify-center cursor-pointer"
          >
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.7812 5.98389C12.7812 6.3667 12.4805 6.66748 12.125 6.66748H2.82812L6.46484 10.1128C6.73828 10.3589 6.73828 10.7964 6.49219 11.0425C6.24609 11.3159 5.83594 11.3159 5.5625 11.0698L0.75 6.47607C0.613281 6.33936 0.558594 6.17529 0.558594 5.98389C0.558594 5.81982 0.613281 5.65576 0.75 5.51904L5.5625 0.925293C5.83594 0.679199 6.24609 0.679199 6.49219 0.952637C6.73828 1.19873 6.73828 1.63623 6.46484 1.88232L2.82812 5.32764H12.125C12.5078 5.32764 12.7812 5.62842 12.7812 5.98389Z" fill="#252525" />
            </svg>

            GO BACK
          </div>

        </div>
      </div> :
        <div>Something Went Wrong. Please try again later</div>
      }
      {/* </Layout> */}
    </AuthIsSignedIn>
  );
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || {},
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(PaymentPage);
