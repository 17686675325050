import Layout from "../Components/Layout";
import ProfileForm from "../Components/forms/profile-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link, withRouter } from "react-router-dom";
import services from "../server/services";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { updateAuth } from "../actions";
import querystring from "querystring";
import queryString from "query-string";
import { AuthIsSignedIn, AuthIsNotSignedIn } from "../contexts/authContext";

const SettingPage = (props) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let configureCalendar = async () => {
      const { code } = queryString.parse(window.location.search);
      if (code) {
        try {
          await services["google-calendar-auth"].create({ code });
          props.history.push("/dashboard/settings");
          toast.success("Google calendar has been configured!", {
            hideProgressBar: true,
            autoClose: 2000,
          });
        } catch (err) {
          props.history.push("/dashboard/settings");
          toast.error("Google auth token is invalid!", {
            hideProgressBar: true,
            autoClose: 2000,
          });
        }
      }
    };

    configureCalendar();
  }, []);

  useEffect(() => {
    const getUserCalendarData = async () => {
      if (props.user._id) {
        await services["google-calendar-auth"].find({ userId: props.user._id });
      }
    };

    getUserCalendarData();
  }, [props.user]);

  const updateGeneralInfo = async (generalInfo) => {
    const { _id, password, confirmPassword } = generalInfo;

    if (password !== confirmPassword) {
      toast.error("Password and Confirm password do not match!", {
        hideProgressBar: true,
        autoClose: 2000,
      });
      return;
    }

    if (password === "") {
      delete generalInfo.password;
    }

    try {
      setLoading(true);
      let response = await services.users.patch(_id, generalInfo);
      const currentState = JSON.parse(sessionStorage.state);
      currentState.auth.user = Object.assign(
        {},
        currentState.auth.user,
        response.value
      );

      props.dispatch(updateAuth(currentState.auth));

      toast.success("General info updated!", {
        hideProgressBar: true,
        autoClose: 2000,
      });
      setLoading(false);
    } catch (err) {
      toast.error(err.message, { hideProgressBar: true, autoClose: 2000 });
      setLoading(false);
    }
  };

  const authenticateGoogle = () => {
    const options = {
      redirect_uri: process.env.REACT_APP_GOOGLE_APP_REDIRECT_URL,
      client_id: process.env.REACT_APP_GOOGLE_APP_CLIENT_ID,
      access_type: "offline",
      response_type: "code",
      prompt: "consent",
      scope: [
        "https://www.googleapis.com/auth/calendar",
        "https://www.googleapis.com/auth/calendar.events",
      ].join(" "),
    };

    const url = `${
      process.env.REACT_APP_GOOGLE_APP_ROOT_URL
    }?${querystring.stringify(options)}`;

    window.location.href = url;
  };

  return (
    <AuthIsSignedIn>
      <Layout>
        <div className="page-breadcumb-area settingPage">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="wpo-breadcumb-wrap">
                  <h2>Setting</h2>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <span>&nbsp; / &nbsp;</span>
                      <span>Setting</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content container">
          <div className="page_header">
            <h3 className="pageHeading">Settings</h3>
          </div>
          <div className="settingPage">
            <div className="profilePage_sec">
              <div className="Profile_edit_form">
                <div className="general_profile_tab">
                  <div className="profile_tab_header">
                    <h4>General</h4>
                  </div>
                  <div className="profile_tab_content">
                    <ProfileForm
                      loading={loading}
                      onSubmit={updateGeneralInfo}
                      initialValues={props.user}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="profilePage_sec ">
              <div className="Profile_edit_form">
                <div className="general_profile_tab">
                  <div className="profile_tab_header">
                    <h4>Calendar</h4>
                  </div>
                  <div className="profile_tab_content">
                    <h3>Google Calendar</h3>
                    {props.googleCalendarAuth ? (
                      <div className="form-group-action text-left">
                        {" "}
                        <button className="btn btn-primary" disabled>
                          Authenticated
                        </button>
                      </div>
                    ) : (
                      <div className="form-group-action text-left">
                        <button
                          onClick={authenticateGoogle}
                          className="btn btn-primary"
                        >
                          Authenticate
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="profilePage_sec">
              <div className="Profile_edit_form">
                <div className="general_profile_tab">
                  <div className="profile_tab_header">
                    <h4>Prelgate PDF</h4>
                  </div>
                  <div className="profile_tab_content">
                    {props.user.prelegatePdf ? (
                      <div className="form-group-action text-left">
                        <button
                          onClick={() => {
                            window.open(props.user.prelegatePdf).focus();
                          }}
                          className="btn btn-primary"
                        >
                          Download
                        </button>
                      </div>
                    ) : (
                      <div
                        className="form-group-action text-left"
                        style={{ color: "black" }}
                      >
                        Please use our prlegate service for free recommendation.{" "}
                        <Link className="clickHere" to="/service/prelegate">
                          Click here
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </AuthIsSignedIn>
  );
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || {},
    googleCalendarAuth:
      (state["google-calendar-auth"] &&
        state["google-calendar-auth"].queryResult &&
        state["google-calendar-auth"].queryResult.data.length &&
        state["google-calendar-auth"].queryResult.data[0]) ||
      false,
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(SettingPage);
