import React, { useEffect } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import Lawyem from "../../Components/LawyemModule/Lawyem/Lawyem";
import Layout from "../../Components/Layout";
import qs from "qs";
import { Helmet } from "react-helmet";

const LawyemPage = (props) => {
  const { tag } = useParams();
  // const tag = qs.parse(props.location.search, { ignoreQueryPrefix: true }).tag;

  useEffect(() => {
    window.scroll(0, 0);
    return () => {};
  }, []);
  return (
    <>
      {props?.userRole === "lawyer" ? (
        <Helmet>
          <meta
            name="title"
            content="Get Lawyer - Nuptial | Divorce Solicitor UK"
          />
          <meta
            name="description"
            content="Legateca provides experienced solicitors in the UK for a variety of legal cases, including nuptial, employment, and divorce. Contact us at +441635745074 today!"
          />
          <meta
            name="keywords"
            content="legal,lawyer,divorce,divorce calculator,divorce lawyer,divorce advice ,no fault divorce,low cost divorce uk,divorce solicitors,applying for divorce uk,nuptial agreement, Best immigration solicitors"
          />
          {/* <title>
            Legateca - Divorce Lawyer Advice, Divorce Solicitors UK, Nuptial
            Agreement
          </title> */}
        </Helmet>
      ) : (
        <Helmet>
          <title>Divorce Mediation UK | Family Mediation UK</title>
          <meta
            name="title"
            content="Divorce Mediation UK | Family Mediation UK"
          />
          <meta
            name="description"
            content="We provide Family Mediation Services in the UK to help couples and families through their separation and divorce in the most healthy way possible."
          />
          <meta
            name="keywords"
            content="legal,lawyer,divorce,mediation,divorce mediation,divorce mediation uk,mediation services, Family mediation UK"
          />
          {/* <title>Legateca -Divorce Mediation UK, Mediation Services</title> */}
        </Helmet>
      )}
      <Lawyem userRole={props.userRole} tag={tag} />
    </>
    // <Layout>
    // </Layout>
  );
};

export default LawyemPage;
