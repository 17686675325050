import React from "react";
import { Link } from "react-router-dom";
import { Results } from "../Homepage/Homepage";
// import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper styles
// import 'swiper/swiper-bundle.min.css';

// Install Swiper modules
// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
const ImageWithText = ({
  showKnowMore,
  image,
  legatecafirstlogo,
  paragraph,
  title,
  to,
  alt,
  heading,
  btnVisibility,
  reverseRow,
  backHidden,
  handleClick,
  btnValue,
  href,
  size,
}) => {
  return (
    <section
      className={
        backHidden === "false"
          ? "w-full font-montserrat "
          : "w-full lg:mb-[165px] 3xl:mt-[100px] 3xl:mb-[200px]  mb-10 font-montserrat "
      }
    >
      <div
        className={`lg:-px-0 flex ${
          reverseRow ? "flex-row-reverse" : ""
        } items-center justify-center gap-10 padding-md xl:max-w-[1440px] 3xl:max-w-[2400px]  mx-auto`}
      >
        <div className={`hidden xl:block   `}>
          {/* <div className="relative z-[1000] "> */}
          <div className="relative">
            <img
              className={`w-full  ${
                size ? size : "max-w-[610px] 3xl:max-w-[1000px]"
              } object-fill`}
              src={image}
              alt={alt}
            />
            <div
              className={` ${
                backHidden === "false" ? "hidden" : "xl:block hidden"
              }  absolute bg-[#E3B576]/5 ${
                title === "Our team of lawyers" ? "top-[-48px]" : "top-[-62px]"
              }  z-[-20] left-[-130px] min-w-[670px] max-w-[670px] min-h-[626px] max-h-[626px] 3xl:min-w-[1056px] 3xl:max-w-[1056px] 3xl:min-h-[954px] 3xl:max-h-[954px]`}
            ></div>
          </div>
        </div>

        <div className="xl:max-w-[582px] xl:min-w-[582px] 3xl:max-w-[1100px] 3xl:min-w-[1300px]">
          {/* <div className='2xl:mr-32 xl:mr-24 xl:my-3 2xl:my-16 '> */}
          <div className="3xl:flex flex-col gap-3">
            {/* <div className='uppercase justify-center text-[#E3B576] text-[14px] font-[500] leading-[45px] tracking-[0.25px]'> */}
            <h2 className="text-[#E3B576]  text-values-align lg:text-sm 3xl:text-[30px] text-base not-italic font-medium 3xl:!leading-[130%] !leading-[45px] tracking-[0.28px] uppercase">
              {title}
            </h2>
            {/* <h1 className='text-[#006766] text-[42px] font-[80px] leading-[45px] tracking-[0.84px] uppercase '> */}

            <h2 className="text-[#006766] text-values-align text-[32px] 3xl:text-[60px] lg:text-[42px] not-italic lg:font-medium font-semibold 3xl:!leading-[130%] lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase">
              {heading}
            </h2>

            {/* <div className='text-[#252525] text-[14px] font-[400] leading-[26px] tracking-[0.28px]'> */}
            {paragraph}
            {!btnVisibility && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick && handleClick();
                  if (e.target === e.currentTarget) {
                    return;
                  }
                }}
                className="flex justify-center md:justify-start"
              >
                <div
                  onClick={(e) => {
                    handleClick && handleClick();
                    if (e.target === e.currentTarget) {
                      return;
                    }
                  }}
                  className={`${
                    btnValue !== "View case Study"
                      ? "hover:bg-[#E3B576] bg-[#006766]"
                      : "hover:bg-ThemeSecondary bg-ThemePrimary"
                  } px-[40px] py-[9px] 3xl:py-6 3xl:px-10 flex rounded-md 3xl:rounded-lg font-montserrat `}
                >
                  <a
                    // onClick={onClick}

                    className="flex no-underline "
                    href={to}
                  >
                    <img
                      src={legatecafirstlogo}
                      alt="nill"
                      className="3xl:h-[66px] 3xl:w-[50px]"
                    />
                    <div
                      className={` ${
                        reverseRow
                          ? "text-white text-sm not-italic font-medium leading-[45px] tracking-[0.28px] uppercase"
                          : " "
                      } my-auto ml-3 text-white   uppercase tracking-[0.28px] font-[80px] 3xl:text-[30px] text-[14px] `}
                    >
                      {reverseRow ? btnValue : "Know More"}
                    </div>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>

        {showKnowMore ? <Results /> : null}
      </div>
    </section>
  );
};

export default ImageWithText;
