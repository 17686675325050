import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout";
import { Helmet } from "react-helmet";
import { HeroSection } from "../About";
import Footer from "../../Components/Footer";
import { Anchor, Description, SectionCard, TableOfContents } from "../Privacy";

const Terms = (props) => {
  // useEffect(() => {
  //   window.scroll(0, 0);
  //   return () => {};
  // }, []);

  try {
    let prevMetaDesc = document.getElementById("baseDescription");
    prevMetaDesc.remove();
    //use the piece of loading state to return other component until you have the data
  } catch (error) { }
  const [selectedPara, setselectedPara] = useState(0)
  const arrayOfContents = [
    'Information about us',
    'Contact details',
    'General',
    'Changes to these terms',
    'Changes to our site',
    'Accessing our site',
    'Your account and password',
    'Ownership of Intellectual property rights',
    'Agreement for Online and Electronic Usage',
    'Amendments to this Agreement',
    'Protect Your Password',
    'Privacy',
    'Services related terms',
    'License to Submissions to the Site',
    'Copyrights',
    'General disclaimer',
    'Information disclaimer',
    'Warranty disclaimer',
    'Hyperlinks and search results',
    'Limitation of liability',
    'Enforceability',
    'Entire agreement',
    'Third party rights',
    'Law and jurisdiction',
  ]
  return (
    <>
    <Helmet>
          <title>Terms & Conditions - Legateca</title>
          <link
            rel="canonical"
            href="https://legateca.com/terms-and-conditions"
          />
          <meta name="robots" content="INDEX,FOLLOW" />
          <meta name="title" content="Terms & Conditions - Legateca" />
          <meta
            name="description"
            content="Welcome to Legateca! Read our Terms and Conditions to understand how we provide services and information, our liabilities and your rights."
          ></meta>
        </Helmet>
      <HeroSection
        heading="Terms & Conditions"
        bgImg={'./terms.png'} />
      <main className="xl:py-[100px] py-[70px] xl:px-0 px-10 w-full xl:max-w-[1240px] 3xl:max-w-[2400px] mx-auto font-montserrat">
        <section className="flex justify-between xl:gap-0 gap-10 w-full xl:flex-row flex-col-reverse">
          <div className="w-full xl:max-w-[818px] 3xl:max-w-[1520px] flex flex-col xl:gap-[46px] gap-5">
            <div className="text-[#006766] text-2xl 3xl:text-[60px] not-italic font-medium !leading-[31px] 3xl:!leading-[130%] tracking-[0.48px] uppercase">
              Terms & Condition
            </div>
            <SectionCard id={0} head='Information about us'>
              <Description>
                <div className="flex flex-col items-start">
                  <span>
                    Legateca is an official trading name for AIWON BUSINESS SERVICES LTD ("We"), a company registered in England and Wales under company number 11412650 whose registered address is at 41 Heather Drive Thatcham RG18 4BU, UK.
                  </span>
                  Legateca.com is a site operated by AIWON BUSINESS SERVICES LTD.
                </div>

              </Description>
            </SectionCard>

            <SectionCard id={1} head='Contact details'>
              <Description>
                You may contact us by writing to the postal address given above, or by email to <Anchor mail={true} href='mailto:info@legateca.com'>info@legateca.com</Anchor> or by telephone on +44 1635 745074.         </Description>
            </SectionCard>

            <SectionCard id={2} head='General'>
              <Description>
                Please note the Legateca platform is not a law firm and does not provide legal advice. Any information, documents or otherwise obtained from or provided by Legateca is general information and is not a legal advice. You should always seek legal advice from a legal professional before creating, using or relying on our services and documents.
              </Description>

              <Description>
                You may access legal professionals and legal advice directly when you purchase certain Legateca subscriptions or use our marketplace.
              </Description>
              <Description>
                These terms and conditions tell you about the terms on which you may make use of Legateca.com (our website), whether as a guest or a registered user. Use of our site includes accessing, browsing, or registering to use our site.
              </Description>
              <Description>
                Please read these terms and conditions carefully before you start to use our site, as these will apply to your use of our site.
              </Description>
              <Description>
                By using our site, you confirm that you accept these terms and conditions and that you agree to comply with them. If you do not agree to these terms and conditions, you must not use our site.
              </Description>
              <Description>
                All information is provided on a volunteer basis by users of this Web site and members of Legateca.com (hereinafter referred as the 'Legateca'). Users of this Web site and members of Legateca have varying levels of knowledge; Legateca does not certify accuracy of any information or certify that any level of expertise relevant to that information has been met. Your access to and use of Legateca (the 'Site') is governed by these Terms of Use and disclaimer clause on the site and any notices and consent provided or obtained in connection with the Site (collectively, the 'Agreement'). As used in this Agreement ',' Legateca 'We,' 'Us,' or 'Our' refers to Legateca, its members and designers/developers. 'You' or 'Your' refers to you, the user of this Site. Herein 'country' should be interpreted as the country where the user resides.              </Description>
              <Description>
                These terms and conditions also include our <Anchor href='/privacy-policy'>Privacy Policy</Anchor>  which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our site, you consent to such processing, and you warrant that all data provided by you is accurate.
              </Description>
            </SectionCard>
            <SectionCard id={3} head='Changes to these terms'>
              <Description>
                We may revise these terms and conditions at any time by amending this page. Please check this page from time to time to take notice of any changes we made, as they are binding on you.
              </Description>
            </SectionCard>
            <SectionCard id={4} head='Changes to our site'>
              <Description>
                We may update our site from time to time and may change the content at any time.
              </Description>
            </SectionCard>

            <SectionCard id={5} head='Accessing our site'>
              <Description>
                Our site is made available free of charge.</Description>
              <Description>
                We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. Access to our site is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our site without notice. We will not be liable to you if for any reason our site is unavailable at any time or for any period.
              </Description>
              <Description>
                You are responsible for making all arrangements necessary for you to have access to our site.
                You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms and conditions and other applicable terms and conditions, and that they comply with them.
              </Description>
            </SectionCard>
            <SectionCard id={6} head='Your account and password'>
              <Description>
                If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.
              </Description>
              We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms and conditions.
              <Description>
                If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <Anchor mail={true} href='mailto:info@legateca.com'>info@legateca.com</Anchor>.
              </Description>
            </SectionCard>
            <SectionCard id={7} head='Ownership of Intellectual property rights'>
              <Description>
                It is hereby notified that we are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
              </Description>
              <Description>
                However, You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.
                Also, You must not modify the printout/paper or digital copies of any materials you have printed off or downloaded in any way, and nor make any similar-illustrative copies in any form.
              </Description>
              <Description>
                Our ownership status (and that of any identified contributors) as the authors of content on our site must always be understood and agreed upon by you.
              </Description>
              <Description>
                None of provisions allow You to use any part of the content on our site for commercial purposes without obtaining a permission, licence to do so from us or our licensors.
              </Description>
            </SectionCard>
            <SectionCard id={8} head='Agreement for Online and Electronic Usage'>
              <Description>
                By virtue of your usage of the Site that is conducted electronically, you agree that Legateca may communicate with you online/electronically for all aspects of your use of the Site, including sending you electronic notices. If you do not wish to deal electronically, you should not use this Site. Issuing, management and delivery of any electronic notices is not impeccable, so you agree to contact Legateca if you need information about the Site. You agree to keep all records relating to your use of the Site and to print or make an electronic copy of this Agreement and any disclosures provided on the Site. Except as prohibited by law, you waive any law requiring different communication methods in connection with your use of this Site Legateca reserves the right, at its sole discretion, to deliver notices and disclosures by other means such as postal mail.
              </Description>
            </SectionCard>
            <SectionCard id={9} head='Amendments to this Agreement'>
              <Description>
                Upon being visitor/user of this Site, you are bound by the version of this Agreement that is in effect on the date of your visit. The terms of this Agreement may be amended from time to time so please review it when you visit the Site. Any use of the Site after the effective date of any amendment will establish your consent to the amended Agreement.
              </Description>
            </SectionCard>
            <SectionCard id={10} head='Protect Your Password'>
              <Description>
                You are responsible for maintaining the confidentiality of the password that you choose to access and use the Site ('Account'). Subject to applicable law, you agree to be liable for all uses of your Account whether or not actually authorized by you, including but not limited to uses of your Account to submit questions at the Site or to answer questions through the Site. This means that you should not supply your password to anyone who is not authorized to take actions for you.
              </Description>
            </SectionCard>
            <SectionCard id={11} head='Privacy'>
              <Description>
                Legateca may use your email address for the purposes it considers and deems fit. Information about you may be used by Legateca for the purposes it considers fit.
              </Description>
            </SectionCard>
            <SectionCard id={12} head='Services related terms'>
              <Description>
                While you make use of our services, you are solely responsible for communications posted by you or through your Account. You agree that violation of any of the following terms constitutes a formal material breach of this Agreement:
              </Description>
              <Description>
                Violation of Services usage for any purpose in malefaction of local, state, national, or international laws; Providing false information on your registration form or impersonating any person; Posting material that infringes on the intellectual property rights of others or on the privacy or publicity rights of others; Posting or transmitting any information, data, text, files, links, software, chat, communication, or any other materials that are unlawful, harmful, threatening, abusive, invasive of another's privacy, harassing, defamatory, slanderous, vulgar, obscene, hateful, racist, embarrassing or otherwise objectionable to any other person or entity as determined by Legateca in its sole discretion;
              </Description>
              <Description>
                Posting or transmitting any unsanctioned advertising, promotional materials, or any other forms of unsanctioned solicitation, including, without limitation, 'junk mail,' 'Spam,' 'chain letters,' or any unsolicited mass distribution of email; Posting surveys, contests, or pyramid schemes; Posting improper questions or answers that are not related to the particular topic; Stalking, harassing, or otherwise harming others;
                Distributing viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer;
              </Description>
              <Description>
                Collecting or storing personal data about other users of the Site; Inducing, enticing, soliciting, recruiting, luring, or attempting to induce, entice, solicit, recruit, or lure users or Experts to another web site which, in Legateca’s reasonable determination, is a competitor of Legateca Engaging in any other conduct that inhibits any other person from using or enjoying the Q&A Service or the Site;
              </Description>
              <Description>
                Engaging in any other behaviour on the Site, which in Legateca’s sole discretion is unacceptable. Legateca may (but is not obligated) to remove Content for any reason, with or without notice to you, including without limitation Submissions (as defined below) that do not conform with the rules Service and terminate your access to the Site.
              </Description>
            </SectionCard>
            <SectionCard id={13} head='License to Submissions to the Site'>
              <Description>
                Should you provide this Site with information, including without limitation, postings, feedback, data, questions, comments or suggestions ('Submissions') those Submissions will be deemed not to be confidential. You grant Legateca a royalty-free, perpetual, irrevocable, world-wide license to use, copy, reproduce, create derivative works from, adapt, modify, publish, edit, translate, sell, distribute, transmit, transfer, publicly display, publicly perform, and display the Submissions without any limitation and in any media or any form now known or later developed. Legateca may sublicense these rights to third parties.
              </Description>
            </SectionCard>
            <SectionCard id={14} head='Copyrights'>
              <Description>
                Various content and services on the Site, including text, graphics, logos, icons, images, and videos and the selection and arrangement thereof, in addition to any concepts, know-how, tools, frameworks, software, applications or other technology, algorithms, models, processes, and industry perspectives underlying or embedded in the foregoing, along with any enhancements to or derivative works thereof (the “Site Content”) is the exclusive property of Prof Services or its licensors and, to the extent applicable, is protected by UK copyright laws.
              </Description>
              <Description>
                Neither the Site Content nor functionality of the Site, may be copied, reproduced, modified, reverse engineered, altered (including the removal or disabling of any security or technological safeguards, disclaimers, or legends) uploaded, published, uploaded, posted, transmitted, or distributed in any way without our written permission, except for those uses specified in Sections above for the use of site content. All rights not expressly granted are reserved.
              </Description>
            </SectionCard>
            <SectionCard id={15} head='General disclaimer'>
              <Description>
                Legateca does not promise that the Site will be error-free, uninterrupted, nor that it will provide specific results from use of the Site or any content, search or link on it.
              </Description>
              <Description>
                The Site and its content are delivered on an ‘as-is’ and ‘as-available’ basis. Legateca cannot ensure that files you download from the Site will be free of viruses or contamination or destructive features.
              </Description>
              <Description>
                Legateca will not be liable for any Losses of any kind arising from the use of this Site, including without limitation, direct, indirect, incidental, and punitive and consequential damages. Legateca disclaims any and all liability for the acts, omissions and conduct of any third party users in connection with or otherwise related to your use of the Site.
              </Description>
            </SectionCard>
            <SectionCard id={16} head='Information disclaimer'>
              <Description>
                Our Site contains general information only and nothing contained in this Site or any correspondence constitutes legal advice. The information is not advice and should not be treated as such.
              </Description>
              <Description>
                You must not rely on the information on our Site as an alternative to legal advice from your lawyer or other professional legal services provider. Some of Our Services may be supported by Artificial Intelligence Technology but does not perform the function of a professional lawyer; nor a replacement of a lawyer, or intended to be a lawyer or replacement thereof.
              </Description>
              <Description>
                No lawyer-client, attorney-client or solicitor-client relationship shall be created through the use of our Site.
                You should always consult your own qualified lawyer, financial adviser or other qualified professional regarding any specific problem.
              </Description>
              <Description>
                You should never delay seeking legal advice, disregard legal advice, or commence or discontinue any legal action because of information on our Site.
              </Description>
              <Description>
                The information on our Site is provided without any representations or warranties, express or implied. In particular, we do not warrant or represent that the information on this Site will be available at any time in whole or in part; or, that it is or shall remain accurate, truthful, complete and kept up-to-date. We accept no liability whatsoever for any loss or damage caused or arising directly or indirectly in connection with any use of, or reliance placed on, the contents of this Site by you. We further accept no responsibility for the content of any other site mentioned on this site, linked to or from it in any way. Nothing herein will: (i) limit or exclude any liability for death or personal injury resulting from negligence; (ii) limit or exclude any liability for fraud or fraudulent misrepresentation; (iii) limit any liabilities in any way that is not permitted under applicable law; or (iv) exclude any liabilities that may not be excluded under applicable law.
              </Description>
            </SectionCard>
            <SectionCard id={17} head='Warranty disclaimer'>
              <Description>
                This site and its services are provided on an 'as is' basis. The site makes no other representations or warranties of any kind, express or implied, including without limitation:
              </Description>
              <Description>
                The implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement; that the site or the services will meet your requirements, will always be available, accessible, uninterrupted, timely, secure or operate without error; Any obligation, liability, right, claim or remedy in tort, whether or not arising from the negligence of the site;
              </Description>
              <Description>
                The site expressly disclaims any and all warranties and/or conditions, express or implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose, with respect to the services it provides pursuant to this agreement. The client's exclusive remedy and the site sole liability, if any, shall be limited to the aggregate amount of charges paid by you to the site for the specific services, which are the basis of any claim (s) by you;
              </Description>
              <Description>
                We do not guarantee continuous, uninterrupted or secure access to our services, and operation of our site may be interfered with by numerous factors outside our control;
              </Description>
              <Description>
                The payments made to the site for paying the respective office fees, service fee and expenses incurred are not to be calculated for the aggregate fees paid to the site and shall be excluded for the refund by the site ;
                To the full extent permissible under applicable law, the site disclaims any and all such warranties
              </Description>
            </SectionCard>
            <SectionCard id={18} head='Hyperlinks and search results'>
              <Description>
                The Site may contain hyperlinks or produce search results that reference or link to third party websites throughout the World Wide Web. These are provided for your ease of reference only and Legateca does not endorse such websites or accept any responsibility for the content of such websites and cannot therefore guarantee, represent or warrant that the content contained in the sites is accurate, legal and/or inoffensive or that they will not contain viruses or otherwise impact your computer. By using the Site to search for or link to another website, you agree and understand that you may not make any claim against Legateca for any Losses, whatsoever, resulting from your use of the Site to obtain search results or to link to another website.
              </Description>
            </SectionCard>
            <SectionCard id={19} head='Limitation of liability'>
              <Description>
                Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.
              </Description>
              <Description>
                To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to the Site or any content on it, whether express or implied.
              </Description>
              <Description>
                We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with (i) use of, or inability to use, the Site; or (ii) use of or reliance on any content displayed on the Site; including any dispute with one or more users of the Site.
              </Description>
              <Description>
                If you are a business user, we will not be liable for: (i) loss of profits, sales, business, or revenue; (ii) business interruption; (iii) loss of anticipated savings; (iv) loss of business opportunity, goodwill or reputation; or (v) any indirect or consequential loss or damage.
              </Description>
              <Description>
                If you are a consumer user, we only provide the Site to you for domestic and private use. You agree not to use the Site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.
              </Description>
              <Description>
                We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data, or other proprietary material due to your use of the Site or to your downloading of any content on it, or on any website linked to it.
              </Description>
              <Description>
                We assume no responsibility for the content of websites linked on the Site. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.
              </Description>
            </SectionCard>
            <SectionCard id={20} head='Enforceability'>
              <Description>
                If it turns out that a particular clause is not enforceable, this will not affect any other clauses.
              </Description>
            </SectionCard>
            <SectionCard id={21} head='Entire agreement'>
              <Description>
                These terms together with the Legateca Privacy Policy which is incorporated by these references together constitute the entire agreement between you and Legateca in relation to your use of the Site and supersedes any prior representations, inducements or agreements relating to its subject matter.
              </Description>
            </SectionCard>
            <SectionCard id={22} head='Third party rights'>
              <Description>
                These terms govern the relationship between the you and Legateca and do not create any third-party beneficiary rights.
              </Description>
            </SectionCard>
            <SectionCard id={23} head='Law and jurisdiction'>
              <Description>
                These Terms are governed by the laws of the United Kingdom without reference to the principles of conflicts of laws thereof.
              </Description>
            </SectionCard>
          </div>
          <TableOfContents array={arrayOfContents} />
          <div className="w-full xl:block hidden xl:max-w-[378px] 3xl:max-w-[778px] ">
            <div className="text-[#006766] pb-4 text-[22px] 3xl:text-[50px] not-italic font-semibold !leading-[149%] 3xl:!leading-[130%] tracking-[0.44px] uppercase">
              Table of Contents
            </div>
            {
              arrayOfContents.map((it, id) => {
                return <div href={`#heading${id}`} onClick={() => {
                  setselectedPara(id)
                  window.location.replace(`#heading${id}`)

                }} key={id} className={`font-montserrat ${id === selectedPara ? 'text-white bg-[#006766] font-[600]' : 'text-[#252525] border-b font-light border-[#bbb]'}  cursor-pointer  px-[10px] max-3xl:py-4 3xl:px-[40px] 3xl:py-12 text-sm 3xl:text-[30px] not-italic  !leading-[149%] 3xl:!leading-[130%] tracking-[0.28px]`}>
                  {`${id + 1}`}. {it}
                </div>
              })
            }
          </div>
        </section>

      </main >

      <Footer />
    </>
  )
  // return (
  //   <Layout>
  //     <div>
  //       <Helmet>
  //         <title>Terms & Conditions - Legateca</title>
  //         <link
  //           rel="canonical"
  //           href="https://legateca.com/terms-and-conditions"
  //         />
  //         <meta name="robots" content="INDEX,FOLLOW" />
  //         <meta name="title" content="Terms & Conditions - Legateca" />
  //         <meta
  //           name="description"
  //           content="Welcome to Legateca! Read our Terms and Conditions to understand how we provide services and information, our liabilities and your rights."
  //         ></meta>
  //       </Helmet>
  //     </div>
  //     <section className="page-breadcumb-area">
  //       <div className="container">
  //         <div className="row">
  //           <div className="col-12">
  //             <div className="wpo-breadcumb-wrap">
  //               <h2>Terms & Conditions</h2>
  //               <ul>
  //                 <li>
  //                   <Link to="/home">Home</Link>
  //                 </li>
  //                 <li>
  //                   <span>&nbsp; / &nbsp;</span>
  //                   <span>Terms & Conditions</span>
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //     <div className="sec-categories">
  //       <section className="content-page policy-content about-box">
  //         <div className="container">
  //           <h1 className="mb-4"> Terms & Conditions </h1>
  //           <h3>Information about us </h3>
  //           <p>
  //             Legateca is an official trading name for AIWON BUSINESS SERVICES
  //             LTD ("We"), a company registered in England and Wales under
  //             company number 11412650 whose registered address is at 41 Heather
  //             Drive Thatcham RG18 4BU, UK.{" "}
  //           </p>
  //           <p>
  //             Legateca.com is a site operated by AIWON BUSINESS SERVICES LTD.
  //           </p>
  //           <h3>Contact details</h3>
  //           <p>
  //             You may contact us by writing to the postal address given above,
  //             or by email to{" "}
//             <Link to="mailto:info@legateca.com">info@legateca.com</Link> or by
  //             telephone on +44 1635 745074.
  //           </p>
  //           <h3>General</h3>
  //           <p>
  //             Please note the Legateca platform is not a law firm and does not
  //             provide legal advice. Any information, documents or otherwise
  //             obtained from or provided by Legateca is general information and
  //             is not a legal advice. You should always seek legal advice from a
  //             legal professional before creating, using or relying on our
  //             services and documents.
  //           </p>
  //           <p>
  //             You may access legal professionals and legal advice directly when
  //             you purchase certain Legateca subscriptions or use our
  //             marketplace.{" "}
  //           </p>
  //           <p>
  //             These terms and conditions tell you about the terms on which you
  //             may make use of Legateca.com (our website), whether as a guest or
  //             a registered user. Use of our site includes accessing, browsing,
  //             or registering to use our site.{" "}
  //           </p>
  //           <p>
  //             Please read these terms and conditions carefully before you start
  //             to use our site, as these will apply to your use of our site.{" "}
  //           </p>
  //           <p>
  //             By using our site, you confirm that you accept these terms and
  //             conditions and that you agree to comply with them. If you do not
  //             agree to these terms and conditions, you must not use our site.{" "}
  //           </p>
  //           <p>
  //             All information is provided on a volunteer basis by users of this
  //             Web site and members of Legateca.com (hereinafter referred as the
  //             'Legateca'). Users of this Web site and members of Legateca have
  //             varying levels of knowledge; Legateca does not certify accuracy of
  //             any information or certify that any level of expertise relevant to
  //             that information has been met. Your access to and use of Legateca
  //             (the 'Site') is governed by these Terms of Use and disclaimer
  //             clause on the site and any notices and consent provided or
  //             obtained in connection with the Site (collectively, the
  //             'Agreement'). As used in this Agreement ',' Legateca 'We,' 'Us,'
  //             or 'Our' refers to Legateca, its members and designers/developers.
  //             'You' or 'Your' refers to you, the user of this Site. Herein
  //             'country' should be interpreted as the country where the user
  //             resides.
  //           </p>
  //           <p>
  //             These terms and conditions also include our
  //             <Link to="/privacy-policy">Privacy Policy</Link> which sets out
  //             the terms on which we process any personal data we collect from
  //             you, or that you provide to us. By using our site, you consent to
  //             such processing, and you warrant that all data provided by you is
  //             accurate.{" "}
  //           </p>
  //           <h3>Changes to these terms </h3>
  //           <p>
  //             We may revise these terms and conditions at any time by amending
  //             this page.  Please check this page from time to time to take
  //             notice of any changes we made, as they are binding on you.{" "}
  //           </p>
  //           <h3>Changes to our site </h3>
  //           <p>
  //             We may update our site from time to time and may change the
  //             content at any time.{" "}
  //           </p>
  //           <h3>Accessing our site </h3>
  //           <p>Our site is made available free of charge. </p>
  //           <p>
  //             We do not guarantee that our site, or any content on it, will
  //             always be available or be uninterrupted. Access to our site is
  //             permitted on a temporary basis. We may suspend, withdraw,
  //             discontinue or change all or any part of our site without notice.
  //             We will not be liable to you if for any reason our site is
  //             unavailable at any time or for any period.{" "}
  //           </p>
  //           <p>
  //             You are responsible for making all arrangements necessary for you
  //             to have access to our site. {" "}
  //           </p>
  //           <p>
  //             You are also responsible for ensuring that all persons who access
  //             our site through your internet connection are aware of these terms
  //             and conditions and other applicable terms and conditions, and that
  //             they comply with them. {" "}
  //           </p>
  //           <h3>Your account and password </h3>
  //           <p>
  //             If you choose, or you are provided with, a user identification
  //             code, password or any other piece of information as part of our
  //             security procedures, you must treat such information as
  //             confidential. You must not disclose it to any third party.{" "}
  //           </p>
  //           <p>
  //             We have the right to disable any user identification code or
  //             password, whether chosen by you or allocated by us, at any time,
  //             if in our reasonable opinion you have failed to comply with any of
  //             the provisions of these terms and conditions.{" "}
  //           </p>
  //           <p>
  //             If you know or suspect that anyone other than you knows your user
  //             identification code or password, you must promptly notify us at
  //             <Link to="mailto:info@legateca.com">&nbsp;info@legateca.com</Link>
  //             .{" "}
  //           </p>
  //           <h3>Ownership of Intellectual property rights </h3>
  //           <p>
  //             It is hereby notified that we are the owner or the licensee of all
  //             intellectual property rights in our site, and in the material
  //             published on it.  Those works are protected by copyright laws and
  //             treaties around the world.  All such rights are reserved.{" "}
  //           </p>
  //           <p>
  //             However, You may print off one copy, and may download extracts, of
  //             any page(s) from our site for your personal use and you may draw
  //             the attention of others within your organisation to content posted
  //             on our site.
  //           </p>
  //           <p>
  //             Also, You must not modify the printout/paper or digital copies of
  //             any materials you have printed off or downloaded in any way, and
  //             nor make any similar-illustrative copies in any form.
  //           </p>
  //           <p>
  //             Our ownership status (and that of any identified contributors) as
  //             the authors of content on our site must always be understood and
  //             agreed upon by you.{" "}
  //           </p>
  //           <p>
  //             None of provisions allow You to use any part of the content on our
  //             site for commercial purposes without obtaining a permission,
  //             licence to do so from us or our licensors.{" "}
  //           </p>
  //           <h3>Agreement for Online and Electronic Usage</h3>
  //           <p>
  //             By virtue of your usage of the Site that is conducted
  //             electronically, you agree that Legateca may communicate with you
  //             online/electronically for all aspects of your use of the Site,
  //             including sending you electronic notices. If you do not wish to
  //             deal electronically, you should not use this Site. Issuing,
  //             management and delivery of any electronic notices is not
  //             impeccable, so you agree to contact Legateca if you need
  //             information about the Site. You agree to keep all records relating
  //             to your use of the Site and to print or make an electronic copy of
  //             this Agreement and any disclosures provided on the Site. Except as
  //             prohibited by law, you waive any law requiring different
  //             communication methods in connection with your use of this Site
  //             Legateca reserves the right, at its sole discretion, to deliver
  //             notices and disclosures by other means such as postal mail.
  //           </p>
  //           <h3>Amendments to this Agreement</h3>
  //           <p>
  //             Upon being visitor/user of this Site, you are bound by the version
  //             of this Agreement that is in effect on the date of your visit. The
  //             terms of this Agreement may be amended from time to time so please
  //             review it when you visit the Site. Any use of the Site after the
  //             effective date of any amendment will establish your consent to the
  //             amended Agreement.
  //           </p>
  //           <h3>Protect Your Password</h3>
  //           <p>
  //             You are responsible for maintaining the confidentiality of the
  //             password that you choose to access and use the Site ('Account').
  //             Subject to applicable law, you agree to be liable for all uses of
  //             your Account whether or not actually authorized by you, including
  //             but not limited to uses of your Account to submit questions at the
  //             Site or to answer questions through the Site. This means that you
  //             should not supply your password to anyone who is not authorized to
  //             take actions for you.
  //           </p>
  //           <h3>Privacy</h3>
  //           <p>
  //             Legateca may use your email address for the purposes it considers
  //             and deems fit. Information about you may be used by Legateca for
  //             the purposes it considers fit.
  //           </p>
  //           <h3>Services related terms</h3>
  //           <p>
  //             While you make use of our services, you are solely responsible for
  //             communications posted by you or through your Account. You agree
  //             that violation of any of the following terms constitutes a formal
  //             material breach of this Agreement:
  //           </p>
  //           <p>
  //             Violation of Services usage for any purpose in malefaction of
  //             local, state, national, or international laws; Providing false
  //             information on your registration form or impersonating any person;
  //             Posting material that infringes on the intellectual property
  //             rights of others or on the privacy or publicity rights of others;
  //             Posting or transmitting any information, data, text, files, links,
  //             software, chat, communication, or any other materials that are
  //             unlawful, harmful, threatening, abusive, invasive of another's
  //             privacy, harassing, defamatory, slanderous, vulgar, obscene,
  //             hateful, racist, embarrassing or otherwise objectionable to any
  //             other person or entity as determined by Legateca in its sole
  //             discretion;
  //           </p>
  //           <p>
  //             Posting or transmitting any unsanctioned advertising, promotional
  //             materials, or any other forms of unsanctioned solicitation,
  //             including, without limitation, 'junk mail,' 'Spam,' 'chain
  //             letters,' or any unsolicited mass distribution of email; Posting
  //             surveys, contests, or pyramid schemes; Posting improper questions
  //             or answers that are not related to the particular topic; Stalking,
  //             harassing, or otherwise harming others;
  //           </p>
  //           <p>
  //             Distributing viruses, corrupted files, or any other similar
  //             software or programs that may damage the operation of another's
  //             computer;
  //           </p>
  //           <p>
  //             Collecting or storing personal data about other users of the Site;
  //             Inducing, enticing, soliciting, recruiting, luring, or attempting
  //             to induce, entice, solicit, recruit, or lure users or Experts to
  //             another web site which, in Legateca’s reasonable determination, is
  //             a competitor of Legateca Engaging in any other conduct that
  //             inhibits any other person from using or enjoying the Q&A Service
  //             or the Site;
  //           </p>
  //           <p>
  //             Engaging in any other behaviour on the Site, which in Legateca’s
  //             sole discretion is unacceptable. Legateca may (but is not
  //             obligated) to remove Content for any reason, with or without
  //             notice to you, including without limitation Submissions (as
  //             defined below) that do not conform with the rules Service and
  //             terminate your access to the Site.
  //           </p>
  //           <h3>License to Submissions to the Site</h3>
  //           <p>
  //             Should you provide this Site with information, including without
  //             limitation, postings, feedback, data, questions, comments or
  //             suggestions ('Submissions') those Submissions will be deemed not
  //             to be confidential. You grant Legateca a royalty-free, perpetual,
  //             irrevocable, world-wide license to use, copy, reproduce, create
  //             derivative works from, adapt, modify, publish, edit, translate,
  //             sell, distribute, transmit, transfer, publicly display, publicly
  //             perform, and display the Submissions without any limitation and in
  //             any media or any form now known or later developed. Legateca may
  //             sublicense these rights to third parties.
  //           </p>
  //           <h3>Copyrights</h3>
  //           <p>
  //             Various content and services on the Site, including text,
  //             graphics, logos, icons, images, and videos and the selection and
  //             arrangement thereof, in addition to any concepts, know-how, tools,
  //             frameworks, software, applications or other technology,
  //             algorithms, models, processes, and industry perspectives
  //             underlying or embedded in the foregoing, along with any
  //             enhancements to or derivative works thereof (the “Site Content”)
  //             is the exclusive property of Prof Services or its licensors and,
  //             to the extent applicable, is protected by UK copyright laws.{" "}
  //           </p>
  //           <p>
  //             Neither the Site Content nor functionality of the Site, may be
  //             copied, reproduced, modified, reverse engineered, altered
  //             (including the removal or disabling of any security or
  //             technological safeguards, disclaimers, or legends) uploaded,
  //             published, uploaded, posted, transmitted, or distributed in any
  //             way without our written permission, except for those uses
  //             specified in Sections above for the use of site content. All
  //             rights not expressly granted are reserved.
  //           </p>
  //           <h3>General disclaimer</h3>
  //           <p>
  //             Legateca does not promise that the Site will be error-free,
  //             uninterrupted, nor that it will provide specific results from use
  //             of the Site or any content, search or link on it.
  //           </p>
  //           <p>
  //             The Site and its content are delivered on an ‘as-is’ and
  //             ‘as-available’ basis. Legateca cannot ensure that files you
  //             download from the Site will be free of viruses or contamination or
  //             destructive features.
  //           </p>
  //           <p>
  //             Legateca will not be liable for any Losses of any kind arising
  //             from the use of this Site, including without limitation, direct,
  //             indirect, incidental, and punitive and consequential damages.
  //             Legateca disclaims any and all liability for the acts, omissions
  //             and conduct of any third party users in connection with or
  //             otherwise related to your use of the Site.
  //           </p>
  //           <h3>Information disclaimer</h3>
  //           <p>
  //             Our Site contains general information only and nothing contained
  //             in this Site or any correspondence constitutes legal advice. The
  //             information is not advice and should not be treated as such.
  //           </p>
  //           <p>
  //             You must not rely on the information on our Site as an alternative
  //             to legal advice from your lawyer or other professional legal
  //             services provider. Some of Our Services may be supported by
  //             Artificial Intelligence Technology but does not perform the
  //             function of a professional lawyer; nor a replacement of a lawyer,
  //             or intended to be a lawyer or replacement thereof.
  //           </p>
  //           <p>
  //             No lawyer-client, attorney-client or solicitor-client relationship
  //             shall be created through the use of our Site.
  //           </p>
  //           <p>
  //             You should always consult your own qualified lawyer, financial
  //             adviser or other qualified professional regarding any specific
  //             problem.
  //           </p>
  //           <p>
  //             You should never delay seeking legal advice, disregard legal
  //             advice, or commence or discontinue any legal action because of
  //             information on our Site.
  //           </p>
  //           <p>
  //             The information on our Site is provided without any
  //             representations or warranties, express or implied. In particular,
  //             we do not warrant or represent that the information on this Site
  //             will be available at any time in whole or in part; or, that it is
  //             or shall remain accurate, truthful, complete and kept up-to-date.
  //             We accept no liability whatsoever for any loss or damage caused or
  //             arising directly or indirectly in connection with any use of, or
  //             reliance placed on, the contents of this Site by you. We further
  //             accept no responsibility for the content of any other site
  //             mentioned on this site, linked to or from it in any way. Nothing
  //             herein will: (i) limit or exclude any liability for death or
  //             personal injury resulting from negligence; (ii) limit or exclude
  //             any liability for fraud or fraudulent misrepresentation; (iii)
  //             limit any liabilities in any way that is not permitted under
  //             applicable law; or (iv) exclude any liabilities that may not be
  //             excluded under applicable law.
  //           </p>
  //           <h3>Warranty disclaimer</h3>
  //           <p>
  //             This site and its services are provided on an 'as is' basis. The
  //             site makes no other representations or warranties of any kind,
  //             express or implied, including without limitation:
  //           </p>
  //           <p>
  //             The implied warranties of merchantability, fitness for a
  //             particular purpose, title, and non-infringement; that the site or
  //             the services will meet your requirements, will always be
  //             available, accessible, uninterrupted, timely, secure or operate
  //             without error; Any obligation, liability, right, claim or remedy
  //             in tort, whether or not arising from the negligence of the site;
  //           </p>
  //           <p>
  //             The site expressly disclaims any and all warranties and/or
  //             conditions, express or implied, including but not limited to the
  //             implied warranties of merchantability and fitness for a particular
  //             purpose, with respect to the services it provides pursuant to this
  //             agreement. The client's exclusive remedy and the site sole
  //             liability, if any, shall be limited to the aggregate amount of
  //             charges paid by you to the site for the specific services, which
  //             are the basis of any claim (s) by you;
  //           </p>
  //           <p>
  //             We do not guarantee continuous, uninterrupted or secure access to
  //             our services, and operation of our site may be interfered with by
  //             numerous factors outside our control;
  //           </p>
  //           <p>
  //             The payments made to the site for paying the respective office
  //             fees, service fee and expenses incurred are not to be calculated
  //             for the aggregate fees paid to the site and shall be excluded for
  //             the refund by the site ;
  //           </p>
  //           <p>
  //             To the full extent permissible under applicable law, the site
  //             disclaims any and all such warranties
  //           </p>
  //           <h3>Hyperlinks and search results</h3>
  //           <p>
  //             The Site may contain hyperlinks or produce search results that
  //             reference or link to third party websites throughout the World
  //             Wide Web. These are provided for your ease of reference only and
  //             Legateca does not endorse such websites or accept any
  //             responsibility for the content of such websites and cannot
  //             therefore guarantee, represent or warrant that the content
  //             contained in the sites is accurate, legal and/or inoffensive or
  //             that they will not contain viruses or otherwise impact your
  //             computer. By using the Site to search for or link to another
  //             website, you agree and understand that you may not make any claim
  //             against Legateca for any Losses, whatsoever, resulting from your
  //             use of the Site to obtain search results or to link to another
  //             website.
  //           </p>
  //           <h3>Limitation of liability</h3>
  //           <p>
  //             Nothing in these terms of use excludes or limits our liability for
  //             death or personal injury arising from our negligence, or our fraud
  //             or fraudulent misrepresentation, or any other liability that
  //             cannot be excluded or limited by English law.
  //           </p>
  //           <p>
  //             To the extent permitted by law, we exclude all conditions,
  //             warranties, representations or other terms which may apply to the
  //             Site or any content on it, whether express or implied.
  //           </p>
  //           <p>
  //             We will not be liable to you for any loss or damage, whether in
  //             contract, tort (including negligence), breach of statutory duty,
  //             or otherwise, even if foreseeable, arising under or in connection
  //             with (i) use of, or inability to use, the Site; or (ii) use of or
  //             reliance on any content displayed on the Site; including any
  //             dispute with one or more users of the Site.
  //           </p>
  //           <p>
  //             If you are a business user, we will not be liable for: (i) loss of
  //             profits, sales, business, or revenue; (ii) business interruption;
  //             (iii) loss of anticipated savings; (iv) loss of business
  //             opportunity, goodwill or reputation; or (v) any indirect or
  //             consequential loss or damage.
  //           </p>
  //           <p>
  //             If you are a consumer user, we only provide the Site to you for
  //             domestic and private use. You agree not to use the Site for any
  //             commercial or business purposes, and we have no liability to you
  //             for any loss of profit, loss of business, business interruption,
  //             or loss of business opportunity.
  //           </p>
  //           <p>
  //             We will not be liable for any loss or damage caused by a virus,
  //             distributed denial-of-service attack, or other technologically
  //             harmful material that may infect your computer equipment, computer
  //             programs, data, or other proprietary material due to your use of
  //             the Site or to your downloading of any content on it, or on any
  //             website linked to it.
  //           </p>
  //           <p>
  //             We assume no responsibility for the content of websites linked on
  //             the Site. Such links should not be interpreted as endorsement by
  //             us of those linked websites. We will not be liable for any loss or
  //             damage that may arise from your use of them.
  //           </p>
  //           <h3>Enforceability</h3>
  //           <p>
  //             If it turns out that a particular clause is not enforceable, this
  //             will not affect any other clauses.
  //           </p>
  //           <h3>Entire agreement</h3>
  //           <p>
  //             These terms together with the Legateca Privacy Policy which is
  //             incorporated by these references together constitute the entire
  //             agreement between you and Legateca in relation to your use of the
  //             Site and supersedes any prior representations, inducements or
  //             agreements relating to its subject matter.
  //           </p>
  //           <h3>Third party rights</h3>
  //           <p>
  //             These terms govern the relationship between the you and Legateca
  //             and do not create any third-party beneficiary rights.
  //           </p>
  //           <h3>Law and jurisdiction</h3>
  //           <p>
  //             These Terms are governed by the laws of the United Kingdom without
  //             reference to the principles of conflicts of laws thereof.
  //           </p>
  //         </div>
  //       </section>
  //     </div>
  //   </Layout>
  // );
};

export default Terms;
