import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Contactus from '../../Components/Contactus/Contactus';

const ContactUsPage = (props) => {

  try {
    let prevMetaDesc = document.getElementById("baseDescription")
    prevMetaDesc.remove()
    //use the piece of loading state to return other component until you have the data
  } catch (error) {
  }
  useEffect(() => {
    window.scroll(0, 0);
    return () => { };
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact Legateca For Solicitors in Uk | Legal advice</title>
        <link rel="canonical" href="https://legateca.com/contact-us" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta name="title" content="Contact Legateca For Solicitors in Uk | Legal advice" />
        <meta name="description" content="Get in touch with Legateca for professional legal advice and a lawyer in the Uk. Call us at +44 1635 745074 now!"></meta>
      </Helmet>
      <Contactus />
    </>

    // <Layout>
    //   <div>
    // <Helmet>
    //   <title>Contact Legateca For Solicitors in Uk | Legal advice</title>
    //   <link rel="canonical" href="https://legateca.com/contact-us" />
    //   <meta name="robots" content="INDEX,FOLLOW" />
    //   <meta name="title" content="Contact Legateca For Solicitors in Uk | Legal advice" />
    //   <meta name="description" content="Get in touch with Legateca for professional legal advice and a lawyer in the Uk. Call us at +44 1635 745074 now!"></meta>
    // </Helmet>
    //   </div>
    //   <Contactus />
    // </Layout>
  );
};

export default ContactUsPage;
