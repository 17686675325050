import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const HelpCard = ({
  setHoveredDiv,
  hoveredDiv,
  img,
  navigateLink,
  value,
  paragraph,
  heading,
  alt,
  extraClasses,
}) => {
  const [isMedium, setIsMedium] = useState(false);
  const router = useHistory();
  useEffect(() => {
    const handleResize = () => {
      const isMediumScreen = window.matchMedia("(max-width: 1023px)").matches;
      setIsMedium(isMediumScreen);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className=" mx-auto w-full">
      <div
        onMouseEnter={() => setHoveredDiv(value)}
        onMouseLeave={() => setHoveredDiv(null)}
        className="relative overflow-hidden  border-none cursor-pointer rounded-[5px]  "
      >
        <div
          onClick={() => router.push(navigateLink)}
          // className={`div2 absolute bg-gradient-to-b from-[#e3b67649] to-[#0a4c4bde] rounded-t-sm h-[125%] 2xl:h-[430px] pt-52 2xl:pt-60 text-left px-8 rounded-[5px] delay-75
          className={`div2 ${
            extraClasses ? extraClasses : ""
          } absolute bg-gradient-to-b from-[#e3b67649]  flex flex-col justify-end 3xl:px-10 px-6 to-[#0a4c4bde] rounded-t-sm text-left h-full w-full
        ${isMedium || hoveredDiv ? "down-to-up" : "lg:hidden "}`}
        >
          <div className="text-white text-[14px] 3xl:flex flex-col gap-3 3xl:min-h-[300px] 3xl:max-h-[300px]">
            <div
              // className="text-white mb-1 md:text-[18px] text-base tracking-[0.36px] leading-[250%] font-semibold"
              className="text-white 3xl:text-[50px] md:text-[18px] text-base not-italic font-semibold  !leading-[45px] tracking-[0.36px] uppercase"
              to={navigateLink}
            >
              {heading}
            </div>
            {paragraph}
            <p className="text-white text-xs 3xl:text-[50px] lg:text-sm font-[500] !leading-45 tracking-tighter ">
              View -
            </p>
          </div>
        </div>
        <img className="" src={img} alt={alt} />
      </div>
    </div>
  );
};

export default HelpCard;
