import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Layout from "../../Components/Layout";
import Userprofile from "../../assets/images/user.png";
import { Nav, Tab } from "react-bootstrap";
import EditProfile from "../../Components/Profile/EditProfile";
import ViewDocument from "../../Components/Profile/ViewDocument";
import YourAppointment from "../../Components/Profile/YourAppointment";
import PaymentHistory from "../../Components/Profile/PaymentHistory";
import { feathersAuthentication } from "../../server/feathers-binding";
import Verification from "../../Components/Profile/Verification";
import { Helmet } from "react-helmet";
import { AuthIsSignedIn, AuthIsNotSignedIn } from "../../contexts/authContext";
import { HeroSection } from "../About";
import Footer from "../../Components/Footer";
import Divider from '@mui/material/Divider'

const ProfilePage = (props) => {
  const [profilePic, setProfilePic] = useState(Userprofile);
  const [selectedSection, setselectedSection] = useState('My profile');
  useEffect(() => {
    if (!props.user.email) {
      props.history.push({
        pathname: "/login",
        state: window.location.pathname,
      });
    }
  }, [props]);

  useEffect(() => {
    if (props.user.role === "user") {
      setProfilePic(props.user.profile_img);
    }
    if (props.userData.role === "user") {
      setProfilePic(props.userData.profile_img);
    }
  }, [props.user, props.userData]);
  const navItems = [
    {
      title: 'My profile',
      svg: <svg width={15} height={17} viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.60376 9.66602C11.635 9.66602 14.1038 12.1348 14.1038 15.166C14.1038 15.7285 13.635 16.166 13.1038 16.166H1.10376C0.54126 16.166 0.10376 15.7285 0.10376 15.166C0.10376 12.1348 2.54126 9.66602 5.60376 9.66602H8.60376ZM1.60376 14.666H12.5725C12.3225 12.6973 10.635 11.166 8.60376 11.166H5.60376C3.54126 11.166 1.85376 12.6973 1.60376 14.666ZM7.10376 8.16602C4.88501 8.16602 3.10376 6.38477 3.10376 4.16602C3.10376 1.97852 4.88501 0.166016 7.10376 0.166016C9.29126 0.166016 11.1038 1.97852 11.1038 4.16602C11.1038 6.38477 9.29126 8.16602 7.10376 8.16602ZM7.10376 1.66602C5.69751 1.66602 4.60376 2.79102 4.60376 4.16602C4.60376 5.57227 5.69751 6.66602 7.10376 6.66602C8.47876 6.66602 9.60376 5.57227 9.60376 4.16602C9.60376 2.79102 8.47876 1.66602 7.10376 1.66602Z" fill="currentColor" />
      </svg>,
    },
    {
      title: 'ID Verification',
      svg: <svg width={15} height={17} viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1038 0.458984C13.1975 0.458984 14.1038 1.36523 14.1038 2.45898V14.459C14.1038 15.584 13.1975 16.459 12.1038 16.459H2.10376C0.97876 16.459 0.10376 15.584 0.10376 14.459V2.45898C0.10376 1.36523 0.97876 0.458984 2.10376 0.458984H12.1038ZM12.6038 14.459V2.45898C12.6038 2.20898 12.3538 1.95898 12.1038 1.95898H2.10376C1.82251 1.95898 1.60376 2.20898 1.60376 2.45898V14.459C1.60376 14.7402 1.82251 14.959 2.10376 14.959H12.1038C12.3538 14.959 12.6038 14.7402 12.6038 14.459ZM10.6038 12.459C10.8538 12.459 11.1038 12.709 11.1038 12.959C11.1038 13.2402 10.8538 13.459 10.6038 13.459H3.60376C3.32251 13.459 3.10376 13.2402 3.10376 12.959C3.10376 12.709 3.32251 12.459 3.60376 12.459H10.6038ZM7.10376 10.709C5.01001 10.709 3.35376 9.05273 3.35376 6.95898C3.35376 4.89648 5.01001 3.20898 7.10376 3.20898C9.16626 3.20898 10.8538 4.89648 10.8538 6.95898C10.8538 9.05273 9.16626 10.709 7.10376 10.709ZM9.79126 6.45898C9.63501 5.61523 9.07251 4.89648 8.32251 4.52148C8.54126 5.05273 8.66626 5.74023 8.69751 6.45898H9.79126ZM8.69751 7.45898C8.66626 8.20898 8.51001 8.86523 8.32251 9.42773C9.07251 9.02148 9.63501 8.33398 9.79126 7.45898H8.69751ZM7.10376 4.30273C6.88501 4.55273 6.54126 5.30273 6.47876 6.45898H7.72876C7.63501 5.30273 7.29126 4.55273 7.10376 4.30273ZM7.72876 7.45898H6.47876C6.54126 8.64648 6.88501 9.39648 7.10376 9.64648C7.29126 9.39648 7.63501 8.64648 7.72876 7.45898ZM5.85376 4.52148C5.10376 4.89648 4.54126 5.61523 4.38501 6.45898H5.47876C5.51001 5.74023 5.63501 5.05273 5.85376 4.52148ZM5.47876 7.45898H4.38501C4.54126 8.33398 5.10376 9.02148 5.85376 9.42773C5.63501 8.86523 5.51001 8.20898 5.47876 7.45898Z" fill="currentColor" />
      </svg>

    },
    {
      title: 'My Documents',
      svg: <svg width={13} height={17} viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.10376 2.25195C0.10376 1.1582 0.97876 0.251953 2.10376 0.251953H7.26001C7.79126 0.251953 8.29126 0.470703 8.66626 0.845703L11.51 3.68945C11.885 4.06445 12.1038 4.56445 12.1038 5.0957V14.252C12.1038 15.377 11.1975 16.252 10.1038 16.252H2.10376C0.97876 16.252 0.10376 15.377 0.10376 14.252V2.25195ZM10.6038 14.252V5.25195H8.10376C7.54126 5.25195 7.10376 4.81445 7.10376 4.25195V1.75195H2.10376C1.82251 1.75195 1.60376 2.00195 1.60376 2.25195V14.252C1.60376 14.5332 1.82251 14.752 2.10376 14.752H10.1038C10.3538 14.752 10.6038 14.5332 10.6038 14.252Z" fill="currentColor" />
      </svg>
    },
    {
      title: 'My appointments',
      svg: <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.85376 2.04492H9.35376V0.794922C9.35376 0.388672 9.66626 0.0449219 10.1038 0.0449219C10.51 0.0449219 10.8538 0.388672 10.8538 0.794922V2.04492H12.1038C13.1975 2.04492 14.1038 2.95117 14.1038 4.04492V14.0449C14.1038 15.1699 13.1975 16.0449 12.1038 16.0449H2.10376C0.97876 16.0449 0.10376 15.1699 0.10376 14.0449V4.04492C0.10376 2.95117 0.97876 2.04492 2.10376 2.04492H3.35376V0.794922C3.35376 0.388672 3.66626 0.0449219 4.10376 0.0449219C4.51001 0.0449219 4.85376 0.388672 4.85376 0.794922V2.04492ZM1.60376 14.0449C1.60376 14.3262 1.82251 14.5449 2.10376 14.5449H12.1038C12.3538 14.5449 12.6038 14.3262 12.6038 14.0449V6.04492H1.60376V14.0449Z" fill="currentColor" />
      </svg>
    },
    {
      title: 'Payment history',
      svg: <svg width={19} height={15} viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.35376 10.3379C5.76001 10.3379 6.10376 10.6816 6.10376 11.0879C6.10376 11.5254 5.76001 11.8379 5.35376 11.8379H3.85376C3.41626 11.8379 3.10376 11.5254 3.10376 11.0879C3.10376 10.6816 3.41626 10.3379 3.85376 10.3379H5.35376ZM11.3538 10.3379C11.76 10.3379 12.1038 10.6816 12.1038 11.0879C12.1038 11.5254 11.76 11.8379 11.3538 11.8379H7.85376C7.41626 11.8379 7.10376 11.5254 7.10376 11.0879C7.10376 10.6816 7.41626 10.3379 7.85376 10.3379H11.3538ZM16.1038 0.837891C17.1975 0.837891 18.1038 1.74414 18.1038 2.83789V12.8379C18.1038 13.9629 17.1975 14.8379 16.1038 14.8379H2.10376C0.97876 14.8379 0.10376 13.9629 0.10376 12.8379V2.83789C0.10376 1.74414 0.97876 0.837891 2.10376 0.837891H16.1038ZM16.1038 2.33789H2.10376C1.82251 2.33789 1.60376 2.58789 1.60376 2.83789V3.83789H16.6038V2.83789C16.6038 2.58789 16.3538 2.33789 16.1038 2.33789ZM16.6038 6.83789H1.60376V12.8379C1.60376 13.1191 1.82251 13.3379 2.10376 13.3379H16.1038C16.3538 13.3379 16.6038 13.1191 16.6038 12.8379V6.83789Z" fill="currentColor" />
      </svg>
    },
  ]
  function renderingTheSections() {
    switch (selectedSection) {
      case 'My profile':
      default:
        return <EditProfile history={props.history} />
      case 'ID Verification':

        return <Verification history={props.history} />
      // return <div className="flex flex-col gap-6">
      //   <div style={{
      //     borderRadius: '11px',
      //     border: '1px solid #EBEBEB'
      //   }} className="py-[26px] pl-[30px] pr-[44px]">
      //     <Typography varient={'green-head'}>ID Verification</Typography>
      //     <div className="pt-[26px] flex flex-col gap-3">
      //       <Typography varient={'black-head'}>
      //         Before starting the verification process, here’s what you need
      //       </Typography>
      //       <div>
      //         <ParaComp>
      //           A valid government-issued photo ID document. Not a photocopy or a picture of an ID document. Ensure that the ID document is not expired.
      //         </ParaComp>
      //         <ParaComp>
      //           A device with a camera, if possible, use a mobile device. Cameras on mobile devices typically take higher-quality photos than a webcam.
      //         </ParaComp>
      //       </div>
      //     </div>
      //   </div>
      //   <div style={{
      //     borderRadius: '11px',
      //     border: '1px solid #EBEBEB'
      //   }} className="py-[26px] pl-[30px] pr-[44px]">
      //     <div className="pt-[26px] flex flex-col gap-3">
      //       <Typography varient={'black-head'}>
      //         The quality of the images captured affects success rates dramatically. Below are a few best practices to help ensure that your verification succeeds
      //       </Typography>
      //       <div>
      //         <ParaComp>
      //           Capture a clear image. Make sure that the images are not too dark or bright, and don’t have a glare. Hold steady and allow your camera to focus to avoid blurry photos.
      //         </ParaComp>
      //         <ParaComp>
      //           Do not block any part of your ID document in the image. Ideally you can lay it flat to take the photo.
      //         </ParaComp>
      //         <ParaComp>
      //           Do not block any part of your face. Remove sunglasses, masks, or other accessories.
      //         </ParaComp>
      //         <ParaComp>
      //           Find a location with ambient lighting. Avoid spaces with strong overhead lights that cast a shadow on your face or ID document. Avoid sitting directly in front of a bright light which can wash out your face and add a glare to your ID document.
      //         </ParaComp>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="pt-7 lg:max-w-[240px]">
      //     {/* <ButtonForDashboard handleClick={()=>}>Verify your identity</ButtonForDashboard> */}
      //   </div>
      // </div >
      case 'My Documents':
        return <ViewDocument history={props.history} />
      // return <div className="flex flex-col gap-6">
      //   <div style={{
      //     borderRadius: '11px',
      //     border: '1px solid #EBEBEB'
      //   }} className="py-[26px] pl-[30px] pr-[44px]">
      //     <div className='flex items-start justify-between'>
      //       <Typography varient={'green-head'}>Free Summary</Typography>
      //       <div className='flex flex-col gap-[10px] items-start'>
      //         <Typography varient={'light-head'}>Created at</Typography>
      //         <Typography varient={'black-head'}>october 27, 2023</Typography>
      //       </div>
      //     </div>
      //     <div className="pt-[16px] flex flex-col gap-3">
      //       <Typography varient={'black-head'}>
      //         Please download your Summary
      //       </Typography>
      //       <Typography varient={'para'}>
      //         Here you will find the latest report. For previous reports, please check your email.
      //       </Typography>
      //       <div className=" xl:max-w-[240px]">
      //         <ButtonForDashboard  >Download pdf</ButtonForDashboard>
      //       </div>
      //     </div>
      //   </div>
      //   <div style={{
      //     borderRadius: '11px',
      //     border: '1px solid #EBEBEB'
      //   }} className="py-[26px] pl-[30px] pr-[44px]">
      //     <div className='flex items-start justify-between'>
      //       <Typography varient={'green-head'}>Instant Advice</Typography>
      //       <div className='flex flex-col gap-[10px] items-start'>
      //         <Typography varient={'light-head'}>Created at</Typography>
      //         <Typography varient={'black-head'}>october 27, 2023</Typography>
      //       </div>
      //     </div>
      //     <div className="pt-[16px] flex flex-col gap-3">
      //       <Typography varient={'black-head'}>
      //         Please download your Summary
      //       </Typography>
      //       <Typography varient={'para'}>
      //         Here you will find all reports generated by our AI powered Legal advisor.
      //       </Typography>
      //       <div className=" xl:max-w-[240px]">
      //         <ButtonForDashboard  >Download pdf</ButtonForDashboard>
      //       </div>
      //     </div>
      //   </div>
      // </div >
      case 'My appointments':
        return <YourAppointment history={props.history} />
      case 'Payment history':
        return <PaymentHistory history={props.history} />
    }
  }
  function heightCont() {
    switch (selectedSection) {
      default:
        return
      case 'ID Verification':
        return "864px"
      case 'My Documents':
        return "700px"
      case 'My appointments':
        return "660px"
      case 'Payment history':
        return "646px"

    }
  }
  if (props.user.email) {
    return (
      <AuthIsSignedIn>
        <Helmet>
          <link rel="canonical" href="https://legateca.com/profile" />
          <meta name="robots" content="INDEX,FOLLOW" />
          <meta
            name="description"
            content="Legateca provides professional legal advice and services for individuals, businesses, organizations, and more. Get the best legal advice and support from us!"
          ></meta>
        </Helmet>
        <HeroSection bgImg={'/dashboard.png'} heading={'My Account'} />
        <main className="xl:py-[80px] xl:px-0 px-8 flex flex-col gap-[54px] py-[50px] w-full max-w-[1240px] 3xl:max-w-[2400px] mx-auto">
          <div className="text-[32px] text-values-align text-ThemeSecondary not-italic font-medium leading-[48px] tracking-[0.64px] uppercase">
            Account settings
          </div>
          <section
            style={{
              minHeight: heightCont(),
            }}
            className="border-2 border-ThemeSecondary flex xl:flex-row flex-col rounded-lg">
            <aside className="w-full py-[72px] px-[34px] flex flex-col xl:justify-between xl:min-w-[270px] xl:max-w-[270px] xl:border-r border-[#EBEBEB]">
              <ul className="list-none !p-0 flex flex-col gap-[38px]">
                {navItems.map((it, id) => {
                  return <li onClick={() => setselectedSection(it.title)} key={id} className={` ${it.title !== selectedSection ? 'text-[#252525]' : '!text-ThemeSecondary font-[600]'} cursor-pointer flex items-center gap-6 text-base not-italic  leading-[118.5%] capitalize`}>
                    {it.svg}
                    {it.title}
                  </li>
                })}
                <li onClick={() => {
                  props.logout();
                  localStorage.clear();
                  props.history.push("/");
                }} className="cursor-pointer xl:hidden flex items-center gap-6 text-base not-italic  leading-[118.5%] capitalize">
                  <svg width={17} height={15} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.10376 13.373C6.10376 13.8105 5.76001 14.123 5.35376 14.123H3.10376C1.41626 14.123 0.10376 12.8105 0.10376 11.123V3.12305C0.10376 1.4668 1.41626 0.123047 3.10376 0.123047H5.35376C5.76001 0.123047 6.10376 0.466797 6.10376 0.873047C6.10376 1.31055 5.76001 1.62305 5.35376 1.62305H3.10376C2.26001 1.62305 1.60376 2.31055 1.60376 3.12305V11.123C1.60376 11.9668 2.26001 12.623 3.10376 12.623H5.35376C5.76001 12.623 6.10376 12.9668 6.10376 13.373ZM15.885 6.62305L11.9163 2.37305C11.635 2.06055 11.1663 2.06055 10.8538 2.3418C10.5413 2.62305 10.5413 3.0918 10.8225 3.4043L13.6038 6.37305H5.82251C5.41626 6.37305 5.10376 6.7168 5.10376 7.12305C5.10376 7.56055 5.41626 7.87305 5.82251 7.87305H13.5725L10.76 10.873C10.4788 11.1855 10.4788 11.6543 10.7913 11.9355C10.9788 12.0605 11.1663 12.123 11.3225 12.123C11.51 12.123 11.6975 12.0605 11.8538 11.9043L15.8225 7.6543C16.1663 7.37305 16.1663 6.9043 15.885 6.62305Z" fill="#252525" />
                  </svg>

                  Logout</li>
              </ul>
              <div onClick={() => {
                props.logout();
                localStorage.clear();
                props.history.push("/");
              }} className="xl:flex hidden cursor-pointer items-center gap-2">
                <svg width={17} height={15} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.10376 13.373C6.10376 13.8105 5.76001 14.123 5.35376 14.123H3.10376C1.41626 14.123 0.10376 12.8105 0.10376 11.123V3.12305C0.10376 1.4668 1.41626 0.123047 3.10376 0.123047H5.35376C5.76001 0.123047 6.10376 0.466797 6.10376 0.873047C6.10376 1.31055 5.76001 1.62305 5.35376 1.62305H3.10376C2.26001 1.62305 1.60376 2.31055 1.60376 3.12305V11.123C1.60376 11.9668 2.26001 12.623 3.10376 12.623H5.35376C5.76001 12.623 6.10376 12.9668 6.10376 13.373ZM15.885 6.62305L11.9163 2.37305C11.635 2.06055 11.1663 2.06055 10.8538 2.3418C10.5413 2.62305 10.5413 3.0918 10.8225 3.4043L13.6038 6.37305H5.82251C5.41626 6.37305 5.10376 6.7168 5.10376 7.12305C5.10376 7.56055 5.41626 7.87305 5.82251 7.87305H13.5725L10.76 10.873C10.4788 11.1855 10.4788 11.6543 10.7913 11.9355C10.9788 12.0605 11.1663 12.123 11.3225 12.123C11.51 12.123 11.6975 12.0605 11.8538 11.9043L15.8225 7.6543C16.1663 7.37305 16.1663 6.9043 15.885 6.62305Z" fill="#252525" />
                </svg>

                Logout</div>
            </aside>
            <div className="w-full px-[10px] md:px-[30px] xl:pt-[70px] pb-[42px] flex flex-col gap-[38px]">
              {
                selectedSection === 'ID Verification' ? <div className='flex sm:flex-row flex-col items-start gap-2 sm:gap-0 sm:items-center justify-between'>
                  <div className="text-[#006766] text-2xl not-italic font-bold leading-[31px] tracking-[0.48px] uppercase">{selectedSection}</div>
                  <div className="flex items-center gap-[15px]">
                    <div className=' text-[#252525]/40 text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase'>STATUS</div>
                    <div className='flex items-center gap-2 text-[#CA0000]'>
                      <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.89429 5.46875C6.17554 5.1875 6.64429 5.1875 6.92554 5.46875L8.39429 6.96875L9.89429 5.46875C10.1755 5.1875 10.6443 5.1875 10.9255 5.46875C11.238 5.78125 11.238 6.25 10.9255 6.53125L9.45679 8L10.9255 9.46875C11.238 9.78125 11.238 10.25 10.9255 10.5312C10.6443 10.8438 10.1755 10.8438 9.89429 10.5312L8.39429 9.0625L6.92554 10.5312C6.64429 10.8438 6.17554 10.8438 5.89429 10.5312C5.58179 10.25 5.58179 9.78125 5.89429 9.46875L7.36304 8L5.89429 6.53125C5.58179 6.25 5.58179 5.78125 5.89429 5.46875ZM16.4255 8C16.4255 12.4375 12.8318 16 8.42554 16C3.98804 16 0.425537 12.4375 0.425537 8C0.425537 3.59375 3.98804 0 8.42554 0C12.8318 0 16.4255 3.59375 16.4255 8ZM8.42554 1.5C4.83179 1.5 1.92554 4.4375 1.92554 8C1.92554 11.5938 4.83179 14.5 8.42554 14.5C11.988 14.5 14.9255 11.5938 14.9255 8C14.9255 4.4375 11.988 1.5 8.42554 1.5Z" fill="currentColor" />
                      </svg>

                      <span className="text-inherit text-base not-italic font-medium leading-[normal]">
                        ID Not Verified</span>
                    </div>

                  </div>
                </div> : <div className="text-[#006766] text-2xl not-italic font-bold leading-[31px] tracking-[0.48px] uppercase">{selectedSection}</div>
              }
              <div className="w-full h-full">{renderingTheSections()}</div>
            </div>

          </section>
        </main>
        <Footer />
      </AuthIsSignedIn>
    )
  } else {
    return "";
  }

  // if (props.user.email) {
  //   return (
  //     <AuthIsSignedIn>
  //       <Layout>
  //         <div>
  // <Helmet>
  //   <link rel="canonical" href="https://legateca.com/profile" />
  //   <meta name="robots" content="INDEX,FOLLOW" />
  // </Helmet>
  //         </div>
  //         <div className="page-breadcumb-area profilePage">
  //           <div className="container">
  //             <div className="row">
  //               <div className="col-12">
  //                 <div className="wpo-breadcumb-wrap">
  //                   <h2>My Account</h2>
  //                   <ul>
  //                     <li>
  //                       <a href="/">Home</a>
  //                     </li>
  //                     <li>
  //                       <span>&nbsp; / &nbsp;</span>
  //                       <span>My Account</span>
  //                     </li>
  //                   </ul>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="page-content container">
  //           <div className="page_header">
  //             <h3 className="pageHeading">My Account</h3>
  //           </div>
  //           <div className="profilePage_sec settingPage row">
  //             <Tab.Container
  //               id="left-tabs-example"
  //               defaultActiveKey="first"
  //               className="profilePage_sec"
  //             >
  //               <div className="col-12 col-md-4 col-lg-3">
  //                 <div className="profile_sidebar">
  //                   <div className="sidebar_user_profile">
  //                     <div className="v-avatar is-large">
  //                       <img
  //                         className="avatar"
  //                         src={profilePic ? profilePic : Userprofile}
  //                         alt=""
  //                       />
  //                     </div>
  //                     <div className="user_designation">
  //                       <span>
  //                         {props.user.firstName} {props.user.lastName}
  //                       </span>
  //                       <span>{props.user.email}</span>
  //                     </div>
  //                   </div>
  //                   <div className="userProfile_tabs">
  //                     <Nav variant="pills" className="flex-column">
  //                       <Nav.Item>
  //                         <Nav.Link eventKey="first">
  //                           <i className="far fa-edit"></i> Edit Profile
  //                         </Nav.Link>
  //                       </Nav.Item>

  //                       <Nav.Item>
  //                         <Nav.Link eventKey="second">
  //                           <i className="far fa-id-badge"></i> ID Verification
  //                         </Nav.Link>
  //                       </Nav.Item>

  //                       <Nav.Item>
  //                         <Nav.Link eventKey="third">
  //                           <i className="fas fa-cog"></i> My Documents
  //                         </Nav.Link>
  //                       </Nav.Item>
  //                       <Nav.Item>
  //                         <Nav.Link eventKey="four">
  //                           <i className="fas fa-history"></i> My Appointments
  //                         </Nav.Link>
  //                       </Nav.Item>
  //                       <Nav.Item>
  //                         <Nav.Link eventKey="five">
  //                           <i className="far fa-money-bill-alt"></i> Payment
  //                           History
  //                         </Nav.Link>
  //                       </Nav.Item>
  //                       <Nav.Item>
  //                         <Nav.Link eventKey="six">
  //                           <div
  //                             onClick={() => {
  //                               props.logout();
  //                               localStorage.clear();
  //                               props.history.push("/");
  //                             }}
  //                           >
  //                             <i className="far fa-user"></i> Logout
  //                           </div>
  //                         </Nav.Link>
  //                       </Nav.Item>
  //                     </Nav>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="col-12 col-md-8 col-lg-9">
  //                 <Tab.Content className="Profile_edit_form">
  //                   <Tab.Pane eventKey="first">
  // <EditProfile history={props.history} />
  //                   </Tab.Pane>

  //                   <Tab.Pane eventKey="second">
  //                     <Verification history={props.history} />
  //                   </Tab.Pane>

  //                   <Tab.Pane eventKey="third">
  //                     <ViewDocument history={props.history} />
  //                   </Tab.Pane>
  //                   <Tab.Pane eventKey="four">
  //                     <YourAppointment history={props.history} />
  //                   </Tab.Pane>
  //                   <Tab.Pane eventKey="five">
  //                     <PaymentHistory history={props.history} />
  //                   </Tab.Pane>
  //                 </Tab.Content>
  //               </div>
  //             </Tab.Container>
  //           </div>
  //         </div>
  //       </Layout>
  //     </AuthIsSignedIn>
  //   );
  // } else {
  //   return "";
  // }
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || {},
    userData: (state.users && state.users.data) || {},
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout: feathersAuthentication.logout })
)(ProfilePage);


export const Typography = ({ varient, children }) => {
  function varientValues() {
    switch (varient) {
      case 'green-head':
        return 'text-[#006766] text-base not-italic font-semibold leading-[118.5%] capitalize'
      case 'black-head':
        return 'text-primary-tw text-base not-italic font-semibold leading-[118.5%] capitalize'
      case 'light-head':
        return 'text-[#252525]/40 text-center text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase'
      case 'para':
        return 'text-[#252525] flex gap-2 items-start text-sm not-italic font-normal leading-[26px] tracking-[0.28px]'
      default:
        return
    }
  }
  return (
    <div className={varientValues()}>
      {children}
    </div>
  )

}
export const ParaComp = ({ children }) => {
  return (
    <Typography varient={'para'}>
      <svg className="mt-[11px] min-h-[3px] min-w-[5px] max-h-[3px] max-w-[5px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
        <path d="M8.52742 1.45643C6.5703 -0.492032 3.40462 -0.484208 1.4569 1.4724C-0.49177 3.42962 -0.484572 6.59549 1.47223 8.54333C3.42997 10.4918 6.59628 10.4846 8.54369 8.52737C10.4914 6.57014 10.4848 3.40396 8.52742 1.45643Z" fill="#030104" />
        <path d="M4.15794 7.47839L3.60467 9.10795C4.40798 9.36739 5.27294 9.38523 6.08501 9.16116L5.87691 7.46618C5.3227 7.66335 4.71466 7.66741 4.15794 7.47839ZM2.54068 4.1066L0.774141 3.83777C0.542254 4.67211 0.565725 5.56341 0.844553 6.38617L2.5629 5.95586C2.33163 5.36343 2.3235 4.7034 2.54068 4.1066ZM9.00066 3.54672L7.44692 4.07092C7.69665 4.73001 7.67098 5.46921 7.36963 6.11202L9.04416 6.32233C9.33362 5.42007 9.31892 4.44177 9.00066 3.54672ZM3.56524 0.902236L3.88381 2.63226C4.51813 2.3337 5.24697 2.30365 5.89975 2.54213L6.39514 0.93447C5.48323 0.607118 4.4834 0.596477 3.56524 0.902236Z" fill="#030104" />
        <path d="M5.00018 6.91416C6.05739 6.91416 6.91442 6.05707 6.91442 4.9998C6.91442 3.94253 6.05739 3.08545 5.00018 3.08545C3.94297 3.08545 3.08594 3.94253 3.08594 4.9998C3.08594 6.05707 3.94297 6.91416 5.00018 6.91416Z" fill="#030104" />
      </svg>
      {children}
    </Typography>
  )
}
export const ButtonForDashboard = ({ role, handleClick, disabled, type, children, varient }) => {
  function vari() {
    switch (varient && varient) {
      default:
        return
      case undefined:
        return 'active:bg-ThemeSecondary hover:bg-ThemePrimary bg-ThemeSecondary text-white'
      case 'bgwhite':
        return 'border hover:!text-ThemeSecondary hover:!bg-white border-ThemeSecondary bg-white text-ThemeSecondary'
      case 'bgyellow':
        return 'hover:!text-white hover:!bg-ThemePrimary  bg-ThemePrimary text-white'
    }
  }
  return (
    <>
      {
        role ?
          <div onClick={handleClick} className={` ${vari()} border-none ${disabled ? 'pointer-events-none' : ''} cursor-pointer w-full text-center px-[10px] py-[20px] rounded-md text-sm not-italic font-medium !leading-none tracking-[0.28px] uppercase `}>
            {children}
          </div>
          :
          <button type={type ? type : 'text'} disabled={disabled} onClick={handleClick} className={` ${vari()} border-none  w-full text-center px-[10px] py-[20px] rounded-md text-sm not-italic font-medium !leading-none tracking-[0.28px] uppercase `}>
            {children}
          </button>}
    </>
  )
}