const Summary = (props) => {
  return (
    <div className="card ml-5 mt-5">
      <div className="container text-center">
        <h1>Your response summary</h1>
      </div>
      <ul className="list-group">
        {props.summary.map((res, i) => {
          return (
            <li key={i} className="list-group-item">
              <h5>{props.allQuestins[i].question}</h5>
              {props.allQuestins[i].answers.map((ans, index) => {
                return res.answer_id === ans._id ? (
                  <h4 key={index} className="ul.list-group .list-group-item">
                    {" "}
                    {ans.answer}{" "}
                  </h4>
                ) : props.allQuestins[index].type === 2 ? (
                  <h4 key={index} className="ul.list-group .list-group-item">
                    {res.answer_id}
                  </h4>
                ) : props.allQuestins[index].type === 1 ? (
                  <h4 key={index} className="ul.list-group .list-group-item">
                    {" "}
                    {res.answer_id}{" "}
                  </h4>
                ) : null;
              })}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Summary;
