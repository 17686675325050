import React, { useEffect } from "react";
import Ourteam from "../../Components/Ourteam/Ourteam";
import Layout from "../../Components/Layout";

const OurTeamPage = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
    return () => {
    };
  }, []);

  return (

    <Layout>    
      <Ourteam />
    </Layout>
  );
};

export default OurTeamPage;
