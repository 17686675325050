import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoginAuthFirst from "../../Components/Modals/LoginAuth";
import { Markup } from "interweave";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import { HeroSection } from "../About";
import Footer from "../../Components/Footer";

const SummaryPageBlog = (props) => {
  let { id } = useParams();
  var URL = `${process.env.REACT_APP_API_URL}/blogs/?slug=${id}`;
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true); // set some state for loading
  const [selectedStateValue, setselectedStateValue] = useState(0);
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    getProductdata();
  }, []);

  async function getProductdata() {
    const request = await fetch(URL)
      .then((request) => request.json())
      .then((data) => {
        // console.log("data", data);
        localStorage.setItem("serviceData", JSON.stringify(data));
        setProductData(data.data[0]);
        setLoading(false); // set Loading to false when you have the data
      })
      .catch((err) => console.log(err));
  }
  const [totalPages, setTotalPages] = useState(1);

  let jsondata = "";
  let pageSummary,
    pageImage,
    pageName,
    _id,
    question_URL,
    canonicalLink,
    metaDescription,
    metaKeywords,
    metaTitle;

  let pageData = JSON.parse(localStorage.getItem("serviceData"));

  try {
    pageName = pageData.data[0].title;
    pageSummary = pageData.data[0].body;
    canonicalLink = pageData.data[0].canonicalLink;
    metaDescription = pageData.data[0].metaDescription;
    metaKeywords = pageData.data[0].metaKeywords;
    metaTitle = pageData.data[0].metaTitle;
    pageImage = pageData.data[0].image;
    _id = pageData.data[0]._id;
    question_URL = window.location.origin + `/service/prelegate/${_id}`;
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }
  try {
    let prevMetaDesc = document.getElementById("baseDescription");
    let prevMetakeys = document.getElementById("basekeywords");
    prevMetakeys.remove();
    prevMetaDesc.remove();
    //use the piece of loading state to return other component until you have the data
  } catch (error) {}
  const fetchData = async (page) => {
    try {
      const skip = (page - 1) * 9;
      // Replace this with your actual API endpoint
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/blogs?$limit=${9}&$skip=${skip}`
      );
      if (response.ok) {
        const data = await response.json();
        setBlogData(data.data); // Assuming your API response has a "data" property
        // console.log(data.total / 9)
        setTotalPages(Math.ceil(data.total / 9)); // Total number of pages from the API
      } else {
        // Handle API error
        console.error("Error fetching data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Fetch data from your API based on the currentPage
    fetchData(1);
  }, []);
  if (loading) {
    return (
      <>
        <HeroSection heading={"Articles Details"} bgImg={"/articles.png"} />
        <div className="w-full h-screen flex flex-col justify-center items-center">
          <div class="loader-loader"></div>
        </div>
        <Footer />
      </>
    );
    // return <div>Loading...</div>;
  }
  // Trigger the effect whenever currentPage changes

  const css = `
    .summaryContainer {
      font-family: "Georama", sans-serif !important;
      all:initial;
    }

    .summaryContainer ol>li{
      list-style-type: number; 
    }

    .summaryContainer ul>li{
      list-style-type: initial; 
    }
    
    .summaryContainer table {
      width: 100%;
      border-collapse: collapse;
      margin: 25px 0;
      font-size: 0.9em;
      font-family: "Georama", sans-serif !important;
      min-width: 400px;
      text-align: unset !important;
   }

    .summaryContainer td {
      font-family: "Georama", sans-serif !important;
      border-color:black;
      border-style:solid;
      border-width:1px;
      overflow:hidden;padding:10px 5px;
      word-break:normal;
    }

    .summaryContainer h1 {
      font-family: "Georama", sans-serif !important;
      text-transform: none;
      display: block;
      font-size: 2em;
      font-weight: bold;
      color: #6a7894 !important;
      line-height: 20px;
    }

    .summaryContainer h2 {
      font-family: "Georama", sans-serif !important;
      text-transform: none;
      display: block;
      font-size: 1.5em;
      font-weight: bold;
      color: #6a7894 !important;
      line-height: 20px;
    }

    .summaryContainer h3 {
      font-family: "Georama", sans-serif !important;
      text-transform: none;
      display: block;
      font-size: 1.17em;
      font-weight: bold;
      color: #6a7894 !important;
      line-height: 20px;
    }

    .summaryContainer h4 {
      font-family: "Georama", sans-serif !important;
      text-transform: none;
      display: block;
      font-size: 1em;
      font-weight: bold;
      color: #6a7894 !important;
      line-height: 20px;
    }

    .summaryContainer h5 {
      font-family: "Georama", sans-serif !important;
      text-transform: none;
      display: block;
      font-size: .83em;
      font-weight: bold;
      color: #6a7894 !important;
      text-align: initial;
      line-height: 20px;
    }

    .summaryContainer h6 {
      font-family: "Georama", sans-serif !important;
      text-transform: none;
      display: block;
      font-size: .67em;
      font-weight: bold;
      color: #6a7894 !important;
      line-height: 20px;
    }


    .wpo-breadcumb-wrap h2  {
      font-family: "Georama", sans-serif !important;
      color: #f9f9f9 !important;
    }

  `;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <link rel="canonical" href={canonicalLink} />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta name="description" content={metaDescription}></meta>
        <meta name="keywords" content={metaKeywords}></meta>
      </Helmet>
      <HeroSection heading={"Articles Details"} bgImg={"/articles.png"} />
      <main className="xl:py-[100px] py-[70px] w-full mx-auto xl:px-0 md:px-8 px-6 xl:max-w-[1240px] 3xl:max-w-[2400px]">
        <section className="w-full xl:flex-row flex-col-reverse gap-[30px] flex xl:justify-between">
          <div className="flex flex-col gap-10 items-start">
            <div className="flex cursor-pointer flex-col gap-[35px] items-start w-full h-full xl:max-w-[820px] xl:min-w-[820px]">
              <img
                src={productData?.logoimage}
                alt="ss"
                className="w-full object-cover"
              />
              <div className="flex flex-col w-full items-start gap-4">
                <div className="text-[rgba(0,0,0,0.34)] text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase">
                  {productData?.title}
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: productData?.body }}
                  className="cursor-auto"
                  // className="text-[#252525] text-[22px] not-italic font-semibold leading-[normal] tracking-[0.44px] capitalize"
                >
                  {/* {productData?.title} */}
                </div>
                {/* <div className='text-[#252525] text-sm not-italic font-normal leading-[142.014%] tracking-[0.28px]'>
                  {productData?.description}
                </div> */}
              </div>
            </div>
          </div>
          <div className="w-full h-full xl:max-w-[358px] flex flex-col gap-[30px]">
            <div className="border pointer-events-none opacity-40 flex items-center border-[#D9D9D9] rounded-[10px] pl-4">
              <input
                className="bg-white !border-none outline-none focus:!border-none"
                placeholder="Search"
              />
              <div className="px-[14px] py-3 border-[#D9D9D9] border-l flex justify-content-center items-center">
                <svg
                  width={13}
                  height={13}
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4648 11.1904C12.6992 11.4248 12.6992 11.7764 12.4648 11.9873C12.3711 12.1045 12.2305 12.1514 12.0898 12.1514C11.9258 12.1514 11.7852 12.1045 11.668 11.9873L8.52734 8.84668C7.68359 9.52637 6.62891 9.90137 5.50391 9.90137C2.83203 9.90137 0.652344 7.72168 0.652344 5.02637C0.652344 2.35449 2.80859 0.151367 5.50391 0.151367C8.17578 0.151367 10.3789 2.35449 10.3789 5.02637C10.3789 6.1748 10.0039 7.22949 9.32422 8.0498L12.4648 11.1904ZM1.77734 5.02637C1.77734 7.1123 3.44141 8.77637 5.52734 8.77637C7.58984 8.77637 9.27734 7.1123 9.27734 5.02637C9.27734 2.96387 7.58984 1.27637 5.52734 1.27637C3.44141 1.27637 1.77734 2.96387 1.77734 5.02637Z"
                    fill="#006766"
                  />
                </svg>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="text-[22px] font-semibold text-ThemeSecondary uppercase">
                categories{" "}
              </div>
              <div>
                {[
                  "Business",
                  "Disputes",
                  "Employment",
                  "Family",
                  "Immigration",
                  "Property",
                ].map((it, id) => {
                  return (
                    <div
                      onClick={() => setselectedStateValue(id)}
                      key={id}
                      className={`py-[20px] cursor-pointer ${
                        it === productData.category
                          ? "bg-ThemeSecondary text-base text-white font-[500] "
                          : "border-b pointer-events-none opacity-40 border-[#BBBBBB] text-base text-[#252525]"
                      } pl-[10px] `}
                    >
                      {it}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-[22px] font-semibold text-ThemeSecondary uppercase">
                recent articles{" "}
              </div>
              <div className="flex flex-col gap-[30px]">
                {blogData?.map((it, id) => {
                  if (it._id === productData?._id) return null;
                  return (
                    <div
                      key={id}
                      onClick={() =>
                        window.location.replace(`/articles/${it.slug}`)
                      }
                      className="flex gap-[10px] cursor-pointer"
                    >
                      <img
                        src={it?.logoimage}
                        className="w-[62px] h-[62px] "
                        alt="blogs"
                      />
                      <div className="min-w-[286px] max-w-[286px] ">
                        <div>{it.title}</div>
                        <div className="flex items-center pr-[30px] w-full justify-between">
                          <div className="flex gap-2 items-center text-[#00000057] ">
                            <svg
                              width={13}
                              height={15}
                              viewBox="0 0 13 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.40039 7.05664C6.61914 7.05664 6.83789 7.27539 6.83789 7.49414V10.1191C6.83789 10.3652 6.61914 10.5566 6.40039 10.5566H3.77539C3.5293 10.5566 3.33789 10.3652 3.33789 10.1191V7.49414C3.33789 7.27539 3.5293 7.05664 3.77539 7.05664H6.40039ZM4.21289 0.0566406C4.56836 0.0566406 4.86914 0.357422 4.86914 0.712891V1.80664H8.80664V0.712891C8.80664 0.357422 9.08008 0.0566406 9.46289 0.0566406C9.81836 0.0566406 10.1191 0.357422 10.1191 0.712891V1.80664H11.2129C12.1699 1.80664 12.9629 2.59961 12.9629 3.55664V12.3066C12.9629 13.291 12.1699 14.0566 11.2129 14.0566H2.46289C1.47852 14.0566 0.712891 13.291 0.712891 12.3066V3.55664C0.712891 2.59961 1.47852 1.80664 2.46289 1.80664H3.55664V0.712891C3.55664 0.357422 3.83008 0.0566406 4.21289 0.0566406ZM11.6504 5.30664H2.02539V12.3066C2.02539 12.5527 2.2168 12.7441 2.46289 12.7441H11.2129C11.4316 12.7441 11.6504 12.5527 11.6504 12.3066V5.30664Z"
                                fill="currentColor"
                              />
                            </svg>
                            <span className="text-inherit text-sm not-italic font-medium tracking-[0.28px] uppercase">
                              {convertDateFormat(it.createdAt)}
                            </span>
                          </div>
                          <div className="text-[#006766] text-xs not-italic font-semibold  tracking-[0.24px] uppercase">
                            <span>VIEW</span>
                            <svg
                              className="inline ml-2"
                              width={13}
                              height={2}
                              viewBox="0 0 13 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.2129 0.806641C12.2129 1.13477 11.9551 1.36914 11.6504 1.36914H0.775391C0.447266 1.36914 0.212891 1.13477 0.212891 0.806641C0.212891 0.501953 0.447266 0.244141 0.775391 0.244141H11.6504C11.9551 0.244141 12.2129 0.501953 12.2129 0.806641Z"
                                fill="#006766"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
    // <Layout>
    //   <style>
    //     {css}
    //   </style>
    //   <div>
    // <Helmet>
    //   <title>{metaTitle}</title>
    //   <link rel="canonical" href={canonicalLink} />
    //   <meta name="robots" content="INDEX,FOLLOW" />
    //   <meta name="description" content={metaDescription}></meta>
    //   <meta name="keywords" content={metaKeywords}></meta>
    // </Helmet>
    //   </div>
    //   <section className="page-breadcumb-area">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-12">
    //           <div className="wpo-breadcumb-wrap">
    //             <h2>{pageName}</h2>
    //             <ul>
    //               <li>
    //                 <Link to="/home">Home</Link>
    //               </li>
    //               <li>
    //                 <span>&nbsp; / &nbsp;</span>
    //                 <Link
    //                       to="/articles"
    //                 >
    //                   Articles
    //                 </Link>
    //               </li>
    //               <li>
    //                 <span>&nbsp; / &nbsp;</span>

    //                 <Markup content={pageName} />
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>

    //   <div className="sec-categories">
    //     <section className="content-page policy-content about-box">
    //       <div className="container">
    //         {/* <img src={pageImage} alt="image" />             */}
    //         <div className="summaryContainer">
    //           <div dangerouslySetInnerHTML={{ __html: pageSummary }} />
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    // </Layout>
  );
};
export default SummaryPageBlog;
export function convertDateFormat(inputDateStr) {
  // Create a new Date object
  var inputDate = new Date(inputDateStr);

  // Extract the components
  var year = inputDate.getFullYear();
  var month = inputDate.toLocaleString("default", { month: "short" });
  var day = inputDate.getDate();

  // Format the date string
  var formattedDate = month + " " + day + ", " + year;

  return formattedDate;
}
