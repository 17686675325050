import React from 'react'
import { useEffect } from 'react';

const SiaraWrapper = ({ children }) => {
    const script = document.createElement("script");
    useEffect(() => {
        const REACT_APP_CYBERSIARA_PUBLIC_KEY =
            process.env.REACT_APP_CYBERSIARA_PUBLIC_KEY;

        script.innerHTML = `
        $(function () {
          var PublicKey = "${REACT_APP_CYBERSIARA_PUBLIC_KEY}";
          InitCaptcha(PublicKey);
          $('.CaptchaSubmit').click(function () {
            if (CheckCaptcha()) {
              // console.log(CyberSiaraToken);
              localStorage.removeItem("verifyToken")
              
            }
          });
        });
      `;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            <div className="SiaraShield flex justify-center sm:justify-start"></div>
            <span className="CaptchaSubmit">
                {children}
            </span>
        </>
    )
}

export default SiaraWrapper