import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import services from "../../server/services";
import { updateAuth } from "../../actions";
import ProfileForm from "./profile-form";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { AuthContext } from "../../contexts/authContext";

const EditProfile = (props) => {
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [croppedImage, setCroppedImage] = useState("");
  let authToken = localStorage.getItem("idToken");
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const updateGeneralInfo = async (generalInfo) => {
    const { _id, password, confirmPassword, oldpassword } = generalInfo;
    if (!password) {
      delete generalInfo.password;
    } else {

    
      if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{9,}$/.test(oldpassword)
      ) {
        toast.error(
          "Old password must follow the policy i.e. should be more than 8 character, At least one uppercase letter, At least one lowercase letter, At least one digit, At least one special symbol",
          { hideProgressBar: true, autoClose: 2000 }
        );
        return;
      } else {
        if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{9,}$/.test(password)
        ) {
          toast.error(
            "Password must follow the policy i.e. should be more than 8 character, At least one uppercase letter, At least one lowercase letter, At least one digit, At least one special symbol",
            { hideProgressBar: true, autoClose: 2000 }
          );
          return;
        } else if (password !== confirmPassword) {
          toast.error("Password and Confirm password do not match!", {
            hideProgressBar: true,
            autoClose: 2000,
          });
          return;
        }

        else {
          updatePassword(oldpassword, password);

          // if (selectedCountry !== null) {
          //   generalInfo.country = selectedCountry.name
          // } else {

          //   toast.error("Country Has not been selected", {
          //     hideProgressBar: true,
          //     autoClose: 2000,
          //   });
          //   return
          //   // throw new Error('Country Has not been selected')
          // }
        }
      }
    }

    try {
      if (selectedCountry === null) {
        toast.error("Country Has not been selected", {
          hideProgressBar: true,
          autoClose: 2000,
        });
        return
      }
      generalInfo.country = selectedCountry.name

      setLoading(true);
      if (croppedImage) {
        try {
          let key = uuidv4();

          let preSignUrlData = await axios.get(
            `${process.env.REACT_APP_API_URL
            }/public-files?key=${key}.${"jpg"}&type=${"image/jpeg"}`,
            {
              headers: {
                Authorization: authToken,
              },
            }
          );

          if (preSignUrlData.status === 200) {
            let awsResponse = await axios.put(
              preSignUrlData.data.priSignUrl,
              croppedImage
            );

            if (awsResponse.status === 200) {
              generalInfo.profile_img = `https://${process.env.REACT_APP_AWS_PUBLIC_BUCKET_NAME}.${process.env.REACT_APP_AWS_BUCKET_ENDPOINT}/${key}.jpg`;
              if (selectedTimezone.label) {
                generalInfo.timezone = selectedTimezone;
              }
              let response = await services.users.patch(_id, generalInfo,);
              const currentState = JSON.parse(sessionStorage.state);
              currentState.auth.user = Object.assign(
                {},
                currentState.auth.user,
                response.value
              );

              dispatch(updateAuth(currentState.auth));
              toast.success("GeAneral info updated!", {
                hideProgressBar: true,
                autoClose: 2000,
              });
              setLoading(false);

              if (localStorage.getItem("idToken")) {
                await services.authentication.create({
                  strategy: "jwt",
                  accessToken: localStorage.getItem("idToken"),
                });
              }
            }
          } else {
            throw new Error("Something went wrong");
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      } else {
        if (selectedTimezone.label) {
          generalInfo.timezone = selectedTimezone;
        }
        let response = await services.users.patch(_id, generalInfo);
        const currentState = JSON.parse(sessionStorage.state);
        currentState.auth.user = Object.assign(
          {},
          currentState.auth.user,
          response.value
        );

        dispatch(updateAuth(currentState.auth));
        toast.success("General info updated!", {
          hideProgressBar: true,
          autoClose: 2000,
        });
        setLoading(false);
        if (localStorage.getItem("idToken")) {
          await services.authentication.create({
            strategy: "jwt",
            accessToken: localStorage.getItem("idToken"),
          });
        }
      }
    } catch (err) {
      toast.error(err.message, { hideProgressBar: true, autoClose: 2000 });
      setLoading(false);
    }
  };

  let updatePassword = async (oldpassword, newpassword) => {
    try {
      let cognitoRes = await authContext.changePassword(
        oldpassword,
        newpassword
      );
    } catch (error) {
      toast.error("Unable to update password. Please try again later", {
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  };
  return (
    <div className="general_profile_tab">
      <ProfileForm
        loading={loading}
        onSubmit={updateGeneralInfo}
        initialValues={props.user}
        auth={props.auth}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        setCroppedImage={setCroppedImage}
        history={props.history}
        changeTimezone={(e) => setSelectedTimezone(e)}
        selectedTimezone={selectedTimezone}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || {},
    auth: state.auth && state.auth,
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(EditProfile);
