import React, { useState } from "react";
import Slider from "@mui/material/Slider";
// import '../../../../Sass/questionaire.scss'
const PrelegateQuestion = (props) => {
  const {
    question,
    answerSelected,
    currentSliderValue,
    setCurrentSliderValue,
    parentOption,
    setParentOption,
    showSubQue,
    setShowSubQue,
    currentSubSliderValue,
    setCurrentSubSliderValue,
  } = props;
  // const [currentSliderValue, setCurrentSliderValue] = useState(1);
  // const [parentOption, setParentOption] = useState();
  // const [showSubQue, setShowSubQue] = useState("");
  // const [currentSubSliderValue, setCurrentSubSliderValue] = useState();

  let subAnsDdata = {
    answerType: null,
    text: "",
    selectedOption: null,
    selectedRange: 0,
  };

  const handleOptionSelect = (value, index) => {
    setParentOption(value);
    setShowSubQue(index);
  };

  const handleOptionWithOutSubQues = (value) => {
    let subAnsDdata = {
      answerType: null,
      text: "",
      selectedOption: null,
      selectedRange: 0,
    };
    answerSelected({
      selectedOption: value,
      question: subAnsDdata,
    });
  };

  let setSubAns = (value, type) => {
    subAnsDdata.answerType = type;
    if (type === "text") {
      subAnsDdata.text = value;
    } else if (type === "range") {
      subAnsDdata.selectedRange = value;
    } else if (type === "option") {
      subAnsDdata.selectedOption = value;
    }

    let answerSubmitData = {
      selectedOption: parentOption,
      question: subAnsDdata,
    };
    answerSelected(answerSubmitData);
  };

  let ansType = [];

  if (question.type === "option") {
    ansType = (
      <ul className="answer-list">
        {question.options.map((ans, index) => (
          <li key={ans._id}>
            <input
              type="radio"
              id={ans._id}
              name="selector"
              value={ans.text}
              onChange={(e) => {
                if (ans.question.text) {
                  handleOptionSelect(e.target.value, index);
                } else {
                  handleOptionWithOutSubQues(e.target.value);
                }
              }}
            />
            <label htmlFor={ans._id}>{ans.text}</label>
            <div className="check"></div>

            {showSubQue === index && ans.question.text && (
              <div>
                {ans.question.questionType === "text" ? (
                  <>
                    <div>
                      <div className="box-questions">
                        <div className="questions-answer">
                          <h4>{ans.question.text}</h4>
                          <textarea
                            className="px-6 py-3"
                            key={ans.question._id}
                            id="answers"
                            name="answers"
                            rows="5"
                            cols="50"
                            onChange={(e) => setSubAns(e.target.value, "text")}
                          >
                            { }
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </>
                ) : ans.question.questionType === "range" ? (
                  <div>
                    <div className="box-questions">
                      <div className="questions-answer">
                        <h4>
                          {/* <i
                            className="fa fa-question-circle"
                            aria-hidden="true"
                          ></i> */}
                          {ans.question.text}
                        </h4>
                        <div className="slidecontainer">
                          <input
                            type="range"
                            className="slider"
                            id="myRange"
                            min={ans.question.startRange}
                            max={ans.question.endRange}
                            onChange={(e) => {
                              setSubAns(e.target.value, "range");
                              setCurrentSubSliderValue(e.target.value);
                            }}
                          />

                          <span className="min float-left">
                            {ans.question.startRange}
                          </span>
                          <span className="max float-right">
                            {ans.question.endRange}
                          </span>
                          <div className="text-center">
                            <span className="value">
                              Value: {currentSubSliderValue}
                            </span>
                          </div>
                          <div className="text-center">
                            <span className="value">
                              <div
                                style={{ margin: "20px 0 5px", padding: "0" }}
                              >
                                {" "}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      ans.question.summary &&
                                      ans.question.summary[
                                      currentSubSliderValue - 1
                                      ],
                                  }}
                                ></div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="box-questions">
                      <div className="questions-answer">
                        <h4>{ans.question.text}</h4>
                        <div
                          style={{ textAlign: "left" }}
                          dangerouslySetInnerHTML={{
                            __html: ans.question.description,
                          }}
                        ></div>
                        <ul className="answer-list">
                          {ans.question.options.map((ans1) => (
                            <li key={ans1._id}>
                              <input
                                type="radio"
                                id={ans1._id}
                                name="selector"
                                value={ans1.text}
                                onChange={(e) =>
                                  setSubAns(e.target.value, "option")
                                }
                              />
                              <label htmlFor={ans1._id}>{ans1.text}</label>
                              <div className="check"></div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  } else if (question.type === "range") {
    ansType = (
      <>
        {
          <div className="slidecontainer">
            {/* <input
              type="range"
              className="slider"
              id="myRange"
              value={currentSliderValue}
              min="0"
              max={question.endRange}
              onChange={(e) => {
                answerSelected(e.target.value);
                setCurrentSliderValue(e.target.value);
              }}
              style={{
                // Set current value, min, and max for the progress calculation
                "--current-value": currentSliderValue,
                "--min": question.startRange,
                "--max": question.endRange,
                // Set thumb color
                "--thumb-color": "#0B6767",
                // Set path color
                "--path-color": "#E5E5E5",
                // Set progress color
                "--progress-color": "#0B6767",
              }}
            /> */}
            <Slider
              aria-label="Small steps"
              defaultValue={currentSliderValue}
              value={currentSliderValue}
              // getAriaValueText={currentSliderValue}
              step={1}
              marks
              min={question.startRange}
              max={question.endRange}
              valueLabelDisplay="auto"
              sx={{ color: "#0b6767", height: "6px" }}
              onChange={(e) => {
                answerSelected(e.target.value);
                setCurrentSliderValue(e.target.value);
              }}
            />

            <span className="min float-left">Low</span>
            <span className="max float-right">High</span>
            <div className="text-center">
              <span className="value">Value: {currentSliderValue}</span>
            </div>
            <div style={{ margin: "20px 0 5px", padding: "0" }}>
              {" "}
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    question.summary &&
                    question.summary[currentSliderValue - 1],
                }}
              ></div>
            </div>
          </div>
        }
      </>
    );
  } else {
    ansType = (
      <>
        <textarea
          key={question._id}
          id="answers"
          className="px-6 py-3"

          name="answers"
          rows="5"
          cols="50"
          onChange={(e) => answerSelected(e.target.value)}
        >
          { }
        </textarea>
      </>
    );
  }

  let prelegateQues = [];
  prelegateQues = (
    <div>
      <div className="box-questions">
        <div className="questions-answer">
          <h4>{question.text}</h4>
          <div
            style={{ textAlign: "left" }}
            dangerouslySetInnerHTML={{ __html: question.description }}
          ></div>
          {ansType}
        </div>
      </div>
    </div>
  );

  <div></div>;

  return (
    <>
      <div>{prelegateQues}</div>
    </>
  );
};

export default PrelegateQuestion;
