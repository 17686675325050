import feathers from 'feathers-client';
import reduxifyServices from 'feathers-redux';
import feathersReduxifyAuthentication from 'feathers-reduxify-authentication';
import axios from 'axios';

const { REACT_APP_API_URL }= process.env;

const feathersClient = feathers()
  .configure(feathers.hooks())
  .configure(feathers.rest(REACT_APP_API_URL).axios(axios))
  .configure(feathers.authentication({ storage: window.localStorage, storageKey :"idToken" }));

const servicesRaw = reduxifyServices(feathersClient, [
  'authentication',
  'users',
  'blogs',
  'availability',
  'google-calendar-auth',
  'categories',
  'questions',
  'user-answers',
  'payment',
  'transactions',
  'impersonation',
  'general-availability',
  'booking',
  'ratings',
  'accountancy',
  'general-data',
  'contact-us',
  'chnage-password',
  'availability-exception'
]);

const feathersAuthentication = feathersReduxifyAuthentication(feathersClient, {
  isUserAuthorized: user => user
});

export { feathersClient, servicesRaw, feathersAuthentication };
