import React, { useState, useEffect, useCallback } from "react";
import { Field, reduxForm } from "redux-form";
import Userprofile from "./../../assets/images/user.png";
import Cropper from "react-easy-crop";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TimezoneSelect from "react-timezone-select";
import { ButtonForDashboard, Typography } from "../../Pages/Profile";
import { countries } from "../../assets/data/countries";

let ProfileForm = (props) => {
  const {
    handleSubmit,
    loading,
    auth,
    setCroppedImage,
    initialValues,
    selectedTimezone,
    selectedCountry, setSelectedCountry,
    changeTimezone,
  } = props;
  const [temProfileImage, setTempProfileImage] = useState("");
  const [orignalImg, setOrignalImg] = useState("");
  const [nonCropImg, setNonCropImg] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [blobImg, setBlobImage] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showPage, setShowImg] = useState(true);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        setBlobImage({ blob: file });
        resolve(URL.createObjectURL(file));
      }, orignalImg.type);
    });
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        nonCropImg,
        croppedAreaPixels,
        rotation
      );
      setTempProfileImage(croppedImage);
      setShowImg(true);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setCroppedImage("");
  }, []);

  useEffect(() => {
    if (blobImg) {
      const newImage = new File([blobImg.blob], orignalImg.name, {
        type: orignalImg.contentType,
        lastModified: Date.now(),
      });
      setCroppedImage(newImage);
    }
  }, [blobImg]);
  useEffect(() => {
    if (auth.user?.country) {
      let isCountry = countries.find((country) => country.name === auth.user?.country);
      if (!isCountry) return
      selectCountry(isCountry)
    }
  }, [auth.user])
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const selectCountry = (country) => {
    setSelectedCountry(country);
    setDropdownOpen(false);
  };
  const [togglePass, settogglePass] = useState(false);
  const [togglePass1, settogglePass1] = useState(false);
  const [togglePass2, settogglePass2] = useState(false);
  if (showPage) {
    return (
      <form onSubmit={handleSubmit}>
        {/* <ToastContainer/> */}
        {/* <div className="profile_tab_header">
          <h4>Edit Profile</h4>
        </div> */}
        <div className="profile_tab_content">
          <div className="flex flex-col gap-6">
            {/* <div className="fields-form_sec"> */}
            {/* <div className="fields_group mb-5 fields_full_width"> */}
            <div
              style={{
                borderRadius: "11px",
                border: "1px solid #EBEBEB",
              }}
              className="w-full py-4 flex md:flex-row flex-col gap-6 md:gap-0 md:pl-[30px] px-6 md:pr-[26px] md:justify-between items-center"
            >
              <div className="flex flex-col md:flex-row gap-3 items-center ">
                <div
                  style={{
                    border: "1px dashed #006766",
                  }}
                  className="profile_edit  min-h-[100px] max-h-[100px] min-w-[100px] max-w-[100px] "
                >
                  <img
                    className="!w-full !h-full rounded-full !object-cover"
                    src={
                      temProfileImage
                        ? temProfileImage
                        : initialValues.profile_img
                          ? initialValues.profile_img
                          : Userprofile
                    }
                    alt="Avtar"
                  />
                  <div className="fileUploadEdit flex !flex-col !items-center !p-0 !justify-center">
                    <input
                      type="file"
                      id="uploadImg"
                      accept="image/png, image/jpeg"
                      name="profilePic"
                      onChange={(e) => {
                        if (
                          ["image/png", "image/jpeg"].includes(
                            e.target.files[0].type
                          )
                        ) {
                          setNonCropImg(URL.createObjectURL(e.target.files[0]));
                          setShowImg(false);
                          setOrignalImg(e.target.files[0]);
                        } else {
                          toast.error("Only jpg or png image are allowed", {
                            hideProgressBar: true,
                            autoClose: 2000,
                          });
                        }
                      }}
                    />
                    Change Photo
                  </div>
                </div>
                <div className="flex flex-col items-center md:items-start w-full ">
                  <div className="!text-[#006766] text-lg not-italic font-bold !leading-[31px] tracking-[0.36px] uppercase">
                    {auth.user?.firstName + " " + auth.user?.lastName}
                  </div>
                  <div className="!text-[#252525] text-base not-italic font-medium leading-[normal]">
                    {auth.user?.email}
                  </div>
                  <div className="mt-3 text-[#252525]/40 flex items-center gap-[9px] ">
                    <svg
                      width={13}
                      height={17}
                      viewBox="0 0 13 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.92554 6.42188C8.92554 7.82812 7.80054 8.92188 6.42554 8.92188C5.01929 8.92188 3.92554 7.82812 3.92554 6.42188C3.92554 5.04688 5.01929 3.92188 6.42554 3.92188C7.80054 3.92188 8.92554 5.04688 8.92554 6.42188ZM6.42554 5.42188C5.86304 5.42188 5.42554 5.89062 5.42554 6.42188C5.42554 6.98438 5.86304 7.42188 6.42554 7.42188C6.95679 7.42188 7.42554 6.98438 7.42554 6.42188C7.42554 5.89062 6.95679 5.42188 6.42554 5.42188ZM12.4255 6.42188C12.4255 9.17188 8.76929 14.0156 7.14429 16.0469C6.76929 16.5156 6.05054 16.5156 5.67554 16.0469C4.05054 14.0156 0.425537 9.17188 0.425537 6.42188C0.425537 3.10938 3.08179 0.421875 6.42554 0.421875C9.73804 0.421875 12.4255 3.10938 12.4255 6.42188ZM6.42554 1.92188C3.92554 1.92188 1.92554 3.95312 1.92554 6.42188C1.92554 6.82812 2.05054 7.42188 2.39429 8.23438C2.70679 8.98438 3.17554 9.85938 3.70679 10.7344C4.61304 12.1719 5.64429 13.5469 6.42554 14.5469C7.17554 13.5469 8.20679 12.1719 9.11304 10.7344C9.64429 9.85938 10.113 8.98438 10.4255 8.23438C10.7693 7.42188 10.9255 6.82812 10.9255 6.42188C10.9255 3.95312 8.89429 1.92188 6.42554 1.92188Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span className="text-inherit text-base not-italic font-medium leading-[normal] ">
                      {auth.user.country ? auth.user.country : 'Los Angeles, California, USA'}
                      {/*  */}
                    </span>
                  </div>
                </div>
              </div>
              {auth.user?.isVerfied ? <div className="flex items-center gap-2 text-[#006766]">
                <svg
                  width={17}
                  height={17}
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.01929 11.5469C7.67554 11.8906 7.14429 11.8906 6.80054 11.5469L4.80054 9.54688C4.45679 9.20312 4.45679 8.67188 4.80054 8.32812C5.14429 7.98438 5.67554 7.98438 6.01929 8.32812L7.42554 9.70312L10.8005 6.32812C11.1443 5.98438 11.6755 5.98438 12.0193 6.32812C12.363 6.67188 12.363 7.20312 12.0193 7.54688L8.01929 11.5469ZM16.4255 8.92188C16.4255 13.3594 12.8318 16.9219 8.42554 16.9219C3.98804 16.9219 0.425537 13.3594 0.425537 8.92188C0.425537 4.51562 3.98804 0.921875 8.42554 0.921875C12.8318 0.921875 16.4255 4.51562 16.4255 8.92188ZM8.42554 2.42188C4.83179 2.42188 1.92554 5.35938 1.92554 8.92188C1.92554 12.5156 4.83179 15.4219 8.42554 15.4219C11.988 15.4219 14.9255 12.5156 14.9255 8.92188C14.9255 5.35938 11.988 2.42188 8.42554 2.42188Z"
                    fill="currentColor"
                  />
                </svg>
                <span className="text-inherit text-base not-italic font-medium leading-[normal]">
                  Account Verified
                </span>
              </div> : <div className="flex items-center gap-2 text-[#CA0000]">
                <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.89429 5.46875C6.17554 5.1875 6.64429 5.1875 6.92554 5.46875L8.39429 6.96875L9.89429 5.46875C10.1755 5.1875 10.6443 5.1875 10.9255 5.46875C11.238 5.78125 11.238 6.25 10.9255 6.53125L9.45679 8L10.9255 9.46875C11.238 9.78125 11.238 10.25 10.9255 10.5312C10.6443 10.8438 10.1755 10.8438 9.89429 10.5312L8.39429 9.0625L6.92554 10.5312C6.64429 10.8438 6.17554 10.8438 5.89429 10.5312C5.58179 10.25 5.58179 9.78125 5.89429 9.46875L7.36304 8L5.89429 6.53125C5.58179 6.25 5.58179 5.78125 5.89429 5.46875ZM16.4255 8C16.4255 12.4375 12.8318 16 8.42554 16C3.98804 16 0.425537 12.4375 0.425537 8C0.425537 3.59375 3.98804 0 8.42554 0C12.8318 0 16.4255 3.59375 16.4255 8ZM8.42554 1.5C4.83179 1.5 1.92554 4.4375 1.92554 8C1.92554 11.5938 4.83179 14.5 8.42554 14.5C11.988 14.5 14.9255 11.5938 14.9255 8C14.9255 4.4375 11.988 1.5 8.42554 1.5Z" fill="#CA0000" />
                </svg>
                <span className="text-inherit text-base not-italic font-medium leading-[normal]">
                  Not Verified
                </span>
              </div>}
            </div>
            <div
              className="py-[30px] px-[38px] flex flex-col gap-6 xl:max-h-[300px]"
              style={{
                borderRadius: "11px",
                border: "1px solid #EBEBEB",
              }}
            >
              <Typography varient="green-head">Personal Information</Typography>
              <div className="grid-cont">
                <div className="fields_group">
                  <label className="text-[#252525]/40 text-center font-montserrat text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase">
                    First Name
                  </label>
                  <Field
                    name="firstName"
                    component="input"
                    type="text"
                    className="form-control font-montserrat"
                    required
                  />
                </div>
                <div className="fields_group">
                  <label className="text-[#252525]/40 text-center text-sm not-italic font-montserrat font-medium leading-[normal] tracking-[0.28px] uppercase">
                    Last Name
                  </label>
                  <Field
                    name="lastName"
                    component="input"
                    type="text"
                    className="form-control font-montserrat"
                    required
                  />{" "}
                </div>
                <div className="fields_group">
                  <label className="text-[#252525]/40 font-montserrat text-center text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase">
                    Timezone
                  </label>
                  <TimezoneSelect
                    value={
                      selectedTimezone.label
                        ? selectedTimezone
                        : initialValues.timezone
                          ? initialValues.timezone
                          : {}
                    }
                    onChange={changeTimezone}
                    className="form-control font-montserrat"
                  />
                </div>
              </div>
              <div className="grid-cont">
                <div className="fields_group grow">
                  <label className="text-[#252525]/40 text-center font-montserrat text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase">
                    EMAIL ADDRESS
                  </label>
                  <Field
                    name="email"
                    component="input"
                    type="text"
                    className="form-control font-montserrat"
                    required
                  />
                </div>
                <div className="fields_group grow">
                  <label className="text-[#252525]/40 text-center text-sm not-italic font-montserrat font-medium leading-[normal] tracking-[0.28px] uppercase">
                    Phone
                  </label>
                  <Field
                    name="contactNo"
                    placeholder="(123) 000 1234"
                    component="input"
                    type="text"
                    className="form-control font-montserrat"
                    required
                  />{" "}
                </div>
                <div className="fields_group grow relative" >
                  <label className="text-[#252525]/40 text-center text-sm not-italic font-montserrat font-medium leading-[normal] tracking-[0.28px] uppercase">Country</label>
                  <div className="dropdown ">
                    <div
                      className="text-[#252525] form-control truncate  !p-[14px] !text-base !not-italic !font-medium !leading-[normal] !capitalize !font-montserrat"
                      // className="form-control !p-3 !font-montserrat"
                      onClick={toggleDropdown}
                    >
                      {selectedCountry
                        ? selectedCountry.name
                        : "Select a Country"}
                    </div>
                    {isDropdownOpen && (
                      <div className="dropdown-content cursor-pointer max-h-[180px] overflow-auto flex flex-col gap-2 bg-white border px-3 py-2  border-[#e5e5e5]">
                        {countries.map((country) => (
                          <div
                            key={country.code}
                            className="country-item "
                            onClick={() => selectCountry(country)}
                          >
                            {country.name}
                          </div>
                        ))}
                      </div>
                    )}
                    <svg className={`${isDropdownOpen ? '-rotate-180' : ''} right-4 top-5 absolute`} width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.3259 1.68555L8.35715 7.4043C8.16965 7.56055 7.98215 7.62305 7.8259 7.62305C7.6384 7.62305 7.4509 7.56055 7.29465 7.43555L1.29465 1.68555C0.982147 1.4043 0.982147 0.904297 1.2634 0.623047C1.54465 0.310547 2.04465 0.310547 2.3259 0.591797L7.8259 5.8418L13.2946 0.591797C13.5759 0.310547 14.0759 0.310547 14.3571 0.623047C14.6384 0.904297 14.6384 1.4043 14.3259 1.68555Z" fill="#252525" />
                    </svg>
                  </div>

                </div>
              </div>
              <div className='flex md:flex-row flex-col gap-3 md:max-w-[516px] md:max-h-[74px]'>
                {/* <div className="fields_group grow max-w-[250px]">
                  <label className="text-[#252525]/40 text-center font-montserrat text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase">Bio</label>
                  <Field
                    name="bio"
                    component="input"
                    type="text"
                    placeholder="Legal history about me"
                    className="form-control font-montserrat"
                    required
                  />
                </div> */}

                {/* <div className="fields_group grow">
                  <label className="text-[#252525]/40 text-center text-sm not-italic font-montserrat font-medium leading-[normal] tracking-[0.28px] uppercase">Country</label>
                  <Field
                    name="country"
                    component="input"
                    placeholder="Karachi, Pakistan"
                    type="text"
                    className="form-control font-montserrat"
                    required
                  />{" "}
                </div> */}
              </div>
              {/* <div className="max-w-[240px]">
                <ButtonForDashboard role={true}>Update</ButtonForDashboard>
              </div> */}
              {/* <div className="fields_group_action text-center">
                <button
                  loading={loading}
                  type="submit"
                  className="btn btn-primary"
                >
                  Update
                </button> */}
              {/* </div> */}
            </div>
            <div
              className="py-[30px] px-[38px] flex flex-col gap-6"
              style={{
                borderRadius: "11px",
                border: "1px solid #EBEBEB",
              }}
            >
              <Typography varient="green-head">Password & Security</Typography>

              <div className="fields_group max-w-[536px] relative">
                <label className="text-[#252525]/40 font-montserrat text-start   text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase">
                  Current Password
                </label>
                <Field
                  name="oldpassword"
                  component="input"
                  type={togglePass ? "text" : "password"}
                  className="form-control font-montserrat"
                />
                {!togglePass ? (
                  <svg
                    onClick={() => settogglePass((pr) => !pr)}
                    className="cursor-pointer absolute right-2 top-11 max-[390px]:top-[3.75rem]"
                    width={14}
                    height={11}
                    viewBox="0 0 14 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.76953 1.90039C4.64844 1.23633 5.74219 0.708984 7.08984 0.708984C8.65234 0.708984 9.92188 1.43164 10.8398 2.29102C11.7578 3.13086 12.3633 4.14648 12.6562 4.84961C12.7148 5.00586 12.7148 5.18164 12.6562 5.33789C12.4023 5.96289 11.875 6.86133 11.0938 7.64258L13.1445 9.26367C13.3594 9.41992 13.3984 9.71289 13.2227 9.9082C13.0664 10.123 12.7734 10.1621 12.5781 9.98633L1.01562 0.923828C0.800781 0.767578 0.761719 0.474609 0.9375 0.279297C1.09375 0.0644531 1.38672 0.0253906 1.58203 0.201172L3.76953 1.90039ZM4.53125 2.50586L5.42969 3.20898C5.87891 2.81836 6.44531 2.58398 7.08984 2.58398C8.45703 2.58398 9.58984 3.7168 9.58984 5.08398C9.58984 5.51367 9.47266 5.9043 9.29688 6.23633L10.3516 7.05664C11.0156 6.39258 11.4844 5.63086 11.7383 5.08398C11.4648 4.49805 10.957 3.67773 10.1953 2.97461C9.39453 2.23242 8.35938 1.64648 7.08984 1.64648C6.09375 1.64648 5.23438 1.99805 4.53125 2.50586ZM8.53516 5.65039C8.61328 5.47461 8.65234 5.2793 8.65234 5.08398C8.65234 4.22461 7.94922 3.52148 7.08984 3.52148C7.07031 3.52148 7.05078 3.52148 7.03125 3.52148C7.07031 3.63867 7.08984 3.73633 7.08984 3.83398C7.08984 4.04883 7.03125 4.22461 6.95312 4.40039L8.53516 5.65039ZM8.73047 8.18945L9.55078 8.83398C8.82812 9.22461 8.00781 9.45898 7.08984 9.45898C5.50781 9.45898 4.23828 8.75586 3.32031 7.89648C2.40234 7.03711 1.79688 6.02148 1.50391 5.33789C1.44531 5.18164 1.44531 5.00586 1.50391 4.84961C1.69922 4.40039 2.01172 3.83398 2.46094 3.24805L3.18359 3.83398C2.83203 4.2832 2.57812 4.73242 2.42188 5.08398C2.69531 5.66992 3.20312 6.50977 3.96484 7.21289C4.76562 7.95508 5.80078 8.52148 7.08984 8.52148C7.67578 8.52148 8.22266 8.4043 8.73047 8.18945ZM4.58984 5.08398C4.58984 5.04492 4.58984 4.98633 4.58984 4.92773L5.68359 5.78711C5.87891 6.19727 6.26953 6.50977 6.73828 6.60742L7.83203 7.48633C7.59766 7.54492 7.34375 7.58398 7.07031 7.58398C5.70312 7.58398 4.57031 6.4707 4.57031 5.08398H4.58984Z"
                      fill="#707070"
                    />
                  </svg>
                ) : (
                  <svg
                    onClick={() => settogglePass((pr) => !pr)}
                    className="cursor-pointer absolute right-2 top-11 max-[390px]:top-[3.75rem]"
                    width={14}
                    height={11}
                    viewBox="0 0 13 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.96484 4.74023C3.96484 3.37305 5.07812 2.24023 6.46484 2.24023C7.83203 2.24023 8.96484 3.37305 8.96484 4.74023C8.96484 6.12695 7.83203 7.24023 6.46484 7.24023C5.07812 7.24023 3.96484 6.12695 3.96484 4.74023ZM6.46484 6.30273C7.32422 6.30273 8.02734 5.61914 8.02734 4.74023C8.02734 3.88086 7.32422 3.17773 6.46484 3.17773C6.44531 3.17773 6.42578 3.17773 6.40625 3.17773C6.44531 3.29492 6.46484 3.39258 6.46484 3.49023C6.46484 4.19336 5.89844 4.74023 5.21484 4.74023C5.09766 4.74023 5 4.74023 4.90234 4.70117C4.90234 4.7207 4.90234 4.74023 4.90234 4.74023C4.90234 5.61914 5.58594 6.30273 6.46484 6.30273ZM2.69531 1.94727C3.61328 1.08789 4.88281 0.365234 6.46484 0.365234C8.02734 0.365234 9.29688 1.08789 10.2148 1.94727C11.1328 2.78711 11.7383 3.80273 12.0312 4.50586C12.0898 4.66211 12.0898 4.83789 12.0312 4.99414C11.7383 5.67773 11.1328 6.69336 10.2148 7.55273C9.29688 8.41211 8.02734 9.11523 6.46484 9.11523C4.88281 9.11523 3.61328 8.41211 2.69531 7.55273C1.77734 6.69336 1.17188 5.67773 0.878906 4.99414C0.820312 4.83789 0.820312 4.66211 0.878906 4.50586C1.17188 3.80273 1.77734 2.78711 2.69531 1.94727ZM6.46484 1.30273C5.17578 1.30273 4.14062 1.88867 3.33984 2.63086C2.57812 3.33398 2.07031 4.1543 1.79688 4.74023C2.07031 5.32617 2.57812 6.16602 3.33984 6.86914C4.14062 7.61133 5.17578 8.17773 6.46484 8.17773C7.73438 8.17773 8.76953 7.61133 9.57031 6.86914C10.332 6.16602 10.8398 5.32617 11.1133 4.74023C10.8398 4.1543 10.332 3.33398 9.57031 2.63086C8.76953 1.88867 7.73438 1.30273 6.46484 1.30273Z"
                      fill="#707070"
                    />
                  </svg>
                )}
              </div>

              <div className="grid-cont max-w-[536px]">
                <div className="fields_group relative">
                  <label className="text-[#252525]/40 font-montserrat  text-start   text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase">
                    New Password
                  </label>
                  <Field
                    name="password"
                    component="input"
                    type={togglePass1 ? "text" : "password"}
                    className="form-control font-montserrat"
                  />
                  {!togglePass1 ? (
                    <svg
                      onClick={() => settogglePass1((pr) => !pr)}
                      className="cursor-pointer absolute right-2 top-11 max-[390px]:top-[3.75rem]"
                      width={14}
                      height={11}
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.76953 1.90039C4.64844 1.23633 5.74219 0.708984 7.08984 0.708984C8.65234 0.708984 9.92188 1.43164 10.8398 2.29102C11.7578 3.13086 12.3633 4.14648 12.6562 4.84961C12.7148 5.00586 12.7148 5.18164 12.6562 5.33789C12.4023 5.96289 11.875 6.86133 11.0938 7.64258L13.1445 9.26367C13.3594 9.41992 13.3984 9.71289 13.2227 9.9082C13.0664 10.123 12.7734 10.1621 12.5781 9.98633L1.01562 0.923828C0.800781 0.767578 0.761719 0.474609 0.9375 0.279297C1.09375 0.0644531 1.38672 0.0253906 1.58203 0.201172L3.76953 1.90039ZM4.53125 2.50586L5.42969 3.20898C5.87891 2.81836 6.44531 2.58398 7.08984 2.58398C8.45703 2.58398 9.58984 3.7168 9.58984 5.08398C9.58984 5.51367 9.47266 5.9043 9.29688 6.23633L10.3516 7.05664C11.0156 6.39258 11.4844 5.63086 11.7383 5.08398C11.4648 4.49805 10.957 3.67773 10.1953 2.97461C9.39453 2.23242 8.35938 1.64648 7.08984 1.64648C6.09375 1.64648 5.23438 1.99805 4.53125 2.50586ZM8.53516 5.65039C8.61328 5.47461 8.65234 5.2793 8.65234 5.08398C8.65234 4.22461 7.94922 3.52148 7.08984 3.52148C7.07031 3.52148 7.05078 3.52148 7.03125 3.52148C7.07031 3.63867 7.08984 3.73633 7.08984 3.83398C7.08984 4.04883 7.03125 4.22461 6.95312 4.40039L8.53516 5.65039ZM8.73047 8.18945L9.55078 8.83398C8.82812 9.22461 8.00781 9.45898 7.08984 9.45898C5.50781 9.45898 4.23828 8.75586 3.32031 7.89648C2.40234 7.03711 1.79688 6.02148 1.50391 5.33789C1.44531 5.18164 1.44531 5.00586 1.50391 4.84961C1.69922 4.40039 2.01172 3.83398 2.46094 3.24805L3.18359 3.83398C2.83203 4.2832 2.57812 4.73242 2.42188 5.08398C2.69531 5.66992 3.20312 6.50977 3.96484 7.21289C4.76562 7.95508 5.80078 8.52148 7.08984 8.52148C7.67578 8.52148 8.22266 8.4043 8.73047 8.18945ZM4.58984 5.08398C4.58984 5.04492 4.58984 4.98633 4.58984 4.92773L5.68359 5.78711C5.87891 6.19727 6.26953 6.50977 6.73828 6.60742L7.83203 7.48633C7.59766 7.54492 7.34375 7.58398 7.07031 7.58398C5.70312 7.58398 4.57031 6.4707 4.57031 5.08398H4.58984Z"
                        fill="#707070"
                      />
                    </svg>
                  ) : (
                    <svg
                      onClick={() => settogglePass1((pr) => !pr)}
                      className="cursor-pointer absolute right-2 top-11 max-[390px]:top-[3.75rem]"
                      width={14}
                      height={11}
                      viewBox="0 0 13 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.96484 4.74023C3.96484 3.37305 5.07812 2.24023 6.46484 2.24023C7.83203 2.24023 8.96484 3.37305 8.96484 4.74023C8.96484 6.12695 7.83203 7.24023 6.46484 7.24023C5.07812 7.24023 3.96484 6.12695 3.96484 4.74023ZM6.46484 6.30273C7.32422 6.30273 8.02734 5.61914 8.02734 4.74023C8.02734 3.88086 7.32422 3.17773 6.46484 3.17773C6.44531 3.17773 6.42578 3.17773 6.40625 3.17773C6.44531 3.29492 6.46484 3.39258 6.46484 3.49023C6.46484 4.19336 5.89844 4.74023 5.21484 4.74023C5.09766 4.74023 5 4.74023 4.90234 4.70117C4.90234 4.7207 4.90234 4.74023 4.90234 4.74023C4.90234 5.61914 5.58594 6.30273 6.46484 6.30273ZM2.69531 1.94727C3.61328 1.08789 4.88281 0.365234 6.46484 0.365234C8.02734 0.365234 9.29688 1.08789 10.2148 1.94727C11.1328 2.78711 11.7383 3.80273 12.0312 4.50586C12.0898 4.66211 12.0898 4.83789 12.0312 4.99414C11.7383 5.67773 11.1328 6.69336 10.2148 7.55273C9.29688 8.41211 8.02734 9.11523 6.46484 9.11523C4.88281 9.11523 3.61328 8.41211 2.69531 7.55273C1.77734 6.69336 1.17188 5.67773 0.878906 4.99414C0.820312 4.83789 0.820312 4.66211 0.878906 4.50586C1.17188 3.80273 1.77734 2.78711 2.69531 1.94727ZM6.46484 1.30273C5.17578 1.30273 4.14062 1.88867 3.33984 2.63086C2.57812 3.33398 2.07031 4.1543 1.79688 4.74023C2.07031 5.32617 2.57812 6.16602 3.33984 6.86914C4.14062 7.61133 5.17578 8.17773 6.46484 8.17773C7.73438 8.17773 8.76953 7.61133 9.57031 6.86914C10.332 6.16602 10.8398 5.32617 11.1133 4.74023C10.8398 4.1543 10.332 3.33398 9.57031 2.63086C8.76953 1.88867 7.73438 1.30273 6.46484 1.30273Z"
                        fill="#707070"
                      />
                    </svg>
                  )}
                </div>
                <div className="fields_group relative">
                  <label className="text-[#252525]/40 font-montserrat  text-start   text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase">
                    Confirm Password
                  </label>
                  <Field
                    name="confirmPassword"
                    component="input"
                    type={togglePass2 ? "text" : "password"}
                    className="form-control font-montserrat"
                  />
                  {!togglePass2 ? (
                    <svg
                      onClick={() => settogglePass2((pr) => !pr)}
                      className="cursor-pointer absolute right-2 top-11 max-[390px]:top-[3.75rem]"
                      width={14}
                      height={11}
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.76953 1.90039C4.64844 1.23633 5.74219 0.708984 7.08984 0.708984C8.65234 0.708984 9.92188 1.43164 10.8398 2.29102C11.7578 3.13086 12.3633 4.14648 12.6562 4.84961C12.7148 5.00586 12.7148 5.18164 12.6562 5.33789C12.4023 5.96289 11.875 6.86133 11.0938 7.64258L13.1445 9.26367C13.3594 9.41992 13.3984 9.71289 13.2227 9.9082C13.0664 10.123 12.7734 10.1621 12.5781 9.98633L1.01562 0.923828C0.800781 0.767578 0.761719 0.474609 0.9375 0.279297C1.09375 0.0644531 1.38672 0.0253906 1.58203 0.201172L3.76953 1.90039ZM4.53125 2.50586L5.42969 3.20898C5.87891 2.81836 6.44531 2.58398 7.08984 2.58398C8.45703 2.58398 9.58984 3.7168 9.58984 5.08398C9.58984 5.51367 9.47266 5.9043 9.29688 6.23633L10.3516 7.05664C11.0156 6.39258 11.4844 5.63086 11.7383 5.08398C11.4648 4.49805 10.957 3.67773 10.1953 2.97461C9.39453 2.23242 8.35938 1.64648 7.08984 1.64648C6.09375 1.64648 5.23438 1.99805 4.53125 2.50586ZM8.53516 5.65039C8.61328 5.47461 8.65234 5.2793 8.65234 5.08398C8.65234 4.22461 7.94922 3.52148 7.08984 3.52148C7.07031 3.52148 7.05078 3.52148 7.03125 3.52148C7.07031 3.63867 7.08984 3.73633 7.08984 3.83398C7.08984 4.04883 7.03125 4.22461 6.95312 4.40039L8.53516 5.65039ZM8.73047 8.18945L9.55078 8.83398C8.82812 9.22461 8.00781 9.45898 7.08984 9.45898C5.50781 9.45898 4.23828 8.75586 3.32031 7.89648C2.40234 7.03711 1.79688 6.02148 1.50391 5.33789C1.44531 5.18164 1.44531 5.00586 1.50391 4.84961C1.69922 4.40039 2.01172 3.83398 2.46094 3.24805L3.18359 3.83398C2.83203 4.2832 2.57812 4.73242 2.42188 5.08398C2.69531 5.66992 3.20312 6.50977 3.96484 7.21289C4.76562 7.95508 5.80078 8.52148 7.08984 8.52148C7.67578 8.52148 8.22266 8.4043 8.73047 8.18945ZM4.58984 5.08398C4.58984 5.04492 4.58984 4.98633 4.58984 4.92773L5.68359 5.78711C5.87891 6.19727 6.26953 6.50977 6.73828 6.60742L7.83203 7.48633C7.59766 7.54492 7.34375 7.58398 7.07031 7.58398C5.70312 7.58398 4.57031 6.4707 4.57031 5.08398H4.58984Z"
                        fill="#707070"
                      />
                    </svg>
                  ) : (
                    <svg
                      onClick={() => settogglePass2((pr) => !pr)}
                      className="cursor-pointer absolute right-2 top-11 max-[390px]:top-[3.75rem]"
                      width={14}
                      height={11}
                      viewBox="0 0 13 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.96484 4.74023C3.96484 3.37305 5.07812 2.24023 6.46484 2.24023C7.83203 2.24023 8.96484 3.37305 8.96484 4.74023C8.96484 6.12695 7.83203 7.24023 6.46484 7.24023C5.07812 7.24023 3.96484 6.12695 3.96484 4.74023ZM6.46484 6.30273C7.32422 6.30273 8.02734 5.61914 8.02734 4.74023C8.02734 3.88086 7.32422 3.17773 6.46484 3.17773C6.44531 3.17773 6.42578 3.17773 6.40625 3.17773C6.44531 3.29492 6.46484 3.39258 6.46484 3.49023C6.46484 4.19336 5.89844 4.74023 5.21484 4.74023C5.09766 4.74023 5 4.74023 4.90234 4.70117C4.90234 4.7207 4.90234 4.74023 4.90234 4.74023C4.90234 5.61914 5.58594 6.30273 6.46484 6.30273ZM2.69531 1.94727C3.61328 1.08789 4.88281 0.365234 6.46484 0.365234C8.02734 0.365234 9.29688 1.08789 10.2148 1.94727C11.1328 2.78711 11.7383 3.80273 12.0312 4.50586C12.0898 4.66211 12.0898 4.83789 12.0312 4.99414C11.7383 5.67773 11.1328 6.69336 10.2148 7.55273C9.29688 8.41211 8.02734 9.11523 6.46484 9.11523C4.88281 9.11523 3.61328 8.41211 2.69531 7.55273C1.77734 6.69336 1.17188 5.67773 0.878906 4.99414C0.820312 4.83789 0.820312 4.66211 0.878906 4.50586C1.17188 3.80273 1.77734 2.78711 2.69531 1.94727ZM6.46484 1.30273C5.17578 1.30273 4.14062 1.88867 3.33984 2.63086C2.57812 3.33398 2.07031 4.1543 1.79688 4.74023C2.07031 5.32617 2.57812 6.16602 3.33984 6.86914C4.14062 7.61133 5.17578 8.17773 6.46484 8.17773C7.73438 8.17773 8.76953 7.61133 9.57031 6.86914C10.332 6.16602 10.8398 5.32617 11.1133 4.74023C10.8398 4.1543 10.332 3.33398 9.57031 2.63086C8.76953 1.88867 7.73438 1.30273 6.46484 1.30273Z"
                        fill="#707070"
                      />
                    </svg>
                  )}
                </div>
              </div>
              <div className="max-w-[240px]">
                <ButtonForDashboard type="submit">
                  Update
                </ButtonForDashboard>
              </div>
            </div>
          </div>
          <style>
            {`
          
.grid-cont{
  display:grid;
  grid-template-columns:repeat(auto-fit,minmax(min(10rem,100%),1fr)) ;
  grid-gap:1rem;
}
          `}
          </style>
        </div>
      </form>
    );
  } else {
    return (
      <>
        <div className="image-cropper42">
          <div className="cropper-inner-area">
            <Cropper
              image={nonCropImg}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <div className="text-center mt-3">
              <button
                type="button"
                onClick={showCroppedImage}
                variant="contained"
                color="primary"
                className="getStarted w-auto"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#E3B576",
                  display: "inline-flex",
                  padding: "9px 28px",
                  alignItems: "center",
                  color: "white",
                  gap: "21px",
                  fontWeight: 500,
                  border: "none", // Remove the border

                  textDecoration: "none",
                }}
              >
                Show Result
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
};

ProfileForm = reduxForm({
  form: "profileForm",
  enableReinitialize: true,
  destroyOnUnmount: false,
})(ProfileForm);

export default ProfileForm;
