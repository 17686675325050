import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout";
import { Helmet } from "react-helmet";
import { HeroSection } from "../About";
import Footer from "../../Components/Footer";

import ReactDOMServer from 'react-dom/server';

const Faq = (props) => {
  // useEffect(() => {
  //   window.scroll(0, 0);
  //   return () => { };
  // }, []);

  try {
    let prevMetaDesc = document.getElementById("baseDescription");
    prevMetaDesc.remove();
    //use the piece of loading state to return other component until you have the data
  } catch (error) { }
  let Subheading = 'text-[#E3B576]  text-values-align lg:text-sm 3xl:text-[30px] text-base not-italic font-medium 3xl:!leading-[130%] !leading-[45px] tracking-[0.28px] uppercase'
  let Subheading2 = '!text-[#252525] lg:text-sm text-base 3xl:text-[30px] 3xl:!leading-[130%]  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]'
  let heading = 'text-[#006766] text-[22px] 3xl:text-[50px] lg:text-[30px] not-italic lg:font-medium font-semibold 3xl:!leading-[130%] !leading-[160%] tracking-[0.84px] uppercase'
  const [highlighted, setHighlighted] = useState(null)
  const [highlighted2, setHighlighted2] = useState(null)

  // const general = <a target="_blank" href='https://legateca.com/service/prelegat'>Kickstart your journey for free,</a>
  const faqsArr = [
    {
      title: 'GENERAL',
      dropdowns: [
        {
          heading: ' What is so unique about Legateca, why should I even use you?',
          para: "At Legateca, we uphold our commitment to simplicity and personalization. Unlike generic online information sources, we don't inundate you with unnecessary details. We go beyond being a conventional lawyer's directory – we don't merely redirect your queries for price comparisons. Our services are meticulously designed to offer a smooth transition from distress to relief. <a target='_blank' href='https://legateca.com/service/prelegate'>Kickstart your journey for free,</a> or for a nominal fee, explore the benefits of our AI-driven Legal Advisor. If you're seeking cost-effective expert guidance, choose and schedule sessions with our thoughtfully chosen and extensively vetted legal partners. Your seamless legal experience begins with Legateca."
        },
        {
          heading: 'Are you regulated to provide legal advice?',
          para: 'Legateca operates outside the areas of "reserved legal activities" under the terms of the Legal Services Act 2007 (LSA). This means that we are free to practice those areas of English law, and others, without being required to be regulated and authorised by the Solicitors Regulation Authority (SRA). As a legal technology company, we guide you through the legal process based on your individual circumstances, facilitating seamless connections to expert legal advice.'
        }
      ]

    },
    {
      title: "Get Started",
      dropdowns: [
        {
          heading: 'How does the service work?',
          para: "Consider this as your personalised online legal support service. We offer tailored and valuable information while gathering pertinent details through guided questions. Once completed, you will receive a comprehensive summary report via email, with a copy stored securely in your portal. Opting for mediation or legal services through us? We'll make the report accessible to them in advance (subject to conflict of interest checks, where applicable). This ensures you enter your initial meeting well-prepared, allowing them to understand your situation beforehand and provide more meaningful assistance from the start, ultimately saving you both time and money."
        },
        {
          heading: 'Do I need to register before using service?',
          para: `We send you the summary report by email, so you need to have access to an email address in order to receive it. Once you <a target='_blank' href="${process.env.REACT_APP_URL}/signup">signup</a>, you get access to your portal where you can see documents, view payment history and book legal service providers directly.`
        },
        {
          heading: 'Can I use service multiple times?',
          para: "Absolutely, and at no cost. Although you will receive a summary report via email on each occasion, please note that you can only download the most recent copy from your portal."
        },
      ]

    },
    {
      title: 'Instant Advice',
      dropdowns: [
        {
          heading: 'Can I use Instant Advice service multiple times?',
          para: 'Yes, you can, but please be aware that there will be a charge for each use.'
        },
        {
          heading: 'Can I have a subscription instead of paying per usage for the service',
          para: 'While we are actively working on introducing various packages and bundled services, currently, you will incur charges each time you use <a target="_blank" href="https://legateca.com/service/recommendate">Instant Advice.</a> Nonetheless, by providing accurate details to the best of your ability, you can gain a fairly good understanding of the available options.'
        },
        {
          heading: 'When you say Artificial Intelligence what do you mean?',
          para: 'Technology forms the foundation of our operations. We employ Artificial Intelligence technologies, specifically Machine Learning models, for our Instant Advice service. These models undergo training with pertinent historical data and validation against precise legislative rulings and judicial precedents. To ensure and enhance accuracy, we continuously monitor and retrain our models. For further details, please consult our <a target="_blank" href="https://legateca.com/terms-and-conditions"> Terms and Conditions.</a> '
        },
        {
          heading: "Is it necessary to provide my actual personal information?",
          para: 'If you choose to utilise the services of a legal service provider through us, some of your personal details may be utilised for conflict of interest checks. For example, a lawyer cannot represent both parties in a divorce case. Hence, providing accurate information is in your best interest.'
        },
      ]

    },
    {
      title: 'Find a Lawyer or Mediator',
      dropdowns: [
        {
          heading: 'How can I book appointments?',
          para: 'Simply go to the respective lawyer’s or mediator’s page, make payment for the minimum number of sessions and book appointment(s). You will be able to view your payment history and appointments details on your portal.'
        },
        {
          heading: 'Can I reschedule my appointment?',
          para: "You can't reschedule appointments once booked. However, if you send an email to your chosen lawyer/mediator atleast 24 hours before appointment, then they can reschedule the appointment as per their availability. Please provide 3 preferred slots in your email."
        },
        {
          heading: 'Do I have to book all appointments in one go?',
          para: 'No, you can book future meetings at a later date as well. There is no time limit after paying for the meetings in advance.'
        },
        {
          heading: "What if I am dissatisfied with the service, or if the lawyer doesn't show up?",
          para: 'In such a scenario, you can initiate a dispute within 7 days of the appointment date. We will make every effort to address and resolve the dispute, and if applicable, provide a refund. Subsequently, you can raise dispute to claim refund for future appointments subsequently.'
        },
        {
          heading: "Where can I check my appointments and payment history",
          para: 'Once logged in (<a target="_blank" href="https://legateca.com/signup">signup here</a>), access your profile by clicking on your profile picture located in the top right corner of the page. From there, you can edit your profile, verify your identity, review all your documents, and check the history of your appointments and payments.'
        },

        {
          heading: 'How do I provide feedback?',
          para: 'You can leave a star rating and feedback for each appointment which has been completed.'
        },
        {
          heading: 'Can my payment be used to book a different lawyer?',
          para: 'No, we only allow bookings against payment made for a particular legal service provider. If you are not satisfied by their service, you can always raise a dispute within 7 days of appointment completion and can claim refund for future appointments as well. '
        },
        {
          heading: 'Will I receive meeting invite and notifications?',
          para: 'When you book an appointment, you will receive a notification email with a calendar file. You can download the file and populate into your preferred calendar with appropriate alerts.'
        },
        {
          heading: 'Why is identity verification required?',
          para: "To comply with our service agreements with Legal Service providers, identity verification is mandatory. These providers are obligated to conduct identity and Anti Money Laundering checks. If you haven't completed the identity checks yet, you can always address this during your initial call with the legal professionals."
        },
        {
          heading: 'Can I cancel my appointment?',
          para: 'Certainly! To cancel an appointment, use your portal after signing up. Alternatively, you can raise a dispute or email us at <a target="_blank" href="mailto:info@legateca.com">info@legateca.com</a> at least 24 hours before the scheduled appointment. This allows us to cancel the appointment and process your refund. Please note that failing to notify us within this timeframe will result in the forfeiture of your payment'
        },
        {
          heading: 'Are the lawyers regulated?',
          para: 'Many of our partners are regulated solicitors authorised by the Solicitors Regulation Authority (<a target="_blank" href="https://www.sra.org.uk/">SRA</a>), or Chartered Legal Executives regulated and authorised by <a target="_blank" href="https://cilexregulation.org.uk/">CILEX</a>. Our other legal consultants, while not qualified solicitors, are accomplished professionals possessing the necessary legal experience and knowledge to uphold high standards. '
        },
        {
          heading: 'How are your Mediators qualified to support clients in dispute resolution?',
          para: 'Our team includes Mediators who are qualified by the International Mediation Institute (<a target="_blank" href="https://imimediation.org/">IMI</a>) and have completed accredited mediator training programs by the Civil Mediation Council (<a target="_blank" href="https://civilmediation.org/">CMC</a>) and the Chartered Institute of Arbitrators (<a target="_blank" href="https://www.ciarb.org/constitution/">CIArb</a>). This ensures that our clients receive support in dispute resolution, with facilitated fair and honest discussions.'
        },
      ]

    },
    {
      title: 'Family Law',
      dropdowns: [
        {
          heading: 'How long does it take to get a divorce in the UK?',
          para: 'The divorce process typically takes around 6 to 12 months, but it can vary based on individual circumstances.'
        },
        {
          heading: 'What factors are considered in determining spousal maintenance?',
          para: "Factors include each spouse's financial needs, earning capacity, and any financial responsibilities towards children."
        },
        {
          heading: 'Can grandparents seek visitation rights in family law cases?',
          para: "Grandparents may apply for visitation rights, but the court will consider the child's best interests."
        },
        {
          heading: "What is a prenuptial agreement, and is it legally binding in the UK?",
          para: "A prenuptial agreement outlines how assets are divided in case of divorce; while not strictly binding, the court considers them if fair and freely entered into."
        },
        {
          heading: "How is property divided during a divorce?",
          para: "Property division considers both financial and non-financial contributions, and the court aims for a fair distribution."
        },
        {
          heading: "What is a child arrangements order, and how is it obtained?",
          para: "It outlines the arrangements for a child's living situation, and it can be obtained through negotiation or court proceedings."
        },
        {
          heading: "How can I change a child's name legally?",
          para: "Changing a child's name requires consent from all those with parental responsibility or a court order."
        },
        {
          heading: "What is the difference between a separation agreement and divorce?",
          para: "A separation agreement formalizes living arrangements but doesn't end the marriage, while a divorce legally ends the marriage."
        },
        {
          heading: "Can same-sex couples adopt in the UK?",
          para: "Yes, same-sex couples have the same adoption rights as heterosexual couples."
        },
        {
          heading: "What is the role of a family mediator in resolving disputes?",
          para: "A family mediator facilitates communication and helps parties reach agreements on issues like child custody and financial matters."
        },
        {
          heading: "How is domestic violence addressed in family law cases?",
          para: "The court takes domestic violence seriously and may issue protective orders, affecting child custody and visitation arrangements."
        },
        {
          heading: "Can a child choose which parent to live with?",
          para: "The child's wishes are considered, but the court prioritises the child's best interests."
        },
        {
          heading: "What is a non-molestation order, and how does it protect against abuse?",
          para: "A non-molestation order prohibits someone from engaging in abusive behaviour and may include restrictions on contact or approaching a specific location."
        },
        {
          heading: "Do I need a solicitor for family mediation?",
          para: "While not mandatory, having a solicitor can provide legal advice and ensure your rights are protected during mediation."
        },
        {
          heading: "What is the process for adopting a stepchild?",
          para: "The process involves obtaining consent from all parties and going through an adoption assessment."
        },
        {
          heading: "How are pensions divided in a divorce?",
          para: "Pensions are considered a marital asset and may be subject to division, depending on factors like the length of the marriage."
        },
        {
          heading: "Can a financial settlement be revisited after divorce?",
          para: "In certain circumstances, such as non-disclosure of assets, a financial settlement may be revisited through legal proceedings."
        },
        {
          heading: "What are the legal implications of having a child out of wedlock?",
          para: "Unmarried parents have parental responsibility, but certain rights may need to be established through legal means."
        },
        {
          heading: "How can I protect my assets before marriage?",
          para: "Creating a prenuptial agreement is a common way to protect assets before marriage."
        },
        {
          heading: "What is the difference between a separation and a divorce?",
          para: "Separation means living apart without legally ending the marriage, while divorce legally terminates the marriage."
        },
      ]

    },
    {
      title: 'Employment Law',
      dropdowns: [
        {
          heading: 'What is discrimination in the workplace?',
          para: `Workplace discrimination occurs when an individual is subjected to unfair treatment based on one of the legally recognized "protected characteristics." These include age, disability, gender reassignment, marriage/civil partnership, pregnancy or maternity, race, religion or belief, sex, or sexual orientation`
        },
        {
          heading: "What rights do part-time employees have in the workplace?",
          para: "Part-time employees have the same rights as full-time employees, including holiday entitlement and protection against discrimination."
        },
        {
          heading: "If I feel I am being discriminated against at work, what should I do?",
          para: "It is crucial to seek advice promptly, as delaying can be detrimental. Typically, the initial action involves lodging a complaint or grievance about the situation. However, it's important to note that claims are generally limited to instances of treatment that occurred within the preceding 3 months."
        },
        {
          heading: "Can an employer change terms of employment without consent?",
          para: "Employers generally cannot change significant terms without employee agreement, but some changes may be allowed with notice."
        },
        {
          heading: "What is constructive dismissal, and how can I prove it?",
          para: "Constructive dismissal occurs when an employee resigns due to a fundamental breach of contract; proof may involve demonstrating a serious breach and exhaustion of grievance procedures."
        },
        {
          heading: "What is the difference between unfair dismissal and constructive dismissal?",
          para: "Unfair dismissal occurs when an employer terminates an employee without a valid reason or proper procedure, leading to potential legal claims. This typically requires a qualifying period of continuous service. On the other hand, constructive dismissal arises when an employee resigns due to a fundamental breach of their employment contract by the employer, necessitating a reasonable response to an untenable situation. While unfair dismissal involves employer-initiated termination, constructive dismissal results from the employee's voluntary resignation in response to unacceptable employer conduct. Both scenarios offer legal avenues for remedy, but eligibility criteria and circumstances differ. Employees facing dismissal or contemplating resignation are advised to seek legal advice tailored to their specific situation."
        },
        {
          heading: "What are the steps to initiate a claim for unfair dismissal at work?",
          para: "If you are considering bringing a claim for unfair dismissal at work, provided you have the right to claim (often requiring 2 years’ service with your employer for unfair dismissal rights), you typically need to initiate <a target='_blank' href='https://www.acas.org.uk/'>Acas</a> Early Conciliation first. This involves attempting to reach a settlement with your employer. If Acas Early Conciliation doesn't lead to a resolution, Acas will issue a Certificate, enabling you to commence an Employment Tribunal claim."
            + "" +
            "To start an Employment Tribunal claim, you must complete the necessary form, known as an ET1, providing all required information and detailing the full particulars of your claim. Employment law can be intricate, and errors or missing Employment Tribunal deadlines may jeopardize your claim. It is advisable to seek <a target='_blank' href='https://legateca.com/lawyer-profile/saimah-malik-0001'>specialised employment law advice</a> promptly, preferably after your dismissal or even before, if you're contemplating an unfair dismissal claim."
        },
        {
          heading: "What is an employment tribunal?",
          para: "An employment tribunal is the type of court that deals with employment claims, including claims for unfair dismissal, constructive unfair dismissal, discrimination, unlawful deduction of wages, holiday pay claims, and so on."
        },
        {
          heading: "What is the notice period for termination of employment?",
          para: "Notice periods vary but are usually stated in the employment contract or statutory notice periods apply."
        },
        {
          heading: "Can an employer withhold pay for poor performance?",
          para: "Pay cannot be withheld, but employers can address poor performance through performance improvement plans and disciplinary procedures."
        },
        {
          heading: "What is the procedure for raising a grievance at work?",
          para: "Employees should follow their employer's grievance procedure, outlining their concerns formally.  A grievance process is the process through which a grievance can be investigated and dealt with. You can usually find your employer’s grievance policy and procedure in their staff handbook or office manual, or sometimes in your contract of employment. If your employer changes or updates the grievance policy, they should notify all staff of any changes."
        },
        {
          heading: "Can an employer dismiss an employee on long-term sick leave?",
          para: "Dismissing an employee on long-term sick leave should be approached carefully, and employers must consider adjustments or alternatives."
        },
        {
          heading: "How is overtime pay calculated in the UK?",
          para: "Overtime pay is calculated based on the employment contract and may vary between organizations."
        },
        {
          heading: "How is redundancy compensation calculated?",
          para: "Redundancy pay is based on factors such as age, length of service, and weekly pay, subject to statutory limits."
        },
        {
          heading: "What are the legal requirements for workplace health and safety?",
          para: "Employers must provide a safe working environment, conduct risk assessments, and provide necessary training and protective equipment."
        },
        {
          heading: "Can an employer refuse flexible working requests?",
          para: "Employers can refuse requests for flexible working if there are legitimate business reasons, but they must consider and respond to requests."
        },
        {
          heading: "Can an employer refuse my request to work from home?",
          para: "An employer's discretion to decline a work-from-home request hinges on factors like job prerequisites, company policies, and the specific details of the request. If the nature of the job mandates in-person presence, entails tasks incompatible with remote work, or has valid business justifications, the employer may be justified in refusing the request. A thorough review of company policies, contractual commitments, and an awareness of legal provisions concerning flexible work arrangements is essential."
        },
        {
          heading: "Can an employer request a medical examination for employees?",
          para: "Employers can request a medical examination if there are genuine concerns about an employee's ability to perform their duties."
        },
        {
          heading: "What is the difference between a worker and an employee?",
          para: "Workers have some employment rights, but not all, while employees have a broader set of rights and responsibilities."
        },
        {
          heading: "Can I be dismissed during my probationary period without reason?",
          para: "Dismissal during a probationary period requires adherence to the contractual terms and should not be discriminatory."
        },
        {
          heading: "Are employees entitled to breaks during the working day?",
          para: "Employees are entitled to rest breaks and daily rest periods, as specified by employment regulations."
        },
        {
          heading: "Can an employer change my job role without consultation?",
          para: "Significant changes to job roles generally require consultation, and employees may have legal recourse if not properly consulted."
        },
      ]

    },
    {
      title: 'Immigration Law',
      dropdowns: [
        {
          heading: 'What are the different types of UK visas available?',
          para: `UK visas include work visas, family visas, student visas, and visitor visas, each with specific eligibility criteria.`
        },
        {
          heading: `How long does it take to process a UK visa application?`,
          para: `Processing times vary by visa type and can range from a few weeks to several months.`
        },
        {
          heading: `Can I work in the UK on a tourist visa?`,
          para: `No, a tourist visa does not allow employment; a work visa is required for legal employment. However, the UK’s government has published an update to its immigration rules, thus permitting more activities on Visitor Visas, including remote work, research, provision of legal services and paid engagements, while confirming that the new changes will become effective from January 31, 2024. `
        },
        {
          heading: `What is the process for extending a visa in the UK?`,
          para: `Visa extension processes differ by visa type but generally involve submitting an application before the current visa expires.`
        },
        {
          heading: `Can I switch to a different visa category within the UK?`,
          para: `Some visas allow for switching to another category within the UK; others may require leaving the country.`
        },
        {
          heading: `What are the financial requirements for a UK visa application?`,
          para: `Financial requirements vary by visa type and may include proof of funds for maintenance.`
        },
        {
          heading: `How can I bring my family to the UK as a visa holder?`,
          para: `Family members can apply for dependent visas, provided the main visa holder meets the necessary criteria.`
        },
        {
          heading: `What is the English language requirement for UK visas?`,
          para: `Many visas require proof of English proficiency through recognized language tests.`
        },
        {
          heading: `What is the difference between indefinite leave to remain and British citizenship?`,
          para: `Indefinite leave to remain is a permanent residence status, while British citizenship grants full citizenship rights.`
        },
        {
          heading: `Can I appeal a visa refusal decision?`,
          para: `Yes, most visa refusal decisions can be appealed, but the process varies by visa type.`
        },
        {
          heading: `What is the process for applying for asylum in the UK?`,
          para: `Asylum seekers must submit an application, attend an interview, and provide evidence of persecution in their home country.`
        },
        {
          heading: `Can I work while waiting for a decision on my asylum application?`,
          para: `Asylum seekers can apply for permission to work after waiting for a specific period.`
        },
        {
          heading: `What are the consequences of overstaying a visa in the UK?`,
          para: `Overstaying can lead to deportation, a ban on re-entry, and other legal consequences.`
        },
        {
          heading: `Can I apply for a visa as a victim of domestic violence?`,
          para: `Yes, victims of domestic violence may be eligible for a visa under the Domestic Violence Immigration Rule.`
        },
        {
          heading: `How can I prove a genuine and subsisting relationship for a partner visa?`,
          para: `Proof includes evidence of cohabitation, joint finances, and a genuine commitment to a lasting relationship.`
        },
        {
          heading: `What is the Surinder Singh route for family members?`,
          para: `The Surinder Singh route allows family members of British citizens to return to the UK after living together in another EEA country.`
        },
        {
          heading: `Is there an age limit for dependent children on a family visa?`,
          para: `The age limit for dependent children varies by visa type, and specific criteria must be met.`
        },
        {
          heading: `Can I switch from a Tier 4 student visa to a work visa?`,
          para: `In some cases, students can switch to a work visa after completing their studies in the UK.`
        },
        {
          heading: `What is the process for obtaining British citizenship by naturalisation?`,
          para: `Naturalisation requires meeting residency requirements, demonstrating good character, and passing a citizenship test.`
        },
        {
          heading: `Can I travel outside the UK while my visa application is pending?`,
          para: `Traveling outside the UK while a visa application is pending may affect the application, and it's essential to check the specific rules for the visa category.`
        },
      ]

    },
    {
      title: 'Small Claims',
      dropdowns: [
        {
          heading: `What types of cases qualify for small claims court?`,
          para: `Small claims are for simple cases that don’t involve large amounts of money or complicated issues. They’re often used to get compensation or your money back if something’s gone wrong. Small claims are never usually for more than £10,000.
          You can use small claims for things like a faulty product, poor service, being owed a refund, disputes with your landlord or accidents when you’ve been injured - for example, a car accident.
          `
        },
        {
          heading: `How much does it cost to file a claim in small claims court?`,
          para: `Filing fees vary but are generally lower than those for other court proceedings.`
        },
        {
          heading: `What is the limit for small claims in Northern Ireland?`,
          para: `From 3 October 2022, the maximum value of a claims which can be heard in the small claims court has been increased to £5,000.`
        },
        {
          heading: `Can I recover legal fees in small claims court?`,
          para: `In small claims court, legal fees are generally not recoverable, and parties typically represent themselves.`
        },
        {
          heading: `What is the timeline for small claims court proceedings?`,
          para: `Small claims cases are designed to be resolved more quickly, with a timeline varying depending on the complexity of the case.`
        },
        {
          heading: `How can I enforce a judgment obtained in small claims court?`,
          para: `Various methods, such as garnishment or seizing assets, can be used to enforce a judgment.`
        },
        {
          heading: `Can I appeal a decision made in small claims court?`,
          para: `Appeals are generally limited in small claims court, and they may be allowed only on specific legal grounds.`
        },
        {
          heading: `What evidence is required in a small claims case?`,
          para: `Evidence may include documents, witness statements, and any other relevant information supporting the claim.`
        },
        {
          heading: `Can I settle a small claims case before going to court?`,
          para: `Parties can negotiate and settle a small claims case at any point before a court decision.`
        },
        {
          heading: `What happens if the defendant doesn't respond to a small claims summons?`,
          para: `If the defendant doesn't respond, the claimant may win by default, but the court may still assess the claim.`
        },
        {
          heading: `Can I represent someone else in small claims court?`,
          para: `Parties can represent themselves, and in some cases, a non-lawyer may represent a business.`
        },
        {
          heading: `What is the procedure for filing a small claims case online?`,
          para: `Many jurisdictions offer online filing for small claims cases, streamlining the process.`
        },
        {
          heading: `What types of remedies are available in small claims court?`,
          para: `Remedies may include monetary compensation, orders for specific actions, or the return of property.`
        },
        {
          heading: `Can I file a counterclaim in small claims court?`,
          para: `Defendants can file a counterclaim against the claimant, expanding the scope of the case.`
        },
        {
          heading: `What is the difference between small claims court and mediation?`,
          para: `Small claims court involves a legal decision, while mediation aims to facilitate a voluntary agreement between parties.`
        },
        {
          heading: `Can I file a small claims case against a business?`,
          para: `Yes, small claims court is open to individuals and businesses, providing a simpler and cost-effective way to resolve disputes.`
        },
        {
          heading: `What is the role of the judge in a small claims case?`,
          para: `The judge assesses evidence, listens to arguments, and makes a decision based on the law and facts presented.`
        },
        {
          heading: `Can I file a small claims case for breach of contract?`,
          para: `Small claims court is often used for breach of contract cases, particularly those involving smaller amounts.`
        },
        {
          heading: `Is there a time limit for filing a small claims case?`,
          para: `Yes, there is a time limit, known as the limitation period, for filing a small claims case. The limitation period for most small claims in England and Wales is usually six years from the date when the cause of action occurred. This means that you generally have six years from the date of the incident or breach to bring a small claims case.
          It's crucial to be aware of this time limit and take timely action if you believe you have a small claims case. If the limitation period expires, you may lose the right to pursue your claim through the small claims court. Keep in mind that specific types of claims or circumstances may have different limitation periods, so it's advisable to seek legal advice for your particular situation. Additionally, legal rules and regulations may be updated, so it's recommended to check the latest information or consult with a legal professional for the most current guidance.
          `
        },
      ]

    },
  ]
  const [isOpen, setIsOpen] = useState(Array(22).fill(false))
  return (
    <>
      <Helmet>
        <title>FAQs - Legateca</title>
        <link rel="canonical" href="https://legateca.com/faq" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta name="title" content="FAQs - Legateca " />
        <meta
          name="description"
          content="Get all your answers about employment law, corporate law, family mediation, and divorce legal advice from Legateca. Call now +44 1635 745074 for know more about"
        ></meta>
      </Helmet>
      <HeroSection
        heading="frequently aSKED questions"
        bgImg={'./faq.png'} />
      <main className="xl:py-[100px] py-[70px]">
        <div className='lex flex-col xl:gap-[15px] items-center'>
          <div className="w-full xl:px-0 padding-md  flex flex-col  items-center">
            <div className={Subheading}>
              FAQs
            </div>
            <div className={heading}>
              ASK US ANYTHING
            </div>
          </div>
        </div>
        {
          faqsArr.map((item, id) => {
            return <section onClick={() => setHighlighted(id)} key={id} style={{
              background: highlighted !== id ? 'transparent' : 'rgba(0, 103, 102, 0.06)'
            }} className="w-full ] xl:py-12 py-8">
              <div className="xl:max-w-[1240px] xl:px-0 px-10 xl:flex-row flex-col flex justify-between items-start 3xl:max-w-[2400px] mx-auto">
                <div className={`${heading} xl:max-w-[296px] 3xl:max-w-[496px]`}>
                  {item.title}
                </div>
                <div className="w-full flex flex-col gap-3 xl:max-w-[820px] xl:min-w-[820px] 3xl:max-w-[1620px] 3xl:min-w-[1620px]">
                  {item.dropdowns.map((el, idx) => {
                    return <div style={{
                      borderColor: highlighted2 === id && isOpen[idx] ? '#006766' : '#bbb'
                    }} key={idx} className="py-3 pl-[10px] pr-[26px] flex flex-col gap-[11px] border-b  relative">
                      <div style={{
                        color: highlighted2 === id && isOpen[idx] ? '#006766' : '#707070 '
                      }} onClick={() => {
                        setHighlighted2(id)

                        let temp = isOpen.map((isOpen1, index) =>
                          index === idx ? !isOpen1 : false
                        );
                        setIsOpen(temp)
                      }} className="text-sm 3xl:text-[30px] cursor-pointer not-italic font-semibold !leading-[149%] tracking-[0.28px]">
                        {el.heading}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: el.para }}
                        style={{
                          display: highlighted2 === id && isOpen[idx] ? 'block' : 'none'
                        }} className="text-[#252525] link-text text-text-decoration-none dropdown 3xl:text-[30px] text-sm not-italic font-light !leading-[149%] tracking-[0.28px] " />

                      <svg onClick={() => {
                        setHighlighted2(id)
                        let temp = isOpen.map((isOpen1, index) =>
                          index === idx ? !isOpen1 : false
                        );
                        setIsOpen(temp)
                      }} xmlns="http://www.w3.org/2000/svg" className={`${highlighted2 === id && isOpen[idx] ? 'rotate-180' : ''} w-[14px] h-[12px] 3xl:w-[30px] 3xl:h-[28px] absolute cursor-pointer right-0`} viewBox="0 0 20 12" fill="none">
                        <path d="M10 11L1 1" stroke="#252525" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M19 1L10 11" stroke="#252525" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  })}
                  <style>
                    {`
                    .link-text a{
                      color:inherit !important
                    }
                    `}
                  </style>
                </div>
              </div>

            </section>
          })
        }
        <style>
          {`
    @keyframes slideDown {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: auto; /* Adjust the final height as needed */
        opacity: 1;
      }
    }
    @keyframes slideUp {
      from {
        height: auto; /* Adjust the final height as needed */
        opacity: 1;
      }
      to {
        height: 0;
        opacity: 0;
      }
    }

    .dropdown {
      animation: slideDown 0.5s ease-out; /* Adjust the animation duration and timing function as needed */
    }`}
        </style>
      </main >

      <Footer />

    </>
  )
  // return (
  //   <Layout>
  //     <div>
  // <Helmet>
  //   <title>FAQs - Legateca</title>
  //   <link rel="canonical" href="https://legateca.com/faq" />
  //   <meta name="robots" content="INDEX,FOLLOW" />
  //   <meta name="title" content="FAQs - Legateca" />
  //   <meta
  //     name="description"
  //     content="Get all your answers about employment law, corporate law, family mediation, and divorce legal advice from Legateca. Call now +44 1635 745074 for know more about"
  //   ></meta>
  // </Helmet>
  //     </div>
  //     <section className="page-breadcumb-area">
  //       <div className="container">
  //         <div className="row">
  //           <div className="col-12">
  //             <div className="wpo-breadcumb-wrap">
  //               <h2>FAQs</h2>
  //               <ul>
  //                 <li>
  //                   <Link to="/home">Home</Link>
  //                 </li>
  //                 <li>
  //                   <span>&nbsp; / &nbsp;</span>
  //                   <span>FAQs</span>
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //     <div className="sec-categories">
  //       <section className="content-page faq_page about-box">
  //         <div className="container">
  //           <h1 className="mb-4"> Frequently Asked Questions </h1>
  //           <h2 className="main_heading">General</h2>
  //           <div className="accordion" id="accordionExample">
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="headingOne">
  //                 <button
  //                   className="accordion-button"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseOne"
  //                   aria-expanded="true"
  //                   aria-controls="collapseOne"
  //                 >
  //                   What is so unique about Legateca, why should I even use you?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseOne"
  //                 className="accordion-collapse collapse show"
  //                 aria-labelledby="headingOne"
  //                 data-bs-parent="#accordionExample"
  //               >
  //                 <div className="accordion-body">
  //                   We stand by our motto of making things simple and
  //                   personalised. We don’t overwhelm you with information
  //                   readily available online. We are not a legal service
  //                   providers directory, and don’t just route your queries for
  //                   comparative quotes. We have structured our services to
  //                   provide a seamless experience on your journey from distress
  //                   to relief. Try our free{" "}
  //                   <Link to="/service/prelegate">Prelegate</Link> service, or
  //                   for a small fee try our artificial intelligence powered{" "}
  //                   <Link to="/service/recommendate">Recommendate</Link>{" "}
  //                   service. Very soon, you will be able to try more services.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="headingTwo">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseTwo"
  //                   aria-expanded="false"
  //                   aria-controls="collapseTwo"
  //                 >
  //                   Are you regulated to provide legal advice?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseTwo"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="headingTwo"
  //                 data-bs-parent="#accordionExample"
  //               >
  //                 <div className="accordion-body">
  //                   Legateca operates outside the areas of "reserved legal
  //                   activities" under the terms of the Legal Services Act 2007.
  //                   As a legal technology company, we help you navigate the
  //                   legal process as per your personal circumstances, and by
  //                   connecting you seamlessly to expert legal advisory (coming
  //                   soon).
  //                 </div>
  //               </div>
  //             </div>
  //           </div>

  //           <h2 className="main_heading">Prelegate</h2>
  //           <div className="accordion" id="accordionExample2">
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="headingThree">
  //                 <button
  //                   className="accordion-button"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseThree"
  //                   aria-expanded="true"
  //                   aria-controls="collapseThree"
  //                 >
  //                   How does Prelegate work?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseThree"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="headingThree"
  //                 data-bs-parent="#accordionExample2"
  //               >
  //                 <div className="accordion-body">
  //                   Think of Prelegate as an online ‘paralegal’ service. It
  //                   provides you with customised and helpful information while
  //                   gathering relevant information from you as guided questions.
  //                   Upon completion, you will receive a summary report by email
  //                   and a copy of the report will be stored in your portal. If
  //                   you decide to use mediation or lawyer/solicitor service
  //                   through us (coming soon), we will also make this report
  //                   available for them to view beforehand (subjected to conflict
  //                   of interest checks where applicable). You will be well
  //                   prepared for your initial meeting, and they will be able to
  //                   consider your circumstances ahead of time, and provide more
  //                   meaningful, in-depth assistance early on, which saves your
  //                   time (and money).
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="headingFour">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseFour"
  //                   aria-expanded="false"
  //                   aria-controls="collapseFour"
  //                 >
  //                   Do I need to register before using Prelegate?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseFour"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="headingFour"
  //                 data-bs-parent="#accordionExample2"
  //               >
  //                 <div className="accordion-body">
  //                   We send you the summary report by email, so you need to have
  //                   access to an email address in order to receive it. Once you
  //                   sign up, you get access to your portal where you can see
  //                   documents, view payment history and book legal service
  //                   providers directly (in near future).
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="headingFive">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseFive"
  //                   aria-expanded="false"
  //                   aria-controls="collapseFive"
  //                 >
  //                   Can I use Prelegate service multiple times?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseFive"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="headingFive"
  //                 data-bs-parent="#accordionExample2"
  //               >
  //                 <div className="accordion-body">
  //                   Yes, and it's totally free. While you receive a summary
  //                   report by email each time, you will only be able to download
  //                   the latest copy through your portal.
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <h2 className="main_heading">Recommendate</h2>
  //           <div className="accordion" id="accordionExample3">
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="headingSix">
  //                 <button
  //                   className="accordion-button"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseSix"
  //                   aria-expanded="true"
  //                   aria-controls="collapseSix"
  //                 >
  //                   Can I use Recommendate service multiple times?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseSix"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="headingSix"
  //                 data-bs-parent="#accordionExample3"
  //               >
  //                 <div className="accordion-body">
  //                   Yes you can, though you will be charged each time.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="headingSeven">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseSeven"
  //                   aria-expanded="false"
  //                   aria-controls="collapseSeven"
  //                 >
  //                   Can I have a subscription rather than paying to use the
  //                   service each time.
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseSeven"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="headingSeven"
  //                 data-bs-parent="#accordionExample3"
  //               >
  //                 <div className="accordion-body">
  //                   We are working to offer different packages and bundled
  //                   services. For now, you will be charged each time you use
  //                   Recommendate. However, if you provide all the details
  //                   correctly as best as you can, you can have a fairly good
  //                   idea of the options available to you.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="headingEight">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseEight"
  //                   aria-expanded="false"
  //                   aria-controls="collapseEight"
  //                 >
  //                   When you say Artificial Intelligence what do you mean?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseEight"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="headingEight"
  //                 data-bs-parent="#accordionExample3"
  //               >
  //                 <div className="accordion-body">
  //                   Technology is in the core of our business. We use Artificial
  //                   Intelligence technologies (Machine Learning models) for
  //                   Recommendate service, which have been trained on relevant
  //                   historic data, and validated against results from precise
  //                   legislative rulings and judicial precedents. We constantly
  //                   monitor and retrain our models to maintain and improve the
  //                   accuracy. Please refer to our{" "}
  //                   <Link to="/terms-and-conditions">Terms and Conditions</Link>
  //                   .
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="headingNine">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapseNine"
  //                   aria-expanded="false"
  //                   aria-controls="collapseNine"
  //                 >
  //                   Do I need to enter my real personal details?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapseNine"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="headingNine"
  //                 data-bs-parent="#accordionExample3"
  //               >
  //                 <div className="accordion-body">
  //                   If you decide to engage a legal service provider through us
  //                   (coming soon), then some of your personal details may be
  //                   used by them for conflict of interest checks (e.g. a lawyer
  //                   cannot act for both parties in a divorce). Therefore, it is
  //                   in your interest to be as accurate as possible.
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <h2 className="main_heading">Lawyer/Mediate</h2>
  //           <div className="accordion" id="accordionExample4">
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="heading11">
  //                 <button
  //                   className="accordion-button"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapse11"
  //                   aria-expanded="true"
  //                   aria-controls="collapse11"
  //                 >
  //                   How can I book appointments?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapse11"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="heading11"
  //                 data-bs-parent="#accordionExample4"
  //               >
  //                 <div className="accordion-body">
  //                   Simply go to the respective lawyer or mediator page, make
  //                   payment for the minimum number of sessions and book
  //                   appointment(s). You will be able to view your payment
  //                   history and appointments details on your portal.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="heading12">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapse12"
  //                   aria-expanded="false"
  //                   aria-controls="collapse12"
  //                 >
  //                   Can I reschedule my appointment?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapse12"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="heading12"
  //                 data-bs-parent="#accordionExample4"
  //               >
  //                 <div className="accordion-body">
  //                   You can't reschedule appointments once booked. However, if
  //                   you send an email to your chosen lawyer/mediator atleast 24
  //                   hours before appointment, then they can reschedule the
  //                   appointment as per their availibility. Please provide 3
  //                   preferred slots in your email.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="heading13">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapse13"
  //                   aria-expanded="false"
  //                   aria-controls="collapse13"
  //                 >
  //                   Do I have to book all appointments in one go?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapse13"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="heading13"
  //                 data-bs-parent="#accordionExample4"
  //               >
  //                 <div className="accordion-body">
  //                   No, you can book future meetings at a later date as well.
  //                   There is no time limit after paying for the meetings in
  //                   advance.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="heading14">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapse14"
  //                   aria-expanded="false"
  //                   aria-controls="collapse14"
  //                 >
  //                   What if I don’t like the service or the lawyer doesn’t turn
  //                   up?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapse14"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="heading14"
  //                 data-bs-parent="#accordionExample4"
  //               >
  //                 <div className="accordion-body">
  //                   In this case, you can raise a dispute within 7 days of the
  //                   appointment date. We will endeavour to resolve the dispute
  //                   and refund the money if applicable. You can raise dispute to
  //                   claim refund for future appointments subsequently.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="heading15">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapse15"
  //                   aria-expanded="false"
  //                   aria-controls="collapse15"
  //                 >
  //                   How do I provide feedback?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapse15"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="heading15"
  //                 data-bs-parent="#accordionExample4"
  //               >
  //                 <div className="accordion-body">
  //                   You can leave a star rating and feedback for each
  //                   appointment which has been completed.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="heading16">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapse16"
  //                   aria-expanded="false"
  //                   aria-controls="collapse16"
  //                 >
  //                   Can my payment be used to book a different lawyer?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapse16"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="heading16"
  //                 data-bs-parent="#accordionExample4"
  //               >
  //                 <div className="accordion-body">
  //                   No, we only allow bookings against payment made for a
  //                   particular legal service provider. If you are not satisfied
  //                   by their service, you can always raise a dispute within 7
  //                   days of appointment completion, and can claim refund for
  //                   future appointments as well.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="heading17">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapse17"
  //                   aria-expanded="false"
  //                   aria-controls="collapse17"
  //                 >
  //                   Will I receive meeting invite and notifications?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapse17"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="heading17"
  //                 data-bs-parent="#accordionExample4"
  //               >
  //                 <div className="accordion-body">
  //                   When you book an appointment, you will receive a
  //                   notification email with an .ics files. You can download the
  //                   file and populate into your preferred calendar with
  //                   appropriate alerts.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="heading18">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapse18"
  //                   aria-expanded="false"
  //                   aria-controls="collapse18"
  //                 >
  //                   Why do I need to undergo identity verification?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapse18"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="heading18"
  //                 data-bs-parent="#accordionExample4"
  //               >
  //                 <div className="accordion-body">
  //                   This is needed to comply with our service agreements with
  //                   the Legal Service providers, who have an obligation for
  //                   identity and Anti money laundering checks.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="accordion-item">
  //               <h2 className="accordion-header" id="heading19">
  //                 <button
  //                   className="accordion-button collapsed"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target="#collapse19"
  //                   aria-expanded="false"
  //                   aria-controls="collapse19"
  //                 >
  //                   Can I cancel my appointment?
  //                 </button>
  //               </h2>
  //               <div
  //                 id="collapse19"
  //                 className="accordion-collapse collapse"
  //                 aria-labelledby="heading19"
  //                 data-bs-parent="#accordionExample4"
  //               >
  //                 <div className="accordion-body">
  //                   Yes, you can raise a dispute up until 24 hours of
  //                   appointment time so that we can cancel the appointment and
  //                   initiate refund. Else your payment will be forfeited
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </section>
  //     </div>
  //   </Layout>
  // );
};

export default Faq;
