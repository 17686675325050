import { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactStars from "react-rating-stars-component";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import services from "./../../server/services";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import { ButtonForDashboard, Typography } from "../../Pages/Profile";

const YourAppointment = ({ lawyerData, user, ratings, auth }) => {
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  const handleCloseModal = () => {
    onCloseModal();
  };
  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [reviewData, setReviewData] = useState({
    rating: 1,
  });

  let limit = 5;
  const [showDispute, setShowDispute] = useState(false);
  const [editBooking, setEditBooking] = useState("");
  const [errors, setErrors] = useState({});
  const [disputeData, setDisputeData] = useState({
    reason: "",
  });
  const [skip, setSkip] = useState(0);
  const [pages, setPage] = useState(0);

  const [loading, setLoading] = useState(false);
  const [todayAppointments, setTodayAppointments] = useState([]);
  const [todayAppointmentsError, setTodayAppointmentsError] = useState("");
  const [duration, setDuration] = useState("00:00");

  useEffect(() => {
    if (user.timezone) {
      const timeDuration = moment
        .utc(Math.abs(user.timezone.offset) * 3600 * 1000)
        .format("HH:mm");
      setDuration(timeDuration);
    }
  }, [user]);

  useEffect(() => {
    getLawyerData();
    getUpcommingBooking();
  }, []);

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };

  useEffect(() => {
    getLawyerData();
  }, [skip]);

  useEffect(() => {
    if (lawyerData.queryResult) {
      setPage(Math.ceil(lawyerData.queryResult.total / limit));
    }
  }, [lawyerData]);

  const getLawyerData = () => {
    try {
      if (localStorage.getItem("idToken"))
        services["booking"].find({
          query: {
            userId: user._id,
            $skip: skip,
            $limit: limit,
            $sort: { StartDate: -1 },
          },
        });
    } catch (error) {}
  };

  function meetingLinkAndPlatform(val) {
    if (!lawyerData?.meetingLink) {
      return "Loading";
    }

    switch (val) {
      case "name":
        const link = lawyerData.meetingLink.toLowerCase();
        if (link.includes("zoom")) {
          return "Zoom";
        } else if (link.includes("meet.google.com")) {
          return "Meet";
        } else if (link.includes("teams.live.com")) {
          return "Teams";
        } else {
          return "Video Meeting";
        }
      default:
        return;
    }
  }

  const updateReview = async () => {
    try {
      await services["ratings"].update(reviewData.appointmentId, reviewData);
      if (!ratings.updatePending && !ratings.isError) {
        toast.success("Success", { hideProgressBar: true, autoClose: 2000 });
        setShowReviewDialog(false);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const reviewButtonClickHandler = async (userId, lawyerId, appointmentId) => {
    const reviewData = await services["ratings"].get(appointmentId);
    try {
      if (reviewData && reviewData.value.rating) {
        setReviewData((prevData) => ({
          rating: reviewData.value.rating,
          review: reviewData.value.review,
          userId,
          lawyerId,
          appointmentId,
        }));
      } else {
        setReviewData((prevData) => ({
          rating: 1,
          review: null,
          userId,
          lawyerId,
          appointmentId,
        }));
      }
      setShowReviewDialog(true);

      setReviewData((prevData) => ({
        ...prevData,
        userId,
        lawyerId,
        appointmentId,
      }));
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const raiseDispute = async () => {
    if (!disputeData.reason) {
      setErrors({ reason: "Field is required" });
    } else if (disputeData.reason.trim() === "") {
      setErrors({ reason: "Field is required" });
    } else {
      let newData = JSON.parse(JSON.stringify(editBooking));
      newData.status = "dispute";
      newData.disputeReason = disputeData.reason;
      let response = await services["booking"].patch(newData._id, newData, {
        headers: { type: "dispute" },
      });
      if (response.action.type === "SERVICES_BOOKING_PATCH_FULFILLED") {
        toast.success("Dispute raised successfully", {
          hideProgressBar: true,
          autoClose: 2000,
        });
        getLawyerData();
        getUpcommingBooking();
      } else {
        toast.error("Something went wrong. Please try again later", {
          hideProgressBar: true,
          autoClose: 2000,
        });
      }
      setErrors("");
      setDisputeData("");
      setEditBooking("");
      setShowDispute(false);
      getLawyerData();
    }
  };

  let getUpcommingBooking = async () => {
    setLoading(true);
    let todayDate = moment().format("YYYY-MM-DD");
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/booking?userId=${
        auth.user._id
      }&StartDate='${todayDate} 00:00:00.000Z'&$limit=${1000}&$skip=${0}`,
      {
        headers: {
          Authorization: auth.token,
        },
      }
    );
    if (response.status === 200) {
      setLoading(false);
      setTodayAppointmentsError(false);
      setTodayAppointments(response.data.data);
    } else if (response.status === 401) {
      setLoading(false);
      setTodayAppointmentsError(true);
      setTodayAppointments([]);
      toast.error("Session Expired. Please Login Again.", {
        hideProgressBar: true,
        autoClose: 2000,
      });
    } else {
      setLoading(false);
      setTodayAppointmentsError(true);
      setTodayAppointments([]);
      toast.success("Something went Wrong. Please try again later.", {
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  };
  const tHeadClass =
    "text-[#252525]/40 text-start text-base not-italic font-medium leading-[normal] tracking-[0.28px] uppercase";
  const [isOpen, setIsOpen] = useState(Array(20).fill(false));
  const [viewApp, setViewApp] = useState({});

  const toggleDropdown = (index) => {
    let temp = isOpen.map((it, ind) => (index === ind ? !it : false));
    setIsOpen(temp);
  };
  function calculateDuration(startTime, endTime) {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    // Convert times to minutes
    const startTimeInMinutes = startHour * 60 + startMinute;
    const endTimeInMinutes = endHour * 60 + endMinute;

    // Calculate the duration in minutes
    let durationInMinutes = endTimeInMinutes - startTimeInMinutes;

    // Calculate hours and remaining minutes
    const durationHours = Math.floor(durationInMinutes / 60);
    durationInMinutes %= 60;

    return `${durationHours}h ${durationInMinutes}Mins`;
    // return {
    //   hours: durationHours,
    //   minutes: durationInMinutes,
    // };
  }
  const startTime = "17:30";
  const endTime = "18:00";

  const durations = calculateDuration(startTime, endTime);
  if (Object.keys(viewApp).length) {
    const {
      firstName,
      lastName,
      profile_img,
      occupation,
      meetingLink,
      perSessionFee,
      slug,
      _id,
    } = viewApp.lawyerData;
    const { endTime, startTime } = viewApp;

    function meetingLinkAndPlatform(val) {
      if (!meetingLink) {
        return "Loading";
      }

      switch (val) {
        case "name":
          const link = meetingLink.toLowerCase();
          if (link.includes("zoom")) {
            return "Zoom";
          } else if (link.includes("meet.google.com")) {
            return "Meet";
          } else if (
            link.includes("teams.live.com") ||
            link.includes("teams.microsoft.com")
          ) {
            return "Teams";
          } else {
            return "Video Meeting";
          }
        default:
          return;
      }
    }

    return (
      <div
        style={{
          borderRadius: "11px",
          border: "1px solid #EBEBEB",
        }}
        className="lg:pl-[50px] lg:pr-[30px] px-6 pt-[30px] pb-[40px] flex flex-col gap-[42px]"
      >
        <Typography varient={"green-head"}>
          Your Appointment with Lawyer
        </Typography>
        <div className="flex flex-col gap-[32px] items-start">
          <div className="flex items-center gap-2 lg:gap-6">
            <img
              src={profile_img}
              alt="lawyer"
              className="rounded-full object-contain w-[98px] h-[98px]"
            />
            <div className="flex flex-col items-start">
              <div className="text-[#006766] text-[28px] not-italic font-medium leading-[48px] tracking-[0.56px] uppercase">
                {firstName} {lastName}
              </div>
              <div className="text-[#E3B576] text-sm not-italic font-medium tracking-[0.28px] uppercase">
                {occupation} (UK)
              </div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8477 5.98633C10.8477 5.40234 11.2969 4.9082 11.9258 4.9082C12.5098 4.9082 13.0039 5.40234 13.0039 5.98633V11.5566L16.8223 14.0723C17.3164 14.4316 17.4512 15.1055 17.0918 15.5996C16.7773 16.0938 16.1035 16.2285 15.6094 15.8691L11.2969 12.9941C11.0273 12.8145 10.8477 12.4551 10.8477 12.0957V5.98633ZM11.9258 0.595703C18.2598 0.595703 23.4258 5.76172 23.4258 12.0957C23.4258 18.4746 18.2598 23.5957 11.9258 23.5957C5.54688 23.5957 0.425781 18.4746 0.425781 12.0957C0.425781 5.76172 5.54688 0.595703 11.9258 0.595703ZM2.58203 12.0957C2.58203 17.2617 6.75977 21.4395 11.9258 21.4395C17.0469 21.4395 21.2695 17.2617 21.2695 12.0957C21.2695 6.97461 17.0469 2.75195 11.9258 2.75195C6.75977 2.75195 2.58203 6.97461 2.58203 12.0957Z"
                fill="#006766"
              />
            </svg>

            <div className="text-[#707070] text-lg not-italic font-normal leading-[19.5px] tracking-[0.36px] uppercase">
              60 min session
            </div>
          </div>
          <div className="flex items-start gap-2">
            <svg
              width={27}
              height={18}
              viewBox="0 0 27 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.4473 2.22266C25.9863 2.53711 26.3008 3.12109 26.3008 3.70508V14.4863C26.3008 15.1152 25.9863 15.6992 25.4473 16.0137C25.1777 16.1934 24.8184 16.3281 24.459 16.3281C24.2344 16.3281 23.9648 16.2383 23.7402 16.1484L19.1133 13.9922V14.8457C19.1133 16.4629 17.8105 17.7207 16.2383 17.7207H3.30078C1.68359 17.7207 0.425781 16.4629 0.425781 14.8457V3.3457C0.425781 1.77344 1.68359 0.470703 3.30078 0.470703H16.2383C17.8105 0.470703 19.1133 1.77344 19.1133 3.3457V4.19922L23.7402 2.08789C24.2793 1.81836 24.9531 1.86328 25.4473 2.22266ZM16.957 14.8457V3.3457C16.957 2.98633 16.5977 2.62695 16.2383 2.62695H3.30078C2.89648 2.62695 2.58203 2.98633 2.58203 3.3457V14.8457C2.58203 15.25 2.89648 15.5645 3.30078 15.5645H16.2383C16.5977 15.5645 16.957 15.25 16.957 14.8457ZM24.1445 13.9473V4.28906L19.1133 6.58008V11.6562L24.1445 13.9473Z"
                fill="#006766"
              />
            </svg>
            <span
              href={meetingLink}
              class="!text-[#707070] font-montserrat text-decoration-none text-lg not-italic font-normal leading-[19.5px] tracking-[0.36px] uppercase"
            >
              {meetingLinkAndPlatform("name")}
            </span>
          </div>
          <div className="flex items-start gap-2">
            <svg
              width={27}
              height={21}
              viewBox="0 0 27 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.97266 13.6895C8.55664 13.6895 9.05078 14.1836 9.05078 14.7676C9.05078 15.3965 8.55664 15.8457 7.97266 15.8457H5.81641C5.1875 15.8457 4.73828 15.3965 4.73828 14.7676C4.73828 14.1836 5.1875 13.6895 5.81641 13.6895H7.97266ZM16.5977 13.6895C17.1816 13.6895 17.6758 14.1836 17.6758 14.7676C17.6758 15.3965 17.1816 15.8457 16.5977 15.8457H11.5664C10.9375 15.8457 10.4883 15.3965 10.4883 14.7676C10.4883 14.1836 10.9375 13.6895 11.5664 13.6895H16.5977ZM23.4258 0.0332031C24.998 0.0332031 26.3008 1.33594 26.3008 2.9082V17.2832C26.3008 18.9004 24.998 20.1582 23.4258 20.1582H3.30078C1.68359 20.1582 0.425781 18.9004 0.425781 17.2832V2.9082C0.425781 1.33594 1.68359 0.0332031 3.30078 0.0332031H23.4258ZM23.4258 2.18945H3.30078C2.89648 2.18945 2.58203 2.54883 2.58203 2.9082V4.3457H24.1445V2.9082C24.1445 2.54883 23.7852 2.18945 23.4258 2.18945ZM24.1445 8.6582H2.58203V17.2832C2.58203 17.6875 2.89648 18.002 3.30078 18.002H23.4258C23.7852 18.002 24.1445 17.6875 24.1445 17.2832V8.6582Z"
                fill="#006766"
              />
            </svg>
            <div className="text-[#707070] text-lg not-italic font-normal leading-[19.5px] tracking-[0.36px] uppercase">
              £{perSessionFee} (excl. VAT)
            </div>
          </div>
        </div>
        <div className="flex items-center overflow-auto  justify-between">
          <div className="flex flex-col min-w-[120px] max-w-[120px] items-start gap-[10px]">
            <div className="text-[#707070] text-sm not-italic font-normal leading-[19.5px] tracking-[0.36px] uppercase">
              Time slot
            </div>
            <div className="text-[#252525] text-base not-italic font-medium leading-[normal] capitalize">{`${startTime} -${endTime}`}</div>
          </div>
          <div className="flex flex-col min-w-[190px] max-w-[190px] items-start gap-[10px]">
            <div className="text-[#707070] text-sm not-italic font-normal leading-[19.5px] tracking-[0.36px] uppercase">
              appointment date
            </div>
            <div className="text-[#252525] text-base not-italic font-medium leading-[normal] capitalize">
              {viewApp.date}
            </div>
          </div>
          <div className="flex flex-col min-w-[150px] max-w-[150px] items-start gap-[10px]">
            <div className="text-[#707070] text-sm not-italic font-normal leading-[19.5px] tracking-[0.36px] uppercase">
              Session Duration
            </div>
            <div className="text-[#252525] text-base not-italic font-medium leading-[normal] capitalize">
              {calculateDuration(startTime, endTime)}
            </div>
          </div>
          <div className="flex flex-col min-w-[120px] max-w-[120px] items-start gap-[10px]">
            <div className="text-[#707070] text-sm not-italic font-normal leading-[19.5px] tracking-[0.36px] uppercase">
              Status
            </div>
            <div className="text-[#252525] text-base not-italic font-medium leading-[normal] capitalize">
              {viewApp.status}
            </div>
          </div>
          <div className="flex flex-col min-w-[140px] max-w-[140px] items-start gap-[10px]">
            <div className="text-[#707070] text-sm not-italic font-normal leading-[19.5px] tracking-[0.36px] uppercase">
              Fee
            </div>
            <div className="text-[#252525] text-base not-italic font-medium leading-[normal] capitalize">
              £{perSessionFee}(excl. VAT)
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-4 w-full max-w-[530px]">
          <ButtonForDashboard
            varient="bgwhite"
            handleClick={() => {
              setShowReviewDialog(true);
              reviewButtonClickHandler(
                viewApp.userId,
                viewApp.lawyerId,
                viewApp._id
              );
            }}
          >
            Review Your Session
          </ButtonForDashboard>
          <ButtonForDashboard
            handleClick={() => {
              window.location.replace(`/lawyer-profile/${slug}`);
            }}
          >
            Book Again
          </ButtonForDashboard>
        </div>
        <div
          className={`${
            showReviewDialog
              ? "fixed h-full w-full inset-0 backdrop-blur-sm backdrop-brightness-50 z-[20]"
              : "hidden"
          }`}
          // open={true}
          // open={showReviewDialog}
          // onClick={() => setShowReviewDialog(false)}
        >
          {/* <div className="content_LAuth p-0 h-full "> */}
          <div className="flex flex-col justify-center  items-center h-full ">
            {/* <div className="modal-header45">
            <h3>Appointment Review</h3>
          </div> */}
            <div className="max-w-[862px] relative rounded-[10px] h-full m-auto bg-white max-h-[600px] px-[118px] pt-[100px] pb-[10px] ">
              <div className="absolute top-[-60px] right-[330px] flex flex-col items-center justify-center  rounded-full backdrop-brightness-50  min-w-[134px] min-h-[134px] ">
                <img src="/reviews-img.png" alt="reviews" className="  " />
              </div>
              <div className="text-[#E3B576] text-center text-sm not-italic font-medium leading-[45px] tracking-[0.28px] uppercase">
                Thank you for your time
              </div>
              <div className="text-[#006766] mb-6 text-center text-[42px] not-italic font-medium leading-[48px] tracking-[0.84px] uppercase">
                RATE YOUR EXPERIENCE
              </div>
              <div className="review-card-box">
                <div className="flex items-center justify-center">
                  <ReactStars
                    count={5}
                    value={reviewData.rating}
                    onChange={(event) =>
                      setReviewData({ ...reviewData, rating: event })
                    }
                    size={74}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="field-group"></div>
                <textarea
                  className="fom-control px-[18px] bg-white border border-[#ebebeb] pt-[15px] "
                  name="review"
                  type="text"
                  value={reviewData.review}
                  rows={20}
                  onChange={(event) =>
                    setReviewData({ ...reviewData, review: event.target.value })
                  }
                  placeholder="Write your review on your previous session with the lawyer."
                />
                <div className="content_LAuth_action flex flex-col gap-4">
                  {/* <button
                type="button"
                className="btn-cancel"
                onClick={() => setShowReviewDialog(false)}
              >
                Close
              </button> */}
                  {/* <button
                  type="button"
                  className="getStarted"
                  onClick={updateReview}
                >
                  Submit
                </button> */}
                  <ButtonForDashboard
                    handleClick={updateReview}
                    varient={"bgyellow"}
                  >
                    Submit
                  </ButtonForDashboard>
                  <div
                    onClick={() => setShowReviewDialog(false)}
                    className="flex items-center justify-center gap-2 cursor-pointer"
                  >
                    <svg
                      width={13}
                      height={12}
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.7207 6.14062C12.7207 6.52344 12.4199 6.82422 12.0645 6.82422H2.76758L6.4043 10.2695C6.67773 10.5156 6.67773 10.9531 6.43164 11.1992C6.18555 11.4727 5.77539 11.4727 5.50195 11.2266L0.689453 6.63281C0.552734 6.49609 0.498047 6.33203 0.498047 6.14062C0.498047 5.97656 0.552734 5.8125 0.689453 5.67578L5.50195 1.08203C5.77539 0.835938 6.18555 0.835938 6.43164 1.10938C6.67773 1.35547 6.67773 1.79297 6.4043 2.03906L2.76758 5.48438H12.0645C12.4473 5.48438 12.7207 5.78516 12.7207 6.14062Z"
                        fill="#252525"
                      />
                    </svg>
                    <div className="text-[#252525] text-sm not-italic font-medium leading-[normal] tracking-[0.28px] capitalize">
                      GO BACK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Modal
          open={showReviewDialog}
          onRequestClose={() => setShowReviewDialog(false)}
        >
          <div className="content_LAuth p-0">
            <div className="pb-[30px] pt-[100px] max-w-[624px] mx-auto">
              <div className="text-[#E3B576] text-center text-sm not-italic font-medium leading-[45px] tracking-[0.28px] uppercase">
                Thank you for your time
              </div>
              <div className="text-[#006766] mb-6 text-center text-[42px] not-italic font-medium leading-[48px] tracking-[0.84px] uppercase">
                RATE YOUR EXPERIENCE
              </div>
              <div className="review-card-box">
                <div className="flex items-center justify-center">
                  <ReactStars
                    count={5}
                    value={reviewData.rating}
                    onChange={(event) =>
                      setReviewData({ ...reviewData, rating: event })
                    }
                    size={74}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="field-group">
                </div>
                <textarea
                  className="fom-control"
                  name="review"
                  type="text"
                  value={reviewData.review}
                  rows={5}
                  onChange={(event) =>
                    setReviewData({ ...reviewData, review: event.target.value })
                  }
                  placeholder="Write your review on your previous session with the lawyer."
                />
                <div className="content_LAuth_action flex flex-col gap-4">
                  <ButtonForDashboard handleClick={updateReview} varient={'bgyellow'}>
                    Submit
                  </ButtonForDashboard>
                  <div onClick={() => setShowReviewDialog(false)} className="flex items-center justify-center gap-2 cursor-pointer">
                    <svg width={13} height={12} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.7207 6.14062C12.7207 6.52344 12.4199 6.82422 12.0645 6.82422H2.76758L6.4043 10.2695C6.67773 10.5156 6.67773 10.9531 6.43164 11.1992C6.18555 11.4727 5.77539 11.4727 5.50195 11.2266L0.689453 6.63281C0.552734 6.49609 0.498047 6.33203 0.498047 6.14062C0.498047 5.97656 0.552734 5.8125 0.689453 5.67578L5.50195 1.08203C5.77539 0.835938 6.18555 0.835938 6.43164 1.10938C6.67773 1.35547 6.67773 1.79297 6.4043 2.03906L2.76758 5.48438H12.0645C12.4473 5.48438 12.7207 5.78516 12.7207 6.14062Z" fill="#252525" />
                    </svg>
                    <div className="text-[#252525] text-sm not-italic font-medium leading-[normal] tracking-[0.28px] capitalize">
                      GO BACK
                    </div>
                  </div>
                </div>
              </div>
              <div className='absolute ss  flex flex-col items-center justify-center  rounded-full  min-w-[134px] min-h-[134px] ' >
                <img src='/reviews-img.png' alt='' className='z-[300]  ' />
              </div>

            </div>
          </div>
          <style>{`
      .react-responsive-modal-modal{
      min-width:862px;
      max-width:862px;
      min-height:598px;
      max-height:598px;
      }
      .ss{
        background:rgba(0, 0, 0, 0.5)
      }
      `}</style>

        </Modal> */}
      </div>
    );
  }

  return (
    <div className="general_profile_tab">
      {/* <ToastContainer/> */}
      <Typography varient={"green-head"}>Upcoming Appointments</Typography>
      {/* <div className="profile_tab_header">
        <h4> Today's Appointments </h4>
      </div> */}
      {loading === false && todayAppointments.length === 0 ? (
        <div style={{ padding: "20px 0" }}>No record found</div>
      ) : (
        <div className="profile_tab_content">
          <div className="appointment_list">
            <div className="table-responsive">
              <table
                style={{
                  minHeight: loading ? "100px" : "",
                  maxHeight: loading ? "100px" : "",
                }}
                className="table font-montserrat relative"
              >
                <thead>
                  <tr>
                    <th
                      className={
                        tHeadClass +
                        " !min-w-[120px] !max-w-[120px] font-montserrat"
                      }
                    >
                      Lawyer
                    </th>
                    <th
                      className={
                        tHeadClass +
                        " !min-w-[180px] !max-w-[180px] font-montserrat"
                      }
                    >
                      Appointment Date
                    </th>
                    <th
                      className={
                        tHeadClass +
                        " !min-w-[120px] !max-w-[120px] font-montserrat"
                      }
                    >
                      Time slot
                    </th>
                    <th
                      className={
                        tHeadClass +
                        " !min-w-[120px] !max-w-[120px] font-montserrat"
                      }
                    >
                      Status
                    </th>
                    <th
                      className={
                        tHeadClass +
                        " !min-w-[120px] !max-w-[120px] font-montserrat"
                      }
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <div className="absolute bottom-0 flex items-center w-full justify-center">
                    <div class="loader-loader "></div>
                  </div>
                ) : (
                  // <div>Laoding...</div>
                  <>
                    {todayAppointments.map((item, index) => {
                      let slotStartTime = item.startTime;
                      let slotEndTime = item.endTime;
                      if (user.timezone) {
                        if (user.timezone.offset >= 0) {
                          slotStartTime = moment(item.startTime, "HH:mm")
                            .add(duration, "HH:mm")
                            .format("HH:mm");
                          slotEndTime = moment(item.endTime, "HH:mm")
                            .add(duration, "HH:mm")
                            .format("HH:mm");
                        } else {
                          slotStartTime = moment(item.startTime, "HH:mm")
                            .subtract(duration, "HH:mm")
                            .format("HH:mm");
                          slotEndTime = moment(item.endTime, "HH:mm")
                            .subtract(duration, "HH:mm")
                            .format("HH:mm");
                        }
                      }
                      return (
                        <tbody key={index}>
                          <tr>
                            <td className="!pt-8 !pb-4 font-montserrat  !min-w-[120px] !max-w-[120px] ">
                              {item.lawyerData.firstName}&nbsp;
                              {item.lawyerData.lastName}
                            </td>
                            <td className="!pt-8 !pb-4 font-montserrat !min-w-[180px] !max-w-[180px] ">
                              {item.date}
                            </td>
                            <td className="!pt-8 !pb-4 font-montserrat !min-w-[120px] !max-w-[120px] ">
                              {slotStartTime} - {slotEndTime}
                            </td>
                            <td className="!pt-8 !pb-4 font-montserrat !min-w-[120px] !max-w-[120px] ">
                              <div className="">
                                <p className="succuss">{item.status}</p>
                              </div>
                            </td>
                            <td className="!pt-8 !pb-4 font-montserrat !min-w-[120px] !max-w-[120px] ">
                              <div className="appointment-status">
                                {(item.status === "completed" ||
                                  item.status === "pending") &&
                                item.paymentData.isTrailPayment === false ? (
                                  <button
                                    onClick={() => {
                                      setEditBooking(item);
                                      setShowDispute(true);
                                    }}
                                  >
                                    Dispute
                                  </button>
                                ) : (item.status === "completed" ||
                                    item.status === "pending") &&
                                  item.paymentData.isTrailPayment === true ? (
                                  <div
                                    className="flex cursor-pointer gap-2 items-center"
                                    onClick={() =>
                                      reviewButtonClickHandler(
                                        item.userId,
                                        item.lawyerId,
                                        item._id
                                      )
                                    }
                                  >
                                    <svg
                                      width={19}
                                      height={15}
                                      viewBox="0 0 19 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 7.52148C6 5.33398 7.78125 3.52148 10 3.52148C12.1875 3.52148 14 5.33398 14 7.52148C14 9.74023 12.1875 11.5215 10 11.5215C7.78125 11.5215 6 9.74023 6 7.52148ZM10 10.0215C11.375 10.0215 12.5 8.92773 12.5 7.52148C12.5 6.14648 11.375 5.02148 10 5.02148C9.96875 5.02148 9.9375 5.02148 9.90625 5.02148C9.96875 5.20898 10 5.36523 10 5.52148C10 6.64648 9.09375 7.52148 8 7.52148C7.8125 7.52148 7.65625 7.52148 7.5 7.45898C7.5 7.49023 7.5 7.52148 7.5 7.52148C7.5 8.92773 8.59375 10.0215 10 10.0215ZM3.96875 3.05273C5.4375 1.67773 7.46875 0.521484 10 0.521484C12.5 0.521484 14.5312 1.67773 16 3.05273C17.4688 4.39648 18.4375 6.02148 18.9062 7.14648C19 7.39648 19 7.67773 18.9062 7.92773C18.4375 9.02148 17.4688 10.6465 16 12.0215C14.5312 13.3965 12.5 14.5215 10 14.5215C7.46875 14.5215 5.4375 13.3965 3.96875 12.0215C2.5 10.6465 1.53125 9.02148 1.0625 7.92773C0.96875 7.67773 0.96875 7.39648 1.0625 7.14648C1.53125 6.02148 2.5 4.39648 3.96875 3.05273ZM10 2.02148C7.9375 2.02148 6.28125 2.95898 5 4.14648C3.78125 5.27148 2.96875 6.58398 2.53125 7.52148C2.96875 8.45898 3.78125 9.80273 5 10.9277C6.28125 12.1152 7.9375 13.0215 10 13.0215C12.0312 13.0215 13.6875 12.1152 14.9688 10.9277C16.1875 9.80273 17 8.45898 17.4375 7.52148C17 6.58398 16.1875 5.27148 14.9688 4.14648C13.6875 2.95898 12.0312 2.02148 10 2.02148Z"
                                        fill="#006766"
                                      />
                                    </svg>
                                    <span>View</span>
                                  </div>
                                ) : item.status === "completed" &&
                                  item.paymentData.isTrailPayment === false ? (
                                  <div
                                    className="flex cursor-pointer gap-2 items-center"
                                    onClick={() =>
                                      reviewButtonClickHandler(
                                        item.userId,
                                        item.lawyerId,
                                        item._id
                                      )
                                    }
                                  >
                                    <svg
                                      width={19}
                                      height={15}
                                      viewBox="0 0 19 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 7.52148C6 5.33398 7.78125 3.52148 10 3.52148C12.1875 3.52148 14 5.33398 14 7.52148C14 9.74023 12.1875 11.5215 10 11.5215C7.78125 11.5215 6 9.74023 6 7.52148ZM10 10.0215C11.375 10.0215 12.5 8.92773 12.5 7.52148C12.5 6.14648 11.375 5.02148 10 5.02148C9.96875 5.02148 9.9375 5.02148 9.90625 5.02148C9.96875 5.20898 10 5.36523 10 5.52148C10 6.64648 9.09375 7.52148 8 7.52148C7.8125 7.52148 7.65625 7.52148 7.5 7.45898C7.5 7.49023 7.5 7.52148 7.5 7.52148C7.5 8.92773 8.59375 10.0215 10 10.0215ZM3.96875 3.05273C5.4375 1.67773 7.46875 0.521484 10 0.521484C12.5 0.521484 14.5312 1.67773 16 3.05273C17.4688 4.39648 18.4375 6.02148 18.9062 7.14648C19 7.39648 19 7.67773 18.9062 7.92773C18.4375 9.02148 17.4688 10.6465 16 12.0215C14.5312 13.3965 12.5 14.5215 10 14.5215C7.46875 14.5215 5.4375 13.3965 3.96875 12.0215C2.5 10.6465 1.53125 9.02148 1.0625 7.92773C0.96875 7.67773 0.96875 7.39648 1.0625 7.14648C1.53125 6.02148 2.5 4.39648 3.96875 3.05273ZM10 2.02148C7.9375 2.02148 6.28125 2.95898 5 4.14648C3.78125 5.27148 2.96875 6.58398 2.53125 7.52148C2.96875 8.45898 3.78125 9.80273 5 10.9277C6.28125 12.1152 7.9375 13.0215 10 13.0215C12.0312 13.0215 13.6875 12.1152 14.9688 10.9277C16.1875 9.80273 17 8.45898 17.4375 7.52148C17 6.58398 16.1875 5.27148 14.9688 4.14648C13.6875 2.95898 12.0312 2.02148 10 2.02148Z"
                                        fill="#006766"
                                      />
                                    </svg>
                                    <span>View</span>
                                  </div>
                                ) : (
                                  <div
                                    className="flex cursor-pointer gap-2 items-center"
                                    onClick={() =>
                                      reviewButtonClickHandler(
                                        item.userId,
                                        item.lawyerId,
                                        item._id
                                      )
                                    }
                                  >
                                    <svg
                                      width={19}
                                      height={15}
                                      viewBox="0 0 19 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 7.52148C6 5.33398 7.78125 3.52148 10 3.52148C12.1875 3.52148 14 5.33398 14 7.52148C14 9.74023 12.1875 11.5215 10 11.5215C7.78125 11.5215 6 9.74023 6 7.52148ZM10 10.0215C11.375 10.0215 12.5 8.92773 12.5 7.52148C12.5 6.14648 11.375 5.02148 10 5.02148C9.96875 5.02148 9.9375 5.02148 9.90625 5.02148C9.96875 5.20898 10 5.36523 10 5.52148C10 6.64648 9.09375 7.52148 8 7.52148C7.8125 7.52148 7.65625 7.52148 7.5 7.45898C7.5 7.49023 7.5 7.52148 7.5 7.52148C7.5 8.92773 8.59375 10.0215 10 10.0215ZM3.96875 3.05273C5.4375 1.67773 7.46875 0.521484 10 0.521484C12.5 0.521484 14.5312 1.67773 16 3.05273C17.4688 4.39648 18.4375 6.02148 18.9062 7.14648C19 7.39648 19 7.67773 18.9062 7.92773C18.4375 9.02148 17.4688 10.6465 16 12.0215C14.5312 13.3965 12.5 14.5215 10 14.5215C7.46875 14.5215 5.4375 13.3965 3.96875 12.0215C2.5 10.6465 1.53125 9.02148 1.0625 7.92773C0.96875 7.67773 0.96875 7.39648 1.0625 7.14648C1.53125 6.02148 2.5 4.39648 3.96875 3.05273ZM10 2.02148C7.9375 2.02148 6.28125 2.95898 5 4.14648C3.78125 5.27148 2.96875 6.58398 2.53125 7.52148C2.96875 8.45898 3.78125 9.80273 5 10.9277C6.28125 12.1152 7.9375 13.0215 10 13.0215C12.0312 13.0215 13.6875 12.1152 14.9688 10.9277C16.1875 9.80273 17 8.45898 17.4375 7.52148C17 6.58398 16.1875 5.27148 14.9688 4.14648C13.6875 2.95898 12.0312 2.02148 10 2.02148Z"
                                        fill="#006766"
                                      />
                                    </svg>
                                    <span>View</span>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </>
                )}
              </table>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-10">
        <Typography varient={"green-head"}>All Appointments</Typography>
        {/* <div className="profile_tab_header">
        <h4> All Appointments </h4>
      </div> */}
        {lawyerData.queryResult && lawyerData.queryResult.data.length === 0 ? (
          <div style={{ padding: "20px" }}>No record found</div>
        ) : (
          // <div className="profile_tab_content relative">
          <div className=" relative">
            {/* <div className="appointment_list "> */}
            <div className="table-responsive ">
              {/* <table className="table font-montserrat" >
                <thead>
                  <tr>
                    <th className={tHeadClass + ' !min-w-[120px] !max-w-[120px] font-montserrat'}>Lawyer</th>
                    <th className={tHeadClass + ' !min-w-[200px] !max-w-[200px] font-montserrat'}>Appointment Date</th>
                    <th className={tHeadClass + ' !min-w-[120px] !max-w-[120px] font-montserrat'}>Time slot</th>
                    <th className={tHeadClass + ' !min-w-[120px] !max-w-[120px] font-montserrat'}>Status</th>
                    <th className={tHeadClass + ' !min-w-[120px] !max-w-[120px] font-montserrat'}>Timeline</th>
                    <th className={tHeadClass + ' !min-w-[120px] !max-w-[120px] font-montserrat'}>Action</th>
                  </tr>
                </thead>

                <>
                  {lawyerData.queryResult &&
                    lawyerData.queryResult.data.map((item, index) => {

                      let slotEndTime = item.endTime
                      if (user.timezone) {
                        if (user.timezone.offset >= 0) {
                          slotEndTime = moment(item.endTime, 'HH:mm').add(duration, 'HH:mm').format('HH:mm');
                        } else {
                          slotEndTime = moment(item.endTime, 'HH:mm').subtract(duration, 'HH:mm').format('HH:mm');
                        }
                      }
                      let slotDateStart = item.date
                      let slotTimeStart = item.startTime
                      const splitTime = item.date.split("-")
                      const startTimestring = `${splitTime[2]}-${splitTime[1]}-${splitTime[0]}T${item.startTime}:00`;
                      const startdate = moment(startTimestring);
                      if (user.timezone) {
                        if (user.timezone.offset >= 0) {
                          const slotStartingTime = moment(startdate).add(duration, 'HH:mm').format('DD-MM-YYYY,HH:mm');
                          slotDateStart = slotStartingTime.split(",")[0]
                          slotTimeStart = slotStartingTime.split(",")[1]
                        } else {
                          const slotStartingTime = moment(startdate).subtract(duration, 'HH:mm').format('DD-MM-YYYY,HH:mm');
                          slotDateStart = slotStartingTime.split(",")[0]
                          slotTimeStart = slotStartingTime.split(",")[1]
                        }
                      }
                      const splitDate = slotDateStart.split("-")
                      const dateOfSlot = moment([splitDate[2], splitDate[1], splitDate[0]]);
                      const dateToday = moment().format("YYYY/MM/DD")
                      const splitCurrentDate = dateToday.split('/')
                      const todaysDate = moment([splitCurrentDate[0], splitCurrentDate[1], splitCurrentDate[2]]);
                      const daysDifference = todaysDate.diff(dateOfSlot, 'days')
                      return <>
                        <tbody key={index}>
                          <tr >
                            <td className="!pt-8 !pb-4 font-montserrat !min-w-[120px] !max-w-[120px] ">
                              {item.lawyerData.firstName}&nbsp;
                              {item.lawyerData.lastName}
                            </td>
                            <td className="!pt-8 !pb-4 font-montserrat !min-w-[200px] !max-w-[200px] ">{slotDateStart}</td>
                            <td className="!pt-8 !pb-4 font-montserrat !min-w-[120px] !max-w-[120px] ">
                              {slotTimeStart} - {slotEndTime}
                            </td>
                            <td className="!pt-8 !pb-4 font-montserrat !min-w-[120px] !max-w-[120px] ">
                              <div className="appointment-status">
                                <p className="succuss">
                                  {item.status === "canceled"
                                    ? "cancelled"
                                    : item.status}
                                </p>
                              </div>
                            </td>
                            <td className="!pt-8 !pb-4 !min-w-[120px] !max-w-[120px] ">
                              {daysDifference === -1 ? 'One day left' : daysDifference === 1 ? 'One day ago' : daysDifference === 0 ? 'Today' : daysDifference > 1 ? `${daysDifference} days ago` : daysDifference < 1 ? `${Math.abs(daysDifference)} days left` : null}
                            </td>
                            <td className="!pt-6 !pb-4 align-bottom font-montserrat !min-w-[120px] !max-w-[120px] ">
                              <ReactStars
                                count={5}
                                value={reviewData.rating}
                                onChange={(event) =>
                                  setReviewData({ ...reviewData, rating: event })
                                }
                                size={24}
                                activeColor="#ffd700"
                              />

                            </td>

                            <td className="!pt-8 !pb-4  ">
                              <div className="cursor-pointer" onClick={() => toggleDropdown(index)}>
                                <svg
                                  width={4}
                                  height={14}
                                  viewBox="0 0 4 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 10.0215C2.8125 10.0215 3.5 10.709 3.5 11.5215C3.5 12.3652 2.8125 13.0215 2 13.0215C1.15625 13.0215 0.5 12.3652 0.5 11.5215C0.5 10.709 1.15625 10.0215 2 10.0215ZM2 5.02148C2.8125 5.02148 3.5 5.70898 3.5 6.52148C3.5 7.36523 2.8125 8.02148 2 8.02148C1.15625 8.02148 0.5 7.36523 0.5 6.52148C0.5 5.70898 1.15625 5.02148 2 5.02148ZM2 3.02148C1.15625 3.02148 0.5 2.36523 0.5 1.52148C0.5 0.708984 1.15625 0.0214844 2 0.0214844C2.8125 0.0214844 3.5 0.708984 3.5 1.52148C3.5 2.36523 2.8125 3.02148 2 3.02148Z"
                                    fill="#006766"
                                  />
                                </svg>
                              </div>
                              {isOpen[index] && (
                                <div className="right-5 dd z-10 flex flex-col gap-2 py-2 bg-white px-3 absolute">
                                  <div onClick={() => setViewApp(item)} className='cursor-pointer'>View</div>

                                  <div className="appointment-status">

                                    {(item.status === "completed" ||
                                      item.status === "pending") &&
                                      item.paymentData.isTrailPayment === false && (daysDifference < 7) ? (
                                      <button
                                        onClick={() => {
                                          setEditBooking(item);
                                          setShowDispute(true);
                                        }}
                                      >
                                        Dispute
                                      </button>
                                    ) : (item.status === "completed" ||
                                      item.status === "pending" || item.status === "paidOut") &&
                                      item.paymentData.isTrailPayment === true ?

                                      <div
                                        className='flex cursor-pointer gap-2 items-center'
                                        onClick={() =>
                                          reviewButtonClickHandler(
                                            item.userId,
                                            item.lawyerId,
                                            item._id
                                          )
                                        }
                                      >
                                        <span>Review</span>
                                      </div>

                                      : item.status === "completed" && item.paymentData.isTrailPayment === false && (daysDifference >= 7) ?
                                        <div
                                          className='flex cursor-pointer gap-2 items-center'
                                          onClick={() =>
                                            reviewButtonClickHandler(
                                              item.userId,
                                              item.lawyerId,
                                              item._id
                                            )
                                          }
                                        >
                                          <span>Review</span>
                                        </div> :
                                        (item.status === "canceled" || item.status === "paidOut" || item.status === "dispute") ?
                                          <div
                                            className='flex cursor-pointer gap-2 items-center'
                                            onClick={() =>
                                              reviewButtonClickHandler(
                                                item.userId,
                                                item.lawyerId,
                                                item._id
                                              )
                                            }
                                          >
                                            <span>Review</span>
                                          </div> : (
                                            ""
                                          )}
                                  </div>
                                  <div className='cursor-pointer'>Delete</div>
                                  <div className='cursor-pointer' onClick={() => {
                                    setEditBooking(item);
                                    setShowDispute(true);
                                  }}>Dispute</div>
                                </div>
                              )}


                              <style>
                                {`
                              .dd{
border-radius: 5px;
background: #FFF;
box-shadow: 0px 4px 20.2px 0px rgba(0, 0, 0, 0.06);
                              }
                              `}
                              </style>
                            </td>
                          </tr>
                        </tbody>
                      </>
                    })}
                </>

              </table> */}
              <div
                className="flex flex-col gap-3 w-full"
                onClick={() => {
                  if (isOpen.every((s) => s === false)) {
                    return;
                  }
                  setIsOpen(Array(20).fill(false));
                }}
              >
                {lawyerData.queryResult &&
                  lawyerData.queryResult.data.map((item, index) => {
                    let slotEndTime = item.endTime;
                    if (user.timezone) {
                      if (user.timezone.offset >= 0) {
                        slotEndTime = moment(item.endTime, "HH:mm")
                          .add(duration, "HH:mm")
                          .format("HH:mm");
                      } else {
                        slotEndTime = moment(item.endTime, "HH:mm")
                          .subtract(duration, "HH:mm")
                          .format("HH:mm");
                      }
                    }
                    let slotDateStart = item.date;
                    let slotTimeStart = item.startTime;
                    const splitTime = item.date.split("-");
                    const startTimestring = `${splitTime[2]}-${splitTime[1]}-${splitTime[0]}T${item.startTime}:00`;
                    const startdate = moment(startTimestring);
                    if (user.timezone) {
                      if (user.timezone.offset >= 0) {
                        const slotStartingTime = moment(startdate)
                          .add(duration, "HH:mm")
                          .format("DD-MM-YYYY,HH:mm");
                        slotDateStart = slotStartingTime.split(",")[0];
                        slotTimeStart = slotStartingTime.split(",")[1];
                      } else {
                        const slotStartingTime = moment(startdate)
                          .subtract(duration, "HH:mm")
                          .format("DD-MM-YYYY,HH:mm");
                        slotDateStart = slotStartingTime.split(",")[0];
                        slotTimeStart = slotStartingTime.split(",")[1];
                      }
                    }
                    const splitDate = slotDateStart.split("-");
                    const dateOfSlot = moment([
                      splitDate[2],
                      splitDate[1],
                      splitDate[0],
                    ]);
                    const dateToday = moment().format("YYYY/MM/DD");
                    const splitCurrentDate = dateToday.split("/");
                    const todaysDate = moment([
                      splitCurrentDate[0],
                      splitCurrentDate[1],
                      splitCurrentDate[2],
                    ]);
                    const daysDifference = todaysDate.diff(dateOfSlot, "days");
                    return (
                      <div
                        key={index}
                        style={{ border: "1px solid #EBEBEB" }}
                        className="flex hover:!border-[#E3B576] overflow-auto scroll-cont w-full items-center py-[30px] px-[20px] rounded-md  justify-between font-montserrat"
                      >
                        <div className="flex flex-col min-w-[120px] max-w-[120px] items-start gap-[10px]">
                          <div className={tHeadClass}>Lawyer</div>
                          <div
                            className={
                              "font-montserrat text-[#252525] text-base not-italic font-medium leading-[normal] capitalize "
                            }
                          >
                            {" "}
                            {item.lawyerData.firstName}&nbsp;
                            {item.lawyerData.lastName}
                          </div>
                        </div>
                        <div className="flex flex-col min-w-[190px] max-w-[190px] items-start gap-[10px]">
                          <div className={tHeadClass}>appointment date</div>
                          <div
                            className={
                              "font-montserrat text-[#252525] text-base not-italic font-medium leading-[normal] capitalize "
                            }
                          >
                            {slotDateStart}
                          </div>
                        </div>
                        <div className="flex flex-col min-w-[120px] max-w-[120px] items-start gap-[10px]">
                          <div className={tHeadClass}>TIme slot</div>
                          <div
                            className={
                              "font-montserrat text-[#252525] text-base not-italic font-medium leading-[normal] capitalize "
                            }
                          >
                            {slotTimeStart} - {slotEndTime}
                          </div>
                        </div>
                        <div className="flex flex-col min-w-[120px] max-w-[120px] items-start gap-[10px]">
                          <div className={tHeadClass}>Status</div>
                          <div
                            className={
                              "font-montserrat text-[#252525] text-base not-italic font-medium leading-[normal] capitalize "
                            }
                          >
                            {" "}
                            {item.status === "canceled"
                              ? "cancelled"
                              : item.status}
                          </div>
                        </div>
                        <div className="flex flex-col min-w-[120px] max-w-[120px] items-start gap-[10px]">
                          <div className={tHeadClass}>Timeline</div>
                          <div
                            className={
                              "font-montserrat text-[#252525] text-base not-italic font-medium leading-[normal] capitalize "
                            }
                          >
                            {daysDifference === -1
                              ? "One day left"
                              : daysDifference === 1
                              ? "One day ago"
                              : daysDifference === 0
                              ? "Today"
                              : daysDifference > 1
                              ? `${daysDifference} days ago`
                              : daysDifference < 1
                              ? `${Math.abs(daysDifference)} days left`
                              : null}
                          </div>
                        </div>
                        <div className="flex flex-col min-w-[120px] max-w-[120px] items-start ">
                          <div className={tHeadClass}>Action</div>
                          <div
                            className={
                              "font-montserrat text-[#252525] text-base not-italic font-medium leading-[normal] capitalize "
                            }
                          >
                            <ReactStars
                              count={5}
                              value={reviewData.rating}
                              onChange={(event) => {
                                setReviewData({ ...reviewData, rating: event });
                                setShowReviewDialog(true);
                              }}
                              size={24}
                              activeColor="#ffd700"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-[10px] ">
                          <div className={tHeadClass}></div>
                          <div
                            className="cursor-pointer"
                            onClick={() => toggleDropdown(index)}
                          >
                            <svg
                              width={4}
                              height={14}
                              viewBox="0 0 4 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10.0215C2.8125 10.0215 3.5 10.709 3.5 11.5215C3.5 12.3652 2.8125 13.0215 2 13.0215C1.15625 13.0215 0.5 12.3652 0.5 11.5215C0.5 10.709 1.15625 10.0215 2 10.0215ZM2 5.02148C2.8125 5.02148 3.5 5.70898 3.5 6.52148C3.5 7.36523 2.8125 8.02148 2 8.02148C1.15625 8.02148 0.5 7.36523 0.5 6.52148C0.5 5.70898 1.15625 5.02148 2 5.02148ZM2 3.02148C1.15625 3.02148 0.5 2.36523 0.5 1.52148C0.5 0.708984 1.15625 0.0214844 2 0.0214844C2.8125 0.0214844 3.5 0.708984 3.5 1.52148C3.5 2.36523 2.8125 3.02148 2 3.02148Z"
                                fill="#006766"
                              />
                            </svg>
                          </div>
                          {isOpen[index] && (
                            <div className="right-[1.5rem] b dd z-10 flex flex-col gap-2 py-2 bg-white px-3 absolute">
                              {/* Add your dropdown content here */}
                              <div
                                onClick={() => setViewApp(item)}
                                className="cursor-pointer"
                              >
                                View
                              </div>

                              <div className="appointment-status">
                                {(item.status === "completed" ||
                                  item.status === "pending") &&
                                item.paymentData.isTrailPayment === false &&
                                daysDifference < 7 ? (
                                  <button
                                    onClick={() => {
                                      setEditBooking(item);
                                      setShowDispute(true);
                                    }}
                                  >
                                    Dispute
                                  </button>
                                ) : (item.status === "completed" ||
                                    item.status === "pending" ||
                                    item.status === "paidOut") &&
                                  item.paymentData.isTrailPayment === true ? (
                                  <div
                                    className="flex cursor-pointer gap-2 items-center"
                                    onClick={() =>
                                      // reviewButtonClickHandler(
                                      //   item.userId,
                                      //   item.lawyerId,
                                      //   item._id
                                      // )
                                      {
                                        setShowReviewDialog(true);
                                        setReviewData({
                                          ...reviewData,
                                          userId: item.userId,
                                          lawyerId: item.lawyerId,
                                          appointmentId: item._id,
                                        });
                                      }
                                    }
                                  >
                                    <span>Review</span>
                                  </div>
                                ) : item.status === "completed" &&
                                  item.paymentData.isTrailPayment === false &&
                                  daysDifference >= 7 ? (
                                  <div
                                    className="flex cursor-pointer gap-2 items-center"
                                    onClick={() =>
                                      // reviewButtonClickHandler(
                                      //   item.userId,
                                      //   item.lawyerId,
                                      //   item._id
                                      // )
                                      {
                                        setShowReviewDialog(true);
                                        setReviewData({
                                          ...reviewData,
                                          userId: item.userId,
                                          lawyerId: item.lawyerId,
                                          appointmentId: item._id,
                                        });
                                      }
                                    }
                                  >
                                    <span>Review</span>
                                  </div>
                                ) : item.status === "canceled" ||
                                  item.status === "paidOut" ||
                                  item.status === "dispute" ? (
                                  <div
                                    className="flex cursor-pointer gap-2 items-center"
                                    onClick={() =>
                                      // reviewButtonClickHandler(
                                      //   item.userId,
                                      //   item.lawyerId,
                                      //   item._id
                                      // )
                                      {
                                        setShowReviewDialog(true);
                                        setReviewData({
                                          ...reviewData,
                                          userId: item.userId,
                                          lawyerId: item.lawyerId,
                                          appointmentId: item._id,
                                        });
                                      }
                                    }
                                  >
                                    <span>Review</span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* <div className='cursor-pointer'>Delete</div> */}
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  setEditBooking(item);
                                  setShowDispute(true);
                                }}
                              >
                                Dispute
                              </div>
                            </div>
                          )}
                        </div>

                        <style>{`
                    /* Style for the scroll bar */
                    .scroll-cont::-webkit-scrollbar {
                        height: 6px; /* Set the width of the scrollbar */
                    }
            
                    /* Track style */
                    .scroll-cont::-webkit-scrollbar-track {
                        background-color: #f1f1f1; /* Set the background color of the scrollbar track */
                    }
            
                    /* Handle style */
                    .scroll-cont::-webkit-scrollbar-thumb {
                        background-color: #006766; /* Set the color of the scrollbar handle */
                    }
            
                    /* Handle on hover */
                    .scroll-cont::-webkit-scrollbar-thumb:hover {
                        background-color: #555; /* Set the color of the scrollbar handle on hover */
                    }
  
                    `}</style>
                      </div>
                    );
                  })}
              </div>
            </div>
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        )}
      </div>
      <div
        className={`${
          showReviewDialog
            ? "fixed h-full w-full inset-0 backdrop-blur-sm backdrop-brightness-50 z-[20]"
            : "hidden"
        }`}
        // open={true}
        // open={showReviewDialog}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (e.target === e.currentTarget) {
              // setShowReviewDialog(false);
            }
          }}
          className="flex flex-col justify-center  items-center h-full "
        >
          <div className="max-w-[862px] flex flex-col justify-center relative rounded-[10px] h-full m-auto bg-white max-h-[560px] px-[118px]">
            <div className="absolute top-[-60px] right-[330px] flex flex-col items-center justify-center  rounded-full backdrop-brightness-50  min-w-[134px] min-h-[134px] ">
              <img src="/reviews-img.png" alt="reviews" className="  " />
            </div>
            <div className="flex flex-col gap-3 mt-[4.5rem]">
              <div className="text-[#E3B576] text-center text-sm not-italic font-medium leading-[45px] tracking-[0.28px] uppercase">
                Thank you for your time
              </div>
              <div className="text-[#006766] mb-6 text-center text-[42px] not-italic font-medium leading-[48px] tracking-[0.84px] uppercase">
                RATE YOUR EXPERIENCE
              </div>
              <div className="review-card-box">
                <div className="flex items-center justify-center">
                  <ReactStars
                    count={5}
                    value={reviewData.rating}
                    onChange={(event) =>
                      setReviewData({ ...reviewData, rating: event })
                    }
                    size={74}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="field-group"></div>
                <textarea
                  className="fom-control px-[18px] bg-white border border-[#ebebeb] pt-[15px] "
                  name="review"
                  type="text"
                  value={reviewData.review}
                  rows={20}
                  onChange={(event) =>
                    setReviewData({ ...reviewData, review: event.target.value })
                  }
                  placeholder="Write your review on your previous session with the lawyer."
                />
                <div className="content_LAuth_action flex flex-col gap-4">
                  {/* <button
                type="button"
                className="btn-cancel"
                onClick={() => setShowReviewDialog(false)}
              >
                Close
              </button> */}
                  {/* <button
                  type="button"
                  className="getStarted"
                  onClick={updateReview}
                >
                  Submit
                </button> */}
                  <ButtonForDashboard
                    handleClick={updateReview}
                    varient={"bgyellow"}
                  >
                    Submit
                  </ButtonForDashboard>
                  <div
                    onClick={() => setShowReviewDialog(false)}
                    className="flex items-center justify-center gap-2 cursor-pointer"
                  >
                    <svg
                      width={13}
                      height={12}
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.7207 6.14062C12.7207 6.52344 12.4199 6.82422 12.0645 6.82422H2.76758L6.4043 10.2695C6.67773 10.5156 6.67773 10.9531 6.43164 11.1992C6.18555 11.4727 5.77539 11.4727 5.50195 11.2266L0.689453 6.63281C0.552734 6.49609 0.498047 6.33203 0.498047 6.14062C0.498047 5.97656 0.552734 5.8125 0.689453 5.67578L5.50195 1.08203C5.77539 0.835938 6.18555 0.835938 6.43164 1.10938C6.67773 1.35547 6.67773 1.79297 6.4043 2.03906L2.76758 5.48438H12.0645C12.4473 5.48438 12.7207 5.78516 12.7207 6.14062Z"
                        fill="#252525"
                      />
                    </svg>
                    <div className="text-[#252525] text-sm not-italic font-medium leading-[normal] tracking-[0.28px] capitalize">
                      GO BACK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div open={showDispute} onRequestClose={() => setShowDispute(false)}> */}
      <div
        className={`${
          showDispute
            ? "fixed inset-0 backdrop-blur-sm backdrop-brightness-50 z-[20]"
            : "hidden"
        } flex flex-col justify-center items-center h-full w-full `}
        // open={true}
        // open={showReviewDialog}
        // onClick={() => setShowDispute(false)}
      >
        {/* <Modal open={true} onRequestClose={() => setShowDispute(false)}> */}
        <div className="bg-white w-full px-[158px] flex flex-col justify-center max-w-[862px] h-full max-h-[430px] m-auto">
          <div className="flex flex-col gap-3 w-full">
            <div className="content_LAuth pt-2">
              <div className="text-[#E3B576] text-center text-sm not-italic font-medium leading-[45px] tracking-[0.28px] uppercase">
                We are sorry
              </div>
              <div className="text-[#006766] mb-6 text-center text-[42px] not-italic font-medium leading-[48px] tracking-[0.84px] uppercase">
                Raise a dispute
              </div>
              <textarea
                // className="fom-control  "
                name="reason"
                type="text"
                rows={5}
                className="form-control  !bg-white placeholder:text-[#252525]/40 px-[18px] bg-white border border-[#ebebeb] pt-[15px] text-[#252525] text-sm not-italic font-medium leading-[normal] tracking-[0.28px] capitalize"
                value={disputeData.reason}
                onChange={(event) =>
                  setDisputeData({ ...disputeData, reason: event.target.value })
                }
                placeholder="Please write and explain the reason to raise the dispute."
              />
              {errors.reason && (
                <span className="required" style={{ color: "#ff5a5a" }}>
                  {errors.reason}
                </span>
              )}
            </div>
            <div className="content_LAuth_action">
              {/* <button
              type="button"
              className="btn-cancel"
              onClick={() => setShowDispute(false)}
            >
              Cancel
            </button> */}
              <ButtonForDashboard
                handleClick={raiseDispute}
                varient={"bgyellow"}
              >
                Submit
              </ButtonForDashboard>

              {/* <button
              onClick={() => {
                raiseDispute();
              }}
              type="button"
              className=""
            >
              Submit
            </button> */}
            </div>
            <div
              onClick={() => setShowDispute(false)}
              className="flex items-center justify-center gap-2 cursor-pointer"
            >
              <svg
                width={13}
                height={12}
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7207 6.14062C12.7207 6.52344 12.4199 6.82422 12.0645 6.82422H2.76758L6.4043 10.2695C6.67773 10.5156 6.67773 10.9531 6.43164 11.1992C6.18555 11.4727 5.77539 11.4727 5.50195 11.2266L0.689453 6.63281C0.552734 6.49609 0.498047 6.33203 0.498047 6.14062C0.498047 5.97656 0.552734 5.8125 0.689453 5.67578L5.50195 1.08203C5.77539 0.835938 6.18555 0.835938 6.43164 1.10938C6.67773 1.35547 6.67773 1.79297 6.4043 2.03906L2.76758 5.48438H12.0645C12.4473 5.48438 12.7207 5.78516 12.7207 6.14062Z"
                  fill="#252525"
                />
              </svg>

              <div className="text-[#252525] text-sm not-italic font-medium leading-[normal] tracking-[0.28px] capitalize">
                GO BACK
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
      .react-responsive-modal-modal{
      min-width:862px;
      max-width:862px;
      min-height:460px;
      max-height:460px;
      `}</style>
    </div>
  );
};
{
  /* <Modal
open={showReviewDialog}
onRequestClose={() => setShowReviewDialog(false)}
>
<div className="content_LAuth p-0 ">
  <div className="pb-[30px] pt-[100px] max-w-[624px] mx-auto">
    <div className="text-[#E3B576] text-center text-sm not-italic font-medium leading-[45px] tracking-[0.28px] uppercase">
      Thank you for your time
    </div>
    <div className="text-[#006766] mb-6 text-center text-[42px] not-italic font-medium leading-[48px] tracking-[0.84px] uppercase">
      RATE YOUR EXPERIENCE
    </div>
    <div className="review-card-box">
      <div className="flex items-center justify-center">
        <ReactStars
          count={5}
          value={reviewData.rating}
          onChange={(event) =>
            setReviewData({ ...reviewData, rating: event })
          }
          size={74}
          activeColor="#ffd700"
        />
      </div>
      <div className="field-group">
      </div>
      <textarea
        className="fom-control"
        name="review"
        type="text"
        value={reviewData.review}
        rows={5}
        onChange={(event) =>
          setReviewData({ ...reviewData, review: event.target.value })
        }
        placeholder="Write your review on your previous session with the lawyer."
      />
      <div className="content_LAuth_action flex flex-col gap-4">
        <ButtonForDashboard handleClick={updateReview} varient={'bgyellow'}>
          Submit
        </ButtonForDashboard>
        <div onClick={() => setShowReviewDialog(false)} className="flex items-center justify-center gap-2 cursor-pointer">
          <svg width={13} height={12} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7207 6.14062C12.7207 6.52344 12.4199 6.82422 12.0645 6.82422H2.76758L6.4043 10.2695C6.67773 10.5156 6.67773 10.9531 6.43164 11.1992C6.18555 11.4727 5.77539 11.4727 5.50195 11.2266L0.689453 6.63281C0.552734 6.49609 0.498047 6.33203 0.498047 6.14062C0.498047 5.97656 0.552734 5.8125 0.689453 5.67578L5.50195 1.08203C5.77539 0.835938 6.18555 0.835938 6.43164 1.10938C6.67773 1.35547 6.67773 1.79297 6.4043 2.03906L2.76758 5.48438H12.0645C12.4473 5.48438 12.7207 5.78516 12.7207 6.14062Z" fill="#252525" />
          </svg>
          <div className="text-[#252525] text-sm not-italic font-medium leading-[normal] tracking-[0.28px] capitalize">
            GO BACK
          </div>
        </div>
      </div>
    </div>
    <div className='absolute ss top-0 right-[23rem] flex flex-col items-center justify-center  rounded-full  min-w-[134px] min-h-[134px] ' >
      <img src='/reviews-img.png' alt='' className='  ' />
    </div>

  </div>
</div>
<style>{`
.react-responsive-modal-modal{
min-width:862px;
max-width:862px;
min-height:598px;
max-height:598px;
}
.ss{
background:rgba(0, 0, 0, 0.5)
}
`}</style>

</Modal> */
}

const mapStateToProps = (state) => {
  return {
    lawyerData: (state.booking && state.booking) || {},
    user: (state.auth && state.auth.user) || {},
    auth: (state.auth && state.auth) || {},
    ratings: (state.ratings && state.ratings) || {},
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {})
)(YourAppointment);

export const TableData = () => {
  return <div></div>;
};
