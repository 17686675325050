import { useEffect } from "react";
import Layout from "../../Components/Layout";
import Prelegate from "../../Components/PrelegateModule/Prelegate/Prelegate";


const HomePage = () => {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    // <Layout>
      <Prelegate />
    // </Layout>
  );
};

export default HomePage;
