import { SERVICES_AUTHENTICATION_AUTHENTICATE_FULFILLED } from "../actions/types";
import { feathersAuthentication } from "../server/feathers-binding";

export default function (state, action) {
  let modifiedState = state;
  if (action.type !== SERVICES_AUTHENTICATION_AUTHENTICATE_FULFILLED) {
    //reduce first through featherjs reducer
    modifiedState = feathersAuthentication.reducer(state, action);
  } //we ignore this action otherwise we are autho signed out when we try to authenticate

  switch (action.type) {
      
    case "SERVICES_AUTHENTICATION_CREATE_FULFILLED":

      let newState = Object.assign({}, modifiedState, {
        isSignedIn: true,
        token: action.payload.accessToken,
        user: action.payload.user,
      });
      return newState;
    default:
      return modifiedState;
  }
}
