import Footer from "../../Components/Footer";
import Layout from "../../Components/Layout";
import PrelegateQuestions from '../../Components/PrelegateModule/PrelegateQuestions/PrelegateQuestions';
import { HeroSection } from "../About";

const HomePage = () => {
   return (
      // <Layout>
      <>
         <HeroSection
            heading={"Questionaire"} bgImg={'/recommendateHero.png'}
         />
         <div className="questioneer-main">
            <PrelegateQuestions />
         </div>
         <Footer />
         {/* </Layout> */}
      </>
   );
}

export default HomePage;