import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import { ToastContainer } from "react-toastify";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import AuthProvider from "./contexts/authContext";
import ScrollToTop from "./Components/ScrollToTop";
import HttpsRedirect from "react-https-redirect";
import reportWebVitals from "./reportWebVitals";

const root = document.getElementById("root");

const renderApp = () => (
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <ScrollToTop>
          <HttpsRedirect>
            <App />
          </HttpsRedirect>
          <ToastContainer />
        </ScrollToTop>
      </AuthProvider>
    </Provider>
  </BrowserRouter>
);

if (root.hasChildNodes()) {
  ReactDOM.hydrate(renderApp(), root);
} else {
  ReactDOM.render(renderApp(), root);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
