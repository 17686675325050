import React from "react";
import { Field, reduxForm } from "redux-form";

let ProfileForm = (props) => {
  const { handleSubmit, loading } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="fields-form_sec">
        <div className="fields_group">
          <label>First Name</label>
          <Field
            name="firstName"
            component="input"
            type="text"
            className="form-control"
            required
          />
        </div>
        <div className="fields_group">
          <label>Last Name</label>
          <Field
            name="lastName"
            component="input"
            type="text"
            className="form-control"
            required
          />
        </div>
        {/* <div className="fields_group">
                  <label>Company</label>
                  <Field name="companyName" component="input" type="text" className="form-control" required/>
              </div>
              <div className="fields_group">
                  <label>Contact Number</label>
                  <Field name="contactNo" component="input" type="text" className="form-control" required/>
              </div> */}
        <div className="fields_group fields_full_width">
          <label>Email</label>
          <Field
            name="email"
            component="input"
            type="email"
            className="form-control"
            disabled
          />
        </div>
        {/* <div className="fields_group">
                  <label>Per Session Fee</label>
                  <Field name="perSessionFee" component="input" type="number" className="form-control" required/>
              </div>
              <div className="fields_group">
                  <label>Per Session Commision Percentage</label>
                  <Field name="perSessionCommission" component="input" type="number" className="form-control" required/>
              </div>
              <div className="fields_group fields_full_width">
                  <label>Minimum Sessions To Attend</label>
                  <Field name="minSessionToAttend" component="input" type="number" className="form-control"/>
              </div> */}
        <div className="fields_group">
          <label>Password</label>
          <Field
            name="password"
            component="input"
            type="password"
            className="form-control"
          />
        </div>
        <div className="fields_group">
          <label>Confirm Password</label>
          <Field
            name="confirmPassword"
            component="input"
            type="password"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group-action text-center">
        <button loading={loading} type="submit" className="btn btn-primary">
          Update
        </button>
      </div>
    </form>
  );
};

ProfileForm = reduxForm({
  form: "profileForm",
  enableReinitialize: true,
  destroyOnUnmount: false,
})(ProfileForm);

export default ProfileForm;
