import Layout from '../Components/Layout';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { CalenderModel } from '../Components/CalenderModel';
import { useSelector } from 'react-redux';
import services from '../server/services';
import { toast } from 'react-toastify';
import { AuthIsSignedIn, AuthIsNotSignedIn } from '../contexts/authContext';

const MySchedulePage = (props) => {
  const [timeList, setTimeList] = useState([
    { time: '', endTime: '', eventTitle: '', eventDate: '' },
  ]);
  const [dbtimeList, setDbtimeList] = useState([
    { time: '', endTime: '', eventTitle: '', eventDate: '' },
  ]);

  const [userId, setUserId] = useState(['']);
  const [finalEventForCalender, setFinalEventForCalender] = useState([]);
  const [open, setOpen] = useState(false);
  const [eventDate, setEventDate] = useState('');

  const handleDateClick = (e) => {
    setEventDate(e.dateStr);

    let finalTimeList = filteredArray(dbtimeList, 'eventDate', e.dateStr);
    if (finalTimeList.length > 0) {
      setTimeList(finalTimeList);
    } else {
      setTimeList([
        { time: '01:00', endTime: '01:00', eventTitle: '', eventDate: '' },
      ]);
    }

    setOpen(true);
  };

  const onCloseModal = () => setOpen(false);

  const filteredArray = (arr, key, value) => {
    const newArray = [];
    for (let i = 0, l = arr.length; i < l; i++) {
      if (arr[i][key] === value) {
        newArray.push(arr[i]);
      }
    }
    return newArray;
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...timeList];
    list[index][name] = value;
    setTimeList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = async (index) => {
    const list = [...timeList];
    const obj = list[index];
    if (obj._id) {
      await services.availability.remove(obj._id);
      toast.success('Delete successfully', {
        hideProgressBar: true,
        autoClose: 2000,
      });
      await services.availability.find({ user: userId });
    }
    list.splice(index, 1);
    setTimeList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setTimeList([
      ...timeList,
      { time: '01:00', endTime: '01:00', eventTitle: '', eventDate: '' },
    ]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let dbFinalEvent = await timeList.map((data, index) => {
      return {
        date: eventDate,
        startTime: data.time,
        endTime: data.endTime,
        title: data.eventTitle,
        user: userId,
        _id: data._id ? data._id : undefined,
      };
    });

    let dbData = [];
    for (let i = 0; i < dbFinalEvent.length; i++) {
      if (dbFinalEvent[i]._id) {
        await services.availability.update(
          dbFinalEvent[i]._id,
          dbFinalEvent[i]
        );
      } else {
        dbData.push(dbFinalEvent[i]);
      }
    }

    if (dbData.length > 0) {
      await services.availability.create(dbData);
    }

    toast.success('Saved successfully', {
      hideProgressBar: true,
      autoClose: 2000,
    });

    await services.availability.find({ user: userId });

    setOpen(false);
  };

  const auth = useSelector((state) => state.auth);
  const availability = useSelector((state) => {
    if (
      state.availability &&
      state.availability.queryResult &&
      state.availability.queryResult.length > 0
    ) {
      return state.availability.queryResult;
    } else if (
      state.availability &&
      state.availability.queryResult &&
      state.availability.queryResult.data &&
      state.availability.queryResult.data.length > 0
    ) {
      return state.availability.queryResult.data;
    } else {
      return [];
    }
  });

  useEffect(() => {
    let dataArary = [];
    let finalEventForCalender = [];
    if (availability && availability.length > 0) {
      for (let i = 0; i < availability.length; i++) {
        if (!dataArary.includes(availability[i].date)) {
          dataArary.push(availability[i].date);
          let obj = {
            id: i,
            start: availability[i].date,
            end: availability[i].date,
            overlap: true,
            display: 'background',
            color: 'green',
          };
          finalEventForCalender.push(obj);
        }
      }

      setFinalEventForCalender(finalEventForCalender);
    }

    let dbtimeList =
      availability &&
      availability.length > 0 &&
      availability.map((data, index) => {
        return {
          time: data.startTime,
          endTime: data.endTime,
          eventTitle: data.title,
          eventDate: data.date,
          _id: data._id,
        };
      });

    setDbtimeList(dbtimeList);
  }, [availability]);

  useEffect(() => {
    const getAvailablity = async () => {
      let { user } = auth;
      if (user) {
        setUserId(user._id);
        await services.availability.find({ user: user._id });
      }
    };
    getAvailablity();
  }, [auth]);

  const Calender = (props) => {
    return (
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        // initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        events={props.finalEventForCalender}
        dateClick={(e) => handleDateClick(e)}
      />
    );
  };

  return (
    <AuthIsSignedIn>
      <Layout>
        <div className="registration_Pages">
          <div className="page_header pageflexHeading">
            <h3 className="pageHeading">My Schedule</h3>
            <div className="pageHeading_right">
              <ul>
                <li>
                  <span>•</span>Available
                </li>
                <li>
                  <span>•</span>Not available
                </li>
                <li>
                  <span>•</span>Bookings
                </li>
              </ul>
            </div>
          </div>
          <div className="booking_calender">
            <div className="booking_full_calander">
              <Calender finalEventForCalender={finalEventForCalender} />
            </div>
          </div>
        </div>

        <Modal open={open} onClose={onCloseModal} center>
          <CalenderModel
            handleInputChange={handleInputChange}
            handleRemoveClick={handleRemoveClick}
            handleAddClick={handleAddClick}
            onSubmit={onSubmit}
            timeList={timeList}
          />
        </Modal>
      </Layout>
    </AuthIsSignedIn>
  );
};
export default MySchedulePage;
