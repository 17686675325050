import Layout from '../../Components/Layout';
import Homepage from '../../Components/Homepage/Homepage';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <Layout>
      <div>
        <Helmet>
          <link rel="canonical" href="https://legateca.com" />
          <meta
            name="description"
            content="Get professional legal advice services. We created Legateca with the vision to use technology to make cost-effective, Artificial Intelligence-powered legal consulting and dispute resolution service available in the UK."
          />
          <meta name="robots" content="INDEX,FOLLOW" />
        </Helmet>
      </div>
      <Homepage />
    </Layout>
  );
};

export default HomePage;
