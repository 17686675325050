import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useState, useEffect } from "react";

let LoginForm = (props) => {
  const { handleSubmit } = props;

  useEffect(() => {
    const script = document.createElement("script");
    const REACT_APP_CYBERSIARA_PUBLIC_KEY =
      process.env.REACT_APP_CYBERSIARA_PUBLIC_KEY;

    script.innerHTML = `
      $(function () {
        console.log("Fax")
        var PublicKey = "${REACT_APP_CYBERSIARA_PUBLIC_KEY}";
        InitCaptcha(PublicKey);
        $('.CaptchaSubmit').click(function () {
          if (CheckCaptcha()) {
            // console.log(CyberSiaraToken);
            // Please write your submit button click event function here //
          }
        });
      });
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          name="email"
          component="input"
          type="email"
          className="form-control input-lg"
          placeholder="Email address"
          required
        />
        {props.ValidationError && props.ValidationError.email && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.email}
          </div>
        )}
      </div>
      <div className="SiaraShield centered"></div>

      <div className="form-group text-center mb-0">
        <button
          type="submit"
          className="getStarted !bg-[#006766] hover:!bg-[#e3b576]"
        >
          {/* <button type="submit" className="getStarted"> */}
          Send Reset Code
        </button>
      </div>
      <div className="form-group text-center mb-0">
        <p>
          Already have an account?{" "}
          <Link to={{ pathname: "/login", state: "/home" }}>
            Click here to Login
          </Link>
        </p>
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  return {};
}

LoginForm = compose(
  withRouter,
  reduxForm({
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    form: "login",
  }),
  connect(mapStateToProps)
)(LoginForm);

export default LoginForm;
//1234
