import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { ButtonForDashboard, ParaComp, Typography } from "../../Pages/Profile";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

let getExistingDate;
let exportedDate;
let stripeVerifiedStatus;
let count = 0;

function getCookie(click) {
  let count = click + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(count) == 0) {
      return c.substring(count.length, c.length);
    }
  }
  return "";
}

class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    this.setState({ stripe: await this.props.stripePromise });
  }

  async handleClick(event) {
    // Block native event handling.
    event.preventDefault();

    const { stripe } = this.state;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    const getMail = JSON.parse(sessionStorage.getItem("state"));
    // Call your backend to create the VerificationSession.
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/stripe_verify`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: getMail.auth.user._id,
          token: getMail.auth.token,
          email: getMail.auth.user.email,
          fname: getMail.auth.user.firstName,
          lname: getMail.auth.user.lastName,
        }),
      }
    );
    getExistingDate = getMail.auth.user.stripeVerifiedAt;
    stripeVerifiedStatus = getMail.auth.user.stripeVerifiedAt;
    //const getDates = await fetch('http://localhost:3030/todayDate/', { method: 'POST', headers: {'Content-Type' : 'application/json'}, body: JSON.stringify({'id' : getMail.auth.user._id})});
    //const userDate = await getDates.json();

    const session = await response.json();

    const verify = await stripe.verifyIdentity(session.client_secret);

    const { error } = verify;

    // console.log(verify);

    //const { error } = await stripe.verifyIdentity(session.client_secret);

    if (error) {
      console.log("[error]", error.message);
    } else {
      // console.log("Verification submitted!");
      const uid = getCookie("uid");
      count = parseInt(getCookie("click"));

      if (getMail.auth.user._id != uid) {
        const d = new Date();
        var minutes = 30;
        d.setTime(d.getTime() + minutes * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie =
          "click" + "=" + "0" + ";" + expires + ";path=/; secure;";
      }

      var cn = parseInt(getCookie("click"));

      if (cn >= 0) {
        count = parseInt(getCookie("click"));
        const d = new Date();
        var minutes = 30;
        d.setTime(d.getTime() + minutes * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        count = count + 1;
        document.cookie =
          "click" + "=" + count + ";" + expires + ";path=/; secure;";
        document.cookie =
          "uid" +
          "=" +
          getMail.auth.user._id +
          ";" +
          expires +
          ";path=/; secure;";
      } else {
        const d = new Date();
        var minutes = 30;
        d.setTime(d.getTime() + minutes * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie =
          "click" + "=" + "0" + ";" + expires + ";path=/; secure;";
        document.cookie =
          "uid" +
          "=" +
          getMail.auth.user._id +
          ";" +
          expires +
          ";path=/; secure;";
      }

      const userResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${getMail.auth.user._id}`
      );
      const user = await userResponse.json();

      // console.log(user);
      const sessionState = JSON.parse(sessionStorage.getItem("state"));
      sessionState.auth.user = user;
      sessionStorage.setItem("state", JSON.stringify(sessionState));

      exportedDate = user.stripeVerifiedAt;

      // console.log(session);
      this.setState({ submitted: true });
    }
  }

  render() {
    const { stripe, submitted } = this.state;
    const oldUser = JSON.parse(sessionStorage.getItem("state"));
    const uid = getCookie("uid");
    count = parseInt(getCookie("click"));

    if (count > 3 && oldUser.auth.user._id == uid) {
      return (
        <>
          <br />
          <br />
          <center>
            <img src="../../cross.png" width="200" height="200"></img>
          </center>
          <br />
          <br />
          <h2>You have reached the limit for the number of attempts for now</h2>
          <ul>
            <li>Please try again later.</li>
          </ul>
          <br />
          <br />
          <br />
          <br />
        </>
      );
    }

    if (submitted) {
      sessionStorage.setItem("processing", "true");
      return (
        <>
          <h2>Thank you for submitting you Identity details.</h2>
          <ul>
            <li>
              We are processing your request. Please dont attempt multiple times
              to avoid any issues.
            </li>
            <li>
              You will be able to see the status after sometime. We will also
              send a response at your registered email address.
            </li>
          </ul>
        </>
      );
    }

    if (typeof oldUser.auth.user.stripeVerifiedid == "undefined") {
      oldUser.auth.user.stripeVerifiedStatus = false;
      oldUser.auth.user.stripeVerifiedAt = "1800-01-01T00:00:00.355+00:00";
      oldUser.auth.user.stripeVerifiedExpiration = "Not Verified";
      oldUser.auth.user.stripeVerifiedid = "Not Verified";
      sessionStorage.setItem("state", JSON.stringify(oldUser));
    }

    if (typeof getExistingDate == "undefined") {
      const sessionState = JSON.parse(sessionStorage.getItem("state"));
      getExistingDate = sessionState.auth.user.stripeVerifiedAt;
      exportedDate = sessionState.auth.user.stripeVerifiedAt;
      const stripeVerifiedStatusSet =
        sessionState.auth.user.stripeVerifiedStatus;
      stripeVerifiedStatus = stripeVerifiedStatusSet.toString();
    }

    const oneYearAfter = moment(getExistingDate).add(1, "years");
    if (
      moment().isAfter(oneYearAfter) == true &&
      stripeVerifiedStatus == "true"
    ) {
      return (
        <>
          <br />
          <button
            className="button-ver"
            role="link"
            disabled={!stripe}
            onClick={this.handleClick}
          >
            Your identity check has expired. Click here to verify again.
          </button>
          <br />
          <br />
          Before starting the verification process, here’s what you need:
          <br />
          <ul>
            <li>
              A valid government-issued photo ID document. Not a photocopy or a
              picture of an ID document. Ensure that the ID document is not
              expired.
            </li>
            <li>
              A device with a camera, if possible, use a mobile device. Cameras
              on mobile devices typically take higher-quality photos than a
              webcam.
            </li>
          </ul>
          The quality of the images captured affects success rates dramatically.
          Below are a few best practices to help ensure that your verification
          succeeds:
          <ul>
            <li>
              Capture a clear image. Make sure that the images are not too dark
              or bright, and don’t have a glare. Hold steady and allow your
              camera to focus to avoid blurry photos.
            </li>
            <li>
              Do not block any part of your ID document in the image. Ideally
              you can lay it flat to take the photo.
            </li>
            <li>
              Do not block any part of your face. Remove sunglasses, masks, or
              other accessories.
            </li>
            <li>
              Find a location with ambient lighting. Avoid spaces with strong
              overhead lights that cast a shadow on your face or ID document.
              Avoid sitting directly in front of a bright light which can wash
              out your face and add a glare to your ID document.
            </li>
          </ul>
        </>
      );
    }

    if (
      moment(getExistingDate).isSame(exportedDate) == true &&
      stripeVerifiedStatus == "false"
    ) {
      return (
        <div className="flex flex-col gap-6">
          <div
            style={{
              borderRadius: "11px",
              border: "1px solid #EBEBEB",
            }}
            className="py-[26px] pl-[30px] pr-[44px]"
          >
            <Typography varient={"green-head"}>ID Verification</Typography>
            <div className="pt-[26px] flex flex-col gap-3">
              <Typography varient={"black-head"}>
                Before starting the verification process, here’s what you need
              </Typography>
              <div>
                <ParaComp>
                  A valid government-issued photo ID document. Not a photocopy
                  or a picture of an ID document. Ensure that the ID document is
                  not expired.
                </ParaComp>
                <ParaComp>
                  A device with a camera, if possible, use a mobile device.
                  Cameras on mobile devices typically take higher-quality photos
                  than a webcam.
                </ParaComp>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "11px",
              border: "1px solid #EBEBEB",
            }}
            className="py-[26px] pl-[30px] pr-[44px]"
          >
            <div className="pt-[26px] flex flex-col gap-3">
              <Typography varient={"black-head"}>
                The quality of the images captured affects success rates
                dramatically. Below are a few best practices to help ensure that
                your verification succeeds
              </Typography>
              <div>
                <ParaComp>
                  Capture a clear image. Make sure that the images are not too
                  dark or bright, and don’t have a glare. Hold steady and allow
                  your camera to focus to avoid blurry photos.
                </ParaComp>
                <ParaComp>
                  Do not block any part of your ID document in the image.
                  Ideally you can lay it flat to take the photo.
                </ParaComp>
                <ParaComp>
                  Do not block any part of your face. Remove sunglasses, masks,
                  or other accessories.
                </ParaComp>
                <ParaComp>
                  Find a location with ambient lighting. Avoid spaces with
                  strong overhead lights that cast a shadow on your face or ID
                  document. Avoid sitting directly in front of a bright light
                  which can wash out your face and add a glare to your ID
                  document.
                </ParaComp>
              </div>
            </div>
          </div>
          <div className="pt-7 lg:max-w-[240px]">
            <ButtonForDashboard
              disabled={!stripe}
              handleClick={this.handleClick}
            >
              Verify your identity
            </ButtonForDashboard>
          </div>
        </div>
      );
      // return (
      //   <>

      //     <br />
      //     <button class="button-ver" role="link" disabled={!stripe} onClick={this.handleClick}>
      //       Click here to verify your identity
      //     </button><br /><br />
      //     Before starting the verification process, here’s what you need:
      //     <br />
      //     <ul>
      //       <li>A valid government-issued photo ID document. Not a photocopy or a picture of an ID document. Ensure that the ID document is not expired.</li>
      //       <li>A device with a camera, if possible, use a mobile device. Cameras on mobile devices typically take higher-quality photos than a webcam.
      //       </li>
      //     </ul>

      //     The quality of the images captured affects success rates dramatically. Below are a few best practices to help ensure that your verification succeeds:
      //     <ul>
      //       <li>Capture a clear image. Make sure that the images are not too dark or bright, and don’t have a glare. Hold steady and allow your camera to focus to avoid blurry photos.</li>
      //       <li>Do not block any part of your ID document in the image. Ideally you can lay it flat to take the photo.</li>
      //       <li>Do not block any part of your face. Remove sunglasses, masks, or other accessories.</li>
      //       <li>Find a location with ambient lighting. Avoid spaces with strong overhead lights that cast a shadow on your face or ID document. Avoid sitting directly in front of a bright light which can wash out your face and add a glare to your ID document.</li>
      //     </ul>
      //   </>
      // );
    }

    if (
      moment(getExistingDate).isSame(exportedDate) == true &&
      stripeVerifiedStatus == "true"
    ) {
      return (
        <>
          <br />
          <br />
          <center>
            <img src="../../check.png" width="200" height="200"></img>
          </center>
          <br />
          <br />
          <h2>Your ID is verified.</h2>
          <ul>
            <li>
              We may ask you to verify your ID periodically as per the Terms and
              Conditions.
            </li>
            <li>
              Please check <a href="/faq">FAQs</a> or{" "}
              <a href="/contact-us">contact us</a> if you need any
              clarification.
            </li>
          </ul>
          <br />
          <br />
          <br />
          <br />
        </>
      );
    }

    if (sessionStorage.getItem("processing") == "true" || submitted) {
      sessionStorage.setItem("processing", "true");
      return (
        <>
          <h2>Thanks for submitting your identity document</h2>
          <ul>
            <li>We are process your documents.</li>
          </ul>
        </>
      );
    }
    return (
      <div className="flex flex-col gap-6">
        <div
          style={{
            borderRadius: "11px",
            border: "1px solid #EBEBEB",
          }}
          className="py-[26px] pl-[30px] pr-[44px]"
        >
          <Typography varient={"green-head"}>ID Verification</Typography>
          <div className="pt-[26px] flex flex-col gap-3">
            <Typography varient={"black-head"}>
              Before starting the verification process, here’s what you need
            </Typography>
            <div>
              <ParaComp>
                A valid government-issued photo ID document. Not a photocopy or
                a picture of an ID document. Ensure that the ID document is not
                expired.
              </ParaComp>
              <ParaComp>
                A device with a camera, if possible, use a mobile device.
                Cameras on mobile devices typically take higher-quality photos
                than a webcam.
              </ParaComp>
            </div>
          </div>
        </div>
        <div
          style={{
            borderRadius: "11px",
            border: "1px solid #EBEBEB",
          }}
          className="py-[26px] pl-[30px] pr-[44px]"
        >
          <div className="pt-[26px] flex flex-col gap-3">
            <Typography varient={"black-head"}>
              The quality of the images captured affects success rates
              dramatically. Below are a few best practices to help ensure that
              your verification succeeds
            </Typography>
            <div>
              <ParaComp>
                Capture a clear image. Make sure that the images are not too
                dark or bright, and don’t have a glare. Hold steady and allow
                your camera to focus to avoid blurry photos.
              </ParaComp>
              <ParaComp>
                Do not block any part of your ID document in the image. Ideally
                you can lay it flat to take the photo.
              </ParaComp>
              <ParaComp>
                Do not block any part of your face. Remove sunglasses, masks, or
                other accessories.
              </ParaComp>
              <ParaComp>
                Find a location with ambient lighting. Avoid spaces with strong
                overhead lights that cast a shadow on your face or ID document.
                Avoid sitting directly in front of a bright light which can wash
                out your face and add a glare to your ID document.
              </ParaComp>
            </div>
          </div>
        </div>
        <div className="pt-7 lg:max-w-[240px]">
          <ButtonForDashboard disabled={!stripe} handleClick={this.handleClick}>
            Verify your identity
          </ButtonForDashboard>
        </div>
      </div>
    );
    // return (
    //   <>
    //   <br/>
    //   <button class="button-ver" role="link" disabled={!stripe} onClick={this.handleClick}>
    //     Click here to verify your identity
    //   </button><br/><br/>
    //   Before starting the verification process, here’s what you need:
    //   <br/>
    //   <ul>
    //       <li>A valid government-issued photo ID document. Not a photocopy or a picture of an ID document. Ensure that the ID document is not expired.</li>
    //       <li>A device with a camera, if possible, use a mobile device. Cameras on mobile devices typically take higher-quality photos than a webcam.
    //       </li>
    //   </ul>

    //   The quality of the images captured affects success rates dramatically. Below are a few best practices to help ensure that your verification succeeds:
    //   <ul>
    //       <li>Capture a clear image. Make sure that the images are not too dark or bright, and don’t have a glare. Hold steady and allow your camera to focus to avoid blurry photos.</li>
    //       <li>Do not block any part of your ID document in the image. Ideally you can lay it flat to take the photo.</li>
    //       <li>Do not block any part of your face. Remove sunglasses, masks, or other accessories.</li>
    //       <li>Find a location with ambient lighting. Avoid spaces with strong overhead lights that cast a shadow on your face or ID document. Avoid sitting directly in front of a bright light which can wash out your face and add a glare to your ID document.</li>
    //   </ul>
    //  </>
    // );
  }
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const Verification = () => {
  return <VerifyButton stripePromise={stripePromise} />;
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || {},
    transactionsData: (state.transactions && state.transactions) || {},
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(Verification);
