import {
  SERVICES_AUTHENTICATION_AUTHENTICATE_FULFILLED,
} from './types';

export function updateAuth(payload) {
	return {
		type : SERVICES_AUTHENTICATION_AUTHENTICATE_FULFILLED,
		payload
	}
}
