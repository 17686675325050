import { Field, reduxForm } from "redux-form";
import { useEffect } from "react";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import GoogleButton from "react-google-button";

let LoginForm = (props) => {
  const { handleSubmit } = props;
  useEffect(() => {
    const script = document.createElement("script");
    const REACT_APP_CYBERSIARA_PUBLIC_KEY =
      process.env.REACT_APP_CYBERSIARA_PUBLIC_KEY;

    script.innerHTML = `
      $(function () {
        var PublicKey = "${REACT_APP_CYBERSIARA_PUBLIC_KEY}";
        InitCaptcha(PublicKey);
        $('.CaptchaSubmit').click(function () {
          if (CheckCaptcha()) {
            // console.log(CyberSiaraToken);
            // Please write your submit button click event function here //
          }
        });
      });
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          name="email"
          component="input"
          type="email"
          className="form-control input-lg"
          placeholder="Email address"
          required
        />
      </div>

      <div className="form-group mb-2">
        <Field
          name="password"
          component="input"
          type="password"
          className="form-control input-lg"
          placeholder="Password"
          required
        />
      </div>

      <div className="form-group text-right mb-0">
        <p className="mt-0">
          {" "}
          <Link to="/send-code">Forgot password?</Link>
        </p>
      </div>
      <div className="SiaraShield centered"></div>
      <div className="form-group centered mb-0">
        <button
          style={{
            borderRadius: "5px",
            backgroundColor: "#E3B576",
            display: "inline-flex",
            padding: "9px 28px",
            alignItems: "center",
            color: "white",
            gap: "21px",
            fontWeight: 500,
            border: "none", // Remove the border

            textDecoration: "none",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#006766")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#E3B576")}
          type="submit"
          className="getStarted CaptchaSubmit"
        >
          Login
        </button>
      </div>
      <div className="form-group text-center mb-0">
        <p>
          Don't have an account? <Link to="/signup">Click here to Sign Up</Link>
          <br />
          <br />
          OR
          <br />
          <br />
          <div
            className="fb-login-button"
            data-width=""
            data-size=""
            data-button-type=""
            data-layout=""
            data-auto-logout-link="true"
            data-use-continue-as="false"
          ></div>
          <GoogleButton
            style={{ width: "100%" }}
            type="light"
            onClick={(e) =>
              (window.location =
                "https://legateca.auth.eu-west-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://www.legateca.com/login&response_type=TOKEN&client_id=8ufn5npku15jjl58k5qvldg5d&scope=email openid phone profile")
            }
          />
          <button
            className="fb-button"
            onClick={(e) =>
              (window.location =
                "https://legateca.auth.eu-west-2.amazoncognito.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://www.legateca.com/login&response_type=TOKEN&client_id=8ufn5npku15jjl58k5qvldg5d&scope=email openid phone profile")
            }
          >
            Login with Facebook
          </button>
        </p>
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  return {};
}

LoginForm = compose(
  withRouter,
  reduxForm({
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    form: "login",
  }),
  connect(mapStateToProps)
)(LoginForm);

export default LoginForm;
