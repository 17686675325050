import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import PrelegateIcon from "./../../assets/images/prelegate-icon.png";
import services from "../../server/services";
import { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
import { ButtonForDashboard, Typography } from "../../Pages/Profile";

const ViewDocument = (props) => {
  useEffect(() => {
    GetUserData();
  }, []);
  const GetUserData = async () => {
    await services["users"].get(props.auth._id);
  };
  let SummaryKey = {
    "pdfKey": "https://legeteca-files.s3.us-east-2.amazonaws.com/8a132e3e-6d57-49e1-95fc-6a235a19df9f.pdf",
    "_id": "64e9eaae5c8790001ad0d42a",
    "createdAt": "2023-08-26T12:06:06.209Z"
  }

  if (props.user.firstName) {
    return <div className="flex flex-col  max-h-[620px] gap-6">

      <div style={{
        borderRadius: '11px',
        border: '1px solid #EBEBEB'
      }} className="py-[26px] pl-[30px] overflow-auto pr-[44px]">
        <div className='flex lg:flex-row flex-col gap-3 lg:gap-0 items-start justify-between'>
          <Typography varient={'green-head'}>Instant Advice</Typography>

        </div>
        {props.user.prelegatePdf.length > 0 ? (
          <div style={{
            borderRadius: '11px',
            border: '1px solid #EBEBEB'
          }} className="pt-[26px]  pl-[26px] pb-[20px] pr-[76px] mt-10 flex flex-col gap-4">
            <Typography varient={'black-head'}>
              Please download your Summary
            </Typography>
            <Typography varient={'para'}>
              Here you will find the latest report. For previous reports, please check your email.
            </Typography>
            {_.reverse(props.user.prelegatePdf).slice(0, 1).map((data) => {
              return <div className="flex justify-between items-center w-full">
                <span className='xl:max-w-[240px] xl:min-w-[240px]'>
                  <span className="text-right">
                    <DownloadButton fileId={data.pdfKey} />
                  </span>
                </span>
                <div className='flex flex-col gap-[10px] items-start'>
                  <Typography varient={'light-head'}>Created at</Typography>
                  <Typography varient={'black-head'}>
                    <span>
                      <span>{moment(data.createdAt).format("lll")}</span>
                    </span>
                  </Typography>
                </div>
              </div>
            })}

          </div>
        ) : <div className="text-primary-tw mt-[20px] text-base not-italic font-semibold leading-[118.5%] capitalize">
          Please use our prelegate service to get our free recommendation.

        </div>
        }
      </div>
      <div style={{
        borderRadius: '11px',
        border: '1px solid #EBEBEB'
      }} className="py-[26px] pl-[30px] overflow-auto pr-[44px]">
        <div className='flex lg:flex-row flex-col gap-3 lg:gap-0 items-start justify-between'>
          <Typography varient={'green-head'}>Instant Advice</Typography>

        </div>
        {props.user.recommendatePdf.length > 0 ? (
          _.reverse(props.user.recommendatePdf).map((data) => {
            return <div style={{
              borderRadius: '11px',
              border: '1px solid #EBEBEB'
            }} className="pt-[26px]  pl-[26px] pb-[20px] pr-[76px] mt-10 flex flex-col gap-4">
              <Typography varient={'black-head'}>
                Please download your Summary
              </Typography>
              <Typography varient={'para'}>
                Here you will find the latest report. For previous reports, please check your email.
              </Typography>
              <div className="flex justify-between items-center w-full">
                <span className='xl:max-w-[240px] xl:min-w-[240px]'>
                  <span className="text-right">
                    <DownloadButton fileId={data.pdfKey} />
                  </span>
                </span>
                <div className='flex flex-col gap-[10px] items-start'>
                  <Typography varient={'light-head'}>Created at</Typography>
                  <Typography varient={'black-head'}>
                    <span>
                      <span>{moment(data.createdAt).format("lll")}</span>
                    </span>
                  </Typography>
                </div>
              </div>


              {/* <div className="flex justify-between items-center w-full">
                <span className='xl:max-w-[240px] xl:min-w-[240px]'>
                  <span className="text-right">
                    <DownloadButton fileId={it.pdfKey} />
                  </span>
                </span>
                <div className='flex flex-col gap-[10px] items-start'>
                  <Typography varient={'light-head'}>Created at</Typography>
                  <Typography varient={'black-head'}>
                    <span>
                      <span>{moment(it.createdAt).format("lll")}</span>
                    </span>
                  </Typography>
                </div>
              </div> */}
            </div>
          })
        ) : <div className="text-primary-tw mt-[20px] text-base not-italic font-semibold leading-[118.5%] capitalize">

          Please use our recommendate service to get our paid
          recommendation.
        </div>
        }
      </div>

    </div>
    {/* <div style={{
        borderRadius: '11px',
        border: '1px solid #EBEBEB'
      }} className="py-[26px] pl-[30px] pr-[44px]">
        <div className='flex lg:flex-row flex-col gap-3 lg:gap-0 items-start justify-between'>
          <Typography varient={'green-head'}>Instant Advice</Typography>
          <div className='flex flex-col gap-[10px] items-start'>
            <Typography varient={'light-head'}>Created at</Typography>
            <Typography varient={'black-head'}>   {_.reverse(props.user.prelegatePdf)
              .slice(0, 1)
              .map((data) => (
                <span>
                  <span>{moment(data.createdAt).format("lll")}</span>
                </span>
              ))}</Typography>
          </div>
        </div>
        <div className="pt-[16px] flex flex-col gap-3">
          <Typography varient={'black-head'}>
            Please download your Summary
          </Typography>
          <Typography varient={'para'}>
            Here you will find all reports generated by our AI powered Legal advisor.
          </Typography>
          <div className=" xl:max-w-[240px]">
            {_.reverse(props.user.prelegatePdf)
              .slice(0, 1)
              .map((data) => (
                <span>
                  <span className="text-right">
                    <DownloadButton fileId={data.pdfKey} />
                  </span>
                </span>
              ))}
          </div>
        </div>
      </div>
    </div > */}
    // return (
    //   <div className="general_profile_tab">
    //     <div className="profile_tab_header">
    //       <h4> My Documents </h4>
    //     </div>
    //     <div className="profile_tab_content">
    //       <h3>Free Summary </h3>
    //       {props.user.prelegatePdf.length > 0 ? (
    //         <div className="appointment_list">
    //           <div className="table-responsive">
    //             <table className="table">
    //               <thead>
    //                 <tr>
    //                   <th>Created At</th>
    //                   <th className="text-right">Action</th>
    //                 </tr>
    //               </thead>
    //               <tbody>
    //                 {_.reverse(props.user.prelegatePdf)
    //                   .slice(0, 1)
    //                   .map((data) => (
    //                     <tr>
    //                       <td>{moment(data.createdAt).format("lll")}</td>
    //                       <td className="text-right">
    //                         <DownloadButton fileId={data.pdfKey} />
    //                       </td>
    //                     </tr>
    //                   ))}
    //               </tbody>
    //             </table>
    //           </div>{" "}
    //         </div>
    //       ) : (
    //         <div>
    //           Please use our prelegate service to get our free recommendation.
    //         </div>
    //       )}
    //     </div>

    //     <div className="profile_tab_content">
    //       <h3>Instant Advice</h3>
    //       {props.user.recommendatePdf.length > 0 ? (
    //         <div className="appointment_list">
    //           <div className="table-responsive">
    //             <table className="table">
    //               <thead>
    //                 <tr>
    //                   <th>Created At</th>
    //                   <th className="text-right">Action</th>
    //                 </tr>
    //               </thead>
    //               <tbody>
    //                 {_.reverse(props.user.recommendatePdf).map((data) => (
    //                   <tr>
    //                     <td>{moment(data.createdAt).format("lll")}</td>
    //                     <td className="text-right">
    //                       <DownloadButton fileId={data.pdfKey} />
    //                     </td>
    //                   </tr>
    //                 ))}
    //               </tbody>
    //             </table>
    //           </div>
    //         </div>
    //       ) : (
    //         <div>
    //           Please use our recommendate service to get our paid
    //           recommendation.
    //         </div>
    //       )}
    //     </div>
    //   </div>
    // );
  } else {
    return "";
  }
};

const mapStateToProps = (state) => {
  return {
    user: (state.users && state.users.data) || {},
    auth: (state.auth && state.auth.user) || {},
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(ViewDocument);

let DownloadButton = ({ fileId }) => {
  let [imgUrl, setImageUrl] = useState("");

  useEffect(() => {
    const downloadImage = async (fileId) => {
      try {
        let fileIdArr = fileId.split("/");
        let res = await axios.get(
          `${process.env.REACT_APP_API_URL}/file/${fileIdArr[fileIdArr.length - 1]
          }`
        );
        if (res.status === 200) {
          setImageUrl(res.data.priSignUrl);
        } else {
          throw new Error("error");
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    downloadImage(fileId);
  }, []);

  if (imgUrl) {
    return (
      <button
        id={imgUrl}
        onClick={() => {
          window.open(imgUrl).focus();
        }}
        // className="btn getStarted2 btn-small w-auto"
        className="text-white active:bg-ThemeSecondary border-none hover:bg-ThemePrimary w-full text-center px-[10px] py-[20px] rounded-md text-sm not-italic font-medium !leading-none tracking-[0.28px] uppercase bg-ThemeSecondary"
      >
        Download PDF
      </button>
    );
  } else {
    return "";
  }
};
