// import Layout from "./../Components/Layout";
// import NavigationItem from "./../Components/Homepage/NavigationItem/NavigationItem";
// import Acessible from "./../assets/images/icon-acessible.png";
// import Intuitive from "./../assets/images/icon-Intuitive.png";
// import Affordable from "./../assets/images/icon-affordable.png";
// import EndToEnd from "./../assets/images/icon-end-to-end.png";
// import AboutImg from "./../assets/images/about-img.jpg";
// import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet";

// try {
//   let prevMetaDesc = document.getElementById("baseDescription");
//   prevMetaDesc.remove();
//   //use the piece of loading state to return other component until you have the data
// } catch (error) {}

// const AboutPage = () => {
//   return (
//     <Layout>
//       <div>
// <Helmet>
//   <link rel="canonical" href="https://legateca.com/about" />
//   <meta name="robots" content="INDEX,FOLLOW" />
//   <title>About us - Legateca | Legal Advice | Lawyer | Solicitor</title>
//   <meta
//     name="title"
//     content="About us - Legateca | Legal Advice | Lawyer | Solicitor"
//   />
//   <meta
//     name="description"
//     content="Legateca is a leading legal advice firm, offering a full range of legal services to clients. We specialize in corporate and commercial law, and dispute resolution."
//   ></meta>
// </Helmet>
//       </div>
//       <div className="page-breadcumb-area">
//         <div className="container">
//           <div className="row">
//             <div className="col-12">
//               <div className="wpo-breadcumb-wrap">
//                 <h2>About Us</h2>
//                 <ul>
//                   <li>
//                     <Link to="/home">Home</Link>
//                   </li>
//                   <li>
//                     <span>&nbsp; / &nbsp;</span>
//                     <span>About</span>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <section className="sec-categories">
//         <div className="container">
//           <h4 className="sectionSubHeading">
//             Ordinary people need simple help <br />
//             with their legal troubles
//           </h4>
//           <div className="row mt-3">
//             <div className="col-lg-3 col-md-6 col-sm-6">
//               <div>
//                 <div className="aboutUscard">
//                   <div className="logo-icon">
//                     <img src={Acessible} alt="Logo icon" />
//                   </div>
//                   <h3>Accessible</h3>
//                   <p>
//                     We engage with you in an interactive and personalized
//                     manner, helping you understand where you stand, and what
//                     options are available to you.
//                   </p>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3 col-md-6 col-sm-6">
//               <div>
//                 <div className="aboutUscard">
//                   <div className="logo-icon">
//                     <img src={Intuitive} alt="Logo icon" />
//                   </div>
//                   <h3>Innovative</h3>
//                   <p>
//                     Our automatic recommendations engine uses Artificial
//                     Intelligence (AI) and is built upon precise legislative
//                     rulings and judicial precedents.
//                   </p>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3 col-md-6 col-sm-6">
//               <div>
//                 <div className="aboutUscard">
//                   <div className="logo-icon">
//                     <img src={Affordable} alt="Logo icon" />
//                   </div>
//                   <h3>Affordable</h3>
//                   <p>
//                     You have the ability to choose from SRA regulated
//                     Solicitors, Chartered Legal Executives or low cost McKenzie
//                     Friends to support you with court proceedings.
//                   </p>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3 col-md-6 col-sm-6">
//               <div>
//                 <div className="aboutUscard">
//                   <div className="logo-icon">
//                     <img src={EndToEnd} alt="Logo icon" />
//                   </div>
//                   <h3>End-to-end</h3>
//                   <p>
//                     We enable a seamless experience from issue inception to
//                     resolution. Fill our guided questionnaire, get automatic
//                     recommendations, or just book legal experts.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="row about-content">
//             <div className="col-lg-4 col-md-4 text-center">
//               <img
//                 src={AboutImg}
//                 alt="About Icon"
//                 style={{ borderRadius: "5px" }}
//               />
//             </div>
//             <div
//               className="col-lg-8 col-md-8"
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-evenly",
//                 margin: 0,
//                 padding: 0,
//               }}
//             >
//               <p style={{ padding: 0 }}>
//                 "If you do not believe that professional legal advice is
//                 affordable for ordinary people, then you are not alone.
//               </p>

//               <p style={{ padding: 0 }}>
//                 We created Legateca with the vision to use technology to make
//                 cost effective legal consulting and dispute resolution service
//                 available in the UK.
//               </p>
//               <p style={{ padding: 0 }}>
//                 It all started with the aim to address the challenge of lack of
//                 affordable Legal services; especially after 46% drop in the
//                 Legal Aid assistance post 'Legal Aid, Sentencing and Punishment
//                 of Offenders (LASPO)' Act. Legateca is aimed at bringing parity
//                 between lesser privileged and the privileged in the context of
//                 access to legal services."
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>
//     </Layout>
//   );
// };

// export default AboutPage;

import Layout from "./../Components/Layout";
import NavigationItem from "./../Components/Homepage/NavigationItem/NavigationItem";
import Acessible from "./../assets/images/icon-acessible.png";
import Intuitive from "./../assets/images/icon-Intuitive.png";
import Affordable from "./../assets/images/icon-affordable.png";
import EndToEnd from "./../assets/images/icon-end-to-end.png";
import AboutImg from "./../assets/images/about-img.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../Components/Header";
import ImageWithText from "../Components/ReUseAble/ImageWithText";
import whoWeAreImg from "../assets/images/who-we-are-img.png";
import legatecafirstlogo from "../assets/images/legatecaFirstLogo.svg";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import Swiper from "swiper";
import img1 from "../assets/images/img1.png";
import img2 from "../assets/images/img 2.png";
import img3 from "../assets/images/img3.png";
import img4 from "../assets/images/img4.png";

import { Navigation, Pagination, Autoplay } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import HelpCard from "../Components/ReUseAble/HelpCard";
import Footer from "../Components/Footer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
try {
  let prevMetaDesc = document.getElementById("baseDescription");
  prevMetaDesc.remove();
  //use the piece of loading state to return other component until you have the data
} catch (error) {}

const AboutPage = () => {
  const [showKnowMore, setShowKnowMore] = React.useState(false);

  let propsForImages = {
    showKnowMore,
    image: whoWeAreImg,
    legatecafirstlogo,
  };
  const [toggleExpansion, settoggleExpansion] = useState(0);
  const [hoveredDiv, setHoveredDiv] = useState(null);
  const [hoverFirst, sethoverFirst] = useState(true);
  const reviews = [
    {
      text: "Excellent service from the team from Legateca, especially Zakia who gave me some invaluable help",
      name: "Chris Raywood",
      date: "7 Months ago",
    },
    {
      text: "Amazing and professional work from Zakia, strongly recommend this company for their services",
      name: "Wendy",
      date: "9 Months ago",
    },
    {
      text: "Very promt, accessible almost anytime, reliable, and more importantly professional with a price that will not break your bank",
      name: "Karim Younas",
      date: "Year ago",
    },
    {
      text: "We have received an excellent service from the team at Legateca. Our business immigration case is being handled by an experienced solicitor, who understood our requirements, provided us with options and has been very supportive throughout. We would highly recommend the service.",
      name: "Huang Kifi",
      date: "Year Ago",
    },
  ];
  const valuesFortoggle = [
    "We understand that every legal matter is unique. That's why we have developed a personalised platform that caters to all your contentions. Whether you're looking to end a relationship, need guidance with nuptial agreements, facing an employment-related dispute, or have a legal business issue, we've got the right resources and expertise for you. Select your case, answer a few questions about your situation, and receive a free review. Our network of solicitors, paralegals, mediators, and McKenzie friends ensures you have a wide choice of legal advisors to contact.",
    "  Time is valuable, and we believe in providing instant advice that saves you both time and cost. With our AI-assisted Advisor, powered by the latest legal data and insights, you'll have access to accurate and up-to-date information online. Our AI advisor is currently available for divorce or separation related cases, delivering results with over 80% accuracy. We're continuously expanding our AI capabilities to cover other areas of law as well. Once you've consulted the AI advisor, you can review profiles of vetted lawyers or mediators, choose your preferred professional, and proceed with confidence.",
    "Selecting the right legal professional who can represent your case effectively is crucial. At Legateca, we provide you with a platform that offers a wide selection of verified lawyers and mediators. You can compare their profiles based on fees, expertise, experiences, and previous cases, allowing you to make an informed decision. Once you're satisfied, you can directly book a remote video consultation with your chosen legal expert. For added convenience, you can also share your documents in advance, ensuring your initial meeting is productive and efficient.",
    "When it comes to financial transactions, we prioritise your security. Our platform adheres to the highest industry standards, compliant with the Payment Card Industry Data Security Standards (PCI DSS). This means you can confidently use your cards to pay legal fees and book appointments with our experts. We understand the importance of trust, especially when it comes to paying for sessions before speaking with a professional. If you're not satisfied after a session, simply raise a dispute, and we'll investigate and help facilitate a resolution. If the outcome with the legal professional is unsatisfactory, we'll refund your payment.<a href='/terms-and-conditions' target='_blank' rel='noreferrer'> Please refer to our terms for more details.</a>",
    "With Legateca, you're always in control. We provide you with a dedicated portal where you can access stored documents, review your payments history, and manage your sessions booked with legal experts. Our portal also allows you to verify your identity, saving you time during meetings and allowing you to focus on the core issues. Should you need to book a legal advisor again, you can easily do so from our platform and continue progressing with your case seamlessly.",
  ];
  let Subheading =
    "text-[#E3B576]  text-values-align lg:text-sm 3xl:text-[30px] text-base not-italic font-medium 3xl:!leading-[130%] !leading-[45px] tracking-[0.28px] uppercase";
  let heading =
    "text-[#006766] text-values-align text-[32px] 3xl:text-[60px] lg:text-[42px] not-italic lg:font-medium font-semibold 3xl:!leading-[130%] lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase";
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://legateca.com/about" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <title>About us - Legateca | Legal Advice | Lawyer | Solicitor</title>
        <meta
          name="title"
          content="About us - Legateca | Legal Advice | Lawyer | Solicitor"
        />
        <meta
          name="description"
          content="Legateca is a leading legal advice firm, offering a full range of legal services to clients. We specialize in corporate and commercial law, and dispute resolution."
        ></meta>
      </Helmet>
      <HeroSection heading="About us" bgImg={"./aboutus-back.png"} />
      <main className="w-full ">
        <section className="xl:max-w-[1240px] 3xl:max-w-[2400px] xl:!px-0 md:!px-8 !px-4 mx-auto flex flex-col xl:gap-[100px] gap-[70px] xl:my-[100px] my-[70px]">
          <div className="w-full">
            <ImageWithText
              {...propsForImages}
              title=" Who are we?"
              to={"/faq"}
              backHidden="false"
              alt="Legateca provides personalized services to our customers"
              heading="Empower Your Legal Journey with Confidence and Ease"
              paragraph={
                <div className=" text-values-align  not-italic font-normal ">
                  <p className="!text-[#252525] 3xl:text-[36px] lg:text-sm text-base 3xl:!leading-[130%] lg:!leading-[26px] leading-[34px] tracking-[0.28px] ">
                    {" "}
                    Legal matters can be daunting, expensive, and overwhelming.
                    At Legateca, we understand the challenges you face, and
                    that's why we're here to make the legal process easy and
                    accessible for you. As a simple, budget-friendly, and
                    convenient online platform, we provide a seamless experience
                    on your journey from distress to consolation. With Legateca,
                    you can say goodbye to confusion and hello to clarity.{" "}
                  </p>
                </div>
              }
            />
          </div>
          <div
            onMouseEnter={() => sethoverFirst(false)}
            className="w-full xl:max-h-[300px] xl:min-h-[300px] 3xl:max-h-[500px] 3xl:min-h-[500px] xl:flex xl:gap-0 grid md:grid-cols-2 grid-cols-1 gap-y-5 xl:gap-x-0 gap-x-4 justify-between items-center"
          >
            {[
              {
                cardSvg: "./swap1.png",
                head: "Accessible",
                para: "We engage with you in an interactive and personalized manner, helping you understand where you stand, and what options are available to you.",
              },
              {
                cardSvg: "./idea1.png",
                head: "Innovative",
                para: "Our automatic recommendations engine uses Artificial Intelligence (AI) and is built upon precise legislative rulings and judicial precedents.",
              },
              {
                cardSvg: "./tap1.png",
                head: "Affordable",
                para: "You have the ability to choose from SRA regulated Solicitors, Chartered Legal Executives or low cost McKenzie Friends to support you with court proceedings.",
              },
              {
                cardSvg: "./agreement1.png",
                head: "End-to-end",
                para: "We enable a seamless experience from issue inception to resolution. Fill our guided questionnaire, get automatic recommendations, or just book legal experts.",
              },
            ].map((ite, id) => {
              return (
                <HeroSectionCard
                  key={id}
                  id={id}
                  hoverFirst={hoverFirst}
                  {...ite}
                  valuesFortoggle={valuesFortoggle}
                />
              );
            })}
          </div>
        </section>
        <section className="  xl:mb-[100px] mb-[70px] xl:min-h-[782px] xl:max-h-[782px] 3xl:min-h-[1282px] 3xl:max-h-[1282px] relative">
          <div className="bg-[#006766] xl:min-h-[522px] xl:max-h-[522px] 3xl:min-h-[622px] 3xl:max-h-[622px] xl:!px-0 md:!px-12 !px-10  ">
            <div className="xl:max-w-[1108px] 3xl:max-w-[2400px] xl:pt-[50px] 3xl:pt-[80px] py-[50px]  flex flex-col gap-4 items-center x3l:h-full justify-center mx-auto">
              <div className=" flex flex-col items-center">
                <div
                  className={
                    " lg:text-sm 3xl:text-[30px] text-values-align text-base not-italic font-medium 3xl:!leading-[130%] !leading-[45px] tracking-[0.28px] uppercase text-white"
                  }
                >
                  Ordinary people need simple
                </div>
                <div
                  className={
                    "text-white text-[32px]  text-values-align 3xl:text-[60px] lg:text-[42px] not-italic lg:font-medium font-semibold 3xl:!leading-[130%] lg:leading-[48px] leading-[150%] tracking-[0.84px] uppercase"
                  }
                >
                  help with their legal troubles
                </div>
              </div>
              <div className="text-white text-center 3xl:text-[30px] xl:text-sm not-italic font-normal !leading-[26px] 3xl:!leading-[130%] tracking-[0.28px]">
                <span>
                  If you do not believe that professional legal advice is
                  affordable for ordinary people, then you are not alone.
                </span>
                <div>
                  We created Legateca with the vision to use technology to make
                  cost effective legal consulting and dispute resolution service
                  available in the UK. It all started with the aim to address
                  the challenge of lack of affordable Legal services; especially
                  after 46% drop in the Legal Aid assistance post 'Legal Aid,
                  Sentencing and Punishment of Offenders (LASPO)' Act. Legateca
                  is aimed at bringing parity between lesser privileged and the
                  privileged in the context of access to legal services."
                </div>
              </div>
              <CommonButton
                variantVal="secondary-not-hovered"
                label="Contact Us"
                to="/contact-us"
              />
            </div>
          </div>
          <div className="w-full absolute hidden xl:flex bottom-0 justify-center">
            <div className=" xl:max-w-[1308px] 3xl:max-w-[2400px] xl:px-0 lg:px-8 grid md:grid-cols-2 xl:grid-cols-4 grid-cols-1 gap-5  ">
              <HelpCard
                setHoveredDiv={setHoveredDiv}
                hoveredDiv={hoveredDiv === 1}
                img={img1}
                alt="lawyers instant"
                navigateLink={"/service/prelegate"}
                extraClasses="3xl:pb-5"
                value={1}
                heading={"get started"}
                paragraph={
                  <div className="text-white text-xs 3xl:text-[40px]  md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3  ">
                    Use our "Virtual Paralegal" service for free. Instant
                    results!
                  </div>
                }
              />

              <HelpCard
                setHoveredDiv={setHoveredDiv}
                hoveredDiv={hoveredDiv === 2}
                img={img2}
                extraClasses="3xl:pb-14"
                alt="lawyers instant advice"
                navigateLink={"/service/recommendate"}
                value={2}
                heading={"Instant Advice"}
                paragraph={
                  <div className="text-white text-xs 3xl:text-[40px] md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3  ">
                    Get immediate advice from our Artificial
                    Intelligence-powered Legal Advisor!
                  </div>
                }
              />
              <HelpCard
                setHoveredDiv={setHoveredDiv}
                hoveredDiv={hoveredDiv === 3}
                img={img3}
                navigateLink={"/lawyer"}
                extraClasses="3xl:pb-5"
                alt="lawyers instant"
                value={3}
                heading={"Find a Lawyer"}
                paragraph={
                  <div className="text-white text-xs 3xl:text-[40px] md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3">
                    Find, compare, and hire services from the top lawyers out
                    there
                  </div>
                }
              />

              <HelpCard
                setHoveredDiv={setHoveredDiv}
                hoveredDiv={hoveredDiv === 4}
                img={img4}
                extraClasses="3xl:pb-24"
                alt="mediation services UK
                "
                navigateLink={"/mediate"}
                value={4}
                heading={" Mediators & Coaches"}
                paragraph={
                  <div className="text-white text-xs 3xl:text-[40px] md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3  ">
                    Get impartial third-party assistance online in resolving
                    your disputes here
                  </div>
                }
              />
            </div>
          </div>
        </section>
        <section className=" mb-[70px] xl:hidden">
          <div className=" px-10 grid sm:grid-cols-2 grid-cols-1 gap-5 ">
            <HelpCard
              setHoveredDiv={setHoveredDiv}
              hoveredDiv={hoveredDiv === 1}
              img={img1}
              alt="lawyers instant"
              navigateLink={"/service/prelegate"}
              value={1}
              heading={"get started"}
              paragraph={
                <div className="text-white text-xs 3xl:text-[40px]  md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3  ">
                  Use our "Virtual Paralegal" service for free. Instant results!
                </div>
              }
            />

            <HelpCard
              setHoveredDiv={setHoveredDiv}
              hoveredDiv={hoveredDiv === 2}
              img={img2}
              alt="lawyers instant advice"
              navigateLink={"/service/recommendate"}
              value={2}
              heading={"Instant Advice"}
              paragraph={
                <div className="text-white text-xs 3xl:text-[40px] md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3  ">
                  Get immediate advice from our Artificial Intelligence-powered
                  Legal Advisor!
                </div>
              }
            />
            <HelpCard
              setHoveredDiv={setHoveredDiv}
              hoveredDiv={hoveredDiv === 3}
              img={img3}
              navigateLink={"/lawyer"}
              alt="lawyers instant"
              value={3}
              heading={"Find a Lawyer"}
              paragraph={
                <div className="text-white text-xs 3xl:text-[40px] md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3">
                  Find, compare, and hire services from the top lawyers out
                  there
                </div>
              }
            />

            <HelpCard
              setHoveredDiv={setHoveredDiv}
              hoveredDiv={hoveredDiv === 4}
              img={img4}
              alt="mediation services UK
                "
              navigateLink={"/mediate"}
              value={4}
              heading={" Mediators & Coaches"}
              paragraph={
                <div className="text-white text-xs 3xl:text-[40px] md:text-sm not-italic font-medium 3xl:!leading-[130%] !leading-[19.5px] tracking-[0.28px] mb-3  ">
                  Get impartial third-party assistance online in resolving your
                  disputes here
                </div>
              }
            />
          </div>
        </section>

        <section className="xl:max-w-[1240px] 3xl:max-w-[2400px] xl:!px-0 md:!px-12 !px-10 mx-auto flex 3xl:justify-between xl:flex-row flex-col gap-4 xl:gap-[50px] xl:mb-[100px] mb-[70px]">
          <div className="xl:max-w-[610px] xl:min-w-[610px] 3xl:max-w-[1200px] 3xl:min-w-[1200px] flex flex-col xl:gap-[25px]">
            <div className="flex flex-col gap-[14px]">
              <div className="my-2 xl:my-0">
                <div className={Subheading}>why you should choose us</div>
                <div className={heading}>Getting Legal Solutions Made Easy</div>
                <div
                  className={
                    "text-[#252525] text-sm 3xl:text-[30px] not-italic font-normal !leading-[26px] 3xl:!leading-[130%] tracking-[0.28px]"
                  }
                >
                  Law is complicated, and conflicts can be incredibly stressful.
                  Navigating through it all on your own can feel like an uphill
                  battle. But fret not, because our team of experts is here to
                  assist you every step of the way. Whether you need small
                  business legal advice in the UK or personal legal solutions,
                  we've got you covered. Simply share your grievances with us,
                  and we'll take care of the rest.
                </div>
              </div>
            </div>
            <div className="w-full h-full">
              <img
                className="h-full w-full object-cover"
                src="./Rectangle85.png"
                alt="Rectangle85"
                loading="lazy"
              />
            </div>
          </div>
          <div className="xl:max-w-[580px] xl:mt-[20px] xl:min-w-[580px] 3xl:max-w-[1100px] 3xl:min-w-[1100px]">
            {[
              "Ability To Select Your Dispute",
              "Available AI Advisor",
              "Extensive choice of Legal Experts",
              "Payments with peace of mind",
              "Track Your Appointments",
            ].map((itm, id) => {
              return (
                <LegatecaLegaSection
                  heading={itm}
                  id={id}
                  toggleExpansion={toggleExpansion}
                  settoggleExpansion={settoggleExpansion}
                  valuesFortoggle={valuesFortoggle}
                />
              );
            })}
          </div>
        </section>
        <section className="xl:max-w-[1240px] 2xl:max-w-[1630px] 3xl:max-w-[2400px] xl:!px-0 md:!px-12 !px-10 mx-auto mb-[70px]">
          <GoogleReviews reviews={reviews} />
        </section>
      </main>
      <Footer />
    </>
  );
};

export default AboutPage;

export const LegatecaLegaSection = ({
  toggleExpansion,
  settoggleExpansion,
  id,
  heading,
  valuesFortoggle,
}) => {
  return (
    <>
      <div
        onClick={() => settoggleExpansion(id)}
        className="flex gap-4 items-center cursor-pointer"
      >
        <div className="text-[#E3B576] 3xl:text-[30px] lg:text-lg not-italic font-medium !leading-[45px] tracking-[0.36px] uppercase">
          0{id + 1}.
        </div>
        <div
          onClick={() => settoggleExpansion(id)}
          className="text-[#006766] text-lg not-italic font-semibold !leading-[22px] tracking-[0.36px] uppercase 3xl:text-[40px]   "
        >
          {heading}
        </div>
      </div>
      <hr className="bg-[#CECECE] lg:space-y-4" />
      {id === toggleExpansion && (
        <div
          dangerouslySetInnerHTML={{ __html: valuesFortoggle[toggleExpansion] }}
          className="!text-[#252525] lg:text-sm text-base 3xl:text-[30px] 3xl:!leading-[130%]  not-italic font-normal lg:!leading-[26px] !leading-[34px] tracking-[0.28px]"
        ></div>
      )}
    </>
  );
};

export const GoogleReviews = ({ reviews }) => {
  const [showFullText, setShowFullText] = useState(reviews.map(() => false));
  const swiperRef = useRef(null);
  const testimonialColors = ["#609DC5", "#F20091", "#1492E6", "#C935B6"];
  let classToBoldHeadingInSmallScreens =
    "lg:leading-[48px] leading-[150%] lg:font-medium font-semibold";

  useEffect(() => {
    swiperRef.current = new Swiper(".swiper", {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 1,
      spaceBetween: 20,
      loop: false,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
      },
      autoplay: {
        delay: 3000, // 5 seconds
        disableOnInteraction: false,
      },
    });
  }, []);
  return (
    <div>
      {/* <h2 className='text-[#E3B576] text-[14px] font-[500] tracking-[0.25px]  uppercase'> */}
      <h2 className="text-[#E3B576] lg:p-0 padding-md text-center lg:text-sm text-base  3xl:text-[30px] 3xl:!leading-[130%] not-italic font-medium !leading-[45px] tracking-[0.28px] uppercase">
        testimonials{" "}
      </h2>
      {/* <h2 className='text-[#006766] text-[42px] font-[80px] leading-[45px] tracking-[0.84px] uppercase  '> */}
      <h2
        className={`text-[#006766] lg:p-0 padding-md text-center 3xl:text-[60px] 3xl:!leading-[130%] text-[32px] lg:text-[42px] not-italic ${classToBoldHeadingInSmallScreens} mb-10 3xl:mb-20  tracking-[0.84px] uppercase `}
      >
        WHAT OUR CLIENTS SAY ABOUT US
      </h2>
      <div className="grid-swiper  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* <div className={"swiper flex  overflow-hidden"}> */}
        {/* <div className={"swiper-wrapper flex"}> */}
        {reviews.map((review, index) => (
          // <div key={index} className="text-left">
          <div key={index} className="text-left">
            <div className="shadow-md p-6 3xl:p-10 w-full h-full max-h-full ">
              <div className="flex top-0 ml-auto w-[26px] h-[26px] 3xl:w-20 3xl:h-10 ">
                {/* <img
                        className="flex ml-auto"
                        src={googleIcon}
                        alt="Free icon"
                      /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-full h-full"
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <path
                    d="M26 13.3118C26 12.4338 25.9133 11.5275 25.7688 10.6779H13.2565V15.6908H20.4229C20.1339 17.3052 19.1803 18.7213 17.7644 19.6276L22.0411 22.8846C24.5552 20.5905 26 17.2485 26 13.3118Z"
                    fill="#4280EF"
                  />
                  <path
                    d="M13.2564 26C16.8396 26 19.8449 24.8388 22.0411 22.8563L17.7643 19.6276C16.5796 20.4206 15.048 20.8737 13.2564 20.8737C9.7888 20.8737 6.87021 18.5797 5.80102 15.5209L1.40869 18.8346C3.66265 23.2245 8.22836 26 13.2564 26Z"
                    fill="#34A353"
                  />
                  <path
                    d="M5.80105 15.4926C5.25201 13.8782 5.25201 12.1223 5.80105 10.5079L1.40872 7.16593C-0.469575 10.8478 -0.469575 15.181 1.40872 18.8346L5.80105 15.4926Z"
                    fill="#F6B704"
                  />
                  <path
                    d="M13.2564 5.15508C15.1347 5.12676 16.9841 5.83481 18.3423 7.10929L22.1278 3.3708C19.7293 1.16169 16.5507 -0.0278276 13.2564 0.000494299C8.22836 0.000494299 3.66265 2.77604 1.40869 7.16594L5.80102 10.5079C6.87021 7.42083 9.7888 5.15508 13.2564 5.15508Z"
                    fill="#E54335"
                  />
                </svg>
              </div>
              {/* profile */}
              <div className="flex -mt-5">
                <div
                  className="bg-[#006766] rounded-full w-16 h-16 3xl:w-28 3xl:h-28 3xl:text-[24px] flex justify-center items-center mt-1 mr-2 text-white font-bold"
                  style={{
                    backgroundColor: testimonialColors[index],
                  }}
                >
                  {review.name[0].toUpperCase()}
                </div>
                <div className="mt-auto mb-auto text-[#959aad] font-montserrat ml-2">
                  <div className="text-[#bbb] font-montserrat text-base font-medium leading-5 tracking-tighter capitalize">
                    {review.name}
                  </div>
                  <div className=" text-[#bbb] font-montserrat text-xs font-medium leading-5 tracking-tighter capitalize">
                    {review.date}
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <svg
                  className="w-[96px] h-[16px] 3xl:w-[126px] 3xl:h-[20px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="96"
                  height="16"
                  viewBox="0 0 96 16"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.04851 0.76532C7.15523 0.436005 7.46221 0.213379 7.80782 0.213379C8.1534 0.213379 8.45937 0.436005 8.56609 0.76532L9.90478 4.89017C9.94442 5.01114 10.0217 5.11685 10.1244 5.19205C10.228 5.26727 10.353 5.30792 10.4801 5.30792L14.8174 5.30588C15.163 5.30588 15.4689 5.5285 15.5757 5.85782C15.6824 6.18616 15.5655 6.54599 15.286 6.7493L11.7771 9.29758C11.6734 9.3728 11.5962 9.47852 11.5565 9.59946C11.5169 9.72147 11.5179 9.85257 11.5565 9.97351L12.8983 14.0974C13.005 14.4267 12.8881 14.7865 12.6086 14.9898C12.3291 15.1931 11.9499 15.1931 11.6704 14.9898L8.1636 12.4395C8.0599 12.3643 7.93587 12.3236 7.80782 12.3236C7.67974 12.3236 7.55572 12.3643 7.45205 12.4395L3.94421 14.9898C3.66466 15.1931 3.28655 15.1931 3.00701 14.9898C2.72646 14.7865 2.60958 14.4267 2.71734 14.0974L4.05807 9.97351C4.09771 9.85257 4.09771 9.72147 4.05807 9.59946C4.0194 9.47852 3.94216 9.3728 3.83849 9.29758L0.329642 6.7493C0.0501015 6.54599 -0.0678185 6.18616 0.038932 5.85782C0.146659 5.5285 0.452628 5.30588 0.79824 5.30588L5.13448 5.30792C5.26256 5.30792 5.3876 5.26727 5.49026 5.19205C5.59392 5.11685 5.67116 5.01114 5.70983 4.89017L7.04851 0.76532Z"
                    fill="#F6BB06"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.0485 0.76532C27.1552 0.436005 27.4622 0.213379 27.8078 0.213379C28.1534 0.213379 28.4594 0.436005 28.5661 0.76532L29.9048 4.89017C29.9444 5.01114 30.0217 5.11685 30.1244 5.19205C30.228 5.26727 30.353 5.30792 30.4801 5.30792L34.8174 5.30588C35.163 5.30588 35.4689 5.5285 35.5757 5.85782C35.6824 6.18616 35.5655 6.54599 35.286 6.7493L31.7771 9.29758C31.6734 9.3728 31.5962 9.47852 31.5565 9.59946C31.5169 9.72147 31.5179 9.85257 31.5565 9.97351L32.8983 14.0974C33.005 14.4267 32.8881 14.7865 32.6086 14.9898C32.3291 15.1931 31.9499 15.1931 31.6704 14.9898L28.1636 12.4395C28.0599 12.3643 27.9359 12.3236 27.8078 12.3236C27.6797 12.3236 27.5557 12.3643 27.452 12.4395L23.9442 14.9898C23.6647 15.1931 23.2866 15.1931 23.007 14.9898C22.7265 14.7865 22.6096 14.4267 22.7173 14.0974L24.0581 9.97351C24.0977 9.85257 24.0977 9.72147 24.0581 9.59946C24.0194 9.47852 23.9422 9.3728 23.8385 9.29758L20.3296 6.7493C20.0501 6.54599 19.9322 6.18616 20.0389 5.85782C20.1467 5.5285 20.4526 5.30588 20.7982 5.30588L25.1345 5.30792C25.2626 5.30792 25.3876 5.26727 25.4903 5.19205C25.5939 5.11685 25.6712 5.01114 25.7098 4.89017L27.0485 0.76532Z"
                    fill="#F6BB06"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M47.0485 0.76532C47.1552 0.436005 47.4622 0.213379 47.8078 0.213379C48.1534 0.213379 48.4594 0.436005 48.5661 0.76532L49.9048 4.89017C49.9444 5.01114 50.0217 5.11685 50.1244 5.19205C50.228 5.26727 50.353 5.30792 50.4801 5.30792L54.8174 5.30588C55.163 5.30588 55.4689 5.5285 55.5757 5.85782C55.6824 6.18616 55.5655 6.54599 55.286 6.7493L51.7771 9.29758C51.6734 9.3728 51.5962 9.47852 51.5565 9.59946C51.5169 9.72147 51.5179 9.85257 51.5565 9.97351L52.8983 14.0974C53.005 14.4267 52.8881 14.7865 52.6086 14.9898C52.3291 15.1931 51.9499 15.1931 51.6704 14.9898L48.1636 12.4395C48.0599 12.3643 47.9359 12.3236 47.8078 12.3236C47.6797 12.3236 47.5557 12.3643 47.452 12.4395L43.9442 14.9898C43.6647 15.1931 43.2866 15.1931 43.007 14.9898C42.7265 14.7865 42.6096 14.4267 42.7173 14.0974L44.0581 9.97351C44.0977 9.85257 44.0977 9.72147 44.0581 9.59946C44.0194 9.47852 43.9422 9.3728 43.8385 9.29758L40.3296 6.7493C40.0501 6.54599 39.9322 6.18616 40.0389 5.85782C40.1467 5.5285 40.4526 5.30588 40.7982 5.30588L45.1345 5.30792C45.2626 5.30792 45.3876 5.26727 45.4903 5.19205C45.5939 5.11685 45.6712 5.01114 45.7098 4.89017L47.0485 0.76532Z"
                    fill="#F6BB06"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M67.0485 0.76532C67.1552 0.436005 67.4622 0.213379 67.8078 0.213379C68.1534 0.213379 68.4594 0.436005 68.5661 0.76532L69.9048 4.89017C69.9444 5.01114 70.0217 5.11685 70.1244 5.19205C70.228 5.26727 70.353 5.30792 70.4801 5.30792L74.8174 5.30588C75.163 5.30588 75.4689 5.5285 75.5757 5.85782C75.6824 6.18616 75.5655 6.54599 75.286 6.7493L71.7771 9.29758C71.6734 9.3728 71.5962 9.47852 71.5565 9.59946C71.5169 9.72147 71.5179 9.85257 71.5565 9.97351L72.8983 14.0974C73.005 14.4267 72.8881 14.7865 72.6086 14.9898C72.3291 15.1931 71.9499 15.1931 71.6704 14.9898L68.1636 12.4395C68.0599 12.3643 67.9359 12.3236 67.8078 12.3236C67.6797 12.3236 67.5557 12.3643 67.452 12.4395L63.9442 14.9898C63.6647 15.1931 63.2866 15.1931 63.007 14.9898C62.7265 14.7865 62.6096 14.4267 62.7173 14.0974L64.0581 9.97351C64.0977 9.85257 64.0977 9.72147 64.0581 9.59946C64.0194 9.47852 63.9422 9.3728 63.8385 9.29758L60.3296 6.7493C60.0501 6.54599 59.9322 6.18616 60.0389 5.85782C60.1467 5.5285 60.4526 5.30588 60.7982 5.30588L65.1345 5.30792C65.2626 5.30792 65.3876 5.26727 65.4903 5.19205C65.5939 5.11685 65.6712 5.01114 65.7098 4.89017L67.0485 0.76532Z"
                    fill="#F6BB06"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M87.0485 0.76532C87.1552 0.436005 87.4622 0.213379 87.8078 0.213379C88.1534 0.213379 88.4594 0.436005 88.5661 0.76532L89.9048 4.89017C89.9444 5.01114 90.0217 5.11685 90.1244 5.19205C90.228 5.26727 90.353 5.30792 90.4801 5.30792L94.8174 5.30588C95.163 5.30588 95.4689 5.5285 95.5757 5.85782C95.6824 6.18616 95.5655 6.54599 95.286 6.7493L91.7771 9.29758C91.6734 9.3728 91.5962 9.47852 91.5565 9.59946C91.5169 9.72147 91.5179 9.85257 91.5565 9.97351L92.8983 14.0974C93.005 14.4267 92.8881 14.7865 92.6086 14.9898C92.3291 15.1931 91.9499 15.1931 91.6704 14.9898L88.1636 12.4395C88.0599 12.3643 87.9359 12.3236 87.8078 12.3236C87.6797 12.3236 87.5557 12.3643 87.452 12.4395L83.9442 14.9898C83.6647 15.1931 83.2866 15.1931 83.007 14.9898C82.7265 14.7865 82.6096 14.4267 82.7173 14.0974L84.0581 9.97351C84.0977 9.85257 84.0977 9.72147 84.0581 9.59946C84.0194 9.47852 83.9422 9.3728 83.8385 9.29758L80.3296 6.7493C80.0501 6.54599 79.9322 6.18616 80.0389 5.85782C80.1467 5.5285 80.4526 5.30588 80.7982 5.30588L85.1345 5.30792C85.2626 5.30792 85.3876 5.26727 85.4903 5.19205C85.5939 5.11685 85.6712 5.01114 85.7098 4.89017L87.0485 0.76532Z"
                    fill="#F6BB06"
                  />
                </svg>
              </div>{" "}
              {/* <div className='text-[#252525] font-montserrat text-base font-medium leading-6 tracking-tighter mr-1 mt-4'> */}
              <div className="text-[#252525] text-sm 3xl:text-[30px] 3xl:!leading-[130%]  not-italic font-medium !leading-[26px] tracking-[0.14px] mr-1 mt-4">
                {showFullText[index]
                  ? review.text
                  : review.text.length > 100
                  ? review.text.slice(0, 100) + "..."
                  : review.text}
              </div>
              <div
                className="text-[#006766] font-montserrat 3xl:text-[24px]   text-[14px] font-[80px] leading-6 tracking-tighter mt-3 cursor-pointer"
                // on click toggle read more
                onClick={() =>
                  window.open(
                    "https://maps.app.goo.gl/w9TbTGD6PyYswc3S7",
                    "_blank"
                  )
                }
              >
                Read More
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={"grid-swiper-small swiper flex  overflow-hidden"}>
        <div className={"swiper-wrapper flex"}>
          {reviews.map((review, index) => (
            // <div key={index} className="text-left">
            <div key={index} className="swiper-slide text-left">
              <div className="shadow-md p-6 w-full h-full max-h-full ">
                <div className="flex top-0 ml-auto w-[26px] h-[26px]  3xl:w-20 3xl:h-10 ">
                  {/* <img
                          className="flex ml-auto"
                          src={googleIcon}
                          alt="Free icon"
                        /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-full h-full"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M26 13.3118C26 12.4338 25.9133 11.5275 25.7688 10.6779H13.2565V15.6908H20.4229C20.1339 17.3052 19.1803 18.7213 17.7644 19.6276L22.0411 22.8846C24.5552 20.5905 26 17.2485 26 13.3118Z"
                      fill="#4280EF"
                    />
                    <path
                      d="M13.2564 26C16.8396 26 19.8449 24.8388 22.0411 22.8563L17.7643 19.6276C16.5796 20.4206 15.048 20.8737 13.2564 20.8737C9.7888 20.8737 6.87021 18.5797 5.80102 15.5209L1.40869 18.8346C3.66265 23.2245 8.22836 26 13.2564 26Z"
                      fill="#34A353"
                    />
                    <path
                      d="M5.80105 15.4926C5.25201 13.8782 5.25201 12.1223 5.80105 10.5079L1.40872 7.16593C-0.469575 10.8478 -0.469575 15.181 1.40872 18.8346L5.80105 15.4926Z"
                      fill="#F6B704"
                    />
                    <path
                      d="M13.2564 5.15508C15.1347 5.12676 16.9841 5.83481 18.3423 7.10929L22.1278 3.3708C19.7293 1.16169 16.5507 -0.0278276 13.2564 0.000494299C8.22836 0.000494299 3.66265 2.77604 1.40869 7.16594L5.80102 10.5079C6.87021 7.42083 9.7888 5.15508 13.2564 5.15508Z"
                      fill="#E54335"
                    />
                  </svg>
                </div>
                {/* profile */}
                <div className="flex -mt-5">
                  <div
                    className="bg-[#006766] rounded-full w-16 h-16 flex justify-center items-center mt-1 mr-2 text-white font-bold"
                    style={{
                      backgroundColor: testimonialColors[index],
                    }}
                  >
                    {review.name[0].toUpperCase()}
                  </div>
                  <div className="mt-auto mb-auto text-[#959aad] font-montserrat ml-2">
                    <div className="text-[#bbb] font-montserrat text-base font-medium leading-5 tracking-tighter capitalize">
                      {review.name}
                    </div>
                    <div className=" text-[#bbb] font-montserrat text-xs font-medium leading-5 tracking-tighter capitalize">
                      {review.date}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="96"
                    height="16"
                    viewBox="0 0 96 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.04851 0.76532C7.15523 0.436005 7.46221 0.213379 7.80782 0.213379C8.1534 0.213379 8.45937 0.436005 8.56609 0.76532L9.90478 4.89017C9.94442 5.01114 10.0217 5.11685 10.1244 5.19205C10.228 5.26727 10.353 5.30792 10.4801 5.30792L14.8174 5.30588C15.163 5.30588 15.4689 5.5285 15.5757 5.85782C15.6824 6.18616 15.5655 6.54599 15.286 6.7493L11.7771 9.29758C11.6734 9.3728 11.5962 9.47852 11.5565 9.59946C11.5169 9.72147 11.5179 9.85257 11.5565 9.97351L12.8983 14.0974C13.005 14.4267 12.8881 14.7865 12.6086 14.9898C12.3291 15.1931 11.9499 15.1931 11.6704 14.9898L8.1636 12.4395C8.0599 12.3643 7.93587 12.3236 7.80782 12.3236C7.67974 12.3236 7.55572 12.3643 7.45205 12.4395L3.94421 14.9898C3.66466 15.1931 3.28655 15.1931 3.00701 14.9898C2.72646 14.7865 2.60958 14.4267 2.71734 14.0974L4.05807 9.97351C4.09771 9.85257 4.09771 9.72147 4.05807 9.59946C4.0194 9.47852 3.94216 9.3728 3.83849 9.29758L0.329642 6.7493C0.0501015 6.54599 -0.0678185 6.18616 0.038932 5.85782C0.146659 5.5285 0.452628 5.30588 0.79824 5.30588L5.13448 5.30792C5.26256 5.30792 5.3876 5.26727 5.49026 5.19205C5.59392 5.11685 5.67116 5.01114 5.70983 4.89017L7.04851 0.76532Z"
                      fill="#F6BB06"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27.0485 0.76532C27.1552 0.436005 27.4622 0.213379 27.8078 0.213379C28.1534 0.213379 28.4594 0.436005 28.5661 0.76532L29.9048 4.89017C29.9444 5.01114 30.0217 5.11685 30.1244 5.19205C30.228 5.26727 30.353 5.30792 30.4801 5.30792L34.8174 5.30588C35.163 5.30588 35.4689 5.5285 35.5757 5.85782C35.6824 6.18616 35.5655 6.54599 35.286 6.7493L31.7771 9.29758C31.6734 9.3728 31.5962 9.47852 31.5565 9.59946C31.5169 9.72147 31.5179 9.85257 31.5565 9.97351L32.8983 14.0974C33.005 14.4267 32.8881 14.7865 32.6086 14.9898C32.3291 15.1931 31.9499 15.1931 31.6704 14.9898L28.1636 12.4395C28.0599 12.3643 27.9359 12.3236 27.8078 12.3236C27.6797 12.3236 27.5557 12.3643 27.452 12.4395L23.9442 14.9898C23.6647 15.1931 23.2866 15.1931 23.007 14.9898C22.7265 14.7865 22.6096 14.4267 22.7173 14.0974L24.0581 9.97351C24.0977 9.85257 24.0977 9.72147 24.0581 9.59946C24.0194 9.47852 23.9422 9.3728 23.8385 9.29758L20.3296 6.7493C20.0501 6.54599 19.9322 6.18616 20.0389 5.85782C20.1467 5.5285 20.4526 5.30588 20.7982 5.30588L25.1345 5.30792C25.2626 5.30792 25.3876 5.26727 25.4903 5.19205C25.5939 5.11685 25.6712 5.01114 25.7098 4.89017L27.0485 0.76532Z"
                      fill="#F6BB06"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M47.0485 0.76532C47.1552 0.436005 47.4622 0.213379 47.8078 0.213379C48.1534 0.213379 48.4594 0.436005 48.5661 0.76532L49.9048 4.89017C49.9444 5.01114 50.0217 5.11685 50.1244 5.19205C50.228 5.26727 50.353 5.30792 50.4801 5.30792L54.8174 5.30588C55.163 5.30588 55.4689 5.5285 55.5757 5.85782C55.6824 6.18616 55.5655 6.54599 55.286 6.7493L51.7771 9.29758C51.6734 9.3728 51.5962 9.47852 51.5565 9.59946C51.5169 9.72147 51.5179 9.85257 51.5565 9.97351L52.8983 14.0974C53.005 14.4267 52.8881 14.7865 52.6086 14.9898C52.3291 15.1931 51.9499 15.1931 51.6704 14.9898L48.1636 12.4395C48.0599 12.3643 47.9359 12.3236 47.8078 12.3236C47.6797 12.3236 47.5557 12.3643 47.452 12.4395L43.9442 14.9898C43.6647 15.1931 43.2866 15.1931 43.007 14.9898C42.7265 14.7865 42.6096 14.4267 42.7173 14.0974L44.0581 9.97351C44.0977 9.85257 44.0977 9.72147 44.0581 9.59946C44.0194 9.47852 43.9422 9.3728 43.8385 9.29758L40.3296 6.7493C40.0501 6.54599 39.9322 6.18616 40.0389 5.85782C40.1467 5.5285 40.4526 5.30588 40.7982 5.30588L45.1345 5.30792C45.2626 5.30792 45.3876 5.26727 45.4903 5.19205C45.5939 5.11685 45.6712 5.01114 45.7098 4.89017L47.0485 0.76532Z"
                      fill="#F6BB06"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M67.0485 0.76532C67.1552 0.436005 67.4622 0.213379 67.8078 0.213379C68.1534 0.213379 68.4594 0.436005 68.5661 0.76532L69.9048 4.89017C69.9444 5.01114 70.0217 5.11685 70.1244 5.19205C70.228 5.26727 70.353 5.30792 70.4801 5.30792L74.8174 5.30588C75.163 5.30588 75.4689 5.5285 75.5757 5.85782C75.6824 6.18616 75.5655 6.54599 75.286 6.7493L71.7771 9.29758C71.6734 9.3728 71.5962 9.47852 71.5565 9.59946C71.5169 9.72147 71.5179 9.85257 71.5565 9.97351L72.8983 14.0974C73.005 14.4267 72.8881 14.7865 72.6086 14.9898C72.3291 15.1931 71.9499 15.1931 71.6704 14.9898L68.1636 12.4395C68.0599 12.3643 67.9359 12.3236 67.8078 12.3236C67.6797 12.3236 67.5557 12.3643 67.452 12.4395L63.9442 14.9898C63.6647 15.1931 63.2866 15.1931 63.007 14.9898C62.7265 14.7865 62.6096 14.4267 62.7173 14.0974L64.0581 9.97351C64.0977 9.85257 64.0977 9.72147 64.0581 9.59946C64.0194 9.47852 63.9422 9.3728 63.8385 9.29758L60.3296 6.7493C60.0501 6.54599 59.9322 6.18616 60.0389 5.85782C60.1467 5.5285 60.4526 5.30588 60.7982 5.30588L65.1345 5.30792C65.2626 5.30792 65.3876 5.26727 65.4903 5.19205C65.5939 5.11685 65.6712 5.01114 65.7098 4.89017L67.0485 0.76532Z"
                      fill="#F6BB06"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M87.0485 0.76532C87.1552 0.436005 87.4622 0.213379 87.8078 0.213379C88.1534 0.213379 88.4594 0.436005 88.5661 0.76532L89.9048 4.89017C89.9444 5.01114 90.0217 5.11685 90.1244 5.19205C90.228 5.26727 90.353 5.30792 90.4801 5.30792L94.8174 5.30588C95.163 5.30588 95.4689 5.5285 95.5757 5.85782C95.6824 6.18616 95.5655 6.54599 95.286 6.7493L91.7771 9.29758C91.6734 9.3728 91.5962 9.47852 91.5565 9.59946C91.5169 9.72147 91.5179 9.85257 91.5565 9.97351L92.8983 14.0974C93.005 14.4267 92.8881 14.7865 92.6086 14.9898C92.3291 15.1931 91.9499 15.1931 91.6704 14.9898L88.1636 12.4395C88.0599 12.3643 87.9359 12.3236 87.8078 12.3236C87.6797 12.3236 87.5557 12.3643 87.452 12.4395L83.9442 14.9898C83.6647 15.1931 83.2866 15.1931 83.007 14.9898C82.7265 14.7865 82.6096 14.4267 82.7173 14.0974L84.0581 9.97351C84.0977 9.85257 84.0977 9.72147 84.0581 9.59946C84.0194 9.47852 83.9422 9.3728 83.8385 9.29758L80.3296 6.7493C80.0501 6.54599 79.9322 6.18616 80.0389 5.85782C80.1467 5.5285 80.4526 5.30588 80.7982 5.30588L85.1345 5.30792C85.2626 5.30792 85.3876 5.26727 85.4903 5.19205C85.5939 5.11685 85.6712 5.01114 85.7098 4.89017L87.0485 0.76532Z"
                      fill="#F6BB06"
                    />
                  </svg>
                </div>{" "}
                {/* <div className='text-[#252525] font-montserrat text-base font-medium leading-6 tracking-tighter mr-1 mt-4'> */}
                <div className="text-[#252525] text-sm  not-italic font-medium !leading-[26px] tracking-[0.14px] mr-1 mt-4">
                  {showFullText[index]
                    ? review.text
                    : review.text.length > 100
                    ? review.text.slice(0, 100) + "..."
                    : review.text}
                </div>
                <div
                  className="text-[#006766] font-montserrat text-[14px] font-[80px] leading-6 tracking-tighter mt-3 cursor-pointer"
                  // on click toggle read more
                  onClick={() =>
                    window.open(
                      "https://maps.app.goo.gl/w9TbTGD6PyYswc3S7",
                      "_blank"
                    )
                  }
                >
                  Read More
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="swiper-pagination !-bottom-[7px]"></div>
      </div>
    </div>
  );
};

export const HeroSection = ({ heading, bgImg }) => {
  const router = useHistory();
  return (
    <section className="w-full h-full">
      <div className="min-h-[500px] max-h-[500px] 2xl:min-h-[600px] 2xl:max-h-[600px] 3xl:min-h-[1310px] 3xl:max-h-[1310px] background-cls">
        <Header />
        <h1
          className={` ${
            heading.includes("frequently") ||
            heading.includes("Mediators & Coaches")
              ? "md:max-w-[580px] 3xl:max-w-[880px] 3xl:leading-[130%] xl:pl-[100px] lg:pl-14 md:pr-0 px-6 pt-[160px] 2xl:pt-[210px] 3xl:pt-[550px]"
              : "xl:px-[100px] lg:px-14 px-6 pt-[160px] 2xl:pt-[210px] 3xl:pt-[550px]"
          }  text-values-align  text-white  3xl:text-[60px]  md:text-[42px] text-[32px] not-italic font-medium leading-[48px] tracking-[0.84px] uppercase `}
        >
          {heading}
        </h1>
      </div>
      <style>
        {[
          "Privacy Policy",
          "Contact us",
          "Terms & Conditions",
          "Login",
          "Email confirmation",
          "otp verification",
          "Change your Password",
          "Sign Up",
          "Mediators & Coaches",
          "My Account",
          "Get started",
          "Articles",
          "Articles Details",
          "Questionaire",
          "Lawyer Detail",
          "Instant Advice",
          "Lawyers",
        ].includes(heading) || router.location.pathname.includes("prelegate")
          ? `.background-cls {
          background-image:url(${bgImg});
background-size: cover;
background-repeat:no-repeat;
        }`
          : heading === "Partner with us" || heading === "About us"
          ? `
          @media screen and (max-width: 760px) {
            .background-cls {
              background-image:url(${bgImg});
              background-size: cover;
              background-repeat:no-repeat;
              background-position:78%
            }
          }
          @media screen and (min-width: 761px) {
            .background-cls {
              background-image:url(${bgImg});
    background-size: 100% 100%;
    background-repeat:no-repeat;
            }
          }
          `
          : `
            .background-cls {
                background: linear-gradient(69deg, rgba(227, 181, 118, 0.69) 0.44%, rgba(11, 107, 103, 0.55) 95.33%, rgba(4, 104, 102, 0.55) 99.99%, rgba(0, 103, 102, 0.55) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.34) 0%, rgba(0, 0, 0, 0.34) 100%), url(${bgImg}), lightgray 0px -30.641px / 100% 192% no-repeat;
background-size: cover;
            }
        `}
      </style>
    </section>
  );
};

export const HeroSectionCard = ({
  cardSvg,
  head,
  id,
  para,
  hoverFirst,
  page,
}) => {
  const [showFullText, setShowFullText] = useState(false);

  // Limit for truncating the text
  const truncateLimit = 100;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  return (
    <div
      className={`${
        hoverFirst && id === 0 && "first-card"
      } xl:px-5 3xl:px-10 xl:py-[44px] px-6 py-8 cursor-pointer transition-all ease-in-out duration-300 hover-borders !rounded-lg hover:border-2 h-full xl:max-w-[296px] xl:min-w-[296px] 3xl:max-w-[556px] 3xl:min-w-[556px]`}
    >
      <div className="flex flex-col">
        <img
          alt={head}
          src={cardSvg}
          className="w-[50px] h-[50px] 3xl:w-[100px] 3xl:h-[100px]"
        />
        <div
          className={`${
            page === "prelegate"
              ? "text-left "
              : "!leading-[45px] 3xl:!leading-[130%]"
          } text-[#006766] text-lg 3xl:text-[42px] not-italic font-semibold tracking-[0.36px] uppercase`}
        >
          {head}
        </div>
        <div
          className={`${
            page === "prelegate"
              ? "text-left pt-[15px]"
              : "!leading-[19.5px] 3xl:!leading-[130%]"
          } text-[#707070] text-sm 3xl:text-[30px] not-italic font-medium tracking-[0.28px]`}
          style={{
            maxHeight: showFullText ? "none" : "100px",
            overflow: showFullText ? "visible" : "hidden",
          }}
        >
          {showFullText || para.length <= truncateLimit ? (
            <>
              {para}
              {para.length > truncateLimit && (
                <span
                  className="text-[#006765] cursor-pointer hover:underline"
                  onClick={toggleText}
                >
                  {" "}
                  Show Less
                </span>
              )}
            </>
          ) : (
            <>
              {para.slice(0, truncateLimit)}...
              <span
                className="text-[#006765] cursor-pointer hover:underline"
                onClick={toggleText}
              >
                {" "}
                Show All
              </span>
            </>
          )}
        </div>
      </div>

      <style>
        {`
        .hover-borders {
          border-radius: 5px;
          border: 1px solid transparent;
        }
        
        .hover-borders:hover {
          transition: border-image 0.08s ease-in-out 0.08s;
          border-image: linear-gradient(transparent, #e3b576) 20;
        }

        .first-card {
          border-width: 2px;
          transition: border-image 0.08s ease-in-out 0.08s;
          border-image: linear-gradient(transparent, #e3b576) 20;
        }
        `}
      </style>
    </div>
  );
};

export const CommonButton = ({
  variantVal,
  disabled,
  handleClick,
  to,
  label,
}) => {
  const variant = () => {
    switch (variantVal) {
      default:
      case "primary":
        return "hover:bg-[#E3B576] bg-[#006766]";
      case "secondary":
        return "hover:bg-[#006766] bg-[#E3B576]";
      case "secondary-not-hovered":
        return "bg-[#E3B576]";
    }
  };
  const toProps = !to || to === "" ? {} : { href: to };
  return (
    <div
      style={{
        opacity: disabled ? ".4" : "",
        pointerEvents: disabled ? "none" : "",
      }}
      className="flex justify-center md:justify-start cursor-pointer font-montserrat"
    >
      <div
        onClick={handleClick}
        className={` ${variant()} px-[40px] py-[9px] 3xl:py-6 3xl:px-10 flex rounded-md 3xl:rounded-lg font-montserrat `}
      >
        <a
          {...toProps}
          className="flex no-underline "
          // href="https://legateca.com/about"
        >
          <img
            src={legatecafirstlogo}
            alt="nill"
            className="3xl:h-[66px] 3xl:w-[50px]"
          />
          <div className="my-auto ml-3 text-white   uppercase tracking-[0.28px] font-[80px] 3xl:text-[30px] text-[14px]">
            {label}
          </div>
        </a>
      </div>
    </div>
  );
};
