import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, useParams } from "react-router-dom";
import services from "../server/services";

const AutoLogin = (props) => {
  let { token } = useParams();

  useEffect(() => {
    handleAutoLogin();
  }, []);

  const handleAutoLogin = async () => {
    await services["impersonation"].get(token);
  };

  useEffect(() => {
    let {impersonationData} = props
    if(impersonationData.isFinished && impersonationData.data.data.length>0){
    }
    else{
    }
  }, [props]);

  return "";
};

const mapStateToProps = (state) => {
  return {
    impersonationData: (state.impersonation && state.impersonation) || {},
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(AutoLogin);
