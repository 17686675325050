import Layout from "../../Components/Layout";

const FormFields = () => {
  return ( 
    <Layout>
        <div className="registration_Pages">
            <div className="page_header">
                <h3 className="pageHeading">Table Fields</h3>
            </div>
            <div className="fields-form_sec form_card">
                <div className="fields_group">
                    <label>Input Text</label>
                    <input type="text" className="form-control" />
                </div>
                <div className="fields_group">
                    <label>Radio Field</label>
                    <div className="radioChoose">
                      <div className="choose_label">
                        <input type="radio" className="form-radio" /> Choose One
                      </div>
                      <div className="choose_label">
                        <input type="radio" className="form-radio" /> Choose Two
                      </div>
                      <div className="choose_label">
                        <input type="radio" className="form-radio" /> Choose Three
                      </div>
                    </div>
                </div>
                <div className="fields_group">
                    <label>Checkbox Field</label>
                    <div className="radioChoose">
                      <div className="choose_label">
                        <input type="checkbox" className="form-radio" /> Choose One
                      </div>
                      <div className="choose_label">
                        <input type="checkbox" className="form-radio" /> Choose Two
                      </div>
                      <div className="choose_label">
                        <input type="checkbox" className="form-radio" /> Choose Three
                      </div>
                    </div>
                </div>
                <div className="fields_group">
                    <label>Date Field</label>
                    <input type="date" className="form-control" />
                </div>
                <div className="fields_group">
                    <label>Date Time Local Field</label>
                    <input type="datetime-local" className="form-control" />
                </div>
                <div className="fields_group">
                    <label>Upload File Field</label>
                    <input type="file" className="form-control" />
                </div>
                <div className="fields_group">
                    <label>Phone Field</label>
                    <input type="tel" className="form-control" />
                </div> 
                <div className="fields_group">
                    <label>Time Field</label>
                    <input type="time" className="form-control" />
                </div> 
                <div className="fields_group">
                    <label>Week Field</label>
                    <input type="week" className="form-control" />
                </div> 
                <div className="fields_group">
                    <label>Search Field</label>
                    <input type="search" className="form-control" />
                </div> 
                <div className="fields_group">
                    <label>URl Field</label>
                    <input type="url" className="form-control" />
                </div> 
                <div className="fields_group">
                    <label>Month Field</label>
                    <input type="month" className="form-control" />
                </div> 
                <div className="fields_group">
                    <label>Color Field</label>
                    <input type="color" className="form-control" />
                </div> 
            </div>
        </div>
    </Layout>
   );
}
 
export default FormFields;