import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";

let LoginForm = (props) => {
  const { handleSubmit, changeTimezone, selectedTimezone } = props;
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    const REACT_APP_CYBERSIARA_PUBLIC_KEY =
      process.env.REACT_APP_CYBERSIARA_PUBLIC_KEY;

    script.innerHTML = `
      $(function () {
        var PublicKey = "${REACT_APP_CYBERSIARA_PUBLIC_KEY}";
        InitCaptcha(PublicKey);
        $('.CaptchaSubmit').click(function () {
          if (CheckCaptcha()) {
            // console.log(CyberSiaraToken);
            // Please write your submit button click event function here //
          }
        });
      });
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          name="firstName"
          component="input"
          type="text"
          className="form-control input-lg"
          placeholder="First Name"
        />
        {props.ValidationError && props.ValidationError.firstName && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.firstName}
          </div>
        )}
      </div>
      <div className="form-group">
        <Field
          name="lastName"
          component="input"
          type="text"
          className="form-control input-lg"
          placeholder="Last Name"
        />
        {props.ValidationError && props.ValidationError.lastName && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.lastName}
          </div>
        )}
      </div>
      <div className="form-group">
        <Field
          name="email"
          component="input"
          type="email"
          className="form-control input-lg"
          placeholder="Email address"
        />
        {props.ValidationError && props.ValidationError.email && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.email}
          </div>
        )}
      </div>
      <div className="form-group">
        <Field
          name="password"
          component="input"
          type="password"
          className="form-control input-lg"
          placeholder="Password"
        />
        {props.ValidationError && props.ValidationError.password && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.password}
          </div>
        )}
      </div>
      <div className="form-group">
        <Field
          name="confirmPassword"
          component="input"
          type="password"
          className="form-control input-lg"
          placeholder="Confirm Password"
        />
        {props.ValidationError && props.ValidationError.confirmPassword && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.confirmPassword}
          </div>
        )}
      </div>
      <div className="form-group">
        <TimezoneSelect
          value={selectedTimezone}
          onChange={changeTimezone}
          className="form-control input-lg"
        />
        {props.ValidationError && props.ValidationError.timezone && (
          <div
            style={{
              color: "#fb6161",
            }}
          >
            {props.ValidationError.timezone}
          </div>
        )}
      </div>

      <div>
        <input type="checkbox" id="terms" onChange={() => setTerms(!terms)} />
        <label for="terms">
          {" "}
          <Link to="/terms-and-conditions" target="_blank">
            {" "}
            Terms and Conditions
          </Link>
        </label>
        <br></br>
      </div>
      <div>
        <input
          type="checkbox"
          id="policy"
          onChange={() => setPolicy(!policy)}
        />
        <label for="policy">
          {" "}
          <Link to="/privacy-policy" target="_blank">
            {" "}
            Privacy Policy
          </Link>
        </label>
        <br></br>
        <br></br>
      </div>

      <div className="SiaraShield centered"></div>
      <div className="form-group text-center mb-0 centered">
        <button
          style={{
            borderRadius: "5px",
            backgroundColor: "#E3B576",
            display: "inline-flex",
            padding: "9px 28px",
            alignItems: "center",
            color: "white",
            gap: "21px",
            fontWeight: 500,
            border: "none", // Remove the border

            textDecoration: "none",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#006766")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#E3B576")}
          type="submit"
          className="getStarted"
          disabled={!terms || !policy}
        >
          Sign Up
        </button>
      </div>
      <div className="form-group text-center mb-0">
        <p>
          Already have an account?{" "}
          <Link to={{ pathname: "/login", state: "/home" }}>
            Click here to Login
          </Link>
        </p>
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  return {};
}

LoginForm = compose(
  withRouter,
  reduxForm({
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    form: "login",
  }),
  connect(mapStateToProps)
)(LoginForm);

export default LoginForm;
