import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import ReactPaginate from "react-paginate";
import moment from "moment";
import _ from "lodash";
import services from "../../server/services";
import { Typography } from "../../Pages/Profile";

const PaymentHistory = (props) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  let limit = 5;
  const [skip, setSkip] = useState(0);
  const [pages, setPage] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    getPaymentData();
  }, []);

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
    setSelectedPage(selected);
  };

  useEffect(() => {
    getPaymentData();
  }, [skip]);

  useEffect(() => {
    setPage(
      Math.ceil(
        props.accountancyData.queryResult &&
          props.accountancyData.queryResult.total / limit
      )
    );
  }, [props.accountancyData]);

  const getPaymentData = async () => {
    try {
      if (localStorage.getItem("idToken"))
        services["accountancy"].find({
          query: { id: props.user._id, role: "user", skip: skip, limit: limit },
        });
    } catch (error) {}
  };
  const tHeadClass =
    "text-[#252525]/40 text-start text-sm not-italic font-medium leading-[normal] tracking-[0.28px] uppercase";

  return (
    <div
      style={{
        borderRadius: "11px",
        border: "1px solid #EBEBEB",
      }}
      className="pt-[30px] lg:pl-[50px] px-[20px] lg:pr-[40px]"
    >
      <Typography varient="green-head">Payment Against Appointment</Typography>
      {/* <div className="profile_tab_header">
        <h4> Payment History </h4>
      </div> */}
      {/* <div className="profile_tab_content"> */}
      <div className="">
        {/* <div className="appointment_list"> */}
        <div className="table-responsive">
          {props.transactionsData.isLoading ? (
            <div>Laoding...</div>
          ) : props.accountancyData.queryResult &&
            props.accountancyData.queryResult.total === 0 ? (
            <div>No record found</div>
          ) : props.accountancyData.queryResult &&
            props.accountancyData.queryResult.total > 0 ? (
            <>
              <div className="flex gap-3 items-center flex-col mt-[20px]">
                {props.accountancyData.queryResult.data.map((data, index) => {
                  let totalAmount;
                  let totalAmount1;

                  if (
                    data.groupDetails[0].metaDataObject &&
                    data.groupDetails[0].paymentFor === "lawyer" &&
                    !data.groupDetails[0].metaDataObject.trailPayment
                  ) {
                    const amount = data.groupDetails[0].metaDataObject;
                    const calculatedAmount =
                      amount.totalLawyerFee + amount.totalSessionCommission;
                    const calculatedVat = calculatedAmount * amount.vat;
                    totalAmount1 = calculatedAmount + calculatedVat;
                  } else if (
                    data.groupDetails[0].metaDataObject &&
                    data.groupDetails[0].paymentFor === "lawyer" &&
                    data.groupDetails[0].metaDataObject.trailPayment
                  ) {
                    totalAmount = 0;
                  } else if (
                    data.groupDetails[0].paymentFor === "recommendate"
                  ) {
                    let amount;
                    if (data.groupDetails[0].metaDataObject.discountAmount) {
                      amount =
                        data.groupDetails[0].metaDataObject.discountAmount;
                    } else {
                      amount = data.groupDetails[0].metaDataObject.amount;
                    }
                    totalAmount = (
                      parseFloat(amount) +
                      parseFloat(
                        amount * data?.groupDetails[0]?.metaDataObject?.vat
                      )
                    ).toFixed(2);
                  }
                  return (
                    <div
                      key={index}
                      style={{ border: "1px solid #EBEBEB" }}
                      className="flex overflow-auto hover:!border-[#E3B576] scroll-cont items-center py-[30px] px-[20px] rounded-md  justify-between w-full font-montserrat"
                    >
                      <div className="flex flex-col min-w-[120px] max-w-[120px] items-start gap-[10px]">
                        <div className={tHeadClass}>Date</div>
                        <div
                          className={
                            "font-montserrat min-w-[120px] max-w-[120px] text-[#252525] text-base not-italic font-medium leading-[normal] capitalize "
                          }
                        >
                          {" "}
                          {moment(data.groupDetails[0].createdAt)
                            .format("lll")
                            .slice(0, 12)}
                        </div>
                      </div>
                      <div className="flex flex-col min-w-[150px] max-w-[150px] items-start gap-[10px]">
                        {/* <div className={tHeadClass}>Lawyer</div> */}
                        <div className={tHeadClass}>
                          {data.groupDetails[0].lawyerData[0]?.role || "Lawyer"}
                        </div>
                        <div
                          className={
                            "font-montserrat min-w-[150px] max-w-[150px] text-[#252525] text-base not-italic font-medium leading-[normal] capitalize "
                          }
                        >
                          {data.groupDetails[0].lawyerData.length
                            ? data.groupDetails[0].lawyerData[0].firstName +
                              " " +
                              data.groupDetails[0].lawyerData[0].lastName
                            : "N/A"}
                        </div>
                        {/* <div className={'font-montserrat min-w-[170px] max-w-[170px] text-[#252525] text-base not-italic font-medium leading-[normal] capitalize '}>{data.groupDetails[0].paymentFor}</div> */}
                      </div>
                      <div className="flex flex-col min-w-[140px] max-w-[140px] items-start gap-[10px]">
                        <div className={tHeadClass}>Description</div>
                        <div
                          title={
                            data.groupDetails[0].paymentFor === "lawyer"
                              ? `Payment for session booking service ${
                                  data.groupDetails[0]?.lawyerData[0]?.firstName
                                    ? "- " +
                                      data.groupDetails[0]?.lawyerData[0]
                                        ?.firstName +
                                      " " +
                                      data.groupDetails[0]?.lawyerData[0]
                                        ?.lastName
                                    : ""
                                }`
                              : "Payment for recommendation service"
                          }
                          className={
                            "font-montserrat min-w-[140px] truncate max-w-[140px] text-[#252525] text-base not-italic font-medium leading-[normal] capitalize "
                          }
                        >
                          {" "}
                          {data.groupDetails[0].paymentFor === "lawyer"
                            ? `Payment for session booking service ${
                                data.groupDetails[0]?.lawyerData[0]?.firstName
                                  ? "- " +
                                    data.groupDetails[0]?.lawyerData[0]
                                      ?.firstName +
                                    " " +
                                    data.groupDetails[0]?.lawyerData[0]
                                      ?.lastName
                                  : ""
                              }`
                            : "Payment for recommendation service"}
                        </div>
                      </div>
                      {/* <div className='flex flex-col min-w-[100px] max-w-[100px] items-start gap-[10px]'>
                          <div className={tHeadClass}>TIme slot</div>
                          <div className={'font-montserrat min-w-[100px] max-w-[100px] text-[#252525] text-base not-italic font-medium leading-[normal] capitalize '}>7:00 - 07:30 </div>
                        </div> */}
                      <div className="flex flex-col min-w-[140px] max-w-[140px] items-start gap-[10px]">
                        <div className={tHeadClass}>Fee Payment</div>
                        <div
                          className={
                            "font-montserrat min-w-[140px] max-w-[140px] text-[#252525] text-base not-italic font-medium leading-[normal] capitalize "
                          }
                        >
                          £
                          {data.groupDetails[0].paymentFor === "lawyer"
                            ? parseFloat(totalAmount1).toFixed(2)
                            : parseFloat(totalAmount).toFixed(2)}
                          (VAT INCL.)
                        </div>
                      </div>
                      {/* <div className='flex flex-col min-w-[40px] max-w-[40px] items-center gap-[10px]'>
                          <div className={`invisible ${tHeadClass}`}>eye </div>
                          <div className={'font-montserrat self-end cursor-pointer pl-2 min-w-[40px] max-w-[40px] text-[#252525] text-base not-italic font-medium leading-[normal] capitalize '}>
                            <svg width={22} height={17} viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.625 8.25C6.625 5.78906 8.62891 3.75 11.125 3.75C13.5859 3.75 15.625 5.78906 15.625 8.25C15.625 10.7461 13.5859 12.75 11.125 12.75C8.62891 12.75 6.625 10.7461 6.625 8.25ZM11.125 11.0625C12.6719 11.0625 13.9375 9.83203 13.9375 8.25C13.9375 6.70312 12.6719 5.4375 11.125 5.4375C11.0898 5.4375 11.0547 5.4375 11.0195 5.4375C11.0898 5.64844 11.125 5.82422 11.125 6C11.125 7.26562 10.1055 8.25 8.875 8.25C8.66406 8.25 8.48828 8.25 8.3125 8.17969C8.3125 8.21484 8.3125 8.25 8.3125 8.25C8.3125 9.83203 9.54297 11.0625 11.125 11.0625ZM4.33984 3.22266C5.99219 1.67578 8.27734 0.375 11.125 0.375C13.9375 0.375 16.2227 1.67578 17.875 3.22266C19.5273 4.73438 20.6172 6.5625 21.1445 7.82812C21.25 8.10938 21.25 8.42578 21.1445 8.70703C20.6172 9.9375 19.5273 11.7656 17.875 13.3125C16.2227 14.8594 13.9375 16.125 11.125 16.125C8.27734 16.125 5.99219 14.8594 4.33984 13.3125C2.6875 11.7656 1.59766 9.9375 1.07031 8.70703C0.964844 8.42578 0.964844 8.10938 1.07031 7.82812C1.59766 6.5625 2.6875 4.73438 4.33984 3.22266ZM11.125 2.0625C8.80469 2.0625 6.94141 3.11719 5.5 4.45312C4.12891 5.71875 3.21484 7.19531 2.72266 8.25C3.21484 9.30469 4.12891 10.8164 5.5 12.082C6.94141 13.418 8.80469 14.4375 11.125 14.4375C13.4102 14.4375 15.2734 13.418 16.7148 12.082C18.0859 10.8164 19 9.30469 19.4922 8.25C19 7.19531 18.0859 5.71875 16.7148 4.45312C15.2734 3.11719 13.4102 2.0625 11.125 2.0625Z" fill="#006766" />
                            </svg>


                          </div>
                        </div> */}
                      <style>{`
                    /* Style for the scroll bar */
                    .scroll-cont::-webkit-scrollbar {
                        height: 6px; /* Set the width of the scrollbar */
                    }
            
                    /* Track style */
                    .scroll-cont::-webkit-scrollbar-track {
                        background-color: #f1f1f1; /* Set the background color of the scrollbar track */
                    }
            
                    /* Handle style */
                    .scroll-cont::-webkit-scrollbar-thumb {
                        background-color: #006766; /* Set the color of the scrollbar handle */
                    }
            
                    /* Handle on hover */
                    .scroll-cont::-webkit-scrollbar-thumb:hover {
                        background-color: #555; /* Set the color of the scrollbar handle on hover */
                    }
  
                    `}</style>
                    </div>
                  );
                })}
              </div>
              {/* <>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className={tHeadClass + ' !min-w-[120px] !max-w-[120px] font-montserrat'}>Date</th>
                        <th className={tHeadClass + ' !min-w-[120px] !max-w-[120px] font-montserrat'}>Description</th>
                        <th className={tHeadClass + ' !min-w-[120px] !max-w-[120px] font-montserrat'}>Amount</th>
                        <th className={tHeadClass + ' !min-w-[120px] !max-w-[120px] font-montserrat'}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountancyData.queryResult.data.map(
                        (data, index) => {
                          let totalAmount;
                          if (
                            data.groupDetails[0].metaDataObject &&
                            data.groupDetails[0].paymentFor === "lawyer" &&
                            !data.groupDetails[0].metaDataObject.trailPayment
                          ) {
                            const amount = data.groupDetails[0].metaDataObject;
                            const calculatedAmount =
                              amount.totalLawyerFee +
                              amount.totalSessionCommission;
                            const calculatedVat = calculatedAmount * amount.vat;
                            totalAmount = Math.floor(
                              calculatedAmount + calculatedVat
                            );
                          } else if (
                            data.groupDetails[0].metaDataObject &&
                            data.groupDetails[0].paymentFor === "lawyer" &&
                            data.groupDetails[0].metaDataObject.trailPayment
                          ) {
                            totalAmount = 0;
                          } else if (
                            data.groupDetails[0].paymentFor === "recommendate"
                          ) {
                            let amount;
                            if (data.groupDetails[0].metaDataObject.discountAmount) {
                              amount = data.groupDetails[0].metaDataObject.discountAmount;
                            } else {
                              amount = data.groupDetails[0].metaDataObject.amount;
                            }
                            totalAmount = (
                              parseFloat(amount) +
                              parseFloat(
                                amount * data.groupDetails[0].metaDataObject.vat
                              )
                            ).toFixed(2);
                          }

                          return (
                            <tr key={index}>
                              <td className="font-montserrat">
                                {moment(data.groupDetails[0].createdAt).format(
                                  "lll"
                                )}
                              </td>
                              <td className="font-montserrat">

                                <span>
                                  {data.groupDetails[0].paymentFor === "lawyer"
                                    ? `Payment for session booking service ${data.groupDetails[0]?.lawyerData[0]
                                      ?.firstName
                                      ? "- " +
                                      data.groupDetails[0]?.lawyerData[0]
                                        ?.firstName +
                                      " " +
                                      data.groupDetails[0]?.lawyerData[0]
                                        ?.lastName
                                      : ""
                                    }`
                                    : "Payment for recommendation service"}
                                </span>
                              </td>
                              <td className="font-montserrat">
                                &#163;{`${totalAmount}`}</td>
                              <td className="font-montserrat">

                                <div className="appointment-status">
                                  <p className="succuss">
                                    {data.groupDetails[0].paymentObject
                                      ?.trailPayment
                                      ? "successful"
                                      : (data.groupDetails[0].paymentObject
                                        ?.paymentIntent.status === 'succeeded' ? "successful" : data.groupDetails[0].paymentObject
                                          ?.paymentIntent.status)}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>

                </> */}
            </>
          ) : (
            <>No payment History found</>
          )}
        </div>

        <div>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
      {/* <div className="payment-alert">
        <p>
          <b>Note: </b>Please update session payment other this will auto
          disabled after 3 days.
        </p>
      </div> */}
      <Modal open={open} onClose={onCloseModal} center>
        <div className="raised-Modal-form">
          <div className="modal-header">
            <h2>Raised Amount</h2>
          </div>
          <div className="modal-content">
            <form action="">
              <div className="form-group">
                <label htmlFor="lawyerName">User Name</label>
                <input
                  type="text"
                  placeholder="Enter username"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="lawyerName">Email Address </label>
                <input
                  type="text"
                  placeholder="Enter email"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="lawyerName">You Want Refund ?</label>
                <select className="form-control">
                  <option value="yes">Yes</option>
                  <option value="yes">No</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="lawyerName">Amount</label>
                <input
                  type="text"
                  placeholder="Enter Amount"
                  className="form-control"
                />
              </div>
              <div className="form-group fullWidth">
                <label htmlFor="lawyerName">Reason</label>
                <textarea
                  type="text"
                  placeholder="Write Your reasion"
                  className="form-control"
                ></textarea>
              </div>
              <div className="form-group mb-1 fullWidth text-center">
                <button type="button" className="btn getStarted w-auto">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: (state.auth && state.auth.user) || {},
    transactionsData: (state.transactions && state.transactions) || {},
    accountancyData: (state.accountancy && state.accountancy) || {},
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {})
)(PaymentHistory);
