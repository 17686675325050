import React from "react";
import FreeIcon from "./../assets/images/freeicon.png";
import PaidIcon from "./../assets/images/paidicon.png";
import NavigationItem from "./../Components/Homepage/NavigationItem/NavigationItem";
import { useSelector } from "react-redux";
import Layout from "./../Components/Layout";
import { Helmet } from "react-helmet";

const ServicesPage = () => {
  const { user } = useSelector((store) => store.auth);
  return (
    <Layout>
      <div>
        <Helmet>
          <link rel="canonical" href="https://legateca.com/services" />
          <meta name="robots" content="INDEX,FOLLOW" />
          <meta
            name="description"
            content="Legateca provides professional legal advice and services for individuals, businesses, organizations, and more. Get the best legal advice and support from us!"
          ></meta>
        </Helmet>
      </div>
      <div className="page-breadcumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="wpo-breadcumb-wrap">
                <h2>Our Services</h2>
                <ul>
                  <li>
                    <a href="/lawyem">Home</a>
                  </li>
                  <li>
                    <span>&nbsp; / &nbsp;</span>
                    <span>Our Services</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="sec-categories">
        <div className="container">
          <h2 className="sectionHeading">Our Services</h2>
          <div className="row mt-3">
            <div className="col-lg-3 col-md-6 col-sm-6">
              {user ? (
                <NavigationItem link="/service/prelegate">
                  <div className="item">
                    <div className="servicesFront">
                      <span className="status">
                        <img src={FreeIcon} alt="Free icon" />
                      </span>
                      <h3 className="service-heading">Prelegate</h3>
                      <h6>
                        A safe and secure, completely online ‘paralegal’
                        service, totally free!{" "}
                      </h6>
                    </div>
                    <div className="services-flipTap">
                      <p>
                        Provide your relevant case details as answers to our
                        guided questions. We will deliver a summary at the end,
                        presenting you with further options to explore.
                      </p>
                    </div>
                  </div>
                </NavigationItem>
              ) : (
                <NavigationItem link="/service/prelegate">
                  <div className="item">
                    <div className="servicesFront">
                      <span className="status">
                        <img src={FreeIcon} alt="Free icon" />
                      </span>
                      <h3 className="service-heading">Prelegate</h3>
                      <h6>
                        A safe and secure, completely online ‘paralegal’
                        service, totally free!{" "}
                      </h6>
                    </div>
                    <div className="services-flipTap">
                      <p>
                        Provide your relevant case details as answers to our
                        guided questions. We will deliver a summary at the end,
                        presenting you with further options to explore.
                      </p>
                    </div>
                  </div>
                </NavigationItem>
              )}
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <NavigationItem link="/service/recommendate">
                <div className="item">
                  <div className="servicesFront">
                    {/* <span className="status">
                            <img src={PaidIcon} alt="paid icon" />
                          </span> */}
                    <h3 className="service-heading">Recommendate</h3>
                    <h6>
                      Curious to see how your assets will be split on divorce?
                    </h6>
                  </div>
                  <div className="services-flipTap">
                    <p>
                      Our online calculator uses intelligent algorithms to give
                      an indication of how your property, pensions, and other
                      assets will be split on divorce.
                    </p>
                  </div>
                </div>
              </NavigationItem>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <NavigationItem link="/lawyem">
                <div className="item">
                  <div className="servicesFront">
                    {/* <span className="status"> 
                            <img src={FreeIcon} alt="Free icon" />
                          </span>  */}
                    <h3 className="service-heading">Lawyer</h3>
                    <h6>
                      Find, compare, and hire services from the best lawyers
                      here.{" "}
                    </h6>
                  </div>
                  <div className="services-flipTap">
                    <p>
                      Click here to see a catalogue of lawyers with rates, and
                      their ratings from other users. Pay, and schedule online
                      appointments through us.
                    </p>
                  </div>
                </div>
              </NavigationItem>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <NavigationItem link="/mediate">
                <div className="item">
                  <div className="servicesFront">
                    {/* <span className="status">
                            <img src={FreeIcon} alt="Free icon" />
                          </span> */}
                    <h3 className="service-heading">Mediate</h3>
                    <h6>
                      Get impartial third-party assistance online in resolving
                      your disputes here.
                    </h6>
                  </div>
                  <div className="services-flipTap">
                    <p>
                      Click here to see a catalogue of trusted mediators with
                      rates, and their ratings from other users. Pay, and
                      schedule online appointments through us.
                    </p>
                  </div>
                </div>
              </NavigationItem>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ServicesPage;
