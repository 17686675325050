import React from 'react';
import OurTeam1 from '../../assets/images/team1.jpg';
import OurTeam2 from '../../assets/images/team2.jpg';
import OurTeam3 from '../../assets/images/team3.jpg';
import OurTeam4 from '../../assets/images/team4.jpg';

const ourteam = () => {
    return(
        <article>
         <div className="page-breadcumb-area">
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                          <div className="wpo-breadcumb-wrap">
                              <h2>Our Team</h2>
                              <ul>
                                  <li><a href="/">Home</a></li>
                                  <li><span>&nbsp; / &nbsp;</span><span>Our Team</span></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        <section className="sec-out-team content_area">
            <div className="container">
               <div className="uniqueHeading">
               <h2>Meet Our Most Talented <br /> Qualified Attorneys</h2>
                </div>
                <div className="ourTeamList mt-3">
                    <div className="ourTeam-profile">
                        <span className="user-img"><img src={OurTeam1} alt="Team Member" /></span>
                        <div className="team_info">
                            <h3>Jenelia Orkid</h3>
                            <p>Family Lawyer</p>
                        </div>
                    </div>
                    <div className="ourTeam-profile">
                        <span className="user-img"><img src={OurTeam2} alt="Team Member" /></span>
                        <div className="team_info">
                            <h3>David Harry</h3>
                            <p>Criminal Lawyer</p>
                        </div>
                    </div>
                    <div className="ourTeam-profile">
                        <span className="user-img"><img src={OurTeam3} alt="Team Member" /></span>
                        <div className="team_info">
                            <h3>Darothy Jane</h3>
                            <p>Business Lawyer</p>
                        </div>
                    </div>
                    <div className="ourTeam-profile">
                        <span className="user-img"><img src={OurTeam4} alt="Team Member" /></span>
                        <div className="team_info">
                            <h3>John Albart</h3>
                            <p>Family Lawyer</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>
    );
}

export default ourteam;